import { AnyAction } from 'redux';
import CompanyService from '../../../services/company';
import SaleService from '../../../services/sale'
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Company, Files as filesType, FiscalPermission, ServiceNumber, Equipment, UnavailableFolio, EquipmentsList } from '../../../types/type';
import { initcompany } from '../../../commons/initTypes';
import {
  CompanyAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET,
  MULTICENTER,
  SET_COMPANY,
  SET_DISTRIBUTION_CENTER,
  GET_UNAVAILABLE_FOLIO,
  SET_FISCAL_PERMISSIONS,
  CREATE_FISCAL_PERMISSION,
  PAYMENT_CHANGE,
  UPDATE_FISCAL_PERMISSION,
  DELETE_FISCAL_PERMISSION,
  SET_EQUIPMENTS,
  IS_EQUIMENT_NUMBER,
  SET_COMPANY_IMAGES,
  SET_COMPANY_SALES,
  SET_EQUIPMENT_LIST
} from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { Popups } from 'commons/components/popups/popups';
import { notification } from 'antd';
import { parseApiError } from 'commons/error';

const companyService = new CompanyService();
const saleService = new SaleService();

const startFetch = (): CompanyAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, CompanyAction>;
type IDA = ThunkDispatch<{}, {}, CompanyAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): CompanyAction => {
  return { type: FETCH_COMPLETED };
};

export const getCompanyImages = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const responsepage = await companyService.getCompanyImages();
    dispatch({ type: SET_COMPANY_IMAGES, companyImagesList: responsepage });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
}

export const getCompanySales = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const isEquimentNumber = await companyService.isEquimentNumber();
    dispatch({ type: IS_EQUIMENT_NUMBER, isEquimentNumber });
    const responsepage = await companyService.getCompanySales();
    dispatch({ type: SET_COMPANY_SALES, companySalesList: responsepage });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    notification.error(parseApiError(err));
  }
}

export const page = (page: number, distCenter: boolean): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const responsepage = await companyService.companyList(page);
    dispatch({ type: SET_COMPANY, companyList: responsepage });
    if (distCenter) {
      const multicenter = await companyService.multicenter();
      dispatch({ type: MULTICENTER, multicenter });
      const isEquimentNumber = await companyService.isEquimentNumber();
      dispatch({ type: IS_EQUIMENT_NUMBER, isEquimentNumber });
    }
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(err);
  }
};

export const getCompany = (companyId: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response: Company = await companyService.getCompany(companyId);
    dispatch({ type: SET, company: response });
    const multicenter = await companyService.multicenter();
    dispatch({ type: MULTICENTER, multicenter });
    const isEquimentNumber = await companyService.isEquimentNumber();
    dispatch({ type: IS_EQUIMENT_NUMBER, isEquimentNumber });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    const paymentChange = await saleService.ispaymentChange();
    dispatch({
      type: PAYMENT_CHANGE,
      paymentChange,
    })
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(err);
  }
};
export const cleanCompany = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      dispatch({ type: SET, company: initcompany });
    } catch (err) { }
  };
};

export const saveCompany = (company: Company, files: filesType): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response: Company = await companyService.save(company, files);
    dispatch({ type: SET, company: response });
    const responsepage = await companyService.companyList(1);
    dispatch({ type: SET_COMPANY, companyList: responsepage });
    Popups.notifySuccess('Empresa guardada exitosamente');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};

export const editCompany = (company: Company, files: filesType): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const response: Company = await companyService.save(company, files);
    dispatch({ type: SET, company: response });
    const responsepage = await companyService.companyList(1);
    dispatch({ type: SET_COMPANY, companyList: responsepage });
    Popups.notifySuccess('Empresa guardada exitosamente');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    throw error;
  }
};
export const getDistributionCenter = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const multicenter = await companyService.multicenter();
    dispatch({ type: MULTICENTER, multicenter });
    const isEquimentNumber = await companyService.isEquimentNumber();
    dispatch({ type: IS_EQUIMENT_NUMBER, isEquimentNumber });
    const responseDistribution = await companyService.getDistrubutionCenter();
    dispatch({
      type: SET_DISTRIBUTION_CENTER,
      distributionCenter: responseDistribution,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(err);
  }
};
export const getUnavailableFolio = (callback: Function = (currentFolio: UnavailableFolio) => null): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (!getIsFetching(getState())) {
    dispatch(startFetch());
  }
  try {
    dispatch(startFetch());
    const responseFolio = await companyService.getUnavailableFolio();
    const currentFolio = responseFolio.find(
      responseFolio =>
        responseFolio.status === true,
    );
    callback(currentFolio);
    dispatch({
      type: GET_UNAVAILABLE_FOLIO,
      unavailableFolio: responseFolio,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(err);
  }
};

export const getFiscalPermission = (companyId: string): ITA => async (
  dispatch: IDA,
  getState
): Promise<void> => {
  try {
    dispatch(startFetch());
    const serviceNumber: Array<ServiceNumber> = await companyService.getServiceNumber(companyId);
    const newFiscalPermission = serviceNumber.map((sn) => serviceNumberToFiscalPermission(sn));
    await dispatch({
      type: SET_FISCAL_PERMISSIONS,
      fiscalPermissions: newFiscalPermission,
    });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    Popups.notifyException(error);
  }
};

export const createFiscalPermission = (fiscalPermission: FiscalPermission): ITA => async (
  dispatch: IDA,
  getState
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    const S: ServiceNumber = fiscalPermissionToServiceNumber(fiscalPermission);
    const serviceNumber: ServiceNumber = await companyService.saveServiceNumber(S);
    const newFiscalPermission = serviceNumberToFiscalPermission(serviceNumber);
    dispatch({
      type: CREATE_FISCAL_PERMISSION,
      fiscalPermission: newFiscalPermission,
    })
    Popups.notifySuccess("Se guardó el numero de permiso.");
    dispatch(endFetch());
  } catch (error) {
    Popups.notifyException(error)
    dispatch(endFetch());
  }
};

export const updateFiscalPermission = (fiscalPermission: FiscalPermission): ITA => async (
  dispatch: IDA
): Promise<void> => {
  try {

    const serviceNumber: ServiceNumber = await companyService.updateServiceNumber(
      fiscalPermissionToServiceNumber(fiscalPermission)
    );

    const newFiscalPermission = serviceNumberToFiscalPermission(serviceNumber);
    let folioError : boolean = false;
    if(newFiscalPermission.startFolio !== fiscalPermission.startFolio){
      folioError = true;
      Popups.notifyError("El folio no pudo actualizarse, debe ser mayor a ".concat((newFiscalPermission.startFolio+1).toString()));
    }
    await dispatch({
      type: UPDATE_FISCAL_PERMISSION,
      fiscalPermission: newFiscalPermission,
    })
    if(folioError){
      Popups.notifySuccess("Los demas campos del Número de Permiso fueron actualizados.");
    }else{
      Popups.notifySuccess("Se actualizó.");
    }
    
  } catch (error) {
    Popups.notifyException(error);
  }
};

export const deleteFiscalPermission = (id: string): ITA => async (
  dispatch: IDA
): Promise<void> => {
  try {
    await companyService.deleteServiceNumber(id);
    await dispatch({
      type: DELETE_FISCAL_PERMISSION,
      id,
    })
    Popups.notifySuccess("Se eliminó.");
  } catch (error) {
    Popups.notifyException(error);
  }
};


export const getEquipmentsNumbers = (): ITA => async (
  dispatch: IDA
): Promise<void> => {
  try {
    dispatch(startFetch());
    const equipments: Array<Equipment> = await companyService.getEquipmentsNumbers();
    await dispatch({
      type: SET_EQUIPMENTS,
      equipments,
    });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    Popups.notifyException(error);
  }
};

export const setFiscalPermissions = (fiscalPermissions: Array<FiscalPermission>): ITA => async (
  dispatch: IDA
): Promise<void> => {
  dispatch({
    type: SET_FISCAL_PERMISSIONS,
    fiscalPermissions,
  });
};

export const setEquipmentList = (equipmentList: EquipmentsList): ITA => async (
  dispatch: IDA
): Promise<void> => {
  dispatch({
    type: SET_EQUIPMENT_LIST,
    equipmentList,
  });
};

const fiscalPermissionToServiceNumber = (fiscalPermission: FiscalPermission): ServiceNumber => {
  const equipment: string = fiscalPermission.equipments.reduce((prev, current) => prev += !prev ? current.id : "," + current.id, "")
  return {
    equipment,
    id: fiscalPermission.id,
    lastFolio: fiscalPermission.lastFolio,
    status: fiscalPermission.status,
    serie: fiscalPermission.serie,
    startFolio: fiscalPermission.startFolio,
    numberPermission: fiscalPermission.numberPermission,
    companyId: fiscalPermission.companyId,
    idInvoice: fiscalPermission.idInvoice,
    equipmentList: fiscalPermission.equipmentList
  }
}

export const serviceNumberToFiscalPermission = (serviceNumber: ServiceNumber): FiscalPermission => {

  const equipments: Array<Equipment> = serviceNumber.equipment
    ? serviceNumber.equipment.split(",").map(id => ({
      id,
      equipmentNumber: "",
      applicationType: "",
      distributionCenterId: "",
      tag: "",
    }))
    : [];

  return {
    equipments,
    editable: false,
    id: serviceNumber.id,
    isNew: false,
    lastFolio: serviceNumber.lastFolio,
    numberPermission: serviceNumber.numberPermission,
    serie: serviceNumber.serie,
    startFolio: serviceNumber.startFolio,
    status: serviceNumber.status,
    companyId: serviceNumber.companyId,
    idInvoice: serviceNumber.idInvoice,
    equipmentList: serviceNumber.equipmentList,
  }
}
