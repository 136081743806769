import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Button,
  Col,
  Pagination,
  Form,
  Switch,
  Table,
  Divider,
  Popover,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import './style.css';
import {
  page,
  saveuser,
  desactivateUserById,
  activateUserById,
  trysendpassword,
} from './redux/actions';
import { UsersListI, UserSavedI } from './redux/reducers';
import { RootState } from '../../store/configureStore';
import { User as usertype, Permission, RecordUser } from '../../types/type';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { isAllowed } from 'security/index';
import { Permissions as P } from 'commons/permissions';
import { Redirect } from 'react-router';

interface DispatchProps {
  page: (page: number) => void;
  saveuser: (values: usertype) => void;
  desactivateUserById: (values: string) => void;
  activateUserById: (values: string) => void;
  trysendpassword: (values: string) => void;
}
interface StateProps {
  usersList: UsersListI;
  permissionList: Permission[];
  usersaved: UserSavedI;
}
type Props = FormComponentProps & StateProps & DispatchProps;

class Users extends Component<Props> {
  public state = {
    data: [],
    valor: 0,
    paginationenable: true,
    visible: false,
  };

  public componentWillMount(): void {
    this.props.page(1);
  }

  public handlePaginationChange = (current: number): void => {
    this.props.page(current);
    this.setState({ valor: current });
  };

  public showModal = (): void => {
    this.setState({ visible: true });
  };

  public showPermissionName = (number: string): string => {
    switch (number) {
      case '1':
        return 'Ver Permisos Administrador';
      case '2':
        return 'Ver Permisos Gasera Facturación';
      case '3':
        return 'Ver Permisos Gasera ventas';
      case '4':
        return 'Ver Permisos Cliente';
      case '5':
        return 'Ver Permisos Revisor';
      default:
        return 'Sin permisos';
    }
  };
  public handeChangeEditingValue = (
    row: { id: string },
    isActive: boolean,
  ): void => {
    const { data } = this.state;
    if (data.length > 0) {
      const newData: { id: string; isActive: boolean } = data.filter(
        (dataRow: { id: string; isActive: boolean }): boolean =>
          dataRow.id === row.id,
      )[0];

      newData.isActive = !isActive;
      const filterContent = data.map(
        (dataRow: { id: string }): { id: string } => {
          if (dataRow.id === row.id) {
            return newData;
          }
          return dataRow;
        },
      );

      this.setState({ data: [...filterContent] });
    }
    this.handleActivateOrDeactivateUser(row.id, isActive);
  };
  public handleActivateOrDeactivateUser = (
    userId: string,
    isActive: boolean,
  ): void => {
    if (isActive) this.props.desactivateUserById(userId);
    else this.props.activateUserById(userId);
  };

  public handleTrysendpassword = (email: string): void => {
    this.props.trysendpassword(email);
  };

  private renderTableColumns = (): any => [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (text: string): JSX.Element => (
        <Row>
          <label className="label">{text}</label>
        </Row>
      ),
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Puesto',
      dataIndex: 'position',
      key: 'position',
      render: (text: string, record: RecordUser): JSX.Element => {
        const permission: Permission[] = record.userPermission;

        return !isEmpty(text) ? (
          <Popover
            content={
              <ul>
                {permission.filter((p: Permission) => p.permissionName !== 'CAN_VIEW_REVISOR')
                  .map((permission: Permission): JSX.Element => (
                    <li key={permission.id}>{permission.name}</li>
                  ),
                  )}
              </ul>
            }
            placement="bottom"
            title="Permisos"
            trigger="click"
            style={{
              height: '10rem',
              width: '10rem',
            }}
          >
            <Button>{this.showPermissionName(text)}</Button>
          </Popover>
        ) : (
            <div>Sin permisos</div>
          );
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, item: any): JSX.Element => (
        <Row>
          <Link
            key="3"
            to={{ pathname: `/users/newUser/${item.id}` }}
          >
            Editar
          </Link>
          <Divider type="vertical" />
          <button
            key="resend"
            type="button"
            className="link-button"
            onClick={(): void => this.handleTrysendpassword(item.email)}
          >
            ReEnviar
          </button>
          <Divider type="vertical" />
          <Switch
            key="1"
            disabled={!item.id}
            checked={item.isActive}
            checkedChildren="Activo"
            unCheckedChildren="Inactivo"
            onChange={(): void =>
              this.handeChangeEditingValue({ id: item.id }, item.isActive)
            }
          />
        </Row>
      ),
    },
  ];

  public render = (): JSX.Element => {
    if (!isAllowed([P.CAN_VIEW_USERS])) {
      return <Redirect to="/home" />
    }
    return (
      <Row style={{ backgroundColor: '#E5E5E5' }}>
        <Col
          style={{
            backgroundColor: '#F1F7FF',
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 24,
          }}
        >
          <Link
            key="3"
            to={{ pathname: '/users/newUser/0' }}
          >
            <Button
              type="primary"
              className="button-new"
              size="small"
              icon="plus"
              id="user_new"
            >
              Agregar usuario
            </Button>
          </Link>
        </Col>
        <Row
          style={{ marginLeft: 24, marginRight: 24, backgroundColor: '#fff' }}
        >
          <Col span={24}>
            <Table
              dataSource={this.props.usersList.content}
              columns={this.renderTableColumns()}
              pagination={false}
            />
          </Col>
        </Row>
        <Pagination
          current={(this.props.usersList.number || 0) + 1}
          total={this.props.usersList.totalElements}
          onChange={this.handlePaginationChange}
          style={{ marginLeft: 24, marginTop: 24 }}
        />
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    usersList: states.usersState.usersList,
    permissionList: states.usersState.permissionList,
    usersaved: states.usersState.usersSave,
  };
};

const mapDispatchToProps = {
  page,
  saveuser,
  activateUserById,
  desactivateUserById,
  trysendpassword,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Users));
