export const iusRule = [
  {
    required: true,
    max: 24,
    min: 24,
    message: 'Ingresa un ius valido',
  },
];
export const totalRule = [
  {
    required: true,
    message: 'Ingresa un monto',
  },
];
