/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Brandon Leon <brandon@ssf.com.mx>
 *
 */
import { RevisionTotales } from 'types/type';

/*
 * Action to add a sale to list component
 */
export const SET_TOTALS = 'SET_TOTALS';
export const CLEAN = 'CLEAN';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

/*
 *SET INITIAL DATE
 */
export const SET_INIT_DATE_REVISOR = 'SET_INIT_DATE_REVISOR';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}

interface SetTotalsAction {
  type: typeof SET_TOTALS;
  totals: RevisionTotales;
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetInitialDateRevisor {
  type: typeof SET_INIT_DATE_REVISOR;
  initDateRevisor: Date;
}

interface CLEAN_REVISION {
  type: typeof CLEAN;
}

export type RevisionAction =
  | BeginFetchAction
  | SetTotalsAction
  | EndFetchAction
  | SetInitialDateRevisor
  | CLEAN_REVISION;