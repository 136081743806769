import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RootState } from 'store/configureStore';
import { User, Permission, Customer } from 'types/type';
import { RouteComponentProps } from 'react-router';
import {
  getUserAction,
  getPermissions,
  cleanRedux,
  saveuser,
  getCustomer,
} from '../redux/actions';
import { UserSavedI } from '../redux/reducers';
import { isEmpty } from 'lodash';
import { Input, Select, Button, Row, Col, Modal } from 'antd';
import { emailPattern } from 'commons/regularExpressions';
import { Permissions } from '../../../commons/permissions';
import { Popups } from 'commons/components/popups/popups';

interface HomeRouterProps {
  id: string;
}

interface DispatchProps {
  getUserAction: (id: string) => void;
  getPermissions: () => void;
  cleanRedux: () => void;
  saveuser: (user: User) => void;
  getCustomer: (code: string) => void;
}

interface StateProps {
  user: UserSavedI;
  permissionList: Permission[];
  customer: Customer;
}

type Props = DispatchProps &
  StateProps &
  RouteComponentProps<HomeRouterProps> &
  FormComponentProps;

class newUsers extends Component<Props> {
  public componentWillMount() {
    const { id } = this.props.match.params;
    if (id !== '0') this.props.getUserAction(id);
    else this.props.cleanRedux();
    this.props.getPermissions();
  }

  public componentWillUnmount() {
    this.props.cleanRedux();
    this.props.form.resetFields();
  }

  public state = {
    isClient: false,
    visible: false,
    clientCode: '',
    isValidUser: true,
  };

  public permissionFilter(permissions: string[]): string[] {
    const { permissionList } = this.props;
    let permissionsFilter: string[] = [];
    let c: Permission;
    permissions.forEach((permissionName: string) => {
      c = permissionList.filter(
        (permission: Permission): boolean =>
          permission.permissionName === permissionName,
      )[0];
      permissionsFilter.push(c.id);
    });
    return permissionsFilter;
  }

  private setErrorMessage(): Record<string, any> {
    return {
      response: {
        status: 400,
        data: {
          message: 'El cliente no es valido',
        },
      },
    };
  }

  public saveUser() {
    const {
      form,
      permissionList,
      user: { usersaved },
    } = this.props;
    let isClient = false;
    try {
      if (!this.state.isValidUser) throw this.setErrorMessage();
      form.validateFields((err, values: User): void => {
        if (!err) {
          switch (values.position) {
            case '1':
              const permissionId = permissionList
                .filter(
                  (p: Permission): boolean =>
                    p.permissionName !== Permissions.CAN_VIEW_REVISOR,
                )
                .filter(
                  (p: Permission): boolean =>
                    p.permissionName !== Permissions.CAN_VIEW_SUPPORT
                )
                .map((permission: Permission): string => permission.id);
              values.userPermission = [];
              values.userPermission = permissionId;
              break;
            case '2':
              values.userPermission = [];
              values.userPermission = this.permissionFilter([
                Permissions.CAN_VIEW_SALES,
                Permissions.CAN_CREATE_INVOICES,
                Permissions.CAN_SET_BILLABLE,
              ]);
              break;
            case '3':
              values.userPermission = [];
              values.userPermission = this.permissionFilter([
                Permissions.CAN_VIEW_SALES,
                Permissions.CAN_CREATE_INVOICES,
              ]);
              break;
            case '4':
              values.userPermission = [];
              values.userPermission = this.permissionFilter([
                Permissions.CAN_VIEW_SALES,
                Permissions.CAN_CREATE_INVOICES,
              ]);
              isClient = true;
              break;
            case '5':
              values.userPermission = [];
              values.userPermission = this.permissionFilter([
                Permissions.CAN_VIEW_SALES,
                Permissions.CAN_CREATE_INVOICES,
                Permissions.CAN_SET_BILLABLE,
                Permissions.CAN_VIEW_REVISOR,
              ]);
              break;
            default:
              values.userPermission = [];
          }
          if (isEmpty(usersaved.id))
            this.props.saveuser({ ...values, isClient });
          else {
            usersaved.userPermission = [];
            this.props.saveuser({
              ...usersaved,
              ...values,
              isClient: isClient,
            });
          }
          form.resetFields();
          this.props.cleanRedux();
          this.props.history.push('/users');
        }
      });
    } catch (error) {
      Popups.notifyException(error);
    }
  }

  public render = (): JSX.Element => {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const user = this.props.user.usersaved;
    const customer = this.props.customer;
    return (
      <Row
        style={{
          display: 'flex',
          backgroundColor: '#fff',
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
          padding: 50,
        }}
      >
        <Col style={{ flex: 0.3 }}>
          <span className="label">Información del usuario</span>
        </Col>
        <Col style={{ flex: 0.7 }}>
          <Form layout="vertical">
            <Modal
              visible={!isEmpty(customer) && this.state.visible}
              title="Verificar Usuario"
              okText="Ok"
              cancelButtonProps={{ hidden: true }}
              onOk={() => this.setState({ visible: false, isValidUser: true })}
            >
              <Row>Verifica que el cliente sea el que quieres agregar</Row>
              <Row>Email: {customer.email}</Row>
              <Row>Nombre: {customer.name}</Row>
            </Modal>
            <Row style={{ display: 'flex' }}>
              <Col style={{ flex: 1 }}>
                <Form.Item
                  label={this.state.isClient ? 'Usuario' : 'Puesto'}
                  id="puesto"
                >
                  {getFieldDecorator('position', {
                    initialValue: user.position,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecciona una posición!',
                      },
                    ],
                  })(
                    <Select
                      disabled={user.position === '4'}
                      defaultActiveFirstOption
                      onChange={(value: string): void =>
                        this.setState({
                          isClient: value === '4' ? true : false,
                        })
                      }
                    >
                      <Select.Option value="">
                        Selecciona una opción
                      </Select.Option>
                      <Select.Option value="1"> Administrador </Select.Option>
                      <Select.Option value="2"> Gasera Facturación </Select.Option>
                      <Select.Option value="3"> Gasera ventas </Select.Option>
                      <Select.Option value="4"> Cliente </Select.Option>
                      <Select.Option value="5"> Revisor </Select.Option>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Nombre" id="name" style={{ color: '#fff' }}>
                  {getFieldDecorator('name', {
                    initialValue: customer.name || user.name,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor ingrese un nombre!',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col style={{ flex: 1, paddingLeft: 10 }}>
                <Row style={{ flex: 1 }}>
                  {(this.state.isClient || user.position === '4') && (
                    <Form.Item
                      style={{ width: '100%' }}
                      label="Código Cliente G4S"
                      id="cliente"
                    >
                      {getFieldDecorator('clientCode', {
                        initialValue: Number(user.clientCode) || '',
                        rules: [
                          {
                            required: this.state.isClient,
                            message: 'El código del cliente es requerido',
                          },
                        ],
                      })(
                        <Input.Search
                          style={{ flex: 1, width: '100%' }}
                          size="large"
                          placeholder="Ingresa el código de cliente de G4S"
                          onChange={() => this.setState({ isValidUser: false })}
                          onSearch={(val: string | undefined) => {
                            this.props.getCustomer(
                              val !== undefined ? val : '',
                            );
                            this.setState({
                              visible: val !== undefined ? true : false,
                            });
                          }}
                        />,
                      )}
                    </Form.Item>
                  )}
                </Row>
                <Form.Item style={{ flex: 1 }} label="Correo" id="email">
                  {getFieldDecorator('email', {
                    initialValue: customer.email || user.email,
                    rules: [
                      {
                        pattern: emailPattern,
                        required: true,
                        message: 'Por favor ingrese un correo valido!',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Button onClick={() => this.saveUser()}>
              {user.id ? 'Editar' : 'Nuevo'}
            </Button>
            <Button onClick={() => this.props.history.push('/users')}>
              Cancelar
            </Button>
          </Form>
        </Col>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    user: states.usersState.usersSave,
    permissionList: states.usersState.permissionList,
    customer: states.usersState.customer,
  };
};

const mapDispatchToProps = {
  getUserAction,
  getPermissions,
  cleanRedux,
  saveuser,
  getCustomer,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(newUsers));
