import React, { Component } from 'react';
import { Card, Form, Input, Button } from 'antd';
import { emailPattern } from './../../commons/regularExpressions';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { passwordrecovery } from './../users/redux/actions';
import './style.css';
import { FormComponentProps } from 'antd/lib/form';
import logo from './../../assets/logo.png';
import { Redirect } from 'react-router';
import { UserOutlined } from '@ant-design/icons';

interface DispatchProps {
  passwordrecovery: (values: string) => void;
}

type Props = FormComponentProps & DispatchProps;

class ForgotPassword extends Component<Props> {
  public state = {};

  private handleOnSubmit = (): void => {
    this.props.form.validateFieldsAndScroll(
      (err: any, values: any): void => {
        if (!err) {
          this.props.passwordrecovery(values.email);
        }
      },
    );
  };

  public render = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
    } = this.props;

    if (localStorage.getItem('token')) return <Redirect to="/" />;
    return (
      <Card className="password-card" style={{ textAlign: 'center' }}>
        <img className="side-logo" src={logo} alt="company_logo" />
        <h1>¿Olvidaste tu contraseña?</h1>
        <h3>
          Te podemos ayudar a restablecer tu contraseña. Primero, ingresa tu
          email y te enviaremos un correo para restablecerla.
        </h3>
        <br />

        <Form className="login-form">
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { pattern: emailPattern, message: 'Ingresa un email valido' },
              ],
            })(
              <Input
                size="large"
                prefix={<UserOutlined />}
                placeholder="Email"
              />,
            )}
          </Form.Item>
          <Button className="full-width" type="primary" onClick={() => this.handleOnSubmit()}>
            Enviar
          </Button>
          <Button className="full-width" id="restart_button">
            <a className="forgot-password-link float-right" href="/login">
              Cancelar
            </a>
          </Button>
        </Form>
      </Card>
    );
  };
}
const mapStateToProps = (): {} => {
  return {};
};

const mapDispatchToProps = {
  passwordrecovery,
};

export default connect<{}, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(ForgotPassword));
