/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Brandon Leon <brandon@ssf.com.mx>
 *
 */

import { combineReducers, Reducer } from 'redux';
import { RevisionTotales } from 'types/type';
import { RootState } from 'store/configureStore';
import { BEGIN_FETCH, FETCH_COMPLETED, RevisionAction, SET_INIT_DATE_REVISOR, SET_TOTALS } from './types';
import moment, { Moment } from 'moment';

export interface RevisionState {
  readonly isFetching: boolean;
  readonly initDateRevisor: Moment;
  readonly totals: RevisionTotales;
}


const isFetching: Reducer<boolean> = (
  state = false,
  action: RevisionAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

export const initDateRevisor: Reducer<Date> = (
  state = moment().toDate(),
  action,
): Date => {
  switch (action.type) {
    case SET_INIT_DATE_REVISOR:
      return action.initDateRevisor;
    default:
      return state;
  }
};

export const getIsFetching = (store: RootState): boolean =>
  store.revisorSate.isFetching;

export const totals: Reducer<RevisionTotales> = (
  state: RevisionTotales = { total_day: 0, litters_day: 0, half_day: 0, half_litters: 0, csv: '', percent: 0, readed: false, litters_ret: 0, total_ret: 0, total_ventas: 0, totalCortes: 0, useCut: false, total_ventas_ret: 0 },
  action,
): RevisionTotales => {
  switch (action.type) {
    case SET_TOTALS:
      return action.totals;
    default:
      return state;
  }
}

export default combineReducers<RevisionState>({
  initDateRevisor,
  isFetching,
  totals,
});
