import React from 'react';
import { Layout, Menu, Spin, Col, Row, Button } from 'antd';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import './styles.css';
import styles from './styles';
import * as H from 'history';
import { MenuItem, SubmenuItems, Settings } from '../../../../types/type';
import logoExpanded from '../../../../assets/logo_login.png';
import logoNotExpanded from '../../../../assets/icono_sicom.svg';
import { isAllowed, getToken, Token, isAuthenticated, getDecodeToken } from 'security/index';
import { isEmpty } from 'lodash';
import Revisor from 'routes/revisor';
import { DownCircleOutlined, LoadingOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getPrivacyUrl } from 'routes/configuration/redux/actions';

interface PathParamsType {
  param1: string;
}
const { Content, Footer, Sider, Header } = Layout;
type Props = RouteComponentProps<PathParamsType> & {
  menu: MenuItem[];
  loading?: boolean;
  location: H.Location;
};
class MainLayout extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    const self = this;
    window.addEventListener('resize', this.update);
    document.onkeydown = function (e) {
      if (e.ctrlKey && e.altKey && e.key === 'y') {
        self.setVisibleHiddenView();
      }
    };
  }

  componentDidMount() {
    this.update();
    this.fetchPrivacyUrl();
  }

  setVisibleHiddenView = () => this.setState({ hiddenView: !this.state.hiddenView });

  update = () => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  
  };

  // Function that helps persist the menu focus after a refresh.
  private getSubMenuNameFromURL = (pathname: string): string[] => {
    const stringWithOutFirstSlash = pathname.slice(1);

    // Returns an array with just one item.
    return stringWithOutFirstSlash.indexOf('/') === -1
      ? [stringWithOutFirstSlash]
      : [
        stringWithOutFirstSlash.substring(
          0,
          stringWithOutFirstSlash.indexOf('/'),
        ),
      ];
  };

  private validateUser = (item: MenuItem): boolean => {
    const token: Token = getDecodeToken();
    if (!isEmpty(token.customerCode) && item.client) {
      if (item.key === 'salesUser') return false;
      return true;
    }
    if (!isEmpty(token.customerCode) && item.client === false) {
      if (item.key === 'salesDashboard') return true;
    }
    if (isEmpty(token.customerCode) && token.isClient) {
      if (item.key === 'salesDashboard') return false;
    }
    return item.client === token.isClient;
  }

  private fetchPrivacyUrl = (): void =>{
    getPrivacyUrl().then((value: Settings|undefined)=>{
      this.setState({
        urlPrivacy: value?.privacityUrl,
      });
    }).catch(()=>{
      console.log("Oh rayos");
    });
  }

  private renderMenuItem = (item: MenuItem): JSX.Element => {
    if (!isAllowed(item.permissions)) return <></>;
    if (item.visible || this.validateUser(item)) {
      if (item.subMenu) {
        const subMenuItem = item.subMenu;
        if (item.key === 'cuenta') {
          item.title = this.renderTitle(item);
        }

        return (
          <Menu.SubMenu key={item.key} title={item.title} style={item.style}>
            <Menu.ItemGroup title={subMenuItem.subTitle}>
              {subMenuItem.subMenuItems.map(
                (subMenu: SubmenuItems): JSX.Element =>
                  this.renderNormalMenuItem(subMenu),
              )}
            </Menu.ItemGroup>
          </Menu.SubMenu>
        );
      }
      return this.renderNormalMenuItem(item);
    }
    return <></>;
  };

  private renderTitle = (item: MenuItem | SubmenuItems): JSX.Element => {
    const bussisnesImage = localStorage.getItem('bussisnesImage');
    const bussisnesLogo = localStorage.getItem('bussisnesLogo');
    if (!isEmpty(bussisnesLogo)) {
      const newFavicon = document.createElement('link');
      const oldFavicon = document.getElementById('favicon');
      newFavicon.id = 'favicon';
      newFavicon.rel = 'shortcut icon';
      newFavicon.href = 'data:image/jpg;base64,' + bussisnesLogo;
      if (oldFavicon) {
        document.head.removeChild(oldFavicon);
      }
      document.head.appendChild(newFavicon);
    }
    switch (item.key) {
      case 'cuenta':
        return (
          <span>
            {isEmpty(getToken())
              ? 'user'
              : getDecodeToken().name}
            <DownCircleOutlined />
          </span>
        );
      case 'x':
        return (
          <span>
            <div>
              <img
                style={{ width: this.state.collapsed ? '150px' : '40px' }}
                src={
                  this.state.collapsed
                    ? isEmpty(bussisnesImage)
                      ? logoExpanded
                      : 'data:image/jpg;base64,' + bussisnesImage
                    : isEmpty(bussisnesLogo)
                      ? logoNotExpanded
                      : 'data:image/jpg;base64,' + bussisnesLogo
                }
                className="logo"
                alt="img_logo"
              />
            </div>
          </span>
        );
      default:
        return <span>{item.title}</span>;
    }
  };

  private renderNormalMenuItem = (
    item: MenuItem | SubmenuItems,
  ): JSX.Element => {
    if ((item as MenuItem).permissions) {
      let permissions = (item as MenuItem).permissions;
      if (!isAllowed(permissions)) return <span />;
    }
    let hiddenComponent: boolean = item.key === 'Revisor' && !this.state.hiddenView;
    const component = (
      <Menu.Item
        className="item-menu-central"
        key={item.key}
        style={item.style}
      >
        <Link to={item.path} id={item.key} onClick={() => { if (item.key === 'closeSession' && this.state.hiddenView) this.setVisibleHiddenView() }}>
          {item.icon !== '' && item.key !== 'x' ? (
            item.icon
          ) : null}
          {this.renderTitle(item)}
        </Link>
      </Menu.Item>
    );
    if (hiddenComponent) return <span />;
    return component;
  };

  public state = {
    collapsed: false,
    visible: false,
    screenWidth: null,
    hiddenView: false,
    urlPrivacy: "#"
  };

  private showSider = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  private collapse = () => {
    this.setState({
      collapsed: true,
    });
  };

  public render = () => {
    const VERSION = process.env.REACT_APP_VERSION || '';
    const { children, menu, loading, location } = this.props;

    if (!isAuthenticated()){
      return (
        <Layout style={styles.layout}>
          <Layout.Content>{children}</Layout.Content>
          <Layout.Footer style={{ textAlign: 'center' }}>
            <Row>
              <Col>
              SSF ©{moment().year()} ssf-projects {isEmpty(VERSION) ? 'local' : VERSION}
              </Col>
              <Col>
                <Button shape="round"  size="large" >
                  <a href={this.state.urlPrivacy} target="_blank" rel="noopener noreferrer">
                  Aviso de Privacidad
                  </a>
                </Button>
              </Col>
            </Row>
          </Layout.Footer>
        </Layout>
      );
    }
      
    return (
      <Layout style={styles.layout}>
        <Sider
          breakpoint="lg"
          style={styles.sider}
          trigger={null}
          collapsible
          collapsed={!this.state.collapsed}
          onBreakpoint={this.collapse}
        >
          <Menu
            className="header-menu"
            style={styles.menu}
            mode="vertical-left"
            defaultSelectedKeys={this.getSubMenuNameFromURL(location.pathname)}
          >
            {menu.map(
              (item: MenuItem): JSX.Element =>
                item.key !== 'cuenta' ? (
                  this.renderMenuItem(item)
                ) : (
                    <Menu.Item />
                  ),
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header style={styles.header}>
            <Row justify="space-around">
              <Col span={2}>
                {this.state.collapsed ?
                  <MenuUnfoldOutlined onClick={this.showSider} /> :
                  <MenuFoldOutlined onClick={this.showSider} />}
              </Col>
              <Col span={10}>
                <h1 style={{ textAlign: 'right' }}>
                  {
                    (
                      menu.filter(
                        (item: MenuItem): boolean =>
                          item.path ===
                          '/' + this.getSubMenuNameFromURL(location.pathname),
                      )[0] || {}
                    ).title
                  }
                </h1>
              </Col>
              <Col span={10}>
                <Menu>
                  {
                    this.renderMenuItem(menu.filter((item: MenuItem): Boolean => item.key === 'cuenta')[0])
                  }
                </Menu>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              background: '#E5E5E5',
            }}
          >
            {loading && (
              <div
                className="justify-center"
                style={{ backgroundColor: '#f0f2f5' }}
              >
                <Spin
                  indicator={<LoadingOutlined />}
                />
              </div>
            )}

            <div>
              {
                (this.state.hiddenView && isAllowed((menu.filter((item: MenuItem): boolean => item.key === 'Revisor')[0] || {}).permissions))
                  ? <Revisor onClose={() => () => this.setVisibleHiddenView()} />
                  : children
              }
            </div>
          </Content>
          <Footer style={{ textAlign: 'center', backgroundColor: '#E5E5E5' }}>
            <Row>
              <Col>
                SSF ©{moment().year()} ssf-projects {isEmpty(VERSION) ? 'local' : VERSION}
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    );
  };
}


export default withRouter(MainLayout);