/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';

export interface AccessToken {
  accessToken?: string;
}
const initialAccesTokenState: PasswordState = {
  accessToken: {},
};

export interface PasswordState {
  readonly accessToken: AccessToken;
}

const accessToken: Reducer<PasswordState> = (
  state = initialAccesTokenState,
  action,
): PasswordState => {
  switch (action.type) {
    case 'SET':
      return { ...state, accessToken: action.accessToken };
    default:
      return state;
  }
};

export default combineReducers<PasswordState>({
  accessToken,
});
