import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';

class SupportService extends Service {
    public reactivateIUS = (ius: string, total: number, xmlCancel: File): Promise<string> => {
        var bodyFormData = new FormData();
        bodyFormData.append('ius',ius);
        bodyFormData.append('total',total.toString());
        bodyFormData.append('xmlCancel',xmlCancel);
        return axios({
            url: createCoreServiceUrl('reactivate'),
            method: 'post',
            data: bodyFormData,
            headers: {
              'content-Type':
                'Multipart/form-data;boundary=032a1ab685934650abbe059cb45d6ff3',
            },
          }).then((response: AxiosResponse<any>): any => response.data);
    }
}

export default SupportService;
