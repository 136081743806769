/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 * Modified by Brandon Leon <brandon@ssf.com.mx>
 *
 * This class construct the menu of the application when a user logins.
 */

import React, { Component } from 'react';
import users from './users';
import fiscalData from './fiscalData';
import salesUser from './homeUser/salesUser';
import { MenuItem, SubmenuItems } from '../types/type';
import company from './company';
import sales from './sales';
import Companyformroute from './company/components/Companyformroute';
import LogOut from './login/logout';
import Home from './homeUser'
import UserData from './homeUser/dataUser'
import LogEvent from './log';
import BitacoraComponent from './bitacora'
import Configuration from './configuration';
import Support from './support';

import { Permissions as P } from 'commons/permissions';
import { Redirect } from 'react-router';
import invoiceUser from './homeUser/invoiceUser';
import { BankOutlined, DollarOutlined, EditOutlined, HomeOutlined, ProfileOutlined, SettingOutlined, SmileOutlined, UserOutlined, ToolOutlined } from '@ant-design/icons';

class empty extends Component {
  render = (): JSX.Element => {
    return <div />;
  };
}
const getPath = (): string => window.location.pathname;
const getSubmenu = (): SubmenuItems[] =>
  [
    {
      key: 'closeSession',
      path: '/logout',
      component: LogOut,
      title: 'Cerrar sesion',
      visible: true,
    },
  ].filter(config => config.visible);

export const Menu: MenuItem[] = [
  {
    key: 'x',
    path: '/',
    component: (): JSX.Element => <Redirect to="/sales" />,
    title: null,
    visible: true,
    icon: <HomeOutlined />,
    secure: true,
    style: {
      height: '60px',
      textAlignLast: 'center',
    },
  },
  {
    key:'home',
    path:'/home',
    component: Home,
    title: 'Inicio',
    visible: false,
    icon: <HomeOutlined />,
    secure: true,
    client: true,
  },
  {
    key:'userData',
    path:'/userdata/:id',
    component: UserData,
    title: 'Datos de usuario',
    visible: false,
    icon: <HomeOutlined />,
    secure: true,
  },
  {
    key:'invoiceUser',
    path:'/invoiceUser',
    component: invoiceUser,
    title: 'Facturar',
    visible: false,
    icon: <HomeOutlined />,
    secure: true,
  },
  {
    key: 'fiscalData',
    path: '/fiscalData',
    component: fiscalData,
    title: 'Datos fiscales',
    visible: false,
    icon: <ProfileOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_USERS],
  },
  {
    key: 'salesUser',
    path: '/salesUser',
    component: salesUser,
    title: 'Mis Facturas',
    visible: false,
    icon: <ProfileOutlined />,
    secure: true,
    client: true,
  },
  {
    key:'bitacora',
    path:'/bitacora',
    component: BitacoraComponent,
    title: 'Bitacora',
    visible: false,
    icon: <EditOutlined />,
    secure: true,
    client: true,
    permissions: [P.CAN_VIEW_COMPANIES],
  },
  {
    key: 'Uses',
    path: '/users',
    component: users,
    title: 'Usuarios',
    visible: true,
    icon: <UserOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_USERS],
  },
  {
    key: 'Company',
    path: '/enterprice',
    component: company,
    title: 'Perfiles fiscales',
    visible: true,
    icon: <BankOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_COMPANIES],
  },
  {
    key: 'salesDashboard',
    path: '/sales',
    component: sales,
    title: 'Ventas',
    visible: false,
    icon: <DollarOutlined />,
    secure: true,
    client: false,
  },
  {
    key: 'CompanyForm',
    path: '/enterprice/form/:id',
    component: Companyformroute,
    title: 'Empresa form',
    visible: false,
    icon: <BankOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_COMPANY_DETAILS],
  },
  {
    key: 'Logs',
    path: '/logs',
    title: 'Logs',
    component: LogEvent,
    visible: true,
    icon: <EditOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_LOGS],
  },
  {
    key: 'Config',
    path: '/configuration',
    title: 'Configuraciones',
    component: Configuration,
    visible: true,
    icon: <SettingOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_LOGS],
  },
  {
    key: 'Soporte',
    path: '/support',
    title: 'Soporte',
    component: Support,
    visible: true,
    icon: <ToolOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_SUPPORT],
  },
  /* {
    key: 'sales',
    path: '/sales',
    component: sales,
    title: 'Ventas',
    visible: true,
    icon: 'dollar',getDecodeToken().name
  {
    key: 'salesCustomer',
    path: '/salesCustomer',
    component: sales,
    title: 'Mis Compras',
    visible: true,
    icon: 'dollar',
    secure: true,
    permissions: [P.CAN_VIEW_MY_SALES],
  },
  /* {
    key: 'customers',
    path: '/customers',
    component: Customers,
    title: 'Crédito y Cobranza',
    visible: true,
    icon: 'smile',
    secure: true,
    permissions: [P.CAN_CIEW_CUSTOMER_PORTFOLIO],
  }, */
  {
    key: 'Revisor',
    path: getPath(),
    title: 'Revisor',
    component: empty,
    visible: true,
    icon: <EditOutlined />,
    secure: true,
    permissions: [P.CAN_VIEW_REVISOR],
  },
  {
    key: 'cuenta',
    path: '/cuenta',
    component: empty,
    secure: true,
    title: null,
    visible: true,
    icon: <SmileOutlined />,
    style: {
      float: 'right',
    },
    subMenu: {
      subTitle: 'Usuario',
      subMenuItems: getSubmenu(),
    },
  },
];

export default Menu;
