import { Form, Col, InputNumber, Button, Row } from 'antd';
import React, { FormEvent } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { iusRule, totalRule } from './saleRules';
import MaskedInput from 'react-text-mask';

interface CounterProps extends FormComponentProps {
  handleOnSubmitIUS: (e: FormEvent, form: WrappedFormUtils) => void;
  isFetching: boolean;
}

type Props = CounterProps & FormComponentProps;

class SaleForm extends React.Component<Props> {
  public state = { visible: false };

  private formImputItemRender = (
    label: string,
    name: string,
    mark: boolean,
    rule: any[],// eslint-disable-line
  ): JSX.Element => {
    const hexRegex = /[0-9a-fA-F]/;
    const iusFragment = [hexRegex, hexRegex, hexRegex, hexRegex];
    return (
      <Col span={12} offset={2}>
        <Form.Item label={label} colon={mark}>
          {this.props.form.getFieldDecorator(name, { rules: rule })(
            <MaskedInput
              mask={[
                ...iusFragment,
                '-',
                ...iusFragment,
                '-',
                ...iusFragment,
                '-',
                ...iusFragment,
                '-',
                ...iusFragment,
              ]}
              id="ius"
              showMask
              style={{ fontSize: '21px' }}
              className="ant-input ant-input-large"
            />,
          )}
        </Form.Item>
      </Col>
    );
  };

  public render = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
      handleOnSubmitIUS,
      isFetching,
    } = this.props;

    return (
      <Row gutter={16}>
        <Row
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
          }}
        >
          <Col style={{ justifyContent: 'space-between' }}>
            <Row>
              {'Factura directamente con tu IUS y el monto de tu pedido '}
            </Row>
            <Row style={{ marginTop: 20 }}>
              {
                'Puedes facturar más de un IUS a la vez presionando en "Añadir" '
              }
            </Row>
          </Col>
        </Row>
        <Row>
          <Form onSubmit={(e): void => handleOnSubmitIUS(e, this.props.form)}>
            <Row style={{ backgroundColor: '#f1f7ff' }}>
              {this.formImputItemRender('IUS', 'ius', true, iusRule)}
              <Col offset={3} xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                <Form.Item label="Monto $">
                  {getFieldDecorator('total', {
                    rules: totalRule,
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={23}>
                <Form.Item>
                  <Button
                    className="button-invoice-serch-style"
                    type="dashed"
                    htmlType="submit"
                    id="search_sale_button"
                    block
                  >
                    {isFetching ? 'Loading...' : ' + Añadir  '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Row>
    );
  };
}
export default Form.create<CounterProps>()(SaleForm);
