import { combineReducers, Reducer } from 'redux';
import { BEGIN_FETCH, DownloadAction, FETCH_COMPLETED } from './types';

export interface DownloadState {
    readonly isFetching: boolean;
  }

const isFetching: Reducer<boolean> = (
    state = false,
    action: DownloadAction,
): boolean => {
    switch (action.type) {
        case BEGIN_FETCH:
            return true;
        case FETCH_COMPLETED:
            return false;
        default:
            return state;
    }
};

export default combineReducers<DownloadState>({ isFetching });