/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 * Modified by Brandon Leon <brandon@ssf.com.mx>
 *
 * Here is configured all the Stores of the application that are used by
 * the redux reducers.
 */

import { createStore, combineReducers, applyMiddleware } from 'redux';
import loginState, { LoginState } from 'routes/login/redux/reducers';
import usersState, { UsersState } from 'routes/users/redux/reducers';
import invoiceUserState, { InvoiceUserState } from 'routes/homeUser/invoiceUser/redux/reducers';
import passwordState, {
  PasswordState,
} from 'routes/activatepassword/redux/reducers';
import companyState, { CompanyState } from 'routes/company/redux/reducers';
import invoiceState, { InvoiceState } from 'routes/invoices/redux/reducers';
import saleState, { SaleState } from 'routes/sales/redux/reducers';
import thunk from 'redux-thunk';
import fiscalDataState, { FiscalDataState } from 'routes/fiscalData/redux/reducers';
import newFiscalDataState, { NewFiscalDataState } from 'routes/fiscalData/redux/reducers';
import revisorSate, { RevisionState } from 'routes/revisor/redux/reducers';
import downloadState, { DownloadState } from 'routes/downloadZipFile/redux/reducers';
import logState, {LogState} from 'routes/log/redux/reducers';
import configurationState, {ConfigurationState} from 'routes/configuration/redux/reducers';
import supportState, {SupportState} from 'routes/support/redux/reducers';

/**
 * This is the whole state of the application.
 */
export interface RootState {
  loginState: LoginState;
  usersState: UsersState;
  passwordState: PasswordState;
  companyState: CompanyState;
  invoiceState: InvoiceState;
  saleState: SaleState;
  fiscalDataState: FiscalDataState;
  newFiscalDataState: NewFiscalDataState;
  invoiceUserState: InvoiceUserState;
  revisorSate: RevisionState;
  downloadState: DownloadState;
  logState: LogState;
  configurationState: ConfigurationState;
  supportState: SupportState;
}

/**
 * Combine all the reducers in a big unique Store for the application.
 */
export default createStore(
  combineReducers<RootState>({
    loginState,
    usersState,
    passwordState,
    companyState,
    invoiceState,
    saleState,
    fiscalDataState,
    newFiscalDataState,
    invoiceUserState,
    revisorSate,
    downloadState,
    logState,
    configurationState,
    supportState,
  }),
  applyMiddleware(thunk),
);
