/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, May 2019
 *
 *
 */
import { Sale, SaleSelected } from 'types/type';

/*
 * Action to add a sale to list component
 */
export const SET_SALE = 'SET_SALE';
export const SET_SALE_LIST = 'SET_SALE_LIST';
export const CLEAN = 'CLEAN';
/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

/*
 *Send sale to invoicing
 */
export const INVOICING = 'INVOICING';

/*
 *SET INITIAL DATE
 */
export const SET_INIT_DATE = 'SET_INIT_DATE';

/*
 *SET END DATE
 */
export const SET_END_DATE = 'SET_END_DATE';

export const SET_SUM_TOTALLK = 'SET_SUM_TOTALLK';

export const SET_SUM_TOTALM = 'SET_SUM_TOTALM';

export const SET_SUM_TOTALLK_BILLABLE = 'SET_SUM_TOTALLK_BILLABLE';

export const SET_SUM_TOTALM_BILLABLE = 'SET_SUM_TOTALM_BILLABLE';

export const SET_SUM_TOTALLK_SELECTED = 'SET_SUM_TOTALLK_SELECTED';

export const SET_SUM_TOTALM_SELECTED = 'SET_SUM_TOTALM_SELECTED';

export const SET_SALE_SELECTED = 'SET_SALE_SELECTED';

export const PAYMENT_CHANGE = 'PAYMENT_CHANGE';

export const TOTAL_ELEMENTS_BILLABLE = 'TOTAL_ELEMENTS_BILLABLE';

export const TOTAL_ELEMENTS_SELECTED = 'TOTAL_ELEMENTS_SELECTED';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}
interface SetAction {
  type: typeof SET_SALE_LIST;
  sale: Sale;
}
interface CLEAN_SALES {
  type: typeof CLEAN;
}
interface SetSaleAction {
  type: typeof SET_SALE;
  saleList: {};
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SendToInvoicing {
  type: typeof INVOICING;
  sale: Sale;
}

interface SetInitialDate {
  type: typeof SET_INIT_DATE;
  initDate: Date;
}

interface SetEndDate {
  type: typeof SET_END_DATE;
  endDate: Date;
}

interface SetSumTotalLK {
  type: typeof SET_SUM_TOTALLK;
  sumTotalLK: number;
}

interface SetSumTotalM {
  type: typeof SET_SUM_TOTALM;
  sumTotalM: number;
}

interface SetSumTotalLKBillable {
  type: typeof SET_SUM_TOTALLK_BILLABLE;
  sumTotalLKBillable: number;
}

interface SetSumTotalMBillable {
  type: typeof SET_SUM_TOTALM_BILLABLE;
  sumTotalMBillable: number;
}

interface SetSumTotalLKSelected {
  type: typeof SET_SUM_TOTALLK_SELECTED;
  sumTotalLKSelected: number;
}

interface SetSumTotalMSelected {
  type: typeof SET_SUM_TOTALM_SELECTED;
  sumTotalMSelected: number;
}

interface setSalesSelected {
  type: typeof SET_SALE_SELECTED;
  saleSelectedList: SaleSelected[];
}

interface SetPaymentChangeAction {
  type: typeof PAYMENT_CHANGE;
  paymentChange: boolean;
}

interface SetTotalElementsBillableAction {
  type: typeof TOTAL_ELEMENTS_BILLABLE;
  totalElementsBillable: number;
}

interface SetTotalElementsSelectedAction {
  type: typeof TOTAL_ELEMENTS_SELECTED;
  totalElementsSelected: number;
}

export type SaleAction =
  | EndFetchAction
  | BeginFetchAction
  | CLEAN_SALES
  | SetAction
  | SetSaleAction
  | SendToInvoicing
  | SetInitialDate
  | SetEndDate
  | SetSumTotalLK
  | SetSumTotalM
  | SetSumTotalLKBillable
  | SetSumTotalMBillable
  | SetSumTotalLKSelected
  | SetSumTotalMSelected
  | setSalesSelected
  | SetPaymentChangeAction
  | SetTotalElementsBillableAction
  | SetTotalElementsSelectedAction;
