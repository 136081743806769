const numberFormat = /\B(?=(\d{3})+(?!\d))/g;

// Use on the parser of <InputNumber/> when referencing money.
const numberParser = /\$\s?|(,*)/g;

const onlyNumberPattern = /^[0-9]+$/;

const onlyNumberDecimalPattern = /^\d+\.?\d{0,2}$/;

// Use for emails, accepts only valid emails like 'name@company.extension'
const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

// Use address numbers, validates alphanumeric like: ['A1', '22B', 'AABC','123']
const addressNumberPattern = /^[A-z0-9]+$/;

// Use to validate postal codes with only numbers and a max length of 10.
const postalCodePattern = /^[0-9]{1,10}$/;

// eslint-disable-next-line no-control-regex
const invisibleASCII = /[^\x00-\x7F]+/g;

const spacesformat = /\S+\S+\S+\S+\S+/;

const passwordvalidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

const rfcformat = /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;

const arrayRevisorFormat = /^[0-9,]+$/

export {
  numberFormat,
  numberParser,
  onlyNumberPattern,
  emailPattern,
  addressNumberPattern,
  postalCodePattern,
  invisibleASCII,
  spacesformat,
  passwordvalidation,
  rfcformat,
  onlyNumberDecimalPattern,
  arrayRevisorFormat,
};
