import SaleService from '../../../services/sale';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Popups } from 'commons/components/popups/popups';
import { BEGIN_FETCH, FETCH_COMPLETED, DownloadAction } from './types';

const saleService = new SaleService();

const startFetch = (): DownloadAction => {
  return { type: BEGIN_FETCH };
};

const endFetch = (): DownloadAction => {
  return { type: FETCH_COMPLETED };
};

export const downloadZip = (
  token: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch): Promise<void> => {
    try {
      dispatch(startFetch());
      await saleService.getZipFile(token);
      saleService.deleteZip(token);
      dispatch(endFetch());
    } catch (err) {
      dispatch(endFetch());
      Popups.notifyError('No se pudo descargar archivo solicitado');
    }
  };
}
export const downloadZip2 = (
  token: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch): Promise<void> => {
    try {
      dispatch(startFetch());
      await saleService.getZipFile2(token);
      saleService.deleteZipv2(token);
      dispatch(endFetch());
    } catch (err) {
      dispatch(endFetch());
      Popups.notifyError('No se pudo descargar archivo solicitado');
    }
  };
};