const enviromentSelect = (enviroment: string | undefined): string => {
  switch (enviroment) {
    case 'production':
      return 'http://108.161.131.89';
    default:
      return 'http://localhost:8765';
  }
};

export const GATEWAY_API_URL: string | undefined = enviromentSelect(
  process.env.REACT_APP_STAGE,
);
export const CORE_SERVICE_KEY = 'api';
