import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Button, Col, Pagination, Form, Divider, Card, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import './style.css';
import { page } from './redux/actions';
import { CompanyListI } from './redux/reducers';
import { RootState } from '../../store/configureStore';
import { Link } from 'react-router-dom';
import { Company } from 'types/type';
import Text from 'antd/lib/typography/Text';
import noCompany from 'assets/noCompany.svg';
import FiscalPermissionModal from './components/FiscalPermissionModal';
import { EditOutlined } from '@ant-design/icons';
import { isAllowed } from 'security/index';
import { Permissions as P } from 'commons/permissions';
import { Redirect } from 'react-router';

interface DispatchProps {
  page: (page: number, distCenterId: boolean) => void;
}
interface StateProps {
  companyList: CompanyListI;
  multicenter: boolean;
  isEquimentNumber: boolean;
  isFetching: boolean;
}
type Props = FormComponentProps & StateProps & DispatchProps;

class Users extends Component<Props> {
  public state = {
    data: [],
    valor: 0,
    paginationenable: true,
    visible: false,
    positionItem: 0,
    fiscalPermissionModalVisible: false,
    distCenterId: '',
    companyId: ''
  };

  public componentWillMount(): void {
    this.setState({ positionItem: 0 });
    this.chargeData();
  }
  private async chargeData() {
    await this.props.page(1, true);
  }

  public handlePaginationChange = (current: number): void => {
    this.props.page(current, true);
    this.setState({ valor: current, positionItem: 0 });
  };

  public render = (): JSX.Element => {
    const { companyList: { content }, isFetching } = this.props;
    if (!isAllowed([P.CAN_VIEW_COMPANIES])) {
      return <Redirect to="/home" />
    }
    if (content !== undefined) {
      if ((content.length || []) < 1) {
        return (
          <Spin tip="Cargando..." spinning={isFetching}>
            <Card className="customer-main-card">
              <Card
                bordered={false}
                className="login-card"
                cover={
                  <div className="center-items-card">
                    <Row>
                      <img alt="no company" src={noCompany} />
                    </Row>
                    <Row>
                      <Text>Sin Empresas fiscales agregadas</Text>
                    </Row>
                    <Row>
                      <Link
                        key="1"
                        to={{ pathname: '/enterprice/form/0' }}
                      >
                        <Button type="primary">
                          {'+ Agregar Empresa fiscal'}
                        </Button>
                      </Link>
                    </Row>
                  </div>
                }
              />
            </Card>
          </Spin>
        );
      }
    }
    return (
      <Spin tip="Cargando..." spinning={isFetching}>
        <Row
          style={{
            backgroundColor: '#fff',
            marginLeft: 24,
            marginRight: 24,
            marginTop: 24,
          }}
          gutter={1}
        >
          <p />
          {((content !== undefined && content.length < 1) ||
            this.props.multicenter) && (
              <Link
                key="1"
                to={{ pathname: '/enterprice/form/0' }}
              >
                <Button
                  type="primary"
                  className="button-new"
                  size="default"
                  id="newCompany_button"
                  style={{ marginLeft: 24, marginRight: 24 }}
                >
                  + Agregar empresa fiscal
            </Button>
              </Link>
            )}
          {content !== undefined && content.length < 1 && <Divider />}
          <Col
            span={24}
            style={{ marginLeft: 24, marginRight: 24, marginBottom: 24 }}
          >
            <Row gutter={8}>
              {content !== undefined
                ? content.map(
                  (item: Company): JSX.Element => {
                    return (
                      <Col
                        xs={{ span: 22, offset: 0 }}
                        sm={{ span: 22, offset: 0 }}
                        md={{ span: 11, offset: 0 }}
                        lg={{ span: 11, offset: 0 }}
                        xl={{ span: 7, offset: 0 }}
                        xxl={{ span: 7, offset: 0 }}
                      >
                        {() =>
                          this.setState({
                            positionItem: this.state.positionItem + 1,
                          })
                        }
                        <Card
                          title={item.name}
                          headStyle={{
                            backgroundColor: '#E4EFFF',
                            color: '#3175CA',
                          }}
                          bodyStyle={{
                            height: '250px'
                          }}
                          extra={
                            <Row gutter={2}>
                              <Col span={11}>
                                <Link
                                  key="1"
                                  to={{ pathname: `/enterprice/form/${item.id}` }}
                                  style={{ color: 'black' }}
                                >
                                  <button
                                    id={`edit_${this.state.positionItem}`}
                                    type="button"
                                    className="link-button"
                                  >
                                    <EditOutlined />
                                  </button>
                                </Link>
                              </Col>
                              <Col span={2}>
                                <Divider type="vertical" />
                              </Col>
                            </Row>
                          }
                        >
                          <Col>
                            <p>
                              <Text strong style={{ color: '#646464' }}>Centro de distribucion: </Text><br />
                              <Text style={{ color: '#727272' }}>{item.distributionCenterName || ""}</Text>
                            </p>
                          </Col>
                          <Col>
                            <p>
                              <Text strong style={{ color: '#646464' }}>RFC: </Text>
                              <Text style={{ color: '#727272' }}>
                                {item.rfc}
                              </Text>
                            </p>
                            <p style={{ color: '#727272' }}>
                              {item.city}, {item.state}
                            </p>
                          </Col>
                          {this.props.isEquimentNumber && <Col>
                            <Button onClick={() => this.setState({ fiscalPermissionModalVisible: true, distCenterId: item.distributionCenterId, companyId: item.id })}>Agregar numero de permiso</Button>
                          </Col>}
                        </Card>
                      </Col>
                    );
                  },
                )
                : null}
              {this.state.fiscalPermissionModalVisible &&
                <FiscalPermissionModal
                  visible={this.state.fiscalPermissionModalVisible}
                  onCancel={() => this.setState({ fiscalPermissionModalVisible: false })}
                  onOk={() => this.setState({ fiscalPermissionModalVisible: false })}
                  distCenterId={this.state.distCenterId}
                  companyId={this.state.companyId}
                />
              }
            </Row>
            <p />
          </Col>
          <Pagination
            current={(this.props.companyList.number || 0) + 1}
            total={this.props.companyList.totalElements}
            onChange={this.handlePaginationChange}
          />
        </Row>
      </Spin>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    companyList: states.companyState.companyList,
    multicenter: states.companyState.multicenter,
    isEquimentNumber: states.companyState.isEquimentNumber,
    isFetching: states.companyState.isFetching,
  };
};

const mapDispatchToProps = { page };

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Users));
