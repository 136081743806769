import { Company, FiscalPermission, Equipment, EquipmentsList } from 'types/type';

/*
 * Action to add a company to the staging area
 */
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_COMPANY_IMAGES = 'SET_COMPANY_IMAGES';
export const SET_COMPANY_SALES = 'SET_COMPANY_SALES';
export const SET_DISTRIBUTION_CENTER = 'SET_DISTRIBUTION_CENTER';
export const SET = 'SET';
export const GET_UNAVAILABLE_FOLIO = 'GET_UNAVAILABLE_FOLIO';
export const SET_FISCAL_PERMISSIONS = 'SET_FISCAL_PERMISSIONS';
export const CREATE_FISCAL_PERMISSION = 'CREATE_FISCAL_PERMISSION';
export const UPDATE_FISCAL_PERMISSION = 'UPDATE_FISCAL_PERMISSION';
export const DELETE_FISCAL_PERMISSION = 'DELETE_FISCAL_PERMISSION';
export const SET_EQUIPMENTS = 'SET_EQUIPMENTS';
export const MULTICENTER = 'MULTICENTER';
export const IS_EQUIMENT_NUMBER = 'IS_EQUIMENT_NUMBER';
export const PAYMENT_CHANGE = 'PAYMENT_CHANGE';
export const SET_EQUIPMENT_LIST = 'SET_EQUIPMENT_LIST';

/*
 * Begin a fetch operation action
 */
export const BEGIN_FETCH = 'BEGIN_FETCH';

/*
 * A fetch operation action have been completed
 */
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}

interface SaveCompanyAction {
  type: typeof SAVE_COMPANY;
  companytype: Company;
}
interface SetAction {
  type: typeof SET;
  company: Company;
}
interface SetCompanyAction {
  type: typeof SET_COMPANY;
  companyList: {};
}
interface SetCompanyImagesAction {
  type: typeof SET_COMPANY_IMAGES;
  companyImagesList: {};
}
interface SetCompanySalesAction {
  type: typeof SET_COMPANY_SALES;
  companySalesList: {};
}
interface SetMulticenterAction {
  type: typeof MULTICENTER;
  multicenter: boolean;
}
interface SetIsEquimentNumberAction {
  type: typeof IS_EQUIMENT_NUMBER;
  isEquimentNumber: boolean;
}
interface SetDistributionCenterAction {
  type: typeof SET_DISTRIBUTION_CENTER;
  distributionCenter: {};
}
interface GetUnavailableFolioAction {
  type: typeof GET_UNAVAILABLE_FOLIO;
  unavailableFolio: {};
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

interface SetPaymentChangeAction {
  type: typeof PAYMENT_CHANGE;
  paymentChange: boolean;
}

interface SetFiscalPermissionsAction {
  type: typeof SET_FISCAL_PERMISSIONS;
  fiscalPermissions: Array<FiscalPermission>
}

interface CreateFiscalPermissionAction {
  type: typeof CREATE_FISCAL_PERMISSION;
  fiscalPermission: FiscalPermission
}

interface UpdateFiscalPermissionAction {
  type: typeof UPDATE_FISCAL_PERMISSION;
  fiscalPermission: FiscalPermission
}

interface DeleteFiscalPermissionAction {
  type: typeof DELETE_FISCAL_PERMISSION;
  id: string;
}

interface SetEquipmentsAction {
  type: typeof SET_EQUIPMENTS;
  equipments: Array<Equipment>;
}

interface setEquipmentListAction {
  type: typeof SET_EQUIPMENT_LIST;
  equipmentList: EquipmentsList;
}

export type CompanyAction =
  | EndFetchAction
  | BeginFetchAction
  | SetAction
  | SetCompanyAction
  | SetCompanyImagesAction
  | SetCompanySalesAction
  | SetMulticenterAction
  | SetIsEquimentNumberAction
  | SetDistributionCenterAction
  | SaveCompanyAction
  | GetUnavailableFolioAction
  | SetFiscalPermissionsAction
  | CreateFiscalPermissionAction
  | UpdateFiscalPermissionAction
  | DeleteFiscalPermissionAction
  | SetEquipmentsAction
  | setEquipmentListAction
  | SetPaymentChangeAction;
