/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 * Edit by David Canul, 27 may 2019
 *
 */

import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import {
  saveCompany,
  editCompany,
  getCompany,
  cleanCompany,
  getDistributionCenter,
  getUnavailableFolio,
  getFiscalPermission
} from '../redux/actions';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RootState } from 'store/configureStore';
import logo from '../../../assets/logo_login.png';
import image from '../../../assets/icono_sicom.svg';
import {
  Card,
  Button,
  Input,
  Select,
  Col,
  Upload,
  Row,
  Switch,
  notification,
  InputNumber,
  Spin,
} from 'antd';
import { isEmpty } from 'lodash';
import '../style.css';
import {
  Company,
  DistributionCenter,
  Files as filesType,
  UnavailableFolio,
  FiscalPermission,
} from 'types/type';
import {
  spacesformat,
  rfcformat,
  emailPattern,
  onlyNumberPattern,
} from 'commons/regularExpressions';
import { regimenFiscal } from 'commons/components/regimenFiscal';
import { RouteComponentProps } from 'react-router';
import { Popups } from 'commons/components/popups/popups';
import { Link } from 'react-router-dom';
import { LockOutlined, MailOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';

import { isAllowed } from 'security/index';
import { Permissions as P } from 'commons/permissions';
import { Redirect } from 'react-router';

interface DispatchProps {
  saveCompany: (company: Company, files: filesType) => void;
  editCompany: (company: Company, files: filesType) => void;
  getCompany: (company: string) => void;
  cleanCompany: () => void;
  getDistributionCenter: () => void;
  getUnavailableFolio: (currentFolio: Function) => void;
  getFiscalPermission: (companyId: string) => void;
}

interface HomeRouterProps {
  id: string;
}

interface StateProps {
  company: Company;
  distributionCenter: DistributionCenter[];
  unavailableFolio: UnavailableFolio[];
  isFetching: boolean;
  paymentChange: boolean;
  fiscalPermissions: Array<FiscalPermission>;
  isEquimentNumber: boolean;
}

type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

const initialCurrentFolio: UnavailableFolio = {
  id: 0,
  lastFolio: 0,
  serie: "",
  startFolio: 0,
  status: false
}

/**
 * FIXME describe the porpouse of this component
 */
class CompanyFormComponent extends Component<Props> {
  public state = {
    KeyList: new File([''], ''),
    CerList: new File([''], ''),
    logoUrl: new File([''], ''),
    bussisnesImage: new File([''], ''),
    bussisnesLogo: new File([''], ''),
    FormatList: new File([''], ''),
    serie: "",
    currentFolio: initialCurrentFolio
  };

  public handleBeforeUploadKey = (file: File): boolean => {
    this.setState({ KeyList: file });
    return false;
  };
  private handleRemoveKey = (): void => {
    this.setState({ KeyList: new File([''], '') });
  };

  public handleBeforeUploadCer = (file: File): boolean => {
    this.setState({ CerList: file });
    return false;
  };
  private handleRemoveCer = (): void => {
    this.setState({ CerList: new File([''], '') });
  };

  public handleBeforeUploadLogo = (file: File): boolean => {
    this.setState({ logoUrl: file });
    return false;
  };
  private handleRemoveLogo = (): void => {
    this.setState({ logoUrl: new File([''], '') });
  };

  public handleBeforeUploadFormat = (file: File): boolean => {
    this.setState({ FormatList: file });
    return false;
  };
  private handleRemoveFormat = (): void => {
    this.setState({ FormatList: new File([''], '') });
  };

  public handleBeforeUploadBussisnesImage = (file: File): boolean => {
    if (file.size > 1000000)
      notification.warning({
        message: 'La imagen de la empresa es muy grande',
        description:
          'Se recomienda un tamaño de 2MB maximo un tamaño superior puede causar lentitud en el sistema',
        duration: 0,
      });
    this.setState({ bussisnesImage: file });
    return false;
  };
  private handleRemoveBussisnesImage = (): void => {
    this.setState({ bussisnesImage: new File([''], '') });
  };
  public handleBeforeUploadBussisnesLogo = (file: File): boolean => {
    if (file.size > 1000000)
      notification.warning({
        message: 'El logo de la empresa es muy grande',
        description:
          'Se recomienda un tamaño de 2MB maximo un tamaño superior puede causar lentitud en el sistema',
        duration: 0,
      });
    this.setState({ bussisnesLogo: file });
    return false;
  };
  private handleRemoveBussisnesLogo = (): void => {
    this.setState({ bussisnesLogo: new File([''], '') });
  };

  public uploadButton = (text: string): JSX.Element => (
    <div>
      <Button size="large" style={{ color: '#2E76D1', borderColor: '#2E76D1' }}>
        <UploadOutlined /> {text}
      </Button>
    </div>
  );

  public componentWillUnmount(): void {
    this.props.cleanCompany();
  }

  public componentWillMount(): void {
    this.chargeData();
  }

  private async chargeData() {
    const { id } = this.props.match.params;
    await this.props.getDistributionCenter();
    await this.props.getUnavailableFolio((currentFolio: UnavailableFolio) => {
      if (currentFolio) {
        this.setState({ currentFolio, serie: currentFolio.serie });
      }
    });
    await this.props.getFiscalPermission(id === '0' ? '' : id);
    if (id !== '0') {
      this.props.form.resetFields();
      await this.props.getCompany(id);
    } 
  }
  private setErrorMessage(message: string | null): Record<string, any> {
    return {
      response: {
        status: 400,
        data: {
          message:
            message || 'Es obligatorio seleccionar todas las credenciales',
        },
      },
    };
  }

  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      async (err, values): Promise<void> => {
        const {
          CerList,
          KeyList,
          logoUrl,
          FormatList,
          bussisnesImage,
          bussisnesLogo,
        } = this.state;
        const { id } = this.props.match.params;
        if (!err) {
          try {
            if (id !== '0') {
              const company: Company = { ...values, id };
              let files;
              if (
                CerList.size < 1 ||
                KeyList.size < 1 ||
                company.password === ''
              ) {
                if (
                  CerList.size < 1 &&
                  KeyList.size < 1 &&
                  company.password === ''
                ) {
                  files = {
                    certificate: new File([''], ''),
                    privateKey: new File([''], ''),
                    logo: new File([''], ''),
                    format: new File([''], ''),
                    bussisnesImage: new File([''], ''),
                    bussisnesLogo: new File([''], ''),
                  };
                } else {
                  throw this.setErrorMessage(null);
                }
              } else {
                files = {
                  certificate: CerList,
                  privateKey: KeyList,
                  logo: logoUrl,
                  format: FormatList,
                  bussisnesImage,
                  bussisnesLogo,
                };
              }
              if (logoUrl.size > 0) {
                files = { ...files, logo: logoUrl };
              }
              if (FormatList.size > 0) {
                files = { ...files, format: FormatList };
              }
              if (bussisnesImage.size > 0) {
                files = { ...files, bussisnesImage };
              }
              if (bussisnesLogo.size > 0) {
                files = { ...files, bussisnesLogo };
              }
              const distCenter = this.props.distributionCenter.find(dc => dc.id === company.distributionCenterId);
              if (!isEmpty(distCenter)) company.distributionCenterName = (distCenter || { name: ''}).name;
              await this.props.editCompany(company, files);
            } else {
              const company: Company = { ...values, id: null };
              if (
                CerList.size < 1 ||
                KeyList.size < 1 ||
                company.password === ''
              ) {
                throw this.setErrorMessage(null);
              }
              if (logoUrl.size < 1)
                throw this.setErrorMessage(
                  'La imagen de la factura no puede ir vacia',
                );
              const files = {
                certificate: CerList,
                privateKey: KeyList,
                logo: logoUrl,
                format: FormatList,
                bussisnesImage,
                bussisnesLogo,
              };
              const distCenter = this.props.distributionCenter.find(dc => dc.id === company.distributionCenterId);
              if (!isEmpty(distCenter)) company.distributionCenterName = (distCenter || { name: ''}).name;
              await this.props.saveCompany(company, files);
            }
            localStorage.removeItem('bussisnesImage');
            localStorage.removeItem('bussisnesLogo');
            if (this.props.company.bussisnesImage)
              localStorage.setItem(
                'bussisnesImage',
                this.props.company.bussisnesImage,
              );
            if (this.props.company.bussisnesLogo)
              localStorage.setItem(
                'bussisnesLogo',
                this.props.company.bussisnesLogo,
              );
            this.props.history.push('/enterprice');
          } catch (error) {
            Popups.notifyException(error);
          }
        }
      },
    );
  };
  public render = (): JSX.Element => {
    if (!isAllowed([P.CAN_VIEW_COMPANY_DETAILS])) {
      return <Redirect to="/home" />
    }
    const { company, isFetching, unavailableFolio, paymentChange } = this.props;
    const { currentFolio } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <Spin tip="Cargando..." spinning={this.props.isFetching}>
        <Card>
          <Form onSubmit={this.handleOnSubmit}>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>Información fiscal</h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Nombre Comercial">
                      {getFieldDecorator('comercialName', {
                        initialValue: company ? company.comercialName : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un nombre comercial',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="Nombre comercial" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Razón social">
                      {getFieldDecorator('name', {
                        initialValue: company ? company.name : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un nombre de empresa',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="nombre" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="RFC">
                      {getFieldDecorator('rfc', {
                        initialValue: company ? company.rfc : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un RFC',
                          },
                          {
                            pattern: rfcformat,
                            message: 'Ingresa un rfc válido',
                          },
                          {
                            max: 20,
                            whitespace: true,
                            message: 'No se permiten mas de 20 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="RFC" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Régimen fiscal">
                      {getFieldDecorator('fiscalRegime', {
                        initialValue: company ? company.fiscalRegime : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un nombre de empresa',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(
                        <Select showSearch size="large">
                          {regimenFiscal.map(
                            (item, index): React.ReactNode => (
                              <Select.Option key={item.key} value={item.key}>
                                <div id={'reg_' + index.toString()}>
                                  {item.name}
                                </div>
                              </Select.Option>
                            ),
                          )}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>Domicilio</h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Calle">
                      {getFieldDecorator('street', {
                        initialValue: company ? company.street : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa una calle',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="calle" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    xxl={{ span: 5, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Número">
                      {getFieldDecorator('externalNumber', {
                        initialValue: company ? company.externalNumber : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un número exterior',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="Número exterior" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    xxl={{ span: 5, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Interior (Si aplica)">
                      {getFieldDecorator('internalNumber', {
                        initialValue: company ? company.internalNumber : '',
                        rules: [
                          {
                            whitespace: true,
                            message: 'Ingresa un municipio',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="número Interior" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Colonia">
                      {getFieldDecorator('colony', {
                        initialValue: company ? company.colony : '',
                        rules: [
                          { required: true, message: 'Ingresa una colonia' },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="colonia" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                    xxl={{ span: 5, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Código postal">
                      {getFieldDecorator('zipCode', {
                        initialValue: company ? company.zipCode : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un código postal',
                          },
                          {
                            max: 5,
                            whitespace: true,
                            message: 'No se permiten mas de 5 caracteres',
                          },
                          {
                            pattern: spacesformat,
                            message: 'Ingresa un código postal Válido',
                          },
                        ],
                      })(<Input size="large" placeholder="código postal" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Ciudad">
                      {getFieldDecorator('city', {
                        initialValue: company ? company.city : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa una ciudad',
                          },
                        ],
                      })(<Input size="large" placeholder="ciudad" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Municipio">
                      {getFieldDecorator('municipality', {
                        initialValue: company ? company.municipality : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un municipio',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="municipio" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Estado">
                      {getFieldDecorator('state', {
                        initialValue: company ? company.state : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un estado',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="Estado" />)}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Imagen y logo de la empresa
                </h2>
                  <Col span={12}>
                    <h4>Imagen</h4>
                    {this.state.bussisnesImage.size <= 1 &&
                      company.bussisnesImage ? (
                        <img
                          alt="Logo"
                          width="150"
                          src={'data:image/jpg;base64,' + company.bussisnesImage}
                        />
                      ) : (
                        <img alt="Logo" width="150" src={logo} className="logo" />
                      )}
                  </Col>
                  <Col span={12}>
                    <h4>Logo</h4>
                    {this.state.bussisnesLogo.size <= 1 &&
                      company.bussisnesLogo ? (
                        <img
                          alt="Logo"
                          width="150"
                          src={'data:image/jpg;base64,' + company.bussisnesLogo}
                        />
                      ) : (
                        <img alt="Logo" width="150" src={image} className="logo" />
                      )}
                  </Col>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Upload
                      name="bussisnesImage"
                      action="/"
                      accept=".jpg, .jpeg, .gif, .png"
                      listType="picture"
                      beforeUpload={this.handleBeforeUploadBussisnesImage}
                      onRemove={this.handleRemoveBussisnesImage}
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {this.state.bussisnesImage.size <= 1
                        ? this.uploadButton(
                          isEmpty(company.bussisnesImage)
                            ? 'Seleccionar Imagen de empresa'
                            : 'Cambiar Imagen',
                        )
                        : 'Imagen'}
                    </Upload>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Upload
                      name="formatFile"
                      action="/"
                      accept=".jpg, .jpeg, .gif, .png"
                      listType="picture"
                      beforeUpload={this.handleBeforeUploadBussisnesLogo}
                      onRemove={this.handleRemoveBussisnesLogo}
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {this.state.bussisnesLogo.size <= 1
                        ? this.uploadButton(
                          isEmpty(company.bussisnesLogo)
                            ? 'Seleccionar Logo'
                            : 'Cambiar Logo',
                        )
                        : 'Logo'}
                    </Upload>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>Logo de factura</h2>
                  {this.state.logoUrl.size <= 1 && company.logoUrl && (
                    <img
                      alt="Logo"
                      width="150"
                      src={'data:image/jpg;base64,' + company.logoUrl}
                    />
                  )}
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Upload
                      name="logoFile"
                      action="/"
                      accept=".jpg, .jpeg, .gif, .png"
                      listType="picture"
                      beforeUpload={this.handleBeforeUploadLogo}
                      onRemove={this.handleRemoveLogo}
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {this.state.logoUrl.size <= 1
                        ? this.uploadButton(
                          isEmpty(company.logoUrl)
                            ? 'Seleccionar Logo de factura'
                            : 'Cambiar Logo de factura',
                        )
                        : 'Logo'}
                    </Upload>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>Centro de distribución</h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Centro de distribución G4S">
                      {getFieldDecorator('distributionCenterId', {
                        initialValue: company ? company.distributionCenterId : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Selecciona un centro de distribución',
                          },
                        ],
                      })(
                        <Select showSearch size="large">
                          {this.props.distributionCenter.map(
                            (item, index): React.ReactNode => (
                              <Select.Option key={item.id} value={item.id}>
                                <div id={'dist_' + index.toString()}>
                                  {item.name}
                                </div>
                              </Select.Option>
                            ),
                          )}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>

            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Configuración de la compañia
                </h2>
                </Col>
                <Col span={14}>
                  {!this.props.isEquimentNumber && <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label={'Serie'}>
                      {getFieldDecorator('serie', {
                        initialValue: currentFolio.serie,
                        rules: [
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                          {
                            required: true,
                            message: 'La serie es requerida',
                          },
                          {
                            validator: (obj: object, value: string) => {
                              if (currentFolio && currentFolio.serie === value) {
                                return true;
                              } else {
                                return !unavailableFolio.some(
                                  unavailableFolio =>
                                    unavailableFolio.serie.trim().toLowerCase() === value.trim().toLowerCase(),
                                );
                              }
                            },
                            message: 'La serie no está disponible',
                          },
                        ],
                      })(<Input onChange={(e) => this.setState({ serie: e.target.value })} size="large" placeholder="serie" />)}
                    </Form.Item>
                  </Col>}
                  {!this.props.isEquimentNumber && <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label={'Folio'}>
                      {getFieldDecorator('folio', {
                        initialValue: currentFolio.lastFolio,
                        rules: [
                          {
                            pattern: onlyNumberPattern,
                            message: 'El folio debe ser un número entero',
                          },
                          {
                            required: true,
                            message: 'El folio es requerido',
                          },
                          {
                            validator: (obj: object, value) => {
                              if (this.state.serie === this.state.currentFolio.serie) {
                                return value >= this.state.currentFolio.lastFolio;
                              } else {
                                return true
                              }
                            },
                            message: 'El folio no está disponible',
                          },
                        ],
                      })(<InputNumber size="large" placeholder="Folio" />)}
                    </Form.Item>
                  </Col>}
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label={'Seleccionar ventas de crédito a facturar'}>
                      {getFieldDecorator('selectCreditInvoice', {
                        initialValue: company.selectCreditInvoice,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      label={'Facturar ventas de crédito automáticamente'}
                    >
                      {getFieldDecorator('creditInvoiceAuto', {
                        initialValue: company.creditInvoiceAuto,
                        valuePropName: 'checked',
                        rules: [],
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      label={'Permitir a cliente cancelación de factura'}
                    >
                      {getFieldDecorator('clientCancel', {
                        initialValue: company.clientCancel,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  {paymentChange &&
                    <Col
                      xs={{ span: 23, offset: 1 }}
                      sm={{ span: 23, offset: 1 }}
                      md={{ span: 23, offset: 1 }}
                      lg={{ span: 11, offset: 1 }}
                      xl={{ span: 11, offset: 1 }}
                      xxl={{ span: 11, offset: 1 }}
                    >
                      <Form.Item
                        hasFeedback
                        label={
                          'Texto personalizado para campo condicion de pago a credito'
                        }
                      >
                        {getFieldDecorator('creditFieldCustomText', {
                          initialValue: company.creditFieldCustomText,
                          rules: [
                            {
                              max: 100,
                              whitespace: true,
                              message: 'No se permiten mas de 100 caracteres',
                            },
                            {
                              required: true,
                              message: 'El texto personalizado es requerido',
                            },
                          ],
                        })(
                          <Input size="large" placeholder="Texto personalizado" />,
                        )}
                      </Form.Item>
                    </Col>
                  }
                </Col>
              </Row>
            </Card>

            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>Credenciales SAT</h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Upload
                      name="keyFile"
                      action="/"
                      accept=".key"
                      listType="picture"
                      beforeUpload={this.handleBeforeUploadKey}
                      onRemove={this.handleRemoveKey}
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {this.state.KeyList.size <= 1
                        ? this.uploadButton(
                          isEmpty(company.privateKey)
                            ? 'Seleccionar key'
                            : 'Cambiar key',
                        )
                        : 'Archivo key'}
                    </Upload>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Upload
                      name="cerFile"
                      action="/"
                      accept=".cer"
                      listType="picture"
                      beforeUpload={this.handleBeforeUploadCer}
                      onRemove={this.handleRemoveCer}
                      showUploadList={{ showPreviewIcon: false }}
                    >
                      {this.state.CerList.size <= 1
                        ? this.uploadButton(
                          isEmpty(company.certificate)
                            ? 'Seleccionar cer'
                            : 'Cambiar cer',
                        )
                        : 'Archivo cer'}
                    </Upload>
                  </Col>
                  <Col span={23} offset={1}>
                    <Form.Item
                      hasFeedback
                      label={
                        isEmpty(company.password)
                          ? 'password'
                          : 'Cambiar password'
                      }
                    >
                      {getFieldDecorator('password', {
                        initialValue: '',
                        rules: [
                          {
                            required: company.id ? false : true,
                            whitespace: true,
                            message: 'Ingrese su contraseña del Sat',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(
                        <Input.Password
                          prefix={<LockOutlined />}
                          size="large"
                          placeholder="password"
                          type="password"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>

            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Credenciales de facturación
                </h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Email para publico general">
                      {getFieldDecorator('emailConfig', {
                        initialValue: company ? company.emailConfig : '',
                        rules: [
                          {
                            required: company ? false : true,
                            message: 'Ingresa un email',
                          },
                          {
                            pattern: emailPattern,
                            message: 'Ingresa un email valido',
                          },
                        ],
                      })(
                        <Input prefix={<MailOutlined />} placeholder="Email" />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      hasFeedback
                      label="Usuario del PAC de facturación"
                    >
                      {getFieldDecorator('userConfig', {
                        initialValue: company ? company.userConfig : '',
                        rules: [
                          {
                            required: company ? false : true,
                            message: 'Ingresa un usuario',
                          },
                        ],
                      })(
                        <Input
                          prefix={<UserOutlined />}
                          placeholder="Usuario"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="RFC del PAC de facturación">
                      {getFieldDecorator('rfcConfig', {
                        initialValue: company ? company.rfcConfig : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un RFC',
                          },
                          {
                            pattern: rfcformat,
                            message: 'Ingresa un rfc válido',
                          },
                          {
                            max: 20,
                            whitespace: true,
                            message: 'No se permiten mas de 20 caracteres',
                          },
                        ],
                      })(<Input prefix={<UserOutlined />} size="large" placeholder="RFC" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      hasFeedback
                      label={'password del PAC de facturación'}
                    >
                      {getFieldDecorator('passwordConfig', {
                        initialValue: company ? company.passwordConfig : '',
                        rules: [
                          {
                            required: company ? false : true,
                            whitespace: true,
                            message:
                              'Ingrese su contraseña del PAC de facturación',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(
                        <Input.Password
                          prefix={<LockOutlined />}
                          size="large"
                          placeholder="password"
                          type="password"
                        />,
                      )}
                    </Form.Item>
                  </Col>
                  {!this.props.isEquimentNumber && <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      hasFeedback
                      label={'Numero de permiso del contribuyente.'}
                    >
                      {getFieldDecorator('taxpayerPermitNumber', {
                        initialValue: company.taxpayerPermitNumber,
                        rules: [
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                          {
                            required: true,
                            message:
                              'El numero de permiso del contribuyente es requerido',
                          },
                        ],
                      })(
                        <Input
                          size="large"
                          placeholder="Numero de permiso del contribuyente."
                        />,
                      )}
                    </Form.Item>
                  </Col>}
                </Col>
              </Row>
            </Card>

            <Card className={'customer-card'}>
              <Col span={11} />
              <Col span={6}>
                <Link to="/enterprice">
                  <Button id="cancel_button" type="default" block size="large">
                    Cancelar
                </Button>
                </Link>
              </Col>
              <Col span={6} offset={1}>
                <Button
                  className="button-customer-serch-style"
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  {isFetching
                    ? 'Cargando...'
                    : isEmpty(company.id)
                      ? 'Agregar empresa'
                      : 'Modificar empresa'}
                </Button>
              </Col>
            </Card>
          </Form>
        </Card>
      </Spin>
    );
  };

}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    company: states.companyState.company,
    distributionCenter:
      states.companyState.distributionCenter.distributionCenter,
    unavailableFolio: states.companyState.unavailableFolio.unavailableFolio,
    isFetching: states.companyState.isFetching,
    paymentChange: states.saleState.paymentChange,
    fiscalPermissions: states.companyState.fiscalPermissions,
    isEquimentNumber: states.companyState.isEquimentNumber,
  };
};

const mapDispatchToProps = {
  saveCompany,
  editCompany,
  getCompany,
  cleanCompany,
  getDistributionCenter,
  getUnavailableFolio,
  getFiscalPermission
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(CompanyFormComponent));
