import { combineReducers, Reducer } from 'redux';

export interface SupportState {
  readonly isFetching: boolean;
}

const isFetching: Reducer<boolean> = (
  state = false,
  action = {type: ''},
): boolean => {
  switch (action.type) {
    case 'BEGIN_FETCH':
      return true;
    case 'FETCH_COMPLETED':
      return false;
    default:
      return state;
  }
};

export default combineReducers<SupportState>({
  isFetching,
});