/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */
import SaleService from '../../../services/sale';
import CortesService from 'services/cortes';
import { parseApiError } from '../../../commons/error';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SaleAction,
  SET_END_DATE,
  SET_INIT_DATE,
  SET_SALE,
  SET_SALE_SELECTED,
  SET_SUM_TOTALLK,
  SET_SUM_TOTALLK_BILLABLE,
  SET_SUM_TOTALLK_SELECTED,
  SET_SUM_TOTALM,
  SET_SUM_TOTALM_BILLABLE,
  SET_SUM_TOTALM_SELECTED,
  PAYMENT_CHANGE,
  TOTAL_ELEMENTS_BILLABLE,
  TOTAL_ELEMENTS_SELECTED,
  CLEAN,
  SET_FORMA_PAGO,
  SET_CORTES,
  TOTAL_SALES,
  BILLED_SALES_TOTAL,
  TOTAL_VOLUME_BILLED,
  TOTAL_SALES_SIZE,
  TOTAL_CANCEL_SALES,
  COUNTER_ONLY_SALES,
} from './types';
import { isEmpty } from 'lodash';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { Sale, SalesListAndTotal, FormaPagoCondicion, Corte, ExcelFilters } from 'types/type';
import { Popups } from 'commons/components/popups/popups';
import { getDecodeToken } from 'security';
import moment from 'moment';

const saleService = new SaleService();
const cortesService = new CortesService();

type ITA = ThunkAction<Promise<void>, RootState, {}, SaleAction>;
type IDA = ThunkDispatch<{}, {}, SaleAction>;

const startFetch = (): SaleAction => {
  return { type: BEGIN_FETCH };
};

/* const startBilledFetch = (): SaleAction => {
  return { type: BEGIN_FETCH_BILLED };
}; */
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): SaleAction => {
  return { type: FETCH_COMPLETED };
};

/* const endBilledFetch = (): SaleAction => {
  return { type: FETCH_COMPLETED_BILLED };
};
 */
export const cleanSales = (): SaleAction => {
  return { type: CLEAN };
};

export const setInitDate = (date: Date): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch({ type: SET_INIT_DATE, initDate: date });
  dispatch(endFetch());
};

export const setEndDate = (date: Date): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch({ type: SET_END_DATE, endDate: date });
  dispatch(endFetch());
};

export const invoiceAll = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  Popups.notifySuccess('Se van a facturar todas las ventas mayores a 72hrs');
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    saleService.invoiceAll();
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const changeToInternal = (idCut: string): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      await saleService.changeToInternal(idCut);
      Popups.notifySuccess('Se han modificado las ventas correctamente');
    } catch (err) {
      Popups.notifyException(parseApiError(err));
    }
  };
};

export const changeToSale = (idCut: string): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      await saleService.changeToSale(idCut);
      Popups.notifySuccess('Se han modificado las ventas correctamente');
    } catch (err) {
      Popups.notifyException(parseApiError(err));
    }
  };
};

export const downloadFile = (
  id: string,
  name: string,
  saleFolio: string,
  showFile: boolean,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  dispatch(startFetch());
  Popups.notifySuccess(showFile ? 'Generando Vista previa ' : 'Descargando archivo ' + saleFolio + '-' + name);
  await saleService.downloadFile(id, name, saleFolio, showFile);
  dispatch(endFetch());
};

export const changeBillable = (id: string, billable: boolean): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const {
      saleList,
      sumTotalLKBillable,
      sumTotalMBillable,
      totalElementsBillable,
      saleSelectedList,
    } = getState().saleState;
    const { content } = saleList;
    let response = await saleService.changeBillable(id, billable);
    let newcontent: Sale[] = [];
    let newSumTotalLk = 0;
    let newSumTotalM = 0;
    let newTotalElements = 0;
    let newTotalElementsSelected = 0;
    let newSaleSelectedList = null
    if (content !== undefined) {
      newcontent = content.map((item: Sale) => {
        if (item.id === response.id) {
          if (response.billable) {
            let index = saleSelectedList.findIndex(s => s.id === response.id)
            if (index >= 0) {
              newSaleSelectedList = [...saleSelectedList]
              newSaleSelectedList.splice(index, 1);
            }
            newSumTotalLk = sumTotalLKBillable + Number(response.totalVolume);
            newSumTotalM = sumTotalMBillable + Number(response.total);
            newTotalElements = totalElementsBillable + 1;
            newTotalElementsSelected = totalElementsBillable + 1;
          } else {
            newSumTotalLk = sumTotalLKBillable - Number(response.totalVolume);
            newSumTotalM = sumTotalMBillable - Number(response.total);
            newTotalElements = totalElementsBillable - 1;
            if (!saleSelectedList.some(s => s.id === response.id)) {
              newTotalElementsSelected = totalElementsBillable - 1;
            } else {
              newTotalElementsSelected = totalElementsBillable;
            }
          }
          return response;
        }
        return item;
      });
    }
    if (newSaleSelectedList) {
      dispatch({
        type: SET_SALE_SELECTED,
        saleSelectedList: newSaleSelectedList,
      });
    }
    dispatch({
      type: SET_SALE,
      saleList: { ...saleList, content: newcontent },
    });
    dispatch({
      type: SET_SUM_TOTALM_BILLABLE,
      sumTotalMBillable: newSumTotalM || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_BILLABLE,
      sumTotalLKBillable: newSumTotalLk || 0,
    });
    let totalLkSelected = saleSelectedList.reduce(
      (acumulator, sale) => {
        if (sale.id === id) {
          return acumulator
        } else {
          return acumulator + sale.totalVolume
        }
      },
      0,
    );
    let totalMSelected = saleSelectedList.reduce(
      (acumulator, sale) => {
        if (sale.id === id) {
          return acumulator
        } else {
          return acumulator + sale.total
        }
      },
      0,
    );
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: newSumTotalM - totalMSelected,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected: newSumTotalLk - totalLkSelected,
    });
    dispatch({
      type: TOTAL_ELEMENTS_BILLABLE,
      totalElementsBillable: newTotalElements,
    })
    dispatch({
      type: TOTAL_ELEMENTS_SELECTED,
      totalElementsSelected: newTotalElementsSelected,
    })
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const changeCustomer = (id: string, customerCode: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const { saleList } = getState().saleState;
    const { content } = saleList;
    let response: Sale = await saleService.changeCustomer(id, customerCode);
    let newcontent: Sale[] = [];
    if (content !== undefined) {
      newcontent = content.map((item: Sale) => {
        if (item.id === response.id) {
          return response;
        }
        return item;
      });
    }
    dispatch({
      type: SET_SALE,
      saleList: { ...saleList, content: newcontent },
    });
    Popups.notifySuccess(`Se cambió el cliente correctamente de la venta ${response.IUSCode}`);
    dispatch(endFetch());
  } catch (err) {
    const { saleList } = getState().saleState;
    dispatch({ type: 'CLEAN' })
    dispatch({
      type: SET_SALE,
      saleList
    });
    dispatch(endFetch());
    Popups.notifyException(err);
  }
};

export const changePaymentType = (
  id: string,
  paymentType: string,
  distCenterId: string
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await saleService.changePaymentCondition(id, paymentType, distCenterId);
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const changeformaPago = (
  id: string,
  formaPago: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await saleService.changeFormaPago(id, formaPago);
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const changeToBill = (
  id: string,
  toBill: boolean,
  total: number,
  totalVolume: number,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const {
      saleList,
      saleSelectedList = [],
      sumTotalMSelected,
      sumTotalLKSelected,
    } = getState().saleState;
    let newSaleSelectedList = saleSelectedList;
    const { content } = saleList;
    let newcontent: Sale[] = [];
    if (content !== undefined) {
      newcontent = content.map((item: Sale) => {
        if (item.id === id) {
          return {
            ...item,
            toBill,
          };
        } else {
          return item;
        }
      });
    }
    let newSumTotalMSelected = 0;
    let newSumTotalLkSelected = 0;
    if (toBill) {
      newSaleSelectedList.push({ id, total, totalVolume });
      newSumTotalMSelected = sumTotalMSelected - total;
      newSumTotalLkSelected = sumTotalLKSelected - totalVolume;
    } else {
      newSaleSelectedList = saleSelectedList.filter(sale => sale.id !== id);
      newSumTotalMSelected = sumTotalMSelected + total;
      newSumTotalLkSelected = sumTotalLKSelected + totalVolume;
    }
    dispatch({
      type: SET_SALE,
      saleList: { ...saleList, content: newcontent },
    });
    dispatch({
      type: SET_SALE_SELECTED,
      saleSelectedList: newSaleSelectedList,
    });
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: newSumTotalMSelected,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected: newSumTotalLkSelected,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const alreadyInvoice = (id: string, callback: Function = () => null): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let response = await saleService.alreadyInvoice(id);
    dispatch(endFetch());
    if (response.id) {
      callback()
    }
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

/**
 *This action give sales from db by filters(initDate,endDte,Sale status and Page)
 */

export const page = (
  customer: string,
  initDate: Date,
  endDate: Date,
  filter: string,
  pagePosition: number,
  orderDate: string,
  allowBillable: boolean,
  distributionCenter: string,
  requiredInvoice: string,
  serviceNumberId: string,
  equipmentIds: Array<string>,
  formaPago: string,
  rfc: string,
  cleanSalesSelected = false,
  orderRFC: string,
  cutFolio: Array<string>
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const { saleSelectedList = [] } = getState().saleState;
    let responsepage: SalesListAndTotal;
    if (isEmpty(customer)) {
      responsepage = await saleService.saleList(
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        serviceNumberId,
        equipmentIds,
        formaPago,
        rfc,
        orderRFC,
        cutFolio
      );
    } else {
      responsepage = await saleService.saleListByRfc(
        customer,
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        serviceNumberId,
        equipmentIds,
        formaPago,
        rfc,
        orderRFC,
        cutFolio
      );
    }
    let paymentChange = await saleService.ispaymentChange();
    responsepage.salePage.content.forEach((salePage: Sale) => {
      if (!cleanSalesSelected && saleSelectedList.some(sale => sale.id === salePage.id)) {
        salePage.toBill = true;
      } else salePage.toBill = false;
    });
    if (cleanSalesSelected) {
      dispatch({
        type: SET_SALE_SELECTED,
        saleSelectedList: [],
      });
    }
    dispatch({ type: SET_SALE, saleList: responsepage.salePage });
    dispatch({
      type: SET_SUM_TOTALM,
      sumTotalM: responsepage.sumSales.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK,
      sumTotalLK: responsepage.sumSales.totalVolume || 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_BILLABLE,
      sumTotalMBillable: responsepage.sumSalesTotalBillable.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_BILLABLE,
      sumTotalLKBillable: responsepage.sumSalesTotalBillable.totalVolume || 0,
    });
    let totalLkSelected = saleSelectedList.reduce(
      (acumulator, sale) => acumulator + sale.totalVolume,
      0,
    );
    let totalMSelected = saleSelectedList.reduce(
      (acumulator, sale) => acumulator + sale.total,
      0,
    );
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: responsepage.sumSalesToBill.total - totalMSelected,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected:
        responsepage.sumSalesToBill.totalVolume - totalLkSelected,
    });
    dispatch({
      type: PAYMENT_CHANGE,
      paymentChange,
    });
    dispatch({
      type: TOTAL_ELEMENTS_BILLABLE,
      totalElementsBillable: responsepage.sumSalesTotalBillable.totalElements,
    })
    dispatch({
      type: TOTAL_ELEMENTS_SELECTED,
      totalElementsSelected: responsepage.sumSalesToBill.totalElements,
    });

    dispatch({
      type: TOTAL_SALES,
      totalSales: responsepage.salesBilled.totalElements.toString(),
    });
    dispatch({
      type: BILLED_SALES_TOTAL,
      billedSalestotal: responsepage.salesBilled.total.toString(),
    })
    dispatch({
      type: TOTAL_VOLUME_BILLED,
      totalVolumeSalesTotal: responsepage.salesBilled.totalVolume.toString(),
    });
    dispatch({
      type: TOTAL_SALES_SIZE,
      totalSalesSize: responsepage.salesBilled.totalElements.toString(),
    });
    dispatch({ 
      type: TOTAL_CANCEL_SALES, 
      totalCancelSales: responsepage.totalCanceledSales 
    });
    dispatch({
      type: COUNTER_ONLY_SALES,
      counterOnlySales: responsepage.counterOnlySales,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

/* export const saleInfoBilled = (
  initDate: Date,
  endDate: Date,
  distributionCenter: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  try {
    if (getIsFetchingBilled(getState())) {
      return Promise.resolve();
    }
    dispatch(startBilledFetch());
    let responsepage: SalesListAndTotal;
    responsepage = await saleService.saleInfoBilled(
      initDate,
      endDate,
      distributionCenter,
    );
    dispatch({
      type: TOTAL_SALES,
      totalSales: responsepage.totalSales,
    });
    dispatch({
      type: BILLED_SALES_TOTAL,
      billedSalestotal: responsepage.billedSalestotal,
    })
    dispatch({
      type: TOTAL_VOLUME_BILLED,
      totalVolumeSalesTotal: responsepage.totalVolumeSalesTotal,
    });
    dispatch({
      type: TOTAL_SALES_SIZE,
      totalSalesSize: responsepage.totalSalesSize,
    });
    dispatch(endBilledFetch());
  } catch (err) {
    dispatch(endBilledFetch());
    Popups.notifyException(parseApiError(err));
  }
}; */

/* export const saleInfoBilledAndCanceled = (
  initDate: Date,
  endDate: Date,
  distributionCenter: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  try {
    if (getIsFetchingBilled(getState())) {
      return Promise.resolve();
    }
    dispatch(startBilledFetch());
    let responsepage: BilledAndCanceledInfo;
    responsepage = await saleService.saleInfoBilledAndCanceled(
      initDate,
      endDate,
      distributionCenter,
    );
    dispatch({
      type: TOTAL_SALES,
      totalSales: responsepage.salesBilled.totalElements.toString(),
    });
    dispatch({
      type: BILLED_SALES_TOTAL,
      billedSalestotal: responsepage.salesBilled.total.toString(),
    })
    dispatch({
      type: TOTAL_VOLUME_BILLED,
      totalVolumeSalesTotal: responsepage.salesBilled.totalVolume.toString(),
    });
    dispatch({
      type: TOTAL_SALES_SIZE,
      totalSalesSize: responsepage.salesBilled.totalElements.toString(),
    });
    dispatch({ type: TOTAL_CANCEL_SALES, totalCancelSales: responsepage.totalCanceledSales });






    dispatch(endBilledFetch());
  } catch (err) {
    dispatch(endBilledFetch());
    Popups.notifyException(parseApiError(err));
  }
}; */

/* export const saleInfoCanceled = (
  initDate: Date,
  endDate: Date,
  distributionCenter: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  try {
    if (getIsFetchingBilled(getState())) {
      return Promise.resolve();
    }
    dispatch(startBilledFetch());
    let responsepage: string = '0';
    responsepage = await saleService.saleInfoCanceled(
      initDate,
      endDate,
      distributionCenter,
    );
    dispatch({ type: TOTAL_CANCEL_SALES, totalCancelSales: responsepage });
    dispatch(endBilledFetch());
  } catch (err) {
    dispatch(endBilledFetch());
    Popups.notifyException(parseApiError(err));
  }
}; */

export const createExcelByFilters = (
  endDate: Date,
  initDate: Date,
  orderDate: String,
  filter: String,

  allowBillable: boolean,
  distributionCenter: String,
  requiredInvoice: String,
  serviceNumberId: String,
  equipmentId:  Array<String>,
  formaPago: String,

  RFC: String,
  orderRFC: String,
  cutId: Array<String>,

  userId: String,
  reportType: String,
  emailToSend: String,
  onlyClientSales: boolean,
) : ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    let filters: ExcelFilters = {
      endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      initDate: moment(initDate).format('YYYY-MM-DD HH:mm:ss'),
      orderDate: orderDate,
      allowBillable: allowBillable,
      filter: filter,
      distributionCenter: distributionCenter,
      requiredInvoice: requiredInvoice,
      userId: userId,
      serviceNumberId: serviceNumberId,
      equipmentId: equipmentId,
      cutId: cutId,
      formaPago: formaPago,
      rfc: RFC,
      orderRFC: orderRFC,
      reportType: reportType,
      emailToSend: emailToSend,
      onlyClientSales: onlyClientSales,
    };
    await saleService.getExcelByFilters(filters);    
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

/* export const getExcel = (
  customer: string,
  initDate: Date,
  endDate: Date,
  orderDate: string,
  filter: string,
  allowBillable: boolean,
  distributionCenter: string,
  requiredInvoice: string,
  serviceNumberId: string,
  equipmentId: Array<string>,
  formaPago: string,
  rfc: string,
  orderRFC: string,
  cutFolio: Array<string>
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    if (isEmpty(customer)) {
      await saleService.getExcel(
        initDate,
        endDate,
        orderDate,
        filter,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        serviceNumberId,
        equipmentId,
        formaPago,
        rfc,
        orderRFC,
        cutFolio
      );
    } else {
      await saleService.getExcelByRfc(
        customer,
        initDate,
        endDate,
        orderDate,
        filter,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        serviceNumberId,
        equipmentId,
        formaPago,
        rfc,
        orderRFC,
        cutFolio
      );
    }
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}; */

/* export const getExcelBilled = (
  initDate: Date,
  endDate: Date,
  distributionCenter: string,
  newExcel: boolean,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await saleService.getExcelBilled(
      initDate,
      endDate,
      distributionCenter,
      newExcel
    );
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}; */

/* export const getExcelCancelled = (
  initDate: Date,
  endDate: Date,
  distributionCenter: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await saleService.getExcelCancelled(
      initDate,
      endDate,
      distributionCenter
    );
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}; */

export const invoicingSale = (
  sale: Sale,
  customer: string,
  initDate: Date,
  endDate: Date,
  filter: string,
  pagePosition: number,
  orderDate: string,
  allowBillable: boolean,
  distributionCenter: string,
  requiredInvoice: string,
  serviceNumber: string,
  equipmentIds: Array<string> = [],
  formaPago: string,
  rfc: string,
  orderRFC: string,
  cutFolio: Array<string>
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const id = getDecodeToken().id;
    const _sale = { ...sale, userId: id };
    await saleService.saveSaleToInvoice(_sale);
    let responsepage;
    if (isEmpty(customer)) {
      responsepage = await saleService.saleList(
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        serviceNumber,
        equipmentIds,
        formaPago,
        rfc,
        orderRFC,
        cutFolio
      );
    } else {
      responsepage = await saleService.saleListByRfc(
        customer,
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        '',
        [],
        '',
        rfc,
        orderRFC,
        cutFolio
      );
    }
    dispatch({ type: SET_SALE, saleList: responsepage.salePage });
    dispatch({
      type: SET_SUM_TOTALM,
      sumTotalM: responsepage.sumSales.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK,
      sumTotalLK: responsepage.sumSales.totalVolume || 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_BILLABLE,
      sumTotalMBillable: responsepage.sumSalesTotalBillable.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_BILLABLE,
      sumTotalLKBillable: responsepage.sumSalesTotalBillable.totalVolume || 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: responsepage.sumSalesToBill.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected:
        responsepage.sumSalesToBill.totalVolume || 0,
    });
    dispatch({
      type: TOTAL_ELEMENTS_BILLABLE,
      totalElementsBillable: responsepage.sumSalesTotalBillable.totalElements || 0,
    })
    dispatch({
      type: TOTAL_ELEMENTS_SELECTED,
      totalElementsSelected: responsepage.sumSalesToBill.totalElements || 0,
    })
    dispatch(endFetch());
    Popups.notifySuccess('Venta enviada a facturar');
  } catch (error) {
    Popups.notifyException(error);
    dispatch(endFetch());
  }
};

export const sendAllSalesToInvoice = (
  excludeSaleIds: string[],
  customer: string,
  initDate: Date,
  endDate: Date,
  filter: string,
  pagePosition: number,
  orderDate: string,
  allowBillable: boolean,
  distributionCenter: string,
  requiredInvoice: string,
  serviceNumberId: string,
  equipmentId: Array<string>,
  formaPago: string,
  rfc: string,
  expectedSales: number,
  singleInvoice: boolean,
  orderRFC: string,
  cutFolio: Array<string>,
  idUser: string
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    await saleService.saveAllSalesToInvoice(
      excludeSaleIds,
      initDate,
      endDate,
      orderDate,
      distributionCenter,
      requiredInvoice,
      serviceNumberId,
      equipmentId,
      formaPago,
      rfc,
      expectedSales,
      singleInvoice,
      orderRFC,
      cutFolio,
      idUser
    );
    let responsepage;
    if (isEmpty(customer)) {
      responsepage = await saleService.saleList(
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        serviceNumberId,
        equipmentId,
        formaPago,
        rfc,
        orderRFC,
        cutFolio
      );
    } else {
      responsepage = await saleService.saleListByRfc(
        customer,
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        '',
        '',
        [],
        '',
        rfc,
        orderRFC,
        cutFolio
      );
    }
    dispatch({ type: SET_SALE, saleList: responsepage.salePage });
    dispatch({
      type: SET_SUM_TOTALM,
      sumTotalM: responsepage.sumSales.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK,
      sumTotalLK: responsepage.sumSales.totalVolume || 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_BILLABLE,
      sumTotalMBillable: responsepage.sumSalesTotalBillable.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_BILLABLE,
      sumTotalLKBillable: responsepage.sumSalesTotalBillable.totalVolume || 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: responsepage.sumSalesToBill.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected:
        responsepage.sumSalesToBill.totalVolume || 0,
    });
    dispatch({
      type: TOTAL_ELEMENTS_BILLABLE,
      totalElementsBillable: responsepage.sumSalesTotalBillable.totalElements || 0,
    })
    dispatch({
      type: TOTAL_ELEMENTS_SELECTED,
      totalElementsSelected: responsepage.sumSalesToBill.totalElements || 0,
    })
    dispatch({
      type: SET_SALE_SELECTED,
      saleSelectedList: [],
    });
    dispatch(endFetch());
    Popups.notifySuccess('Ventas enviadas a facturar');
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    Popups.notifyException(error);
  }
};

export const getFormaPago = (): ITA => async (
  dispatch: IDA
): Promise<void> => {
  try {
    dispatch(startFetch());
    const formaPago: Array<FormaPagoCondicion> = await saleService.getformaPago();
    formaPago.forEach((fp) => {
      fp.condicionPagoArray = fp.condicionPago.split(",").map(f => f.trim());
    });
    await dispatch({
      type: SET_FORMA_PAGO,
      formaPago,
    });
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    Popups.notifyException(error);
  }
};

export const setCortes = (
  equipmentIds: Array<string> | undefined,
  initDate: Date,
  endDate: Date
): ITA => async (
  dispatch: IDA
): Promise<void> => {
    try {
      const cortes: Array<Corte> = await cortesService.getCortes(equipmentIds, initDate, endDate);
      await dispatch({
        type: SET_CORTES,
        cortes,
      })
    } catch (error) {
      Popups.notifyException(error);
    }
  };
export const clearCortes = (): ITA => async (
  dispatch: IDA
): Promise<void> => {
  try {
    await dispatch({
      type: SET_CORTES,
      cortes: [],
    })
  } catch (error) {
    Popups.notifyException(error);
  }
};
export const getSaleByIus = (ius: string): ITA => async (
  dispatch: IDA
): Promise<void> => {
  try {
    dispatch(startFetch());
    const sale: Sale = await saleService.getbyIUS(ius);
    dispatch({ type: SET_SALE, saleList: { content: [sale], totalElements: 1 }, });
    dispatch({
      type: SET_SUM_TOTALM,
      sumTotalM: sale.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK,
      sumTotalLK: Number(sale.totalVolume) || 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_BILLABLE,
      sumTotalMBillable: sale.billable ? sale.total : 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_BILLABLE,
      sumTotalLKBillable: sale.billable ? Number(sale.totalVolume) : 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: sale.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected: Number(sale.totalVolume) || 0,
    });
    dispatch({
      type: TOTAL_ELEMENTS_BILLABLE,
      totalElementsBillable: sale.billable ? 1 : 0,
    })
    dispatch({
      type: TOTAL_ELEMENTS_SELECTED,
      totalElementsSelected: 1 || 0,
    })
    dispatch(endFetch());
  } catch (error) {
    dispatch(endFetch());
    Popups.notifyException(error);
  }
};
export const getZipFile = (
  initDate: Date,
  endDate: Date,
  distributionCenter: string,
  email: string,
  customerRfc: string
): ITA => async (
  dispatch: IDA
): Promise<void> => {
    try {
      dispatch(startFetch());
      await saleService.createZip(initDate, endDate, distributionCenter, email, customerRfc);
      dispatch(endFetch());
    }
    catch (err) {
      dispatch(endFetch());
      Popups.notifyException(err);
    }
  }