import React, { Component } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
class InvoiceComplete extends Component {
  public render = (): React.ReactNode => {
    return (
      <Row>
        <Col style={{ margin: '0 auto', textAlign: 'center' }}>
          <Card className="invoice-card">
            <p>
              <CheckCircleOutlined style={{ fontSize: '10rem', color: '#4B86C7' }} />
            </p>

            <p>
              <h1>Factura en proceso, favor de verificar tu correo</h1>
            </p>
            <p>
              <h3>
                En unos minutos recibirás la factura con el correo que nos
                proporcionaste
              </h3>
            </p>
            <p>
              <Link to="/">
                <Button
                  type="primary"
                  className="button-invoice-serch-style"
                  block
                >
                  Ir a Inicio
                </Button>
              </Link>
            </p>
          </Card>
        </Col>
      </Row>
    );
  };
}

export default InvoiceComplete;
