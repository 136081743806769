import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Tabs,
  List,
  Modal,
} from 'antd';
import logo from './../../assets/logo_login.png';
import { Invoice, Sale, Email, Permission, CompanyImages } from 'types/type';
import { Strings as i8n } from 'commons/strings';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  saveInvoce,
  resendInvoce,
  InvoiceActionConstructors,
  addSaleToStagingArea,
  removeSaleToStagingArea,
} from 'routes/invoices/redux/actions';
import { getCompanyImages } from '../company/redux/actions';
import { Popups } from 'commons/components/popups/popups';
import { emailPattern } from './../../commons/regularExpressions';
import { initInvoice } from 'commons/initTypes';
import { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form';
import './style.css';
import { login, canRegisterUser } from './redux/actions';
import { saveuser, getPermissions } from 'routes/users/redux/actions';
import { AccessToken } from './redux/reducers';
import { InvoiceState } from 'routes/invoices/redux/reducers';
import { RootState } from '../../store/configureStore';
import SaleForm from './sale/saleForm';
import FiscalDataForm from './FiscalData/fiscalDataForm';
import { InvoiceRules as r } from './FiscalData/rulesFiscalData';
import { isAuthenticated } from 'security';
import { Permissions } from 'commons/permissions';
import WrappedSignInForm from './signIn'
import { ArrowRightOutlined, CloseCircleOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

//Redirect when invoice is created
const REDIRECT_FINISH = '/factura-finish';

interface DispatchProps {
  login: (username: string, password: string) => void;
  getCompanyImages: () => void;
  saveuser: (user: any, getUserList: boolean) => void;
  getPermissions: () => void;
  canRegisterUser: () => void;
}

/**
 * This is the state of the react component is not shared outside
 * this file, this do not go to the redux store. The state
 * represents an invoice that will be passed in different states
 * of the invoicing wizard.
 */

interface StepsInvice {
  step: number;
  content: JSX.Element;
}

interface StateProps {
  accessToken: AccessToken;
  canRegistred: boolean;
  readonly salesStagingArea: Sale[];
  readonly isFetching: boolean;
  companyImagesList: CompanyImages[];
  permissionList: Permission[];

}

interface InvoiceFormFields {
  ius: string;
  total: number;
}

type Props = FormComponentProps &
  InvoiceState &
  StateProps &
  DispatchProps &
  RouteComponentProps &
  InvoiceActionConstructors;

class Login extends Component<Props> {

  componentWillMount() {
    this.props.canRegisterUser();
    this.props.getPermissions();
    this.props.getCompanyImages();
  }

  componentWillUnmount() {
    this.props.addSaleToStagingArea('0', 0, true);
  }

  public state = {
    forgotPassword: false,
    invoice: initInvoice,
    currentFormStep: 0,
    tabIndex: 1,
    quitSales: false,
    saleBilled: false,
    saleCustomerRfc: '',
    clientCancel: false,
    cancelable: false,
    isSecure: false,
    openModal: false,
    activeKey: '1',
    RFC: '',
    openModalRegister: false,
    showTextRegister: false,
  };

  public handleNext = (): void => {
    const { salesStagingArea } = this.props;
    if (salesStagingArea.length < 1) {
      Popups.notifyError(i8n.NO_TICKET_IN_STAGING_AREA);
    } else {
      this.next();
    }
  };

  private next = (): void => {
    const currentFormStep = this.state.currentFormStep + 1;
    this.setState({ currentFormStep });
  };

  private prev = (): void => {
    const currentFormStep = this.state.currentFormStep - 1;
    this.setState({ currentFormStep });
  };

  public handlePrevious = (): void => {
    this.prev();
  };

  public renderCancelButton = (): JSX.Element => (
    <Button type="ghost" onClick={() => window.location.reload()}>
      Cancelar <CloseCircleOutlined />
    </Button>
  );

  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      (err: any, values: any): void => {
        if (!err) {
          this.props.login(values.username, values.password);
        }
      },
    );
  };

  public permissionFilter(permissions: string[]): string[] {
    const { permissionList } = this.props;
    let permissionsFilter: string[] = [];
    let c: Permission;
    permissions.forEach((permissionName: string) => {
      c = permissionList.filter(
        (permission: Permission): boolean =>
          permission.permissionName === permissionName,
      )[0];
      permissionsFilter.push(c.id);
    });
    return permissionsFilter;
  }

  private saveUser = (e: FormEvent, form: WrappedFormUtils<InvoiceFormFields>): void => {
    e.preventDefault();
    form.validateFields(
      (err, values): void => {
        if (!err) {
          try {
            const userPermission = this.permissionFilter([
              Permissions.CAN_VIEW_SALES,
              Permissions.CAN_CREATE_INVOICES,
            ]);
            this.props.saveuser({ ...values, userPermission, position: 4, isClient: true }, false);
            form.resetFields();
            this.setState({ openModalRegister: false })
          } catch (error) {
            Popups.notifyException(error);
          }
        }
      },
    );
  }

  /**
   * Called when pressed button add Ticket-From-IUS
   */
  private handleOnSubmitIUS = (
    e: FormEvent,
    form: WrappedFormUtils<InvoiceFormFields>,
  ): void => {
    e.preventDefault();
    form.validateFields(
      async (err, formFields: InvoiceFormFields): Promise<void> => {
        if (!err) {
          try {
            await this.props.addSaleToStagingArea(
              `${formFields.ius.toUpperCase()}`,
              formFields.total,
              false,
            );
            form.resetFields();
            form.setFieldsValue({ ius: null });
            form.setFieldsValue({ total: null });
          } catch (error) {
            Popups.notifyException(error);
          }
        }
      },
    );
  };

  /**
   * The staging area that include the found tickets by IUS
   */
  public renderSalesStagingArea = (): JSX.Element => {
    return (
      <Col style={{ backgroundColor: '#fff' }}>
        {this.props.salesStagingArea.length > 0 ? (
          <Card
            className={'card-invoice-content'}
            type="inner"
            title={i8n.IUS_ADDED_IN_STAGING_AREA}
          >
            <List
              dataSource={this.props.salesStagingArea}
              renderItem={(item: Sale, index: number): JSX.Element => (
                <Col>
                  <List.Item
                    actions={
                      this.state.saleBilled
                        ? []
                        : [
                          <button
                            key="1"
                            id={'invoice_delete_' + index.toString()}
                            type="button"
                            className="link-button"
                            onClick={() => {
                              this.props.removeSaleToStagingArea(item);
                              this.setState({
                                saleBilled: false,
                                quitSales: false,
                              });
                            }}
                          >
                            Eliminar
                          </button>,
                        ]
                    }
                  >
                    <List.Item.Meta
                      title={
                        <div id={'invoice_list_' + index.toString()}>
                          {item.IUSCode +
                            ' | $' +
                            item.total +
                            ' | ' +
                            moment(item.endDate).format('DD/MM/YYYY') +
                            ((item.billed) ? ' | ' + item.invoiceRfc : '')
                          }
                        </div>
                      }
                    />
                  </List.Item>
                </Col>
              )}
            />
          </Card>
        ) : (
          <Card bordered={false}>
            <div>
              <p />
            </div>
          </Card>
        )}
      </Col>
    );
  };

  private onFiscalDataProvidedPrevius = (
    e: FormEvent,
    form: WrappedFormUtils<Invoice>,
  ): void => {
    e.preventDefault();
    form.validateFields(
      async (err, values: Invoice): Promise<void> => {
        this.setState({ invoice: { ...values } });
        this.prev();
      },
    );
  };

  /**
   * Create the invoice with all the tickets and the
   * correct fiscal data.
   */
  private createInvoice = async (
    salesStagingArea: Sale[],
    fields: Email,
    invoice: Invoice,
  ): Promise<void> => {
    const Invoice = {
      ...invoice,
      email: fields.email,
      sales: salesStagingArea,
      fiscalRegime: invoice.fiscalRegime,
    };
    await this.props.saveInvoce(Invoice);
    this.props.addSaleToStagingArea('0', 0, true);
    Popups.notifySuccess(i8n.INVOICE_CREATED);
    this.props.history.push(REDIRECT_FINISH);
  };

  /**
   * This function is called when the button invoicing is
   * called. Is the final step.
   */
  private onRequestInvoice = (form: WrappedFormUtils<Invoice>): void => {
    form.validateFields(
      async (err, values: Invoice): Promise<void> => {
        if (err) {
          Promise.resolve();
        } else {
          this.setState({ invoice: { ...values } });
          const invoice = { ...values };
          this.props.form.validateFields(
            async (err, fields: Email): Promise<void> => {
              !Boolean(err.email) &&
                Popups.executeOrNotifyError(
                  (): Promise<void> => {
                    if (this.state.isSecure)
                      this.createInvoice(
                        this.props.salesStagingArea,
                        fields,
                        invoice,
                      );
                    else this.setState({ openModal: true });
                    return Promise.resolve();
                  },
                );
            },
          );
        }
      },
    );
  };

  private resendInvoice = (id: string): void => {
    this.props.addSaleToStagingArea('0', 0, true);
    this.props.resendInvoce(id);
    Popups.notifySuccess(i8n.INVOICE_CREATED);
    this.props.history.push(REDIRECT_FINISH);
  };

  /**
   * Third step of the component wizard. Request email and create the
   * invoice.
   */
  private renderEmailForm = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form>
        <Col span={24}>
          <p style={{ marginTop: 20, marginBottom: 20 }}>
            Ingresa el correo electronico donde quieres que te enviemos la
            factura
          </p>
          <Card
            className={'card-invoice-content'}
            style={{
              backgroundColor: '#f1f7ff',
              margin: '0 auto',
              maxWidth: '30rem',
            }}
          >
            <Form.Item hasFeedback label="Correo Electrónico">
              {getFieldDecorator('email', {
                rules: r.emailRule,
              })(<Input size="large" placeholder="Correo electrónico" />)}
            </Form.Item>
          </Card>
        </Col>
      </Form>
    );
  };

  renderCustomButton = (form: WrappedFormUtils<Invoice>): JSX.Element => (
    <Button
      type="primary"
      disabled={this.props.isFetching}
      onClick={() => this.onRequestInvoice(form)}
    >
      {this.props.isFetching ? 'Loading...' : this.state.isSecure ? 'Facturar' : 'Aceptar'}
    </Button>
  );

  public isCancelable = (): boolean => {
    if (this.state.cancelable && ['XAXX010101000', 'AAA010101AAA'].includes(this.state.RFC)) {
      return true;
    }
    return false;
  };

  messageTextRender = (quitSales: boolean,isCancelable: boolean ,canRegistred: boolean): string => {
    if(quitSales){
      return "Una de las ventas ya ha sido Facturada quita ventas facturadas para facturar nuevas o quita no facturadas para reenviar la factura";
    }
    let textResult = "Esta venta ya fue facturada y no puede cancelarse.";
    if(isCancelable){
      if(!canRegistred){
        textResult = "El registro de usuarios esta deshabilitado, contacta a la gasera para solicitar la factura.";
      }else{
        textResult = "Venta Facturada a público en general. Si desea que sea facturada a su RFC lo invitamos a que se registre en nuestro portal para solicitar la factura.";
      }
    }
    return textResult;
  }

  private makeSteps = (): StepsInvice[] => {
    return [
      {
        step: 0,
        content: (
          <Row>
            {this.props.salesStagingArea.length > 0 &&
              !this.state.quitSales &&
              !this.state.saleBilled
              ? this.props.salesStagingArea.forEach(item => {
                if (item.billed && this.props.salesStagingArea.length > 1) {
                  this.setState({ quitSales: true, saleBilled: false });
                }
                if (item.billed && this.props.salesStagingArea.length === 1) {
                  this.setState({
                    quitSales: false,
                    saleBilled: true,
                    saleCustomerRfc: item.invoiceRfc,
                    clientCancel: item.clientCancel,
                    cancelable: item.cancelable,
                    RFC: item.invoiceRfc,
                  });
                }
              })
              : ''}
            {!this.state.saleBilled && !this.state.quitSales ? (
              <SaleForm
                handleOnSubmitIUS={this.handleOnSubmitIUS}
                isFetching={false}
              />
            ) : (
              <div>
                {this.messageTextRender(this.state.quitSales, this.isCancelable(), this.props.canRegistred)}
              </div>
              
            )}
            {this.renderSalesStagingArea()}
            {!this.state.quitSales &&
              (this.state.saleBilled ? (
                <Row style={{ alignItems: 'center' }}>
                  {/*<Col span={6}>
                    <Button
                      type="primary"
                      id="ius_next_button"
                      onClick={(): void =>
                        this.resendInvoice(
                          this.props.salesStagingArea[0].id || '',
                        )
                      }
                    >
                      Reenviar factura <RetweetOutlined />
                    </Button>
                    </Col>*/}
                  {this.isCancelable() && this.props.canRegistred && (
                    <Col span={11} offset={1}>
                      <Button
                        type="primary"
                        id="ius_next_button"
                        onClick={() => {
                          this.setState({ openModalRegister: true, showTextRegister: true })
                        }}
                      >
                        Registrarme
                      </Button>
                    </Col>
                  )}
                  <Col span={5} offset={1}>
                    <Button
                      id="ius_next_button"
                      onClick={(): void => {
                        this.props.addSaleToStagingArea('', 0, true);
                        this.setState({ saleBilled: false });
                      }}
                    >
                      Regresar <CloseCircleOutlined />
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Button
                  type="primary"
                  id="ius_next_button"
                  onClick={(): void => this.handleNext()}
                  style={{ alignSelf: 'center' }}
                >
                  Siguiente <ArrowRightOutlined />
                </Button>
              ))}
          </Row>
        ),
      },
      {
        step: 1,
        content: (
          <FiscalDataForm
            onFiscalDataProvidedPrevius={this.onFiscalDataProvidedPrevius}
            renderCancelButton={this.renderCancelButton}
            isFetching={this.props.isFetching}
            invoice={this.state.invoice}
            customChildren={this.renderEmailForm}
            customButton={this.renderCustomButton}
          />
        ),
      },
    ];
  };

  getCardStyle = (currentFormStep: number): Record<string, any> => {
    switch (currentFormStep) {
      case 0:
        return { maxWidth: '40rem' };
      case 1:
        return this.state.activeKey === '1' ? { maxWidth: '100rem' } : {};
      default:
        return {};
    }
  };

  public render = (): JSX.Element => {
    const { form: { getFieldDecorator }, companyImagesList } = this.props;
    const { currentFormStep } = this.state;
    let stepDisplay: StepsInvice | undefined;
    const stepContent = this.makeSteps();
    stepDisplay = stepContent.find(
      (item): boolean => currentFormStep === item.step,
    );
    const loginImage = localStorage.getItem('bussisnesImage');
    if (companyImagesList.length > 0) {
      if ((companyImagesList[0] || {}).bussisnesImage !== null) {
        localStorage.setItem(
          'bussisnesImage',
          (companyImagesList[0] || {}).bussisnesImage,
        );
      }
      if ((companyImagesList[0] || {}).bussisnesLogo !== null) {
        localStorage.setItem(
          'bussisnesLogo',
          (companyImagesList[0] || {}).bussisnesLogo,
        );
      }
    }
    if (isAuthenticated()) return <Redirect to="/" />;
    if (this.state.forgotPassword) return <Redirect to="/forgotPassword" />;

    const cardStyle = this.getCardStyle(currentFormStep);
    return (
      <div className="login-form">
        <Modal
          visible={this.state.openModal}
          cancelButtonProps={{ children: 'Cancelar' }}
          onCancel={() => this.setState({ openModal: false })}
          okButtonProps={{ children: 'Aceptar' }}
          onOk={() => this.setState({ openModal: false, isSecure: true })}
        >
          <Row>Estas a punto de generar tu factura.</Row>
          <Row>Este proceso no puede ser cancelado.</Row>
          <Row>
            Te sugerimos que revises tu información y verificar que este
            correcta
          </Row>
          <Row>Si estas seguro presiona aceptar y despues en "Facturar" para generar tu factura.</Row>
          <Row>Si no estas seguro presiona cancelar y revisa tus datos</Row>
        </Modal>
        <Modal
          visible={this.state.openModalRegister}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
          closable={false}
        >
          {this.state.showTextRegister && <Row style={{ textAlign: 'center' }} ><h3>Te invitamos a que te registres</h3></Row>}
          {this.state.showTextRegister && <Row style={{ textAlign: 'center' }} ><h3>para que de esta forma puedas acceder y realizar tus facturas</h3></Row>}
          <WrappedSignInForm saveUser={this.saveUser} goBack={() => this.setState({ activeKey: '2', openModalRegister: false })} />
        </Modal>
        <Card className="login-card" style={cardStyle}>
          <Row style={{ backgroundColor: '#f1f7ff' }}>
            <p />
            {isEmpty(loginImage) ? (
              <img
                style={{
                  width: '300px',
                  margin: 'auto',
                  display: 'block',
                }}
                src={logo}
                alt="company_logo"
              />
            ) : (
              <img
                style={{
                  width: '300px',
                  margin: 'auto',
                  display: 'block',
                }}
                alt="Logo"
                src={'data:image/jpg;base64,' + loginImage}
              />
            )}
            <p />
          </Row>
          <Tabs activeKey={this.state.activeKey} onChange={(activeKey: string) => this.setState({ activeKey })}>
            <TabPane tab="Facturar con IUS" key="1">
              <Col offset={1}>{stepDisplay ? stepDisplay.content : null}</Col>
            </TabPane>
            <TabPane tab="Iniciar Sesion" key="2">
              <Form onSubmit={this.handleOnSubmit}>
                <Row>
                  <Row style={{ textAlign: 'center' }}>
                    <p />
                    <h2>Iniciar Sesión</h2>
                    <p />
                  </Row>
                  <Row>
                    <Col span={2}>
                      <div>&nbsp;</div>
                    </Col>
                    <Col span={20}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator('username', {
                          rules: [
                            { required: true, message: 'Ingresa un email' },
                            {
                              pattern: emailPattern,
                              message: 'Ingresa un email valido',
                            },
                          ],
                        })(
                          <Input
                            prefix={<MailOutlined />}
                            placeholder="Email"
                          />,
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <div>&nbsp;</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}>
                      <div>&nbsp;</div>
                    </Col>
                    <Col span={20}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator('password', {
                          rules: [
                            {
                              required: true,
                              message: 'Ingresa una contraseña',
                            },
                          ],
                        })(
                          <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Contraseña"
                          />,
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <div>&nbsp;</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10} offset={2}><a href="/forgotPassword">¿Olvidaste tu contraseña?</a></Col>
                    {this.props.canRegistred && <Col span={10}> <button type="button" className="link-button" onClick={() => this.setState({ openModalRegister: true, showTextRegister: false })}>Registrarse</button></Col>}
                  </Row>
                  <Form.Item />
                </Row>
                <Button
                  block
                  className="button-invoice-serch-style"
                  type="primary"
                  htmlType="submit"
                  id="login_button"
                >
                  Iniciar sesión
                </Button>
              </Form>
            </TabPane>
          </Tabs>
        </Card>
      </div>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    accessToken: states.loginState.accessToken,
    canRegistred: states.loginState.canRegistred,
    salesStagingArea: states.invoiceState.salesStagingArea,
    isFetching: states.invoiceState.isFetching,
    companyImagesList: states.companyState.companyImagesList,
    permissionList: states.usersState.permissionList,
  };
};

/**
 * Maps the possible actions that can be executed in this component.
 */
const mapDispatchToProps = {
  login,
  addSaleToStagingArea,
  removeSaleToStagingArea,
  saveInvoce,
  resendInvoce,
  getCompanyImages,
  saveuser,
  getPermissions,
  canRegisterUser,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Login));
