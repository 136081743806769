import { combineReducers, Reducer } from 'redux';
import { LogEvent } from 'types/type';

export interface LogState {
  readonly LogEventList: LogListI;
  readonly isFetching: boolean;
}

export interface LogListI {
  content: LogEvent[];
  number?: number;
  totalElements?: number;
  totalPages?: number;
}

const isFetching: Reducer<boolean> = (
  state = false,
  action = {type: ''},
): boolean => {
  switch (action.type) {
    case 'BEGIN_FETCH':
      return true;
    case 'FETCH_COMPLETED':
      return false;
    default:
      return state;
  }
};

const LogEventList: Reducer<LogListI> = (
  state: LogListI = { content: [] },
  action = { type: '' },
): LogListI => {
  switch (action.type) {
    case 'SET':
      return { ...state, ...action.LogEventList };
    default:
      return state;
  }
};


export default combineReducers<LogState>({
  LogEventList,
  isFetching,
});