/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the sales crawled from g4s
 */
import React, { Component } from 'react';
import {
  Row,
  Pagination,
  Col,
  DatePicker,
  Table,
  Divider,
  Switch,
  Checkbox,
  Spin,
  Select,
  Button,
  message,
  Modal,
  Form,
  Card,
  Input,
  Descriptions,
  Popconfirm,
  Tag,
  Tabs,
} from 'antd';
import { ExportOutlined, FileSearchOutlined, FileExcelOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Sale,
  SaleSelected,
  Invoice,
  Email,
  FiscalPermission,
  Equipment,
  FormaPagoCondicion,
  FiscalData,
  Corte,
  CompanySales,
  SystemConfigInvoice,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from '../../store/configureStore';
import { SaleList } from './redux/reducers';
import {
  alreadyInvoice,
  changeBillable,
  changePaymentType,
  changeformaPago,
  changeToBill,
  downloadFile,
  invoiceAll,
  invoicingSale,
  page,
  sendAllSalesToInvoice,
  setEndDate,
  setInitDate,
  cleanSales,
  getFormaPago,
  setCortes,
  clearCortes,
  getSaleByIus,
  changeCustomer,
  changeToInternal,
  changeToSale,
  getZipFile,
  createExcelByFilters,
} from './redux/actions';
import {
  addSaleToStagingArea,
  cancelInvoice,
  InvoiceActionConstructors,
  removeSaleToStagingArea,
  resendInvoce,
  saveInvoce,
} from '../invoices/redux/actions';
import { getFiscalData } from '../homeUser/invoiceUser/redux/actions'
import { canRegisterUser } from '../login/redux/actions';
import moment, { Moment } from 'moment';
import Text from 'antd/lib/typography/Text';
import { Permissions as P } from 'commons/permissions';
import { isAllowed, getDecodeToken } from 'security/index';
import { SelectValue } from 'antd/lib/select';
import { isEmpty } from 'lodash';
import {
  getCompanySales,
  getFiscalPermission,
  getEquipmentsNumbers,
} from '../company/redux/actions';
import { InvoiceState } from 'routes/invoices/redux/reducers';
import FiscalDataForm from '../login/FiscalData/fiscalDataForm';
import { InvoiceRules as r } from '../login/FiscalData/rulesFiscalData';
import { FormComponentProps } from 'antd/lib/form';
import { initInvoice } from 'commons/initTypes';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Popups } from 'commons/components/popups/popups';
import { Strings as i8n } from 'commons/strings';
import { Redirect } from 'react-router';
import { subStringWithEllipsis } from 'commons/functions';
import MaskedInput from 'react-text-mask';
import { getSystemConfigInvoice } from '../configuration/redux/actions'
import './style.css';
import { parseApiError } from 'commons/error';

const { Search } = Input;

interface DispatchProps {
  page: (
    customer: string,
    initDate: Date,
    endDate: Date,
    filter: string,
    pagePosition: number,
    orderDate: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    serviceNumberId: string,
    equipmentIds: Array<string>,
    formaPago: string,
    rfc: string,
    cleanSalesSelected: boolean,
    orderRFC: string,
    cutFolio: Array<string>
  ) => void;
  /* saleInfoBilledAndCanceled: (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
  ) => void; */
  /* getExcel: (
    customer: string,
    initDate: Date,
    endDate: Date,
    orderDate: string,
    filter: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    serviceNumberId: string,
    equipmentId: Array<string>,
    formaPago: string,
    rfc: string,
    orderRFC: string,
    cutFolio: Array<string>
  ) => void; */
  downloadFile: (id: string, name: string, saleFolio: string, showFile: boolean) => void;
  invoicingSale: (
    sale: Sale,
    customer: string,
    initDate: Date,
    endDate: Date,
    filter: string,
    pagePosition: number,
    orderDate: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    serviceNumberId: string,
    equipmentIds: Array<string>,
    formaPago: string,
    rfc: string,
    orderRFC: string,
    cutFolio: Array<string>
  ) => void;
  sendAllSalesToInvoice: (
    excludeSaleIds: string[],
    customer: string,
    initDate: Date,
    endDate: Date,
    filter: string,
    pagePosition: number,
    orderDate: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    serviceNumberId: string,
    equipmentIds: Array<string>,
    formaPago: string,
    rfc: string,
    expectedSales: number,
    singleInvoice: boolean,
    orderRFC: string,
    cutFolio: Array<string>,
    idUser: string
  ) => void;
  changeBillable: (id: string, billable: boolean) => void;
  changePaymentType: (id: string, paymentType: string, distCenterId: string) => void;
  changeformaPago: (id: string, formaPago: string) => void;
  changeToBill: (
    id: string,
    toBill: boolean,
    total: number,
    totalVolume: number,
  ) => void;
  resendInvoce: (id: string) => void;
  cancelInvoice: (id: string) => void;
  getCompanySales: () => void;
  setInitDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  alreadyInvoice: (id: string, callback: Function) => void;
  invoiceAll: () => void;
  getFiscalPermission: (companyId: string) => void;
  getEquipmentsNumbers: () => void;
  cleanSales: () => void;
  getFormaPago: () => void;
  setCortes: (
    equipmentIds: Array<string> | undefined,
    initDate: Date,
    endDate: Date
  ) => void;
  clearCortes: () => void;
  getFiscalData: (userId: string, isSales: boolean, distCent: string) => void;
  canRegisterUser: () => void;
  getSaleByIus: (ius: string) => void;
  /* getExcelBilled: (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
    newExcel: boolean,
  ) => void; */
  changeCustomer: (id: string, customerCode: string) => void;
  changeToInternal: (idCut: string) => void;
  changeToSale: (idCut: string) => void;
  getZipFile: (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
    email: string,
    customerRfc: string
  ) => void;
  /* getExcelCancelled: (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
  ) => void; */
  getSystemConfigInvoice: () => void;
  createExcelByFilters: (
    endDate: Date,
    initDate: Date,
    orderDate: string,
    filter: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    serviceNumberId: string,
    equipmentId: Array<string>,
    formaPago: string,
    rfc: string,
    orderRFC: string,
    cutFolio: Array<string>,

    userId: string,
    reportType: string,
    emailToSend: string,
    isAdmin: boolean,
  ) => void;
}
interface StateProps {
  salePage: SaleList;
  isFetching: boolean;
  isFetchingBilled: boolean;
  companySalesList: CompanySales[];
  initialDate: Moment;
  endedDate: Moment;
  multicenter: boolean;
  isEquimentNumber: boolean;
  sumTotalLK: number;
  sumTotalM: number;
  sumTotalLKBillable: number;
  sumTotalMBillable: number;
  sumTotalLKSelected: number;
  sumTotalMSelected: number;
  saleSelectedList: SaleSelected[];
  salesStagingArea: Sale[];
  paymentChange: boolean;
  totalElementsBillable: number;
  totalElementsSelected: number;
  fiscalPermissions: Array<FiscalPermission>;
  equipmentNumbers: Array<Equipment>;
  cortes: Array<Corte>;
  formaPago: Array<FormaPagoCondicion>;
  fiscalDataList: FiscalData[];
  canRegistred: boolean;
  totalSales: string;
  billedSalestotal: string;
  totalVolumeSalesTotal: string;
  totalSalesSize: string;
  totalCancel: string;
  systemConfigInvoice: SystemConfigInvoice;
  counterOnlySales: string;
}

type Props = StateProps &
  FormComponentProps &
  InvoiceState &
  DispatchProps &
  InvoiceActionConstructors;

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const hexRegex = /[0-9a-fA-F]/;
const iusFragment = [hexRegex, hexRegex, hexRegex, hexRegex];

let VIEW_BILLABLES_SALES = isAllowed([P.CAN_SET_BILLABLE]);

const defaultEquipmentsIds: Array<string> | any = [];
const defaultCutFolio: Array<string> = [];

class SalesListComponent extends Component<Props> {
  public state = {
    pagePosition: 1,
    invoiceStatus: 'SALE_INVOICE',
    unitType: 'L',
    endOpen: false,
    orderDate: 'DESC',
    dc: '00000000-0000-0000-0000-000000000000',
    invoiceRequired: 'ALL',
    showModal: false,
    showModalCorte: false,
    invoice: initInvoice,
    serviceNumberId: '',
    equipmentIds: defaultEquipmentsIds,
    serviceNumberEquipmentIds: [],
    formaPago: '',
    rfc: "ALL",
    orderRFC: 'DESC',
    cutFolio: defaultCutFolio,
    cutFoliosToShow: undefined,
    IUS: "",
    idCutSelect: '',
    tabs: "1",
    buttonMarcarAInternas: false,
    downloadModal: false,
    pdf: true,
    excelModal: false,
    excelReportType: "VENTAS",
    excelEmail: "",
  };

  private getInitialTime = (): Moment => moment(this.props.initialDate);

  private getEndTime = (): Moment => moment(this.props.endedDate);

  /**
   * This function execute when the component is mounting and get the first page from
   * sales
   */
  public componentWillMount(): void {
    if (!getDecodeToken().isClient) {
      VIEW_BILLABLES_SALES = isAllowed([P.CAN_SET_BILLABLE]);
      this.chargeData();
    }
  }

  public componentWillUnmount(): void {
    this.props.cleanSales();
  }

  public async chargeData() {
    this.setState({ excelEmail: getDecodeToken().email });
    await this.props.getSystemConfigInvoice();
    await this.props.canRegisterUser();
    await this.props.getCompanySales();
    await this.props.getEquipmentsNumbers();
    await this.props.getFiscalPermission("");
    await this.props.getFormaPago();
    const company =
      this.props.companySalesList && this.props.companySalesList[0];
    if (company !== undefined) {
      this.chargeInfoBilledAndCanceled(this.getInitialTime().toDate(), this.getEndTime().toDate(), company.distributionCenterId);
      //this.chargeInfoBilledAndCanceled(this.getInitialTime().toDate(), this.getEndTime().toDate(), '00000000-0000-0000-0000-000000000000');
      await this.props.page(
        getDecodeToken().customerCode || '',
        this.getInitialTime().toDate(),
        this.getEndTime().toDate(),
        this.state.invoiceStatus,
        this.state.pagePosition,
        this.state.orderDate,
        VIEW_BILLABLES_SALES,
        //'00000000-0000-0000-0000-000000000000',
        company.distributionCenterId || '',
        this.state.invoiceRequired,
        this.state.serviceNumberId,
        this.state.equipmentIds,
        this.state.formaPago,
        this.state.rfc,
        true,
        this.state.orderRFC,
        this.state.cutFolio
      );
      //await this.props.getFiscalData("", true, this.state.dc.toString());
      //this.setState({ dc: '00000000-0000-0000-0000-000000000000' });
      await this.props.getFiscalData("", true, company.distributionCenterId || '');
      this.setState({ dc: company.distributionCenterId || '' });
    }
    this.props.saleSelectedList.forEach((sale: SaleSelected) => this.props.changeToBill(sale.id, false, sale.total, sale.totalVolume));
  }

  public async chargeInfoBilledAndCanceled(init: Date, end: Date, DC: string | undefined) {
    /* await this.props.saleInfoBilled(init, end, DC || '');
    await this.props.saleInfoCanceled(init, end, DC || ''); */
  }

  /**
   * This function change page and replaces current position in the pagination
   */
  public handlePaginationChange = (currentPage: number): void => {
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      currentPage,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      false,
      this.state.orderRFC,
      this.state.cutFolio
    );
    this.setState({ valor: currentPage });
  };

  /**
   * This function called when a date picker (start or end) changes.
   */
  public onDatePickerCutChange = (
    startDateValue: Moment,
    endDateValue: Moment,
  ): void => {
    var start = moment(startDateValue);
    var end = moment(endDateValue);
    start.set({ hour: 0, minute: 0, seconds: 0 });
    end.set({ hour: 23, minute: 59, seconds: 59 });
    if (startDateValue) {
      this.onDatePickerChange(start, end);
    }
  };

  public onDatePickerChange = (
    startDateValue: Moment,
    endDateValue: Moment,
  ): void => {
    if (startDateValue && endDateValue) {
      this.onChangeTime(startDateValue, endDateValue);
    }
  };

  private async onChangeTime(startDateValue: Moment, endDateValue: Moment) {
    const { invoiceStatus, pagePosition } = this.state;
    await this.props.setInitDate(startDateValue.toDate());
    await this.props.setEndDate(endDateValue.toDate());
    this.chargeInfoBilledAndCanceled(startDateValue.toDate(), endDateValue.toDate(), this.state.dc);
    await this.props.page(
      getDecodeToken().customerCode || '',
      startDateValue.toDate(),
      endDateValue.toDate(),
      invoiceStatus,
      pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
    await this.setEquipmentIds(this.state.equipmentIds, startDateValue.toDate(), endDateValue.toDate());
    this.setState({ IUS: "" });
  }

  /**this function change radio element  and find status on the db from state element*/
  public onChangeRadio = (value: SelectValue): void => {
    this.setState({
      invoiceStatus: value,
      IUS: ""
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      value.toString(),
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
    this.forceUpdate();
  };

  public onChangeOrderDate = (value: SelectValue): void => {
    this.setState({
      orderDate: value.toString(),
      IUS: "",
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      value.toString(),
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      false,
      this.state.orderRFC,
      this.state.cutFolio
    );
  };

  public onChangeDistributionCenter = (value: string): void => {
    if(value==="Todos"){
      this.onChangeDC("00000000-0000-0000-0000-000000000000");
    }
    else this.onChangeDC(value);
  };

  public async onChangeDC(value: string) {
    this.setState({
      dc: value,
      equipmentIds: [],
      IUS: ""
    });
    this.chargeInfoBilledAndCanceled(this.getInitialTime().toDate(), this.getEndTime().toDate(), value);
    await this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      value,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      [],
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
    await this.props.getFiscalData("", true, value);

  }

  getEquipmentsIdsFromServiceNumber = (serviceNumberId: string) => {
    const { fiscalPermissions } = this.props
    const serviceNumber = fiscalPermissions.find(fp => fp.id === serviceNumberId)
    if (serviceNumber) {
      return serviceNumber.equipments.map(e => e.id);
    } else {
      return [];
    }
  }

  onSelectCutNumber = (value: string): void => {
    this.setState({ idCutSelect: value });
  }

  convertToInternal = async () => {
    if (!isEmpty(this.state.idCutSelect)) {
      try {
        this.setState({ buttonMarcarAInternas: false });
        await this.props.changeToInternal(this.state.idCutSelect);
      } catch (err) {
        this.setState({ buttonMarcarAInternas: false, idCutSelect: '' });
        console.log(err);
      }
      this.setState({ showModalCorte: !this.state.showModalCorte, idCutSelect: '' });
      this.onChangeEquipment('', false);
    }
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      defaultCutFolio
    );
    return 0;
  }
  convertToSales = async () => {
    if (!isEmpty(this.state.idCutSelect)) {
      try {
        this.setState({ buttonMarcarAInternas: false });
        await this.props.changeToSale(this.state.idCutSelect);
      } catch (err) {
        this.setState({ buttonMarcarAInternas: false, idCutSelect: '' });
        console.log(err);
      }
      this.setState({ showModalCorte: !this.state.showModalCorte, idCutSelect: '' });
      this.onChangeEquipment('', false);
    }
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      defaultCutFolio
    );
    return 0;
  }

  public onChangeServiceNumber = (value: string): void => {
    let serviceNumberEquipmentIds: Array<string> = []
    if (value) {
      serviceNumberEquipmentIds = this.getEquipmentsIdsFromServiceNumber(value);
    }
    this.setState({
      serviceNumberId: value,
      serviceNumberEquipmentIds,
      IUS: ""
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      value,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
  };

  setEquipmentIds = async (equipmentIds: Array<string>, initDate: Date | null = null, endDate: Date | null = null) => {
    if (equipmentIds.length > 0) {
      await this.props.setCortes(
        equipmentIds,
        initDate || this.getInitialTime().toDate(),
        endDate || this.getEndTime().toDate()
      );
    } else {
      await this.props.clearCortes();
    }
    this.setCutFoliosToShow();
    this.setState({
      equipmentIds,
      cutFolio: defaultCutFolio,
      IUS: ""
    });
  }


  public onChangeEquipment = (value: string, add: boolean = true) => {
    var { equipmentIds } = this.state;
    if (add) {
      if(value === 'Todos'){
        equipmentIds = [];
        let equipmentsIdList = this.returnEquipmentsNumber();
        equipmentsIdList.forEach(eq =>{
          equipmentIds.push(eq);
        });
        this.setEquipmentIds(equipmentIds);

      }else{
        equipmentIds.push(value);
        this.setEquipmentIds(equipmentIds);
      }
      
    } else if (value === '') {
      equipmentIds = [];
      this.setEquipmentIds(equipmentIds);
    } else {
      equipmentIds = equipmentIds.filter((eid: string) => eid !== value);
      this.setEquipmentIds(equipmentIds);
    }
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      defaultCutFolio
    );
  };

  public returnEquipmentsNumber = (): Array<String> => {
    let listaEquipments: Array<String> = this.props.equipmentNumbers.filter(en => {
      if (this.state.dc === '00000000-0000-0000-0000-000000000000'){
        return true;
      } 
      else if (this.state.serviceNumberEquipmentIds.length > 0) {
        if (this.state.serviceNumberEquipmentIds.some(eId => eId === en.id)) {
          return true
        } else {
          return false
        }
      } else if (this.state.dc) {
        if (this.state.dc === en.distributionCenterId) {
          return true
        } else {
          return false;
        }
      } else {
        return false;
      }
    }).map((en: Equipment)=>{
      return en.id;
    });
    return listaEquipments;
  }

  public onChangeFormaPago = (value: string): void => {
    this.setState({
      formaPago: value,
      IUS: "",
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      value,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
  };

  public onChangeRequiredInvoice = (value: SelectValue): void => {
    this.setState({
      invoiceRequired: value,
      IUS: "",
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      value.toString(),
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
  };

  public onChangeCutFolio = (value: Array<string>): void => {
    this.setState({
      cutFolio: (value.includes("ALL"))? this.props.cortes.map(cF => { return cF.cutid;}): value,
      IUS: "",
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      (value.includes("ALL"))? this.props.cortes.map(cF => { return cF.cutid;}) : value
    );
  };

  public onChangeRfc = (value: SelectValue): void => {
    this.setState({
      rfc: value.toString(),
      IUS: ""
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      value.toString(),
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
  };

  public onChangeRfcOrder = (value: SelectValue): void => {
    this.setState({
      orderRFC: value,
    });
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      value.toString(),
      this.state.cutFolio
    );
  };

  setAlreadyInvoice = (saleId: string) => {
    const self = this
    this.props.alreadyInvoice(saleId, () => {
      self.props.page(
        getDecodeToken().customerCode || '',
        self.getInitialTime().toDate(),
        self.getEndTime().toDate(),
        self.state.invoiceStatus,
        (this.props.salePage.number || 0) + 1,
        self.state.orderDate,
        VIEW_BILLABLES_SALES,
        self.state.dc,
        self.state.invoiceRequired,
        self.state.serviceNumberId,
        self.state.equipmentIds,
        self.state.formaPago,
        self.state.rfc,
        false,
        self.state.orderRFC,
        this.state.cutFolio
      );
    })
  }

  isAnyFormaPagoOnPageDifferent = () => {
    const { salePage, saleSelectedList } = this.props
    if (salePage.content) {
      return salePage.content.filter((sale) => (
        !saleSelectedList.some(s => s.id === sale.id)
      )).some((sale, index, sales) => {
        if (index === 0) {
          return false
        } else {
          if (sale.formaPago !== sales[index - 1].formaPago) {
            return true
          } else {
            return false
          }
        }
      });
    } else {
      return false;
    }
  }

  isAnyConditionTypeOnPageDifferent = () => {
    const { salePage, saleSelectedList } = this.props
    if (salePage.content) {
      return salePage.content.filter((sale) => (
        !saleSelectedList.some(s => s.id === sale.id)
      )).some((sale, index, sales) => {
        if (index === 0) {
          return false
        } else {
          if (sale.paymentConditionType !== sales[index - 1].paymentConditionType) {
            return true
          } else {
            return false
          }
        }
      });
    } else {
      return false;
    }
  }

  sendAllSalesToSingleInvoice = async () => {
    const { totalElementsSelected, salePage, saleSelectedList } = this.props;
    const totalPages = salePage.totalPages !== undefined ? salePage.totalPages : 0
    if (totalElementsSelected < 1) {
      Popups.notifyError("No hay ventas a facturar.");
    } else if (this.props.isEquimentNumber && this.state.rfc === 'ALL') {
      Popups.notifyError("Las ventas deben tener el mismo RFC.\nPorfavor seleccione el filtro \"RFC\".");
    } else if (totalPages === 1 && this.isAnyConditionTypeOnPageDifferent()) {
      Popups.notifyError("Seleccione solamente ventas con el mismo tipo de pago.");
    } else if (totalPages === 1 && this.isAnyFormaPagoOnPageDifferent()) {
      Popups.notifyError("Seleccione solamente ventas con la misma forma de pago.");
    } else if (totalPages > 1 && !this.state.formaPago) {
      Popups.notifyError("Las ventas deben tener la misma forma de pago.\nPorfavor seleccione el filtro \"Forma de Pago\".");
    } else if (totalPages > 1 && this.state.formaPago && this.isAnyFormaPagoOnPageDifferent()) {
      Popups.notifyError("Seleccione solamente ventas con la misma forma de pago.");
    } else {
      await this.props.sendAllSalesToInvoice(
        saleSelectedList ? saleSelectedList.map(s => s.id) : [],
        getDecodeToken().customerCode || '',
        this.getInitialTime().toDate(),
        this.getEndTime().toDate(),
        this.state.invoiceStatus,
        (this.props.salePage.number || 0) + 1,
        this.state.orderDate,
        VIEW_BILLABLES_SALES,
        this.state.dc,
        this.state.invoiceRequired,
        this.state.serviceNumberId,
        this.state.equipmentIds,
        this.state.formaPago,
        this.state.rfc,
        totalElementsSelected - saleSelectedList.length,
        true,
        this.state.orderRFC,
        this.state.cutFolio,
        getDecodeToken().id
      )
      this.props.page(
        getDecodeToken().customerCode || '',
        this.getInitialTime().toDate(),
        this.getEndTime().toDate(),
        this.state.invoiceStatus,
        this.state.pagePosition,
        this.state.orderDate,
        VIEW_BILLABLES_SALES,
        this.state.dc,
        this.state.invoiceRequired,
        this.state.serviceNumberId,
        this.state.equipmentIds,
        this.state.formaPago,
        this.state.rfc,
        true,
        this.state.orderRFC,
        this.state.cutFolio
      );
    }
  }

  onSelectPaymentType = async (row: Sale, value: string) => {
    await this.props.changePaymentType(row.id, value, this.state.dc);
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      (this.props.salePage.number || 0) + 1,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
  }

  onSelectFormaPago = async (row: Sale, value: string) => {
    await this.props.changeformaPago(row.id, value);
    this.props.page(
      getDecodeToken().customerCode || '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      (this.props.salePage.number || 0) + 1,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      this.state.serviceNumberId,
      this.state.equipmentIds,
      this.state.formaPago,
      this.state.rfc,
      true,
      this.state.orderRFC,
      this.state.cutFolio
    );
  }


  renderCutNumbers = () => {
    if (this.props.cortes.length > 0) {
      return this.props.cortes.map((corte: Corte): JSX.Element => (
        <Select.Option value={corte.cutid}>{`# ${corte.equipmentcutfolio.toString()} - Equipo: ${corte.equipmentnumber} ${subStringWithEllipsis(corte.equipmenttag, 14)}`}</Select.Option>
      ),
      );
    }
  }

  renderPermissionNumbers = () => {
    const { dc } = this.state;
    const { companySalesList, fiscalPermissions } = this.props;
    if (!isEmpty(dc)) {
      if (!isEmpty(companySalesList)) {
        const company = companySalesList.find(comp => comp.distributionCenterId === dc)
        if (company !== undefined) {
          const fpFilter = fiscalPermissions.filter(fp => (fp.companyId === company.id));
          return fpFilter.map(
            (np: FiscalPermission): JSX.Element => (
              <Select.Option value={np.id}>{np.numberPermission}</Select.Option>
            ),
          );
        }
      }
    }
  }

  private renderDistributionCenterSelect = (): JSX.Element => {
    const { companySalesList } = this.props;
    return <div>
      {(!isEmpty(companySalesList)) && (
        <Select
          size="small"
          style={{ width: '100%' }}
          value={this.state.dc}
          defaultValue="Todos"
          onChange={(value: string): void => {
            this.onChangeDistributionCenter(value);
          }
          }
        >
          <Select.Option style={{ fontSizeAdjust: "initial" }} value="00000000-0000-0000-0000-000000000000">Todos</Select.Option>
          {companySalesList.map(
            (dc: CompanySales): JSX.Element => (
              <Select.Option style={{ fontSizeAdjust: "initial" }} value={dc.distributionCenterId}>{dc.distributionCenterName}</Select.Option>
            ),
          )}
        </Select>
      )}
    </div>
  }

  renderEquipmentNumbers = () => this.props.equipmentNumbers.filter(en => {
    if (this.state.dc === '00000000-0000-0000-0000-000000000000'){
      return true;
    } 
    else if (this.state.serviceNumberEquipmentIds.length > 0) {
      if (this.state.serviceNumberEquipmentIds.some(eId => eId === en.id)) {
        return true
      } else {
        return false
      }
    } else if (this.state.dc) {
      if (this.state.dc === en.distributionCenterId) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  }).map(
    (en: Equipment): JSX.Element => (
      <Select.Option value={en.id}>{`${en.equipmentNumber} ${en.applicationType} ${en.tag}`}</Select.Option>
    ),
  )

  /**
   * This function render elements from the filters
   */
  private renderFilters = (): JSX.Element => {
    return (
      <Row
        style={{
          backgroundColor: '#F1F7FF',
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 23,
          paddingBottom: 23,
        }}
      >
        <Row gutter={8}>
          <Col xs={24} sm={24} md={24} lg={11} xl={8} xxl={6} style={{ paddingLeft: 24, paddingRight: 24 }}>
            <label className="label">{
              'Consultar período de venta' 
            }</label>
            <br />
            <DatePicker.RangePicker
              value={[
                moment(this.props.initialDate),
                moment(this.props.endedDate),
              ]}
              onChange={(dates: any) => {
                this.props.setInitDate(moment(dates[0]).toDate());
                this.props.setEndDate(moment(dates[1]).toDate());
              }}
              style={{ width: '320px' }}
              showTime
              size="small"
              format={DATE_FORMAT}
              onOk={(dates: any): void => {
                this.onDatePickerChange(dates[0], dates[1]);
                this.props.saleSelectedList.forEach((sale: SaleSelected) => this.props.changeToBill(sale.id, false, sale.total, sale.totalVolume));
              }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={9}
            xl={6}
            xxl={6}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <label className="label">Centro de distribucion:</label>
            {this.renderDistributionCenterSelect()}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={5}
            xxl={5}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <label className="label">{'Estatus:'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue="SALE_INVOICE"
              value={this.state.invoiceStatus}
              onChange={(value: SelectValue): void => {
                if (value !== 'INVOICE') this.setState({ tabs: '1' });
                else this.setState({ tabs: '2' });
                this.onChangeRadio(value);
              }}
              style={{ width: '100%' }}
            >
              <Select.Option value={'SALE_INVOICE'}>Todo</Select.Option>
              <Select.Option value={'NOT_INVOICE'}>Ventas</Select.Option>
              <Select.Option value={'INVOICE'}>Facturas</Select.Option>
              <Select.Option value={'IN_PROGRESS'}>Ventas en proceso de facturación</Select.Option>
              <Select.Option value={'ERROR'}>Facturas fallidas</Select.Option>
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={5}
            xxl={5}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <label className="label">{'Mostrar cantidad como:'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue="L"
              onChange={(value: SelectValue): void =>
                this.setState({ unitType: value.toString() })
              }
              style={{ width: '100%' }}
            >
              <Select.Option value={'L'}>Litros</Select.Option>
              <Select.Option value={'K'}>Kilos</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            xxl={6}
            style={{ paddingLeft: 24, paddingRight: 24 }}
            hidden={!this.props.isEquimentNumber}
          >
            <label className="label">{'Número de permiso:'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue=""
              onSelect={(value: string): void => {
                this.onChangeServiceNumber(value);
              }
              }
              style={{ width: '100%' }}
            >
              <Select.Option value="">Todos</Select.Option>
              {this.renderPermissionNumbers()}
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            xxl={6}
            style={{ paddingLeft: 24, paddingRight: 24 }}
            hidden={!this.props.isEquimentNumber}
          >
            <label className="label">{'Número de equipo:'}</label>
            <Select
              mode="multiple"
              showSearch
              filterOption={(input, { props: { children } }) => {
                if (children !== undefined && children !== null) {
                  return children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
              defaultActiveFirstOption
              defaultValue={this.state.equipmentIds}
              value={this.state.equipmentIds}
              onSelect={(value: string) => this.onChangeEquipment(value)}
              onDeselect={(value: string) => this.onChangeEquipment(value, false)}
              style={{ width: '100%', minWidth: 200 }}
            >
              <Select.Option value={'Todos'}>{'Todos'}</Select.Option>
              {this.renderEquipmentNumbers()}
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            xxl={6}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <label className="label">{'Forma de Pago:'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue={""}
              value={this.state.formaPago}
              onSelect={this.onChangeFormaPago}
              style={{ width: '100%' }}
            >
              <Select.Option value="">Todos</Select.Option>
              {this.props.formaPago.map(fp => <Select.Option key={fp.id} value={fp.codigoSat}>
                {fp.descripcion}
              </Select.Option>)}
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={5}
            xl={5}
            xxl={5}
            style={{ paddingLeft: 24, paddingRight: 24 }}
            hidden={!this.props.canRegistred}
          >
            <label className="label">{'RFC:'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue="ALL"
              showSearch
              onChange={(value: SelectValue): void => this.onChangeRfc(value)}
              style={{ width: '100%' }}
            >
              <Select.Option value={'ALL'}>Todos</Select.Option>
              {this.props.fiscalDataList.map(fs =>
                <Select.Option value={fs.rfc}>{fs.rfc}</Select.Option>
              )}
            </Select>
          </Col>
        </Row>
      </Row >
    );
  };

  /**
   * Third step of the component wizard. Request email and create the
   * invoice.
   */
  private renderEmailForm = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form>
        <Col span={24}>
          <p style={{ marginTop: 20, marginBottom: 20 }}>
            Ingresa el correo electronico donde quieres que te enviemos la
            factura
          </p>
          <Card
            className={'card-invoice-content'}
            style={{
              backgroundColor: '#f1f7ff',
              margin: '0 auto',
              maxWidth: '30rem',
            }}
          >
            <Form.Item hasFeedback label="Correo Electrónico">
              {getFieldDecorator('email', {
                rules: r.emailRule,
              })(<Input size="large" placeholder="Correo electrónico" />)}
            </Form.Item>
          </Card>
        </Col>
      </Form>
    );
  };

  renderCustomButton = (form: WrappedFormUtils<Invoice>): JSX.Element => (
    <Button
      size="large"
      type="primary"
      disabled={this.props.isFetching}
      onClick={() => this.onRequestInvoice(form)}
    >
      {this.props.isFetching ? 'Loading...' : 'Facturar'}
    </Button>
  );

  /**
   * This function is called when the button invoicing is
   * called. Is the final step.
   */
  private onRequestInvoice = (form: WrappedFormUtils<Invoice>): void => {
    form.validateFields(
      async (err, values: Invoice): Promise<void> => {
        if (err) {
          Promise.resolve();
        } else {
          this.setState({ invoice: { ...values } });
          const invoice = { ...values };
          this.props.form.validateFields(
            async (err, fields: Email): Promise<void> => {
              !Boolean((err || {}).email) &&
                Popups.executeOrNotifyError(
                  (): Promise<void> => {
                    this.createInvoice(
                      this.props.salesStagingArea,
                      fields,
                      invoice,
                    );
                    form.resetFields();
                    this.setState({ invoice: initInvoice });
                    return Promise.resolve();
                  },
                );
            },
          );
        }
      },
    );
  };

  private createInvoice = async (
    salesStagingArea: Sale[],
    fields: Email,
    invoice: Invoice,
  ): Promise<void> => {
    const Invoice = {
      ...invoice,
      email: fields.email,
      sales: salesStagingArea,
      fiscalRegime: invoice.fiscalRegime,
    };
    await this.props.saveInvoce(Invoice);
    this.props.addSaleToStagingArea('0', 0, true);
    Popups.notifySuccess(i8n.INVOICE_CREATED);
    this.setState({ showModal: !this.state.showModal });
    this.chargeData();
  };

  public renderCancelButton = (): JSX.Element => (
    <Button
      type="ghost"
      onClick={() => {
        this.setState({ showModal: !this.state.showModal });
        this.props.removeSaleToStagingArea(this.props.salesStagingArea[0]);
      }}
    >
      Cancelar <CloseCircleOutlined />
    </Button>
  );

  public allowBillableSwitch = (sale: Sale): boolean => {
    if (
      sale.paymentConditionType === 'CREDIT' &&
      this.props.companySalesList &&
      this.props.companySalesList[0].selectCreditInvoice === false
    ) {
      return true;
    } else {
      return sale.invoiceRequired === true
        ? true
        : !isEmpty(sale.invoice)
          ? true
          : sale.billed;
    }
  };

  public getPaymentConditionType = (paymentCondition: string): string => {
    switch (paymentCondition) {
      case 'CASH':
        return 'Contado';
      case 'CREDIT':
        return 'Crédito';
      default:
        return 'vales';
    }
  };

  public getFormaPagoDescription = (formaPagoCodigoSat: string): string => {
    const { formaPago } = this.props
    const fp = formaPago.find(fp => fp.codigoSat === formaPagoCodigoSat)
    return fp ? fp.descripcion : ""
  };

  public sumaSelectSales = (sumaSelectSales: SaleSelected[]): number => {
    let sum = 0;
    sumaSelectSales.forEach(s => (sum = sum + s.total));
    sum = this.props.sumTotalMBillable - sum;
    return sum;
  };

  public sumaSelectSalesLK = (sumaSelectSales: SaleSelected[]): number => {
    let sum = 0;
    sumaSelectSales.forEach(s => (sum = sum + s.totalVolume));
    sum = this.props.sumTotalLKBillable - sum;
    return sum;
  };

  public formatNumber = (number: number): string =>
    Number(number.toFixed(2)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });

  validateRenderSearchByIUS = (content: Sale[] | undefined): boolean => (this.state.invoiceStatus!=='ERROR') && !(!isEmpty(this.state.IUS) && (content !== undefined && content.length === 1));

  validateRenderCollums = (): boolean => {
    if (this.state.invoiceStatus === 'INVOICE') return false;
    if (this.state.invoiceStatus === 'IN_PROGRESS') return false;
    return true;
  }

  cutFolioEqualsToState(cutFolio = []) {
    const cutFolioState: Array<string> = this.state.cutFolio
    if (cutFolioState === cutFolio) return true;
    if (cutFolioState === null || cutFolio === null) return false;
    if (cutFolioState.length !== cutFolio.length) return false;
    for (var i = 0; i < cutFolioState.length; ++i) {
      if (cutFolioState[i] !== cutFolio[i]) return false;
    }
    return true;
  }

  public handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (filters.endDate) {
      if (filters.endDate.length > 0 && filters.endDate[0] !== this.state.orderDate) {
        this.onChangeOrderDate(filters.endDate[0]);
      } else if (filters.endDate.length < 1) {
        this.onChangeOrderDate(this.state.orderDate);
      }
    }

    if (!this.cutFolioEqualsToState(filters.cutFolio)) {
      this.onChangeCutFolio(filters.cutFolio);
    }

    if (filters.invoiceRequired) {
      if (filters.invoiceRequired.length > 0 && filters.invoiceRequired[0] !== this.state.invoiceRequired) {
        this.onChangeRequiredInvoice(filters.invoiceRequired[0])
      } else if (filters.invoiceRequired < 1) {
        this.onChangeRequiredInvoice(this.state.invoiceRequired)
      }
    }
    if (filters.invoiceRfc) {
      if (filters.invoiceRfc.length > 0 && filters.invoiceRfc[0] !== this.state.orderRFC) {
        this.onChangeRfcOrder(filters.invoiceRfc[0])
      } else if (filters.invoiceRfc < 1) {
        this.onChangeRfcOrder(this.state.orderRFC)
      }
    }
  };

  setCutFoliosToShow = () => {
    let cutFoliosToShow = undefined
    if (this.props.cortes.length > 0) {
      cutFoliosToShow = this.props.cortes
      .sort((a,b):number =>{
        return (a.equipmentcutfolio > b.equipmentcutfolio)? 1 : -1
      })
      .sort((a,b): number =>{
        return (a.equipmentnumber < b.equipmentnumber)? 1 : -1
      })
      .map(corte => ({
        value: corte.cutid,
        text: `Equipo: ${corte.equipmentnumber} - # ${corte.equipmentcutfolio.toString()} ${subStringWithEllipsis(corte.equipmenttag, 30)}`
      }))
      cutFoliosToShow.unshift({ value: "NULL", text: "Sin corte" });
      cutFoliosToShow.unshift({ value: "ALL", text: "Todos" });
    }
    this.setState({ cutFoliosToShow })
  }

  renderDescription = (name: string, value: string): JSX.Element => (
    <Descriptions bordered size="small" style={{ background: "white" }}>
      <Descriptions.Item label={name} children={value} />
    </Descriptions>
  );

  public renderSelectCenter = (): JSX.Element => (
    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ paddingLeft: 24, paddingRight: 24 }}>
      <label className="label">Centro de distribucion:</label>
      {this.renderDistributionCenterSelect()}
    </Col>
  );

  public renderExtraTextOnSendAllSales = (quantity: number): string => {
    let text = "";
    if(quantity > 1000){
      text = "\nLa cantidad de ventas que está por solicitar facturar puede hacer que el proceso de facturación tarde más de lo habitual."
      .concat("\n¿Está seguro de continuar?")
      .concat("\n");
    }
    return text;
  }

  public renderSelectEquipment = (): JSX.Element => (
    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ paddingLeft: 24, paddingRight: 24 }}>
      <label className="label">{'Número de equipo:'}</label>
      <Select
        mode="multiple"
        showSearch
        filterOption={(input, { props: { children } }) => {
          if (children !== undefined && children !== null) {
            return children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        defaultActiveFirstOption
        defaultValue={this.state.equipmentIds}
        value={this.state.equipmentIds}
        onSelect={(value: string) => this.onChangeEquipment(value)}
        onDeselect={(value: string) => this.onChangeEquipment(value, false)}
        style={{ width: '100%', minWidth: 200 }}
      >
        <Select.Option value={'Todos'}>{'Todos'}</Select.Option>
        {this.renderEquipmentNumbers()}
      </Select>
    </Col>
  );

  public renderInformationTables = (): JSX.Element => (
    <div>
      <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
        <Text>Ventas</Text>
        {this.renderDescription('Cantidad', this.formatNumber(this.props.salePage.totalElements || 0))}
        {this.renderDescription("$" + this.formatNumber(this.props.sumTotalM || 0), this.state.unitType === 'L'
          ? `${this.formatNumber(this.props.sumTotalLK || 0)} L`
          : `${this.formatNumber(this.props.sumTotalLK * 2 || 0)} K`)}
      </Col>
      {(this.state.invoiceStatus)!=='ERROR' &&  (this.state.invoiceStatus)!=='IN_PROGRESS' && this.state.tabs !== "2" && <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
        <Text>Ventas Facturables</Text>
        {this.renderDescription('Cantidad', "" + this.formatNumber(this.props.totalElementsBillable || 0))}
        {this.renderDescription("$" + this.formatNumber(this.props.sumTotalMBillable || 0), this.state.unitType === 'L'
          ? `${this.formatNumber(this.props.sumTotalLKBillable || 0)} L`
          : `${this.formatNumber(this.props.sumTotalLKBillable * 2 || 0)} K`)}
      </Col>}
      {(this.state.invoiceStatus)!=='ERROR' && (this.state.invoiceStatus)!=='IN_PROGRESS' &&  this.state.tabs !== "2" && <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
        <Text>Ventas Seleccionadas</Text>
        {this.renderDescription("Cantidad", "" + (this.props.totalElementsSelected - this.props.saleSelectedList.length))}
        {this.renderDescription("$" + this.formatNumber(this.props.sumTotalMSelected || 0), this.state.unitType === 'L'
          ? `${this.formatNumber(this.props.sumTotalLKSelected || 0)} L`
          : `${this.formatNumber(this.props.sumTotalLKSelected * 2 || 0)} K`)}
      </Col>}
      {(this.state.invoiceStatus)!=='ERROR' && (this.state.invoiceStatus)!=='IN_PROGRESS'  && (this.state.invoiceStatus)!=='NOT_INVOICE' && <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6}>
        <Spin tip="Cargando..." spinning={this.props.isFetchingBilled}>
          <Text>Facturas Realizadas</Text>
          {this.renderDescription("cantidad", this.formatNumber(Number(this.props.totalSales) || 0))}
          {this.renderDescription("$" + this.formatNumber(Number(this.props.billedSalestotal) || 0), this.state.unitType === 'L'
            ? `${this.formatNumber(Number(this.props.totalVolumeSalesTotal || 0))} L`
            : `${this.formatNumber(Number(this.props.totalVolumeSalesTotal || 0) * 2)} K`)}
        </Spin>
      </Col>}
    </div>);

  public renderTableData = (tab: String = "1"): JSX.Element => {
    const {
      salePage,
      paymentChange,
      isFetching
    } = this.props;
    let content: Sale[] | undefined = salePage.content;
    const multipleBill = content && content.some(e => e.toBill);
    return (<Table
      scroll={{ x: true }}
      rowKey={(record: Sale) => record.id}
      dataSource={content}
      pagination={false}
      onChange={this.handleTableChange}
      rowClassName={(record) => !record.sale ? "sales_internal" : ""}
    >
      {tab === "2" && <Table.Column title="Estatus factura" fixed='left' render={(text, row: Sale) => <Tag color={row.billed ? "green" : ""} >{row.billed ? "Facturada" : "Sin facturar"}</Tag>} />}
      {tab === "2" && <Table.Column title="Estatus cancelación" dataIndex="status" fixed='left' render={(text) => <Text>{isEmpty(text) ? "Facturada" : text}</Text>} />}
      {this.validateRenderCollums() && <Table.Column
        title="Facturar"
        dataIndex="toBill"
        key="toBill"
        render={(text: boolean, record: Sale) =>
          record.billable && record.sale &&
          isEmpty(record.invoice) && !record.billed && (
            <Checkbox
              checked={!record.toBill}
              onChange={() =>
                this.props.changeToBill(
                  record.id,
                  !record.toBill,
                  record.total,
                  Number(record.totalVolume),
                )
              }
            />
          )
        }
      />}
      {this.validateRenderCollums() && VIEW_BILLABLES_SALES && (
        <Table.Column
          title="Facturable"
          dataIndex="billable"
          key="billable"
          render={(text: boolean, record: Sale) => (
            ((this.allowBillableSwitch(record) || record.sale) && <Switch
              checkedChildren="Facturable"
              unCheckedChildren="No Facturable"
              disabled={this.allowBillableSwitch(record) || !record.sale}
              checked={record.billable}
              onChange={() =>
                this.props.changeBillable(record.id, !text)
              }
            />)
          )}
        />
      )}
      {tab === "1" && <Table.Column
        title="Estatus"
        key="status"
        render={(text, row: Sale) => <Tag color={row.billed ? "green" : ""} >{row.billed ? "Facturada" :row.status}</Tag>}
      />}
      <Table.Column
        title="Fecha y hora de Venta"
        dataIndex="endDate"
        key="endDate"
        filteredValue={[this.state.orderDate]}
        filterMultiple={false}
        filters={[{ value: 'ASC', text: 'Ascendente' }, { value: 'DESC', text: 'Descendente' }]}
      />
      {tab === "2" && <Table.Column
        title="Fecha y hora de Factura"
        dataIndex="invoiceDate"
        key="invoiceDate"
      />}
      <Table.Column
        title="IUS"
        dataIndex="IUSCode"
        key="IUSCode"
        filterIcon={<FileSearchOutlined className="anticon anticon-filter ant-table-filter-selected ant-dropdown-trigger" />}
        filterDropdown={({ confirm }) =>
          <Row gutter={0}>
            <Col span={14}>
              <MaskedInput
                mask={[
                  ...iusFragment,
                  '-',
                  ...iusFragment,
                  '-',
                  ...iusFragment,
                  '-',
                  ...iusFragment,
                  '-',
                  ...iusFragment,
                ]}
                id="ius"
                showMask
                size={23}
                value={this.state.IUS}
                style={{ fontSize: '20px' }}
                className="ant-input ant-input-large"
                onChangeCapture={(e) => {
                  let IUS = e.currentTarget.value.substring(0, 24).toUpperCase();
                  if (!isEmpty(IUS)) this.setState({ IUS });
                }}
              />
            </Col>
            <Col span={10}>
              <Button onClick={
                () => {
                  this.props.getSaleByIus(this.state.IUS);
                  if (confirm) confirm();
                }}
              >Buscar</Button>
              <Button onClick={
                () => {
                  this.setState({ IUS: "" });
                  this.chargeData();
                  if (confirm) confirm();
                }}
              >Borrar</Button>
            </Col>
          </Row>
        }
      />
      <Table.Column
        title="# Cliente"
        dataIndex="customerCode"
        key="customer"
        render={(text: string, row: Sale): JSX.Element =>
          this.allowBillableSwitch({ ...row, paymentConditionType: 'CASH', invoiceRequired: false }) || !row.sale ?
            <Text>{text}</Text> :
            <Search
              style={{ minWidth: 150, maxWidth: 200 }}
              enterButton={<SyncOutlined />}
              defaultValue={text}
              onSearch={(text) => this.props.changeCustomer(row.id, text)}
            />
        }
      />
      <Table.Column
        title="Cliente"
        dataIndex="customerName"
        key="customerName"
      />
      <Table.Column
        title="RFC"
        dataIndex="invoiceRfc"
        key="invoiceRfc"
        filteredValue={[this.state.orderRFC]}
        filterMultiple={false}
        filters={[{ value: 'ASC', text: 'Ascendente' }, { value: 'DESC', text: 'Descendente' }]}
      />
      <Table.Column
        title="Tipo de Pago"
        dataIndex="paymentConditionType"
        key="tipopago"
        render={(text: string, row: Sale): JSX.Element => {
          return !isEmpty(row.invoice) ||
            row.billed ||
            !paymentChange ||
            !VIEW_BILLABLES_SALES ? (
            <Text>{this.getPaymentConditionType(text)}</Text>
          ) : (
            <Select
              defaultValue={text}
              style={{ width: '100%' }}
              onSelect={(value: string) => this.onSelectPaymentType(row, value)}
            >
              <Select.Option key="1" value="CASH">
                Contado
                            </Select.Option>
              <Select.Option key="2" value="CREDIT">
                Crédito
                            </Select.Option>
              <Select.Option key="3" value="VOUCHERS" disabled>
                Vales
                            </Select.Option>
            </Select>
          );
        }}
      />
      <Table.Column
        title="Forma de Pago"
        dataIndex="formaPago"
        key="formaPago"
        render={(text: string, row: Sale): JSX.Element => {
          return !isEmpty(row.invoice) ||
            row.billed ||
            !paymentChange ||
            !VIEW_BILLABLES_SALES ? (
            <Text>{this.getFormaPagoDescription(text)}</Text>
          ) : !isFetching ? (
            <Select
              defaultValue={text}
              style={{ width: '100%', minWidth: 150 }}
              onSelect={(value: string) =>
                this.onSelectFormaPago(row, value)
              }
            >
              {this.props.formaPago.map(fp => {
                let disabled = false;
                const currentConditionType = row.paymentConditionType
                if (currentConditionType === "CREDIT" && fp.codigoSat !== "99") {
                  disabled = true;
                } else if ((currentConditionType === "CASH" || currentConditionType === "VOUCHERS")
                  && fp.codigoSat === "99") {
                  disabled = true;
                } else {
                  disabled = false;
                }
                return <Select.Option disabled={disabled} key={fp.id} value={fp.codigoSat}>
                  {fp.descripcion}
                </Select.Option>
              })}
            </Select>
          ) : <></>;
        }}
      />
      <Table.Column
        title={'Cantidad(' + this.state.unitType + ')'}
        dataIndex="totalVolume"
        key="totalVolume"
        render={(text: string): React.ReactNode => {
          return this.state.unitType === 'L'
            ? this.formatNumber(Number(text))
            : this.formatNumber(Number(text) * 2);
        }}
      />
      <Table.Column title="Monto" dataIndex="total" key="total" render={(row: string) => <Text>{'$' + this.formatNumber(Number(row))}</Text>} />
      <Table.Column title="Equipo" key="equipo" render={(row: string, s: Sale) => {
        const eq = this.props.equipmentNumbers.find(e => e.id === s.equipmentId);
        if (eq === undefined) return <Text>N/A</Text>
        return <Text>{eq.equipmentNumber + " " + eq.applicationType + " " + eq.tag}</Text>
      }
      } />
      <Table.Column
        title="Corte"
        dataIndex="cutFolio"
        key="cutFolio"
        filterMultiple
        filteredValue={this.state.cutFolio}
        filters={this.state.cutFoliosToShow}
        filterDropdown={false}
      />
      <Table.Column
        title="Requiere factura"
        dataIndex="invoiceRequired"
        key="invoiceRequired"
        filteredValue={[this.state.invoiceRequired]}
        filterMultiple={false}
        filters={[{ value: 'ALL', text: 'Todas' }, { value: 'REQUIRED', text: 'Requiere Factura' }, { value: 'NOT_REQUIRED', text: 'No Requiere Factura' }]}
        render={(text: boolean): React.ReactNode =>
          text ? <Text>Si</Text> : <Text>No</Text>
        }
      />
      {this.state.invoiceStatus !== 'IN_PROGRESS' && <Table.Column
        title="Acciones"
        dataIndex="invoice"
        key="invoice"
        fixed='right'
        render={(text: string, record: Sale): React.ReactNode => {
          return record.sale ? !isEmpty(record.invoice) ? (
            record.billed ? (
              <span>
                <Row>
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={() => this.props.resendInvoce(record.id)}
                  >
                    Reenviar factura
                                </button>
                </Row>
                <Row>
                  <Popconfirm
                    disabled={this.state.pdf}
                    title="PDF"
                    okText="Descargar PDF"
                    cancelText="Ver PDF"
                    onConfirm={() =>
                      this.props.downloadFile(
                        record.id,
                        'file.pdf',
                        record.IUSCode,
                        false,
                      )}
                    onCancel={() =>
                      this.props.downloadFile(
                        record.id,
                        'file.pdf',
                        record.IUSCode,
                        true,
                      )}
                  >
                    <button
                      type="button"
                      className="link-button"
                      onClickCapture={() => this.setState({ pdf: false })}
                    >
                      PDF
                                </button>
                  </Popconfirm>
                  <Divider type="vertical" />
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={() =>
                      this.props.downloadFile(
                        record.id,
                        'file.xml',
                        record.IUSCode,
                        false,
                      )
                    }
                  >
                    XML
                                </button>
                </Row>
            
                <Row>
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={async () => {
                      try {
                        await this.props.cancelInvoice(record.id);
                      } catch (error) {
                        message.error(error.message);
                      }
                    }}
                  >
                    Cancelar Factura
                                </button>
                </Row>
                
              </span>
            ) : (
              <Row>
                <span>Factura en progreso</span>
              </Row>
            )
          ) : record.billed && isEmpty(record.invoice) ? (
            <span>Facturada por otro medio</span>
          ) : (
            <span>
              {record.billable && (
                <Row>
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={() =>
                      this.props.downloadFile(
                        record.id,
                        'file.pdf',
                        record.IUSCode,
                        true,
                      )
                    }
                  >
                    Vista previa factura
                                    </button>
                  <br />
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={
                      !multipleBill
                        ? () => {
                          this.props.invoicingSale(
                            record,
                            getDecodeToken()
                              .customerCode || '',
                            this.getInitialTime().toDate(),
                            this.getEndTime().toDate(),
                            this.state.invoiceStatus,
                            (this.props.salePage.number || 0) + 1,
                            this.state.orderDate,
                            VIEW_BILLABLES_SALES,
                            this.state.dc,
                            this.state.invoiceRequired,
                            this.state.serviceNumberId,
                            this.state.equipmentIds,
                            this.state.formaPago,
                            this.state.rfc,
                            this.state.orderRFC,
                            this.state.cutFolio
                          );
                        }
                        : () => null
                    }
                  >
                    Facturar
                                </button>
                  <br />
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={async () => {
                      try {
                        await this.props.addSaleToStagingArea(
                          record.IUSCode,
                          record.total,
                          false,
                        );
                        this.setState({
                          showModal: !this.state.showModal,
                        });
                      } catch (error) {
                        Popups.notifyError(`${parseApiError(error).description} Con opcion de agregar datos fiscales`);
                      }
                    }}
                  >
                    Agregar datos fiscales
                                </button><br />
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={() =>
                      this.setAlreadyInvoice(record.id)
                    }
                  >
                    Marcar como facturada
                                </button>
                </Row>
              )}
              {record.canceled && (
                <Row>
                  <button
                    type="button"
                    className="link-button"
                    onClickCapture={() =>
                      this.props.downloadFile(record.id, 'cancel.xml', record.IUSCode, false)
                    }
                  >
                    XML de Cancelación.
                                      </button>
                </Row>
              )}
            </span>
          ) : (<span />);
        }}
      />}
    </Table>)
  }

  validateZip = (): boolean => {
    if (this.state.rfc === 'ALL') {
      Popups.notifyError("Selecciona un unico RFC");
      return false;
    }
    if (this.state.rfc !== 'XAXX010101000') {
      return this.validateNumberOfInvoice();
    }
    else {
      const validYear = this.getInitialTime().year() === this.getEndTime().year();
      const validMonth = this.getInitialTime().month() === this.getEndTime().month();
      const validDay = this.getInitialTime().day() === this.getEndTime().day();
      if (validYear && validMonth && validDay) {
        return this.validateNumberOfInvoice();
      }
      else {
        Popups.notifyError("Solo es posible exportar facturas de 1 dia para este RFC");
        return false;
      }
    }
  }

  validateNumberOfInvoice = (): boolean => {
    if (Number(this.props.billedSalestotal) || 0 > 1) {
      return true;
    } else {
      Popups.notifyError("No existen facturas a exportar");
      return false;
    }
  }

  public render = (): React.ReactNode => {
    const {
      saleSelectedList,
      salePage,
      totalElementsSelected,
      isFetching,
      systemConfigInvoice,
    } = this.props;
    const { maxZipTimeHours, maxZipTimeMinutes } = systemConfigInvoice;
    let zipModalText = '';
    if (maxZipTimeHours > 0) {
      if (maxZipTimeHours > 1) zipModalText = maxZipTimeHours + 'Horas'
      else zipModalText = maxZipTimeHours + ' Hora';
      if (maxZipTimeMinutes > 0) zipModalText = zipModalText + ' y ' + maxZipTimeMinutes + ' Minutos'
    } else {
      zipModalText = maxZipTimeMinutes + ' Minutos';
    }
    if (getDecodeToken().isClient) {
      return <Redirect to="/home" />
    }
    let content: Sale[] | undefined = salePage.content;
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Spin tip="Cargando..." spinning={isFetching}>
        <Modal
          title="Agregar datos fiscales"
          visible={this.state.showModal}
          width="100rem"
          centered
          destroyOnClose
          style={{ paddingTop: '0px' }}
          closable={false}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
        >
          <FiscalDataForm
            showFiscalDataProvidedNext={false}
            showFiscalDataProvidedPrev={false}
            isFetching={isFetching}
            invoice={this.state.invoice}
            customChildren={this.renderEmailForm}
            customButton={this.renderCustomButton}
            renderCancelButton={this.renderCancelButton}
          />
        </Modal>
        <Modal
          title="Fecha de Corte"
          visible={this.state.showModalCorte}
          width='60%'
          style={{ paddingTop: '0px' }}
          closable={false}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
        >
          <Row>
            {this.renderSelectCenter()}
            {this.renderSelectEquipment()}
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ paddingLeft: 24, paddingRight: 24 }}>
              <label className="label">{'Consultar período'}</label>
              <br />
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                value={[
                  moment(this.props.initialDate),
                  moment(this.props.endedDate),
                ]}
                onChange={(dates: any) => {
                  this.props.setInitDate(moment(dates[0]).toDate());
                  this.props.setEndDate(moment(dates[1]).toDate());
                }}
                showTime
                size="small"
                format={DATE_FORMAT}
                onOk={(dates: any): void => {
                  this.onDatePickerChange(dates[0], dates[1]);
                  this.props.saleSelectedList.forEach((sale: SaleSelected) => this.props.changeToBill(sale.id, false, sale.total, sale.totalVolume));
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ paddingLeft: 24, paddingRight: 24 }}>
              <label className="label">{'Número de corte:'}</label>
              <Select
                defaultActiveFirstOption
                showSearch
                value={this.state.idCutSelect}
                filterOption={(input, { props: { children } }) => {
                  if (children !== undefined && children !== null) {
                    return children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return false;
                }}
                onSelect={(value: string): void => {
                  this.onSelectCutNumber(value);
                  this.setState({ buttonMarcarAInternas: true });
                }
                }
                style={{ width: '100%' }}
              >
                {this.renderCutNumbers()}
              </Select>
            </Col>
          </Row>
          <Row style={{ paddingTop: '50px' }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={9} xxl={9} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
              <Popconfirm
                disabled={!this.state.buttonMarcarAInternas}
                title={`Esta seguro que desea convertr estas ventas a internas?`}
                okText="Si"
                cancelText="No"
                onConfirm={this.convertToInternal}
              >
                <Button
                  disabled={!this.state.buttonMarcarAInternas}
                >
                  Convertir ventas a internas
                  </Button>
              </Popconfirm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={9} xxl={9} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
              <Popconfirm
                disabled={!this.state.buttonMarcarAInternas}
                title={`Esta seguro que desea convertir estas pruebas internas a ventas?`}
                okText="Si"
                cancelText="No"
                onConfirm={this.convertToSales}
              >
                <Button
                  disabled={!this.state.buttonMarcarAInternas}
                >
                  Convertir internas a ventas
                  </Button>
              </Popconfirm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
              <Button
                onClick={() => {
                  this.setState({ showModalCorte: !this.state.showModalCorte, idCutSelect: '' });
                  this.onChangeEquipment('', false);
                }}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={`Descarga xml y pdf de las facturas de ${this.state.rfc}`}
          visible={this.state.downloadModal}
          width='60%'
          style={{ paddingTop: '0px' }}
          closable={false}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
        >
          <Row>
            <p>Se descargaran las facturas realizadas desde {moment(this.props.initialDate).format(DATE_FORMAT)} hasta {moment(this.props.endedDate).format(DATE_FORMAT)}</p>
            <p>Se enviara las instrucciones de descarga el archivo zip al correo: {getDecodeToken().email}</p>
            <p>El archivo estara disponible {zipModalText} apartir de la recepcion del correo</p>
          </Row>
          <Row>
            <Col span={4}>
              <Button
                type='primary'
                onClick={() => {
                  this.props.getZipFile(
                    this.getInitialTime().toDate(),
                    this.getEndTime().toDate(),
                    this.state.dc,
                    getDecodeToken().email,
                    this.state.rfc
                  );
                  this.setState({ downloadModal: !this.state.downloadModal });
                }}
              >
                Aceptar y Enviar
            </Button>
            </Col>
            <Col span={4} offset={16}>
              <Button
                type='danger'
                onClick={() => {
                  this.setState({ downloadModal: !this.state.downloadModal });
                }}
              >
                Cancelar
            </Button>
            </Col>
          </Row>
        </Modal>
        <Form>
          <Modal
            title={`Generación de Reporte Excel | ${this.state.excelReportType}`}
            visible={this.state.excelModal}
            width='60%'
            style={{ paddingTop: '0px' }}
            closable={false}
            cancelButtonProps={{ hidden: true }}
            okButtonProps={{ hidden: true }}
            
          >
            <Row>
              <p>El reporte incluirá las {this.state.excelReportType} desde la fecha {moment(this.props.initialDate).format(DATE_FORMAT)} hasta {moment(this.props.endedDate).format(DATE_FORMAT)}</p>
              <p>Se enviara las instrucciones de descarga del documento Excel al correo: {this.state.excelEmail}</p>
              <p>Si lo desea, puede modificar la dirección de correo a la cual se enviará el reporte solicitado.</p>
            </Row>
            <Row>
              <Form.Item>
                {getFieldDecorator("email", {
                  initialValue: this.state.excelEmail,
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "Ingresa un correo electrónico valido",
                    },
                  ],
                })(<Input placeholder="Correo electrónico"  
                      onChange={(output) => {
                        this.setState({ excelEmail: output.target.value });
                      }}/>)}
              </Form.Item>
            </Row>
            <br></br>
            <Row>
              <Col span={4}>
                <Button
                  type='primary'
                  onClick={() => {
                    this.props.createExcelByFilters(
                      this.getInitialTime().toDate(),
                      this.getEndTime().toDate(),
                      this.state.orderDate,
                      this.state.invoiceStatus,
                      VIEW_BILLABLES_SALES,
                      this.state.dc,
                      this.state.invoiceRequired,
                      this.state.serviceNumberId,
                      this.state.equipmentIds,
                      this.state.formaPago,
                      this.state.rfc,
                      this.state.orderRFC,
                      this.state.cutFolio,

                      getDecodeToken().id,
                      this.state.excelReportType,
                      this.state.excelEmail,
                      false,
                    );
                    this.setState({ excelModal: !this.state.excelModal });
                  }}
                >
                  Generar Reporte Excel
              </Button>
              </Col>
              <Col span={4} offset={16}>
                <Button
                  type='danger'
                  onClick={() => {
                    this.setState({ excelModal: !this.state.excelModal });
                  }}
                >
                  Cancelar
              </Button>
              </Col>
            </Row>
          </Modal>
        </Form>
        
        <div className={'content-backgroud'}>
          {this.renderFilters()}
          <Row style={{ marginLeft: 20, marginRight: 20 }}>
            <Row gutter={16} style={{ marginTop: 20 }}>
              <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={5} 
              hidden={
                //this.validateRenderSearchByIUS(content) || 
                this.isHiddenElement("All Results Excel", this.state.invoiceStatus)
                }
              >
                {this.formatNumber(salePage.totalElements || 0)} {this.formatNumber(salePage.totalElements || 0) === "1" ? "Resultado" : "Resultados"}
                <button
                  type="button"
                  className="link-button"
                  onClickCapture={() =>{
                    this.setState({ excelModal: true, excelReportType: "Ventas y Facturas" });
                  }}> | Exportar a Excel<FileExcelOutlined /> <ExportOutlined /></button>
                    
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6} 
                hidden={
                  this.validateRenderSearchByIUS(content) && 
                  ((this.state.invoiceStatus)==='NOT_INVOICE' ||
                  (this.state.invoiceStatus)==='SALE_INVOICE')
                  ? false: true}>
                {this.formatNumber(Number(this.props.counterOnlySales) || 0)} {this.formatNumber(Number(this.props.counterOnlySales) || 0) === "1" ? "Venta" : "Ventas"} 
                <button
                  type="button"
                  className="link-button"
                  onClickCapture={() =>{
                    this.setState({ excelModal: true, excelReportType: "Ventas" });
                  }
                  }> | Reporte de ventas <FileExcelOutlined /> <ExportOutlined /></button>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6} 
              hidden={
                this.validateRenderSearchByIUS(content) && 
                ((this.state.invoiceStatus)==='INVOICE' ||
                (this.state.invoiceStatus)==='SALE_INVOICE')
                ? false: true}
              >
                {this.formatNumber(Number(this.props.totalSales) || 0)} {this.formatNumber(Number(this.props.totalSales) || 0) === "1" ? "Factura" : "Facturas"} 
                <button
                  type="button"
                  className="link-button"
                  onClickCapture={() =>{
                    this.setState({ excelModal: true, excelReportType: "Ventas Facturadas" });
                  }
                  }>| Reporte de facturas <FileExcelOutlined /> <ExportOutlined /></button>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} hidden={!this.validateRenderSearchByIUS(content) || (this.state.invoiceStatus)==='ERROR' || (this.state.invoiceStatus)==='IN_PROGRESS'? true: false}>
                {this.formatNumber(Number(this.props.totalCancel) || 0)} {this.formatNumber(Number(this.props.totalCancel) || 0) === "1" ? "Cancelada" : "Canceladas"}
                <button
                  type="button"
                  className="link-button"
                  onClickCapture={() =>{
                    this.setState({ excelModal: true, excelReportType: "Facturas Canceladas" });
                  }
                  }> | Reporte Canceladas <FileExcelOutlined /> <ExportOutlined /></button>
              </Col>
              <Col span={24}>
                {this.renderInformationTables()}
              </Col>
            </Row>
            <Row gutter={32} style={{ marginTop: 20 }} hidden={this.isHiddenElement("Botones para facturar", this.state.invoiceStatus)}>
              {this.validateRenderSearchByIUS(content) && <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4}>
                <Popconfirm
                  overlayStyle={{ whiteSpace: 'pre-line' }}
                  title={`Enviar ${totalElementsSelected - saleSelectedList.length} ventas a facturar?`.concat(this.renderExtraTextOnSendAllSales(totalElementsSelected - saleSelectedList.length))}
                  okText="Si"
                  cancelText="No"
                  onConfirm={() =>
                    this.props.sendAllSalesToInvoice(
                      saleSelectedList ? saleSelectedList.map(s => s.id) : [],
                      getDecodeToken().customerCode || '',
                      this.getInitialTime().toDate(),
                      this.getEndTime().toDate(),
                      this.state.invoiceStatus,
                      (this.props.salePage.number || 0) + 1,
                      this.state.orderDate,
                      VIEW_BILLABLES_SALES,
                      this.state.dc,
                      this.state.invoiceRequired,
                      this.state.serviceNumberId,
                      this.state.equipmentIds,
                      this.state.formaPago,
                      this.state.rfc,
                      totalElementsSelected - saleSelectedList.length,
                      false,
                      this.state.orderRFC,
                      this.state.cutFolio,
                      getDecodeToken().id
                    )
                  }
                >
                  <Button
                  >
                    Facturar seleccion
                  </Button>
                </Popconfirm>
              </Col>}
              {this.validateRenderSearchByIUS(content) && <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4}>
                <Popconfirm
                  title={`Facturar ${totalElementsSelected - saleSelectedList.length} ventas en una sola factura?`}
                  okText="Si"
                  cancelText="No"
                  onConfirm={this.sendAllSalesToSingleInvoice}
                >
                  <Button
                  >
                    Facturación agrupada
                  </Button>
                </Popconfirm>
              </Col>}
              {/* <Col xs={24} sm={24} md={12} lg={3} xl={3} xxl={3} hidden = {(this.state.invoiceStatus)==='ERROR'? true: false }>
                <Button onClick={() => {
                  this.setState({ downloadModal: this.validateZip() });
                }}>Descargar Zip</Button>
              </Col> */}
              <Col xs={24} sm={24} md={12} lg={3} xl={3} xxl={3}>
                <Button
                  onClick={() => {
                    this.setState({ showModalCorte: !this.state.showModalCorte });
                  }}
                >
                  <SyncOutlined /> Reasignar corte
                  </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col
                span={24}
                style={{ backgroundColor: "white" }}
              >
                <Tabs activeKey={this.state.tabs} onChange={(key) => {
                  this.setState({ tabs: key });
                  if (key === '1') this.onChangeRadio('SALE_INVOICE');
                  if (key === '2') this.onChangeRadio('INVOICE');
                }}>
                  <Tabs.TabPane key="1" tab="Todo">
                    {this.renderTableData(this.state.tabs)}
                  </Tabs.TabPane>
                  <Tabs.TabPane key="2" tab="Facturas">
                    {this.renderTableData(this.state.tabs)}
                  </Tabs.TabPane>
                  <Button>Boton</Button>
                </Tabs>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Pagination
                  style={{ flex: 1 }}
                  current={(this.props.salePage.number || 0) + 1}
                  total={this.props.salePage.totalElements}
                  onChange={this.handlePaginationChange}
                />
              </Col>
            </Row>
          </Row>
        </div>
      </Spin>
    );
  };

  isHiddenElement = (elementName: string, filterName:string): boolean => {
    /*
    posibles filterName

    "SALE_INVOICE"
    "NOT_INVOICE"
    "INVOICE"
    "IN_PROGRESS"
    "ERROR" */

    switch (elementName) {
      case "Botones para facturar":
        if(filterName === "IN_PROGRESS" || filterName === "ERROR"){
          return true;
        }
        break;
        case "All Results Excel":
          if(filterName !== "SALE_INVOICE" && filterName !== "ERROR")
            return true;
          break;
      default:
        break;
    }
    return false;
  }
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    salePage: states.saleState.saleList,
    isFetching: states.saleState.isFetching,
    isFetchingBilled: states.saleState.isFetchingBilled,
    companySalesList: states.companyState.companySalesList,
    initialDate: states.saleState.initDate,
    endedDate: states.saleState.endDate,
    multicenter: states.companyState.multicenter,
    isEquimentNumber: states.companyState.isEquimentNumber,
    sumTotalLK: states.saleState.sumTotalLK,
    sumTotalM: states.saleState.sumTotalM,
    sumTotalLKBillable: states.saleState.sumTotalLKBillable,
    sumTotalMBillable: states.saleState.sumTotalMBillable,
    sumTotalLKSelected: states.saleState.sumTotalLKSelected,
    sumTotalMSelected: states.saleState.sumTotalMSelected,
    saleSelectedList: states.saleState.saleSelectedList,
    salesStagingArea: states.invoiceState.salesStagingArea,
    paymentChange: states.saleState.paymentChange,
    totalElementsBillable: states.saleState.totalElementsBillable,
    totalElementsSelected: states.saleState.totalElementsSelected,
    fiscalPermissions: states.companyState.fiscalPermissions,
    equipmentNumbers: states.companyState.equipments,
    formaPago: states.saleState.formaPago,
    fiscalDataList: states.invoiceUserState.fiscalDataList,
    canRegistred: states.loginState.canRegistred,
    cortes: states.saleState.cortes,
    totalSales: states.saleState.totalSales,
    billedSalestotal: states.saleState.billedSalestotal,
    totalVolumeSalesTotal: states.saleState.totalVolumeSalesTotal,
    totalSalesSize: states.saleState.totalSalesSize,
    totalCancel: states.saleState.totalCancelSales,
    systemConfigInvoice: states.configurationState.systemConfigInvoice,
    counterOnlySales: states.saleState.counterOnlySales,
  };
};

const mapDispatchToProps = {
  page,
  downloadFile,
  invoicingSale,
  sendAllSalesToInvoice,
  changeBillable,
  changePaymentType,
  changeformaPago,
  changeToBill,
  resendInvoce,
  getCompanySales,
  setInitDate,
  setEndDate,
  alreadyInvoice,
  cancelInvoice,
  invoiceAll,
  addSaleToStagingArea,
  removeSaleToStagingArea,
  saveInvoce,
  getFiscalPermission,
  getEquipmentsNumbers,
  cleanSales,
  getFormaPago,
  getFiscalData,
  canRegisterUser,
  setCortes,
  clearCortes,
  getSaleByIus,
  changeCustomer,
  changeToInternal,
  changeToSale,
  getZipFile,
  getSystemConfigInvoice,
  createExcelByFilters,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(SalesListComponent));
