/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';

export interface AccessToken {
  accessToken?: string;
}

const initialAccesTokenState: LoginState = {
  accessToken: {},
  canRegistred: false,
};

export interface LoginState {
  readonly accessToken: AccessToken;
  readonly canRegistred: boolean;
}

const accessToken: Reducer<LoginState> = (
  state = initialAccesTokenState,
  action,
): LoginState => {
  switch (action.type) {
    case 'SET':
      return { ...state, accessToken: action.accessToken };
    default:
      return state;
  }
};

const canRegistred: Reducer<boolean>= (
  state = false,
  action,
): boolean => {
  switch (action.type) {
    case 'SET_CAN_REGISTER':
      return action.canRegistred;
    default:
      return state;
  }
};

export default combineReducers<LoginState>({
  accessToken,
  canRegistred,
});
