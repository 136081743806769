/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * All the strings used in the frontend come from this file
 * we are not using a library for this because this project
 * won't be internacionalizated in the short term so this is
 * a non elegant but at least separated solution.
 */

export class Strings {
  public static ERROR = '👎 Error';
  public static SUCCESS = '👍 Éxito';
  public static INVOICE_CREATED =
    'La factura esta siendo procesada. Revise su correo electrónico o la bitacora.';
  public static TICKET_ALREADY_ADDED = 'El ticket ya ha sido agregado';
  public static NO_TICKET_IN_STAGING_AREA = 'Falta Capturar notas de venta';
  public static TICKET_STEP = 'Ticket';
  public static FISCAL_DATA_STEP = 'Datos fiscales';
  public static BUILD_INVOICE_STEP = 'Generar Factura';
  public static IUS_ADDED_IN_STAGING_AREA = 'IUS agregados';
  public static STAMP_INVOICE_TICKET = 'Factura ticket';
  public static MIN_SEARCH_SRTING_SIZE = 3;
  public static EMPTY_STRING = '';
}
export class messagesCustomer {
  public static ENTERPRICE__NAME_REQUIERED: string =
    'Ingresa un Nombre de empresa';
  public static HUNDRED_CHARACTERS: string =
    'No se permiten mas de 100 caracteres';
  public static TWENTY_CHARACTERS: string =
    'No se permiten mas de 20 caracteres';
  public static FIVE_HUNDRED_CHARACTERS: string =
    'No se permiten mas de 500 caracteres';
  public static FIVE_CHARACTERS: string = 'No se permiten mas de 5 caracteres';
  public static RFC_REQUIERED: string = 'Ingresa un RFC';
  public static RFC_VALID: string = 'Ingresa un rfc válido';
  public static STREET_REQUIERED = 'Ingresa una calle';
  public static EXTERNAL_NUMBER_REQUIRED: string = 'Ingresa un número exterior';
  public static FISCA_REGIMEN_REQUIERED: string = 'Ingresa un régimen fiscal';
  public static ZIP_CODE_REQUIRED: string = 'Ingresa un código postal';
  public static ZIP_CODE_VALID = 'Ingresa un código postal Válido';
  public static COLONY_REQUIRED = 'Ingresa una colonia';
  public static CUSTOMER_CODE_REQUIRED = 'Ingresa un código de Cliente';
  public static INT_ONLY = 'solo se aceptan números enteros';
  public static NUMBERS_ONLY = 'Ingresa una cantidad válida';
  public static COMERCIAL_NAME_REQUIERED = 'Ingresa un nombre comercial';
  public static CITY_REQUIRED = 'Ingresa una ciudad';
  public static MUNICIPALITY_REQUIRED = 'Ingresa un municipio';
  public static STATE_REQUIERED = 'Ingresa un estado';
  public static PASSWORD_REQUIRED = 'Ingresa una contraseña';
  public static CFDI_REQUIRED = 'Seleccione un uso de CFDI';
  public static PAYMET_FORM_REQUIRED = 'Seleccione una forma de pago';
  public static EMAIL_REQUIRED = 'Ingrese un correo electrónico';
  public static EMAIL_VALID = 'Ingresa un correo electrónico valido';
  public static DISTRIBUTION_CENTER_VALID =
    'Selecciona un Centro de distribución';
  public static DATE_REQUIRED = 'Selecciona una fecha';
}
export class labelsCustomer {
  public static CUSTOMER_CODE: string = 'Código G4s';
  public static COMERCIAL_NAME: string = 'Nombre comercial';
  public static DISCOUNT: string = 'Descuento %';
  public static DISTRIBUTION_CENTER: string = 'Centro de distribución G4S';
  public static BIRTHDAY: string = 'cumple Años';
  public static CUSTOMER_TYPE: string = 'Tipo de Cliente';
  public static RFC: string = 'RFC';
  public static BUSINESS_NAME: string = 'Razón social';
  public static EXTERNAL_NUMBER: string = 'Número exterior';
  public static INTERNAL_NUMER: string = 'Número interior';
  public static ZIP_CODE: string = 'Código postal';
  public static STATE: string = 'Estado';
  public static CITY: string = 'Ciudad';
  public static COLONY: string = 'Colonia';
  public static MUNUCIPALY: string = 'Municipio';
  public static STREET: string = 'Calle';
  public static OBSERVATIONS: string = 'Observaciones';
  public static CREDIT_DAYS: string = 'Días de Crédito';
  public static CREDIUT_LIMIT: string = 'Límite de Crédito';
  public static EMAIL: string = 'Correo Electrónico';
  public static PASSWORD: string = 'Contraseña';
  public static PAYMENY_FORM: string = 'Forma de pago';
  public static USE_CFDI: string = 'Uso de CFDI';
  public static INVOICE_AUTOMATIC: string = '¿Deseas autofacturar?';
}
