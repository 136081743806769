import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RootState } from 'store/configureStore';
import { FiscalData } from 'types/type';
import { RouteComponentProps } from 'react-router';
import {
  getFiscalDataAction,
  getPermissions,
  cleanRedux,
  saveFiscalData,
  getCustomer,
} from '../redux/actions';
import { isEmpty } from 'lodash';
import {
  Input,
  Select,
  Button,
  Row,
  Col,
} from 'antd';
import { emailPattern, rfcformat, spacesformat } from 'commons/regularExpressions';
import { getDecodeToken } from 'security';
import FiscalDataService from 'services/fiscalData';
const fiscalDataService = new FiscalDataService();

interface HomeRouterProps {
  id: string;
}

interface DispatchProps {
  getFiscalDataAction: (id: string) => void;
  getPermissions: () => void;
  cleanRedux: () => void;
  saveFiscalData: (fiscalData: FiscalData, callback: Function) => void;
  getCustomer: (code: string) => void;
}

interface StateProps {
  fiscalData: FiscalData;
}

type Props = DispatchProps &
  StateProps &
  RouteComponentProps<HomeRouterProps> &
  FormComponentProps;

class newUsers extends Component<Props> {
  public componentWillMount() {
    const { id } = this.props.match.params;
    if (id !== '0') this.props.getFiscalDataAction(id);
    else this.props.cleanRedux();
    this.props.getPermissions();
    fiscalDataService.fiscalDataList().then((response)=>{
      this.setState({
        regimenFiscal: response.regimenFiscals,
      });
    });
  }

  public componentWillUnmount() {
    this.props.cleanRedux();
    this.props.form.resetFields();
  }

  public state = {
    isClient: false,
    visible: false,
    clientCode: '',
    regimenFiscal: []
  };

  public saveFiscalData() {
    const {
      form,
      fiscalData ,
    } = this.props;
    form.validateFields(
      (err, values: FiscalData): void => {
        if (!err) {
          const userId = getDecodeToken().id
          const callback = () => {
            form.resetFields();
            this.props.cleanRedux();
            this.props.history.push('/fiscalData');
          }
          if (isEmpty(fiscalData.id)) this.props.saveFiscalData({...values, userId}, callback);
          else {
            this.props.saveFiscalData({ ...fiscalData, ...values, userId }, callback );
          }
        }
      },
    );
  }

  public render = (): JSX.Element => {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { fiscalData} = this.props;
    return (
      <Form style={{
        // display: 'flex',
        backgroundColor: '#fff',
        marginTop: 24,
        marginLeft: 24,
        marginRight: 24,
        padding: 50,
      }} layout="vertical">
      {/* <Row
        style={{
          display: 'flex',
          backgroundColor: '#fff',
          marginTop: 24,
          marginLeft: 24,
          marginRight: 24,
          padding: 50,
        }}
      > */}
        <Row style={{display: 'flex', flex:1}}>
          <Col style={{ flex: 0.3 }}>
            <span className="label">Información fiscal</span>
          </Col>
          <Col style={{ flex: 0.7 }}>
              <Row style={{ display: 'flex' }}>
                <Col style={{ flex: 1 }}>
                  <Form.Item label="RFC" id="rfc" style={{ color: '#fff' }}>
                    {getFieldDecorator('rfc', {
                      initialValue: fiscalData.rfc,
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: 'Ingresa un RFC',
                        },
                        {
                          pattern: rfcformat,
                          message: 'Ingresa un rfc válido',
                        },
                        {
                          max: 20,
                          whitespace: true,
                          message: 'No se permiten mas de 20 caracteres',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item hasFeedback label="Régimen fiscal">
                    {getFieldDecorator('fiscalRegime', {
                      initialValue: fiscalData && fiscalData.fiscalRegime ? fiscalData.fiscalRegime : '',
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: 'Ingresa un nombre de empresa',
                        },
                      ],
                    })(
                      <Select showSearch size="large">
                        {this.state.regimenFiscal!== undefined && this.state.regimenFiscal.map((item: {code:string, description:string}): React.ReactNode=>(
                            <Select.Option key={item.code} value={item.code}>
                            <div id={item.code}>
                              {item.code.concat(" - ").concat(item.description)}
                            </div>
                          </Select.Option>
                          ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col style={{ flex: 1, paddingLeft: 10 }}>
                  <Form.Item label="Razón social" id="businessName" style={{ color: '#fff' }}>
                    {getFieldDecorator('businessName', {
                      initialValue: fiscalData.businessName,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingrese una Razón social!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item style={{ flex: 1 }} label="Correo" id="email">
                    {getFieldDecorator('email', {
                      initialValue: fiscalData.email,
                      rules: [
                        {
                          pattern: emailPattern,
                          required: true,
                          message: 'Por favor ingrese un correo valido!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
          </Col>
        </Row>
        <Row style={{display: 'flex', flex:1}}>
          <Col style={{ flex: 0.3 }}>
            <span className="label">Domicilio</span>
          </Col>
          <Col style={{ flex: 0.7 }}>
              <Row style={{ display: 'flex' }}>
                <Col style={{ flex: 1 }}>
                  <Form.Item label="Calle" id="street" style={{ color: '#fff' }}>
                    {getFieldDecorator('street', {
                      initialValue: fiscalData.street,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingrese una Calle!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Colonia" id="colony" style={{ color: '#fff' }}>
                    {getFieldDecorator('colony', {
                      initialValue: fiscalData.colony,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingrese una Colonia!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Ciudad" id="city" style={{ color: '#fff' }}>
                    {getFieldDecorator('city', {
                      initialValue: fiscalData.city,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingrese una Ciudad!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Estado" id="state" style={{ color: '#fff' }}>
                    {getFieldDecorator('state', {
                      initialValue: fiscalData.state,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingrese un Estado!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col style={{ flex: 1, paddingLeft: 10 }}>
                  <Row style={{display: 'flex', flex: 1}}>
                  <Form.Item label="Numero" id="numExt" style={{ color: '#fff' }}>
                    {getFieldDecorator('numExt', {
                      initialValue: fiscalData.numExt,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingrese un Numero!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Interior" id="numInt" style={{ color: '#fff' }}>
                    {getFieldDecorator('numInt', {
                      initialValue: fiscalData.numInt,
                    })(<Input />)}
                  </Form.Item>
                  </Row>
                  <Form.Item label="Código postal" id="zipCode" style={{ color: '#fff' }}>
                    {getFieldDecorator('zipCode', {
                      initialValue: fiscalData.zipCode,
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: 'Ingresa un código postal',
                        },
                        {
                          max: 5,
                          whitespace: true,
                          message: 'No se permiten mas de 5 caracteres',
                        },
                        {
                          pattern: spacesformat,
                          message: 'Ingresa un código postal Válido',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Municipio" id="municipality" style={{ color: '#fff' }}>
                    {getFieldDecorator('municipality', {
                      initialValue: fiscalData.municipality,
                      rules: [
                        {
                          required: true,
                          message: 'Por favor ingrese un Municipio!',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
          </Col>
        </Row>
        <Row style={{display: 'flex', flex: 1}}>
              <Button onClick={() => this.saveFiscalData()}>
                {fiscalData.id ? 'Editar' : 'Aceptar'}
              </Button>
              <Button onClick={() => this.props.history.push('/fiscalData')}>
                Cancelar
              </Button>
        </Row>
      {/* </Row> */}
      </Form>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    fiscalData: states.fiscalDataState.fiscalData.content,
  };
};

const mapDispatchToProps = {
  getFiscalDataAction,
  getPermissions,
  cleanRedux,
  saveFiscalData,
  getCustomer,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(newUsers));