import moment from 'moment';
import { User, FiscalData, SystemConfig, SystemConfigInvoice, Settings, EquipmentsList, ServiceNumber, Equipment, FiscalDataList } from 'types/type';

export const initcompany = {
  id: '',
  name: '',
  rfc: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
  fiscalRegime: '',
  zipCode: '',
  colony: '',
  city: '',
  municipality: '',
  state: '',
  logoUrl: '',
  comercialName: '',
  password: '',
  certificate: new File([''], ''),
  privateKey: new File([''], ''),
  emailConfig: '',
  userConfig: '',
  rfcConfig: '',
  passwordConfig: '',
  bussisnesImage: '',
  bussisnesLogo: '',
  folio: 0,
  serie: '',
  selectCreditInvoice: false,
  creditFieldCustomText: 'A credito',
  paymentConditionType: '',
  creditInvoiceAuto: false,
  taxpayerPermitNumber: '',
  clientCancel: false,
  serviceNumberIds: [],
  useZip: false,
};

export const initDistributionCenter = {
  id: '',
  active: false,
  code: 0,
  name: '',
  centerKey: '',
  priceZoneRegion: 0,
  scheme: 0,
  linked: false,
};

export const initSale = {
  id: '',
  createdAt: '',
  updatedAt: '',
  idUserCreate: '',
  idUserUpdate: '',
  total: 0,
  endDate: '',
  totalVolume: '',
  IUSCode: '',
  distCenterId: '',
  salesFolio: '',
  idG4s: '',
  customerRfc: '',
};

export const initInvoice = {
  id: null,
  name: '',
  rfc: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
  fiscalRegime: '',
  zipCode: '',
  colony: '',
  city: '',
  municipality: '',
  state: '',
  usoCfdi: '',
  formaPago: '',
  email: undefined,
  sales: [],
  idG4s: '',
};

export const initCustomer = {
  id: '',
  name: '',
  createdAt: '',
  updatedAt: '',
  idUserCreate: '',
  idUserUpdate: '',
  businessName: '',
  rfc: '',
  street: '',
  address: '',
  externalNumber: '',
  internalNumber: '',
  zipCode: '',
  colony: '',
  city: '',
  municipality: '',
  state: '',
  usoCfdi: '',
  formaPago: '',
  email: '',
  code: 0,
  creditDays: 0,
  creditLimit: 0,
  debt: 0,
  balance: 0,
  automatic: true,
  distributionCenter: '',
  password: '',
  loginDate: moment(),
  discount: '',
  birthday: moment(),
  comercialName: '',
  observations: '',
  statusCustomer: '',
  statusCredit: '',
  credit: true,
};

export const initUnavailableFolio = {
  id: 0,
  serie: '',
  startFolio: 0,
  lastFolio: 0,
  status: false,
};

export const initUser: User = {
  createdAt: '',
  email: '',
  id: '',
  idUserCreate: null,
  idUserUpdate: null,
  isActive: false,
  name: '',
  numberOpportunity: 0,
  token: '',
  updatedAt: '',
  position: '',
  clientCode: '',
  userPermission: [],
  isClient: false,
  lastName: '',
  secondLastName: '',
  number: '',
  direction: '',
  zipCode: '',
  fiscalRegime: '',
};

export const initFiscalData: FiscalData = {
  id: '',
  userId: '', 
  rfc: '',
  fiscalRegime: '',
  businessName: '',
  email: '',
  street: '',
  colony: '',
  city: '',
  state: '',
  numExt: '',
  numInt: '',
  zipCode: '',
  municipality: '',
}
export const initFiscalDataList: FiscalDataList = {
  "0": [],
  "1": [],
}

export const initSystemConfig: SystemConfig = {
  id: 0,
  genericPublicCode: '',
  autoInvoice: false,
  syncSales: false,
  g4sPaginationSize: 0,
  syncCuts: false,
  g4sCutsSize: 0,
  minTotal: 0.00,
  userRegister: false,
  changePayment: false,
  paymentCodes: '',
  invoiceCredit: false,
  maxConceptsNumber: 0,
}

export const initSystemConfigInvoice: SystemConfigInvoice = {
  id: 0,
  maxZipTimeHours: 0,
  maxZipTimeMinutes: 0,
  maxZipSize: 0,
  rfcEmailReject: '',
  emailContact: '',
  siteContact: '',
}

export const initSettings: Settings = {
  timeOutSince: '',
  timeOutLapse: '',
  timeOutCron: '',
  privacityUrl: '',
  useG4scredentials: false,
  g4sUser: '',
  g4sPassword: '',
  assignPaymentCode: false,
  useCFDIS: [],
}

export const initEquipmentList: EquipmentsList = {
  serviceNumberList: Array<ServiceNumber>(),
  unAssignedEquipments: Array<Equipment>(),
}