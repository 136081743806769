import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Card
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { RootState } from 'store/configureStore';
import { UserOutlined, ProfileOutlined, FileOutlined, CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getDecodeToken } from 'security';

interface DispatchProps {

}
interface StateProps {

}
type Props = StateProps & DispatchProps;

class Home extends Component<Props> {


  public render = (): JSX.Element => {
    return (
      <Row
        style={{
          marginLeft: 24,
          marginRight: 24,
          marginTop: 24,
        }}
      >
        <Col
          span={24}
          style={{ marginLeft: 24, marginRight: 24, marginBottom: 24, paddingTop: 15 }}
        >
          <Row gutter={8}>
            <Col span={11}>
              <Card
                title={<Text style={{ color: '#3175CA' }}><UserOutlined /> Datos de usuario</Text>}
              >
                <Col>
                  <p>
                    <Text strong style={{ color: '#646464' }}>
                      <Link to={`/userData/${getDecodeToken().id}`}>Editar datos de usuario</Link>
                    </Text>
                  </p>
                </Col>
              </Card>
            </Col>
            <Col span={11}>
              <Card
                title={<Text style={{ color: '#3175CA' }}><ProfileOutlined /> Datos fiscales</Text>}
              >
                <Col>
                  <p>
                    <Text strong style={{ color: '#646464' }}>
                      <Link to="/fiscalData">Editar datos fiscales</Link>
                    </Text>
                  </p>
                </Col>
              </Card>
            </Col>
            <Col span={24} style={{ paddingTop: 15 }} />
            <Col span={11}>
              <Card
                title={<Text style={{ color: '#3175CA' }}><FileOutlined /> Facturar</Text>}
              >
                <Col>
                  <p>
                    <Text strong style={{ color: '#646464' }}>
                      <Link to="/invoiceUser">Realizar Facturas</Link>
                    </Text>
                  </p>
                </Col>
              </Card>
            </Col>
            <Col span={11}>
              <Card
                title={<Text style={{ color: '#3175CA' }}><CopyOutlined /> Mis facturas</Text>}
              >
                <Col>
                  <p>
                    <Text strong style={{ color: '#646464' }}>
                      <Link to="/salesUser">Acceder a mis facturas</Link>
                    </Text>
                  </p>
                </Col>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {

  };
};

const mapDispatchToProps = {

};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
