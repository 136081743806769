/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 * Reducers for invoice creation.
 */

import { combineReducers, Reducer } from 'redux';
import { Sale } from 'types/type';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  ADD_SALE_TO_STAGING_AREA,
  REMOVE_SALE_TO_STAGING_AREA,
  InvoiceAction,
  CLEAN_STAGING_AREA,
} from 'store/invoice/types';
import { RootState } from 'store/configureStore';
import { Popups } from 'commons/components/popups/popups';
import { Strings as i8n } from 'commons/strings';

/**
 * The state of the invoice page is composed by a variable stagingArea
 * that represents all the sales that will be issued eventually
 * (you add them using IUS and if found are added to this state variable)
 * and a isFetching that indicates if a backend operation is being issued.
 */
export interface InvoiceState {
  readonly salesStagingArea: Sale[];
  readonly isFetching: boolean;
}

/**
 * The reducer for isFetching state. This is quite simple.
 * By default isFetching start in false.
 */
const isFetching: Reducer<boolean> = (
  state = false,
  action: InvoiceAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};
/**
 * The reducer for the salesStaginArea state variable.
 */
const salesStagingArea: Reducer<Sale[]> = (
  state: Sale[] = [],
  action: InvoiceAction,
): Sale[] => {
  switch (action.type) {
    case ADD_SALE_TO_STAGING_AREA:
      const validator = state.some(
        (element: Sale): boolean => {
          return element.id === action.sale.id;
        },
      );
      if (!validator) {
        state.push(action.sale);
      } else {
        Popups.notifyError(i8n.TICKET_ALREADY_ADDED);
      }
      return state;
    case REMOVE_SALE_TO_STAGING_AREA:
      return state.filter((item: Sale) => item.IUSCode !== action.sale.IUSCode);
    case CLEAN_STAGING_AREA:
      return [];
    default:
      return state;
  }
};

/**
 * Useful accesor for the isFetching property of the InvoiceState
 * in the store. This is used in the action for limiting the number
 * of request that can be made at the same time. The thunk middleware
 * returns the full store not the InvoiceState so this method has to
 * go to RootState and look for the isFetching property.😖
 */
export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export default combineReducers<InvoiceState>({
  salesStagingArea,
  isFetching,
});
