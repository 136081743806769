import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { Sale, SalesListAndTotal, FormaPagoCondicion, ExcelFilters } from '../../types/type';
import moment from 'moment';

class SaleService extends Service {
  public ispaymentChange = (): Promise<boolean> =>
    axios
      .get(createCoreServiceUrl('sale/payment/condition'))
      .then((response: AxiosResponse<boolean>): boolean => response.data);
  invoiceAll() {
    axios
      .post(createCoreServiceUrl('sale/invoiceAll'))
      .then(response => response.data);
  }

  public changeToInternal = (cutId: string): Promise<String> =>
    axios
      .post(createCoreServiceUrl(`sale/changeToIntern/${cutId}`))
      .then((response: AxiosResponse<String>): String => response.data);
  public changeToSale = (cutId: string): Promise<String> =>
    axios
      .post(createCoreServiceUrl(`sale/changeToSale/${cutId}`))
      .then((response: AxiosResponse<String>): String => response.data);

  public alreadyInvoice = (id: string): Promise<Sale> =>
    axios
      .post(createCoreServiceUrl(`sale/already/invoice/${id}`))
      .then((response: AxiosResponse<Sale>): Sale => response.data);
  public changeBillable = (id: string, isBillable: boolean): Promise<Sale> =>
    axios
      .post(createCoreServiceUrl(`sale/billable/${id}/${isBillable}`))
      .then((response: AxiosResponse<Sale>): Sale => response.data);
  public changeCustomer = (id: string, customerCode: string): Promise<Sale> =>
    axios
      .post(createCoreServiceUrl(`sale/customer/${id}/${customerCode}`))
      .then((response: AxiosResponse<Sale>): Sale => response.data);
  public changePaymentCondition = (
    id: string,
    paymentType: string,
    distCenterId: string = ""
  ): Promise<Sale> =>
    axios
      .post(createCoreServiceUrl(`sale/paymentType/${id}/${paymentType}`), { distCenterId })
      .then((response: AxiosResponse<Sale>): Sale => response.data);
  public changeFormaPago = (
    id: string,
    formaPago: string,
  ): Promise<Sale> =>
    axios
      .post(createCoreServiceUrl(`sale/formaPago/${id}/${formaPago}`))
      .then((response: AxiosResponse<Sale>): Sale => response.data);
  public getbyiUSCodeandTotal = (
    iUSCode: string,
    total: number
  ): Promise<Sale> =>
    axios
      .get(createCoreServiceUrl(`sale/${iUSCode}/${total}`))
      .then((response: AxiosResponse<Sale>): Sale => response.data);

  public saleList = (
    initDate: Date,
    endDate: Date,
    filter: string,
    pagePosition: number,
    orderDate: string = 'DESC',
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    userId: string = '',
    serviceNumberId: string = '',
    equipmentIds: Array<string> = [],
    formaPago: string,
    rfc: string,
    orderRFC: string = 'DESC',
    cutFolio: Array<string>
  ): Promise<SalesListAndTotal> =>
    axios
      .get(
        createCoreServiceUrl(
          'sale' +
          `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&filter=${filter}` +
          `&page=${pagePosition}` +
          `&orderDate=${orderDate}` +
          `&distributionCenter=${distributionCenter}` +
          `&allowBillable=${allowBillable}` +
          `&requiredInvoice=${requiredInvoice}` +
          (userId ? `&userId=${userId}` : '') +
          `&equipmentId=${equipmentIds}` +
          (serviceNumberId ? `&serviceNumberId=${serviceNumberId}` : '') +
          (formaPago ? `&formaPago=${formaPago}` : '') +
          (cutFolio !== undefined ? `&cutFolio=${cutFolio}` : '') +
          `&rfc=${rfc}` +
          `&orderRFC=${orderRFC}`
        ),
      )
      .then(
        (response: AxiosResponse<SalesListAndTotal>): SalesListAndTotal =>
          response.data,
      );

  /* public saleInfoBilled = (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
  ): Promise<SalesListAndTotal> =>
    axios
      .get(
        createCoreServiceUrl(
          'sale/saleInfoBilled/' +
          `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&distributionCenter=${distributionCenter}`
        ),
      )
      .then(
        (response: AxiosResponse<SalesListAndTotal>): SalesListAndTotal =>
          response.data,
      );  */

  /* public saleInfoBilledAndCanceled = (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
  ): Promise<BilledAndCanceledInfo> =>
    axios
      .get(
        createCoreServiceUrl(
          'v1/sale/billedAndCanceledInfo/' +
          `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&distributionCenter=${distributionCenter}`
        ),
      )
      .then(
        (response: AxiosResponse<BilledAndCanceledInfo>): BilledAndCanceledInfo =>
          response.data,
      ); */

  /* public saleInfoCanceled = (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
  ): Promise<string> =>
    axios
      .get(
        createCoreServiceUrl(
          'sale/saleInfoCanceled/' +
          `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&distributionCenter=${distributionCenter}`
        ),
      )
      .then(
        (response: AxiosResponse<string>): string =>
          response.data,
      ); */

  public saleListByRfc = (
    customer: string,
    initDate: Date,
    endDate: Date,
    filter: string,
    pagePosition: number,
    orderDate: string = 'DESC',
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    userId: string = '',
    serviceNumberId: string = '',
    equipmentIds: Array<string> = [],
    formaPago: string,
    rfc: string,
    orderRFC: string = 'DESC',
    cutFolio: Array<string>
  ): Promise<SalesListAndTotal> =>
    axios
      .get(
        createCoreServiceUrl(
          'sale/customer/' +
          `?customer=${customer}` +
          `&initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
          `&filter=${filter}` +
          `&page=${pagePosition}` +
          `&orderDate=${orderDate}` +
          `&distributionCenter=${distributionCenter}` +
          `&allowBillable=${allowBillable}` +
          `&requiredInvoice=${requiredInvoice}` +
          (userId ? `&userId=${userId}` : '') +
          `&equipmentId=${equipmentIds}` +
          (serviceNumberId ? `&serviceNumberId=${serviceNumberId}` : '') +
          (formaPago ? `&formaPago=${formaPago}` : '') +
          (cutFolio !== undefined ? `&cutFolio=${cutFolio}` : '') +
          `&rfc=${rfc}` +
          `&orderRFC=${orderRFC}`
        ),
      )
      .then(
        (response: AxiosResponse<SalesListAndTotal>): SalesListAndTotal =>
          response.data,
      );

  /* public getExcel = (
    initDate: Date,
    endDate: Date,
    orderDate: string,
    filter: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    userId: string = '',
    serviceNumberId: string = '',
    equipmentId: Array<string> = [],
    formaPago: string,
    rfc: string,
    orderRFC: string = 'DESC',
    cutFolio: Array<string>
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(
        'sale/excel' +
        `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&filter=${filter}` +
        `&distributionCenter=${distributionCenter}` +
        `&orderDate=${orderDate}` +
        `&allowBillable=${allowBillable}` +
        `&requiredInvoice=${requiredInvoice}` +
        (userId ? `&userId=${userId}` : '') +
        (serviceNumberId ? `&serviceNumberId=${serviceNumberId}` : '') +
        `&equipmentId=${equipmentId}` +
        (formaPago ? `&formaPago=${formaPago}` : '') +
        (cutFolio !== undefined ? `&cutFolio=${cutFolio}` : '') +
        `&rfc=${rfc}` +
        `&orderRFC=${orderRFC}`
      ),
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Ventas de ${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}-${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }); */

    public getExcelByFilters = (excelFilters: ExcelFilters): Promise<any> =>
    axios
      .post(createCoreServiceUrl(`v1/sale/createExcel`), excelFilters)
      .then((response: AxiosResponse<any>): any => response.data);

  /* public getExcelByRfc = (
    customer: string,
    initDate: Date,
    endDate: Date,
    orderDate: string,
    filter: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    userId: string = '',
    serviceNumberId: string = '',
    equipmentId: Array<string> = [],
    formaPago: string,
    rfc: string,
    orderRFC: string = 'DESC',
    cutFolio: Array<string>
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(
        'sale/excel/customer' +
        `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&filter=${filter}` +
        `&customer=${customer}` +
        `&distributionCenter=${distributionCenter}` +
        `&orderDate=${orderDate}` +
        `&allowBillable=${allowBillable}` +
        `&requiredInvoice=${requiredInvoice}` +
        (userId ? `&userId=${userId}` : '') +
        (serviceNumberId ? `&serviceNumberId=${serviceNumberId}` : '') +
        `&equipmentId=${equipmentId}` +
        (formaPago ? `&formaPago=${formaPago}` : '') +
        (cutFolio !== undefined ? `&cutFolio=${cutFolio}` : '') +
        `&rfc=${rfc}` +
        `&orderRFC=${orderRFC}`
      ),
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Ventas del ${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}-${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }); */

  /* public getExcelBilled = (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
    newExcel: boolean,
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(
        (newExcel ? 'sale/new/excel/billed' : 'sale/excel/billed') +
        `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&distributionCenter=${distributionCenter}`
      ),
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${!newExcel ? 'Reporte con ventas' : ' Reporte Facturas'} del ${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}-${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }); */

  /* public getExcelCancelled = (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(
        'sale/excel/cancel' +
        `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&distributionCenter=${distributionCenter}`
      ),
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Reporte Canceladas del ${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}-${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }); */

  public downloadFile = (
    id: string,
    name: string,
    saleFolio: string,
    showFile: boolean,
  ): Promise<void> =>
    axios({
      url: createCoreServiceUrl(showFile ? `sale/preview/${id}` : `sale/files/${id}/${name}`),
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf' }));
      const link = document.createElement('a');
      if(showFile){
        window.open(url);
      } else {
        link.href = url;
        link.setAttribute('download', saleFolio + '-' + name);
        document.body.appendChild(link);
        link.click();
      }
    });

  public saveSaleToInvoice = (sale: Sale): Promise<Sale> =>
    axios
      .post(createCoreServiceUrl(`v1/invoice/admin/requestByOne`), sale)
      .then((response: AxiosResponse<Sale>): Sale => response.data);

  public saveAllSalesToInvoice = (
    excludeSaleIds: string[],
    initDate: Date,
    endDate: Date,
    orderDate: string,
    distributionCenter: string,
    requiredInvoice: string,
    serviceNumberId: string = '',
    equipmentId: Array<string> = [],
    formaPago: string,
    rfc: string,
    expectedSales: number,
    singleInvoice: boolean = false,
    orderRFC: string = 'DESC',
    cutFolio: Array<string>,
    idUser: string
  ): Promise<Sale> =>
    axios
      .post(createCoreServiceUrl('v1/invoice/admin/requestByFilter'), {
        excludeSaleIds,
        initDate: moment(initDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        orderDate,
        distributionCenter,
        requiredInvoice,
        ...(serviceNumberId ? { serviceNumberId } : {}),
        ...(cutFolio !== undefined ? { cutFolio } : {}),
        equipmentId,
        formaPago,
        rfc: rfc,
        expectedSales,
        singleInvoice,
        orderRFC,
        idUser
      })
      .then((response: AxiosResponse<Sale>): Sale => response.data);
  public getformaPago = (): Promise<Array<FormaPagoCondicion>> =>
    axios
      .get(createCoreServiceUrl(`sale/formaPago`))
      .then((response: AxiosResponse<Array<FormaPagoCondicion>>): Array<FormaPagoCondicion> => response.data);
  public getbyIUS = (
    iUSCode: string,
  ): Promise<Sale> =>
    axios
      .get(createCoreServiceUrl(`sale/${iUSCode}`))
      .then((response: AxiosResponse<Sale>): Sale => response.data);
  public createZip = (
    initDate: Date,
    endDate: Date,
    distributionCenter: string,
    email: string,
    customerRfc: string
  ): Promise<any> =>
    axios.post(createCoreServiceUrl(
      `sale/generate/zip/`+
      `${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}/`+
      `${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}/`+
      `${distributionCenter}/`+
      `${email}/`+
      `${customerRfc}`
    )).then(response => response);
  public getZipFile = (
    token: string
  ): Promise<void> =>
    axios.get(createCoreServiceUrl(`sale/download/zip/${token}`), { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'Archivo.zip',
      );
      document.body.appendChild(link);
      link.click();
    });
  public getZipFile2 = (
    token: string
  ): Promise<void> =>
    axios.get(createCoreServiceUrl(`sale/download/v2/zip/${token}`), { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'Archivo.zip',
      );
      document.body.appendChild(link);
      link.click();
    });
  public deleteZip = (
    token: string
  ): Promise<any> => axios.delete(createCoreServiceUrl(`sale/delete/zip/${token}`))

  public deleteZipv2 = (
    token: string
  ): Promise<any> => axios.delete(createCoreServiceUrl(`sale/delete/v2/zip/${token}`))
}

export default SaleService;
