import { AnyAction } from 'redux';

import LogService from '../../../services/log/index';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { LogListI } from './reducers';
import { Pageable } from 'types/type';

const logService = new LogService();

export interface SetAction {
    type: 'SET';
    LogEventList: LogListI;
}

const startFetch = (): { type: string } => {
    return { type: 'BEGIN_FETCH' };
};

const endFetch = (): { type: string } => {
    return { type: 'FETCH_COMPLETED' };
};

export const getLogs = (message: string, loggerName: string, level: string, page: number, pageSize: number, order: string, invoiceLogs: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(startFetch());
            const response: Pageable = invoiceLogs ? await logService.findLogsInvoiceBitacora(message, loggerName, level, page, pageSize, order) : await logService.findLogsBitacora(message, loggerName, level, page, pageSize, order);
            dispatch({ type: 'SET', LogEventList: response });
            dispatch(endFetch());
        } catch (err) {
            notification.error(parseApiError(err));
        }
    }
}