import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import {
  Company,
  DistributionCenter,
  Pageable,
  Files as filesType,
  UnavailableFolio,
  ServiceNumber,
  Equipment,
  EquipmentsList,
  CompanyImages,
  CompanySales,
} from '../../types/type';

class CompanyService extends Service {
  public getAll = (): Promise<Company[]> =>
    axios
      .get(createCoreServiceUrl('company'))
      .then((response: AxiosResponse<Company[]>): Company[] => response.data);
  public save = (company: Company, files: filesType): Promise<Company> => {
    var bodyFormData = new FormData();
    bodyFormData.append(
      'certificate',
      files.certificate !== null
        ? files.certificate
        : JSON.stringify(files.certificate),
    );
    bodyFormData.append(
      'privateKey',
      files.privateKey !== null
        ? files.privateKey
        : JSON.stringify(files.privateKey),
    );
    bodyFormData.append(
      'logo',
      files.logo !== null ? files.logo : JSON.stringify(files.logo),
    );
    bodyFormData.append(
      'format',
      files.format !== null ? files.format : JSON.stringify(files.format),
    );
    bodyFormData.append(
      'bussisnesImage',
      files.bussisnesImage !== null
        ? files.bussisnesImage
        : JSON.stringify(files.bussisnesImage),
    );
    bodyFormData.append(
      'bussisnesLogo',
      files.bussisnesLogo !== null
        ? files.bussisnesLogo
        : JSON.stringify(files.bussisnesLogo),
    );
    bodyFormData.append('company', JSON.stringify(company));
    return axios({
      url: createCoreServiceUrl('company'),
      method: 'post',
      data: bodyFormData,
      headers: {
        'content-Type':
          'Multipart/form-data;boundary=032a1ab685934650abbe059cb45d6ff3',
      },
    }).then((response: AxiosResponse<Company>): Company => response.data);
  };
  public getCompanyImages = (): Promise <CompanyImages> =>
    axios
      .get(createCoreServiceUrl("company/images"))
      .then((response: AxiosResponse<CompanyImages>): CompanyImages => response.data)
  public getCompanySales = (): Promise <CompanySales> =>
    axios
      .get(createCoreServiceUrl("company/sales"))
      .then((response: AxiosResponse<CompanySales>): CompanySales => response.data)
  public getCompany = (companyId: string): Promise<Company> =>
    axios
      .get(createCoreServiceUrl(`company/getcompany/${companyId}`))
      .then((response: AxiosResponse<Company>): Company => response.data);
  public companyList = (page: number): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl(`company/page/${page}`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);
  public multicenter = (): Promise<boolean> =>
    axios
      .get(createCoreServiceUrl('company/multicenter'))
      .then((response: AxiosResponse<boolean>): boolean => response.data);
  public isEquimentNumber = (): Promise<boolean> =>
    axios
      .get(createCoreServiceUrl('company/enabled/EquimentNumber'))
      .then((response: AxiosResponse<boolean>): boolean => response.data);
  public getDistrubutionCenter = (): Promise<DistributionCenter[]> =>
    axios
      .get(createCoreServiceUrl('company/distribuciosncenters'))
      .then(
        (response: AxiosResponse<DistributionCenter[]>): DistributionCenter[] =>
          response.data,
      );
  public getUnavailableFolio = (): Promise<UnavailableFolio[]> =>
    axios
      .get(createCoreServiceUrl('company/folio'))
      .then(
        (response: AxiosResponse<UnavailableFolio[]>): UnavailableFolio[] =>
          response.data,
      );
  public getEquipmentsByCompanyId = (companyId: string): Promise <EquipmentsList> => 
    axios
        .get(createCoreServiceUrl('v33/serviceNumberEquipments'), {
          params: {
            companyId
          }
        })
        .then(
          (response: AxiosResponse<EquipmentsList>): EquipmentsList =>
            response.data,
        );
  public getServiceNumber = (companyId: string = ""): Promise<ServiceNumber[]> =>
    axios
      .get(createCoreServiceUrl('serviceNumber'), {
        params: {
          companyId
        }
      })
      .then(
        (response: AxiosResponse<ServiceNumber[]>): ServiceNumber[] =>
          response.data,
      );
  public saveServiceNumber = (serviceNumber: ServiceNumber): Promise<ServiceNumber> => {
    return axios
      .post(createCoreServiceUrl('serviceNumber'), serviceNumber)
      .then((response: AxiosResponse<ServiceNumber>): ServiceNumber => response.data);
  }
  public updateServiceNumber = (serviceNumber: ServiceNumber): Promise<ServiceNumber> =>
    axios
      .post(createCoreServiceUrl('serviceNumber'), serviceNumber)
      .then(
        (response: AxiosResponse<ServiceNumber>): ServiceNumber =>
          response.data,
      );

  public deleteServiceNumber = (id: string): Promise<ServiceNumber> =>
    axios
      .delete(createCoreServiceUrl(`serviceNumber`), {
        params: {
          serviceNumberID: id
        }
      })
      .then(
        (response: AxiosResponse) =>
          response.data,
      );

  public getEquipmentsNumbers = (): Promise<Equipment[]> =>
    axios
      .get(createCoreServiceUrl('company/equipments'))
      .then(
        (response: AxiosResponse<Equipment[]>): Equipment[] =>
          response.data,
      );

}

export default CompanyService;
