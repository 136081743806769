import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/configureStore';
import { LogListI } from './redux/reducers';
import { getLogs } from './redux/actions';
import { LogEvent } from 'types/type';
import { Col, Pagination, Row, Select, Spin, Table } from 'antd';
import './style.css';
import { SelectValue } from 'antd/lib/select';
import Search from 'antd/lib/input/Search';

import { getDecodeToken } from 'security';
import { Redirect } from 'react-router';
import { isAllowed } from 'security/index';
import { Permissions as P } from 'commons/permissions';

interface DispatchProps {
    getLogs: (message: string, loggerName: string, level: string, page: number, pageSize: number, order: string, invoiceLogs: boolean) => void
}

interface StateProps {
    LogEventList: LogListI;
    isFetching: boolean;
}

type Props = StateProps & DispatchProps;

class BitacoraComponent extends Component<Props> {
    state = {
        message: '',
        logName: 'Bitacora',
        level: 'ALL',
        page: 0,
        pageSize: 10,
        order: 'DESC',
        invoiceLogs: false,
    }
    public componentWillMount(): void {
        this.props.getLogs('', 'Bitacora', 'ALL', 0, 10, 'DESC', false);
    }
    public onChangeMessage = (message: string) => {
        this.setState({ message, page: 0 });
        this.props.getLogs(message, this.state.logName, this.state.level, 0, this.state.pageSize, this.state.order, this.state.invoiceLogs);
    }
    public onChangeLevel = (level: string) => {
        this.setState({ level, page: 0 });
        this.props.getLogs(this.state.message, this.state.logName, level, 0, this.state.pageSize, this.state.order, this.state.invoiceLogs);
    }
    public onChangePage = (page: number) => {
        this.setState({ page });
        this.props.getLogs(this.state.message, this.state.logName, this.state.level, page, this.state.pageSize, this.state.order, this.state.invoiceLogs);
    }
    public onChangePageSize = (pageSize: number = this.state.pageSize) => {
        this.setState({ pageSize, page: 0 });
        this.props.getLogs(this.state.message, this.state.logName, this.state.level, 0, pageSize, this.state.order, this.state.invoiceLogs);
    }
    public onChangeOrder = (order: string) => {
        this.setState({ order });
        this.props.getLogs(this.state.message, this.state.logName, this.state.level, this.state.page, this.state.pageSize, order, this.state.invoiceLogs);
    }
    public onChangeLogType = (invoiceLogs: boolean) => {
        this.setState({ invoiceLogs });
        this.props.getLogs(this.state.message, this.state.logName, this.state.level, this.state.page, this.state.pageSize, this.state.order, invoiceLogs);
    }

    public onChangeLogName = (logName: string) => {
        this.setState({ logName });
        this.props.getLogs(this.state.message, logName, this.state.level, this.state.page, this.state.pageSize, this.state.order, this.state.invoiceLogs);
    }
    public handleTableChange = (pagination: any, filters: any, sorter: any) => {
        if (filters.timestamp) {
            if (filters.timestamp.length > 0 && filters.timestamp[0] !== this.state.order) {
                this.onChangeOrder(filters.timestamp[0]);
            } else if (filters.timestamp.length < 1) {
                this.onChangeOrder(this.state.order);
            }
        }
    };
    public render = (): React.ReactNode => {
        if (!getDecodeToken().isClient){ return <Redirect to="/logs" /> }
        if (!isAllowed([P.CAN_VIEW_COMPANIES])) { return <Redirect to="/home" /> }
        const { LogEventList, isFetching } = this.props;
        return <Spin spinning={isFetching} tip='Cargando...'>
            <Row style={{ marginTop: 20 }}>
                <Row
                    style={{
                        backgroundColor: '#F1F7FF',
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 23,
                        paddingBottom: 23,
                    }}
                ><Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={11} xl={8} xxl={6} style={{ paddingLeft: 24, paddingRight: 24 }}>
                            <label className="label">Nivel de Log</label>
                            <br />
                            <Select
                                defaultActiveFirstOption
                                defaultValue={'ALL'}
                                onChange={(value: SelectValue): void => {
                                    this.onChangeLevel(value.toString());
                                }}
                                style={{ width: '100%' }}
                            >
                                <Select.Option value={'ALL'}>Todos</Select.Option>
                                <Select.Option value={'INFO'}>INFO</Select.Option>
                                <Select.Option value={'WARN'}>WARN</Select.Option>
                                <Select.Option value={'ERROR'}>ERROR</Select.Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={18} style={{ paddingLeft: 24, paddingRight: 24 }}>
                            <label className="label">Mensaje:</label>
                            <Search allowClear enterButton onSearch={(value) => this.onChangeMessage(value)} />
                        </Col>
                    </Row>
                </Row>
                <Col span={3}>
                    <Row>
                        <Row>Total de elementos:</Row>
                        <Row>{LogEventList !== undefined ? LogEventList.totalElements : 0}</Row>
                    </Row>
                </Col>
                <Col span={3}>
                    <label>Elementos por pagina</label>
                    <br />
                    <Select style={{ width: '100%', minWidth: 80 }} value={this.state.pageSize} onChange={this.onChangePageSize}>
                        <Select.Option value={10}>10</Select.Option>
                        <Select.Option value={20}>20</Select.Option>
                        <Select.Option value={30}>30</Select.Option>
                        <Select.Option value={40}>40</Select.Option>
                        <Select.Option value={50}>50</Select.Option>
                        <Select.Option value={60}>60</Select.Option>
                        <Select.Option value={70}>70</Select.Option>
                        <Select.Option value={80}>80</Select.Option>
                        <Select.Option value={90}>90</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                    </Select>
                </Col>
                <Col
                    span={24}
                    style={{ backgroundColor: "white" }}
                >
                    <Table
                        scroll={{ x: true }}
                        rowKey={(record: LogEvent) => record.eventId}
                        dataSource={this.props.LogEventList.content}
                        pagination={false}
                        onChange={this.handleTableChange}
                        locale={{ emptyText: 'Sin Logs' }}
                    >
                        <Table.Column
                            title="Nivel"
                            dataIndex="levelString"
                        />
                        <Table.Column
                            title="Fecha y Hora"
                            dataIndex="timestamp"
                            filteredValue={[this.state.order]}
                            filterMultiple={false}
                            filters={[{ value: 'ASC', text: 'Ascendente' }, { value: 'DESC', text: 'Descendente' }]}
                        />
                        <Table.Column
                            title="Mensaje"
                            dataIndex="formattedMessage"
                            ellipsis
                        />
                    </Table>
                </Col>
                <Col span={10}>
                    <Pagination
                        style={{ flex: 1 }}
                        current={(this.props.LogEventList.number || 0) + 1}
                        pageSize={this.state.pageSize}
                        total={this.props.LogEventList.totalElements}
                        onChange={e => this.onChangePage(e - 1)}
                    />
                </Col>
            </Row>
        </Spin>
    }
}

const mapStateToProps = (states: RootState): StateProps => {
    return {
        LogEventList: states.logState.LogEventList,
        isFetching: states.logState.isFetching,
    }
}

const mapDispatchToProps = {
    getLogs
};

export default connect<StateProps, DispatchProps, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
)(BitacoraComponent);