import {
  rfcformat,
  spacesformat,
  emailPattern,
  onlyNumberPattern,
  onlyNumberDecimalPattern,
} from 'commons/regularExpressions';
import { ValidationRule } from 'antd/lib/form';
import { messagesCustomer } from 'commons/strings';

export class InvoiceRules {
  public static nameRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.ENTERPRICE__NAME_REQUIERED,
    },
    {
      whitespace: true,
    },
  ];

  public static rfcRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.RFC_REQUIERED,
    },
    {
      pattern: rfcformat,
      message: messagesCustomer.RFC_VALID,
    },
    {
      max: 20,
      whitespace: true,
      message: messagesCustomer.TWENTY_CHARACTERS,
    },
  ];

  public static streetRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.STREET_REQUIERED,
    },
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];
  public static observationsRule: ValidationRule[] = [
    {
      max: 500,
      whitespace: true,
      message: messagesCustomer.FIVE_HUNDRED_CHARACTERS,
    },
  ];

  public static externalNumberRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.EXTERNAL_NUMBER_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static internalNumberRule: ValidationRule[] = [
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static fiscalRegimeRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.FISCA_REGIMEN_REQUIERED,
    },
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static zipCodeRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.ZIP_CODE_REQUIRED,
    },
    {
      max: 5,
      whitespace: true,
      message: messagesCustomer.FIVE_CHARACTERS,
    },
    {
      pattern: spacesformat,
      message: messagesCustomer.ZIP_CODE_VALID,
    },
  ];

  public static colonyRule: ValidationRule[] = [
    {
      required: true,
      message: messagesCustomer.COLONY_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];
  public static codeRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberPattern,
      message: messagesCustomer.CUSTOMER_CODE_REQUIRED,
    },
  ];
  public static numberRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberPattern,
      message: messagesCustomer.INT_ONLY,
    },
  ];

  public static numberRulePercent: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberPattern,
      message: messagesCustomer.INT_ONLY,
    },
  ];
  public static numberDecimalRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      pattern: onlyNumberDecimalPattern,
      message: messagesCustomer.NUMBERS_ONLY,
    },
  ];
  public static comercialNameRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.COMERCIAL_NAME_REQUIERED,
    },
  ];
  public static cityRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.CITY_REQUIRED,
    },
  ];

  public static municipalityRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.MUNICIPALITY_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static stateRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.STATE_REQUIERED,
    },
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static passwordRule: ValidationRule[] = [
    {
      required: true,
      whitespace: true,
      message: messagesCustomer.PASSWORD_REQUIRED,
    },
    {
      max: 100,
      whitespace: true,
      message: messagesCustomer.HUNDRED_CHARACTERS,
    },
  ];

  public static usoCfdiRule: ValidationRule[] = [
    {
      required: true,
      message: messagesCustomer.CFDI_REQUIRED,
    },
  ];

  public static formaPagoRule: ValidationRule[] = [
    {
      required: true,
      message: messagesCustomer.PAYMET_FORM_REQUIRED,
    },
  ];

  public static emailRule: ValidationRule[] = [
    {
      required: true,
      message: messagesCustomer.EMAIL_REQUIRED,
    },
    {
      pattern: emailPattern,
      message: messagesCustomer.EMAIL_VALID,
    },
  ];
}
