import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { isAuthenticated } from './index';

interface PrivateRouteProps extends RouteProps {
  component: any;// eslint-disable-line
}

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(routeProps): React.ReactNode =>
        isAuthenticated() ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
