import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { Pageable } from '../../types/type';

class LogService extends Service {
  public findLogs = (message: string, loggerName: string, level: string, page: number, pageSize: number, order: string): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl(
        'logs/findLogs' +
        `?message=${message}` +
        `&loggerName=${loggerName}` +
        `&level=${level}` +
        `&page=${page}` +
        `&pageSize=${pageSize}` +
        `&order=${order}`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);
  public findLogsInvoice = (message: string, loggerName: string, level: string, page: number, pageSize: number, order: string): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl(
        'logs/findLogs/invoice' +
        `?message=${message}` +
        `&loggerName=${loggerName}` +
        `&level=${level}` +
        `&page=${page}` +
        `&pageSize=${pageSize}` +
        `&order=${order}`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);
  public findLogsBitacora = (message: string, loggerName: string, level: string, page: number, pageSize: number, order: string): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl(
        'logs/findLogsBitacora' +
        `?message=${message}` +
        `&loggerName=${loggerName}` +
        `&level=${level}` +
        `&page=${page}` +
        `&pageSize=${pageSize}` +
        `&order=${order}`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);
  public findLogsInvoiceBitacora = (message: string, loggerName: string, level: string, page: number, pageSize: number, order: string): Promise<Pageable> =>
      axios
        .get(createCoreServiceUrl(
          'logs/findLogsBitacora/invoice' +
          `?message=${message}` +
          `&loggerName=${loggerName}` +
          `&level=${level}` +
          `&page=${page}` +
          `&pageSize=${pageSize}` +
          `&order=${order}`))
        .then((response: AxiosResponse<Pageable>): Pageable => response.data);
}

export default LogService;
