/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import { FiscalData, FiscalDataList } from 'types/type';
import { initFiscalData } from 'commons/initTypes';

export interface FiscalDataI {
  content: FiscalData;
}

export interface FiscalDataListI {
  content: FiscalData[];
  number?: number;
  totalElements?: number;
}
export interface newFiscalDataListI {
  content: FiscalDataList;
  number?: number;
  totalElements?: number;
}

export interface FiscalDataState {
  readonly fiscalData: FiscalDataI;
  readonly fiscalDataList: FiscalDataListI;
}
export interface NewFiscalDataState {
  readonly fiscalData: FiscalDataI;
  readonly fiscalDataList: newFiscalDataListI;
}

const fiscalData: Reducer<FiscalDataI> = (
  state: FiscalDataI = { content: initFiscalData },
  action,
): FiscalDataI => {
  switch (action.type) {
    case 'SAVE':
      return { content: { ...state.content, ...action.fiscalData } };
    default:
      return state;
  }
};
const fiscalDataList: Reducer<FiscalDataListI> = (
  state: FiscalDataListI = { content: [] },
  action,
): FiscalDataListI => {
  switch (action.type) {
    case 'SET':
      return { ...state, ...action.fiscalDataList } ;
    default:
      return state;
  }
};

export default combineReducers<FiscalDataState>({
  fiscalData,
  fiscalDataList
});
