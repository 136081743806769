export default {
  layout: { minHeight: '100vh' },
  header: { background: '#fff', padding: 0 },
  sider: { background: '#fff', padding: 0 },
  menu: { lineHeight: '4rem' },
  content: {
    background: '#fff',
    margin: '1%',
  },
  spin: { fontSize: 42 },
};
