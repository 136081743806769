import { AnyAction } from 'redux';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { Pageable, Permission, Customer, FiscalData, FiscalDataFilters } from '../../../types/type';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { initFiscalData } from 'commons/initTypes';
import CustomerService from 'services/customers';
import FiscalDataService from 'services/fiscalData';

const fiscalDataService = new FiscalDataService();
const customerService = new CustomerService();

type ITA = ThunkAction<Promise<void>, {}, {}, AnyAction>;
type IDA = ThunkDispatch<{}, {}, AnyAction>;

export interface SetAction {
  type: 'SET';
  fiscalDataList: {};
}
export interface SetPermissionAction {
  type: 'SET_PERMISSION';
  permissionList: Permission[];
}
export interface SetCustomerAction {
  type: 'SET_CUSTOMER';
  customer: Customer;
}
export interface SaveAction {
  type: 'SAVE';
  fiscalData: FiscalData;
}
export interface UpdateAction {
  type: 'UPDATE';
  userupdate: {};
}

export type Action = SetAction;

export const set = (fiscalDataList: {}): SetAction => {
  return { type: 'SET', fiscalDataList };
};
export const setPermission = (
  permissionList: Permission[],
): SetPermissionAction => {
  return { type: 'SET_PERMISSION', permissionList };
};
export const setCustomer = (customer: Customer): SetCustomerAction => {
  return { type: 'SET_CUSTOMER', customer };
};

export const fiscalDataSave = (fiscalData: FiscalData): SaveAction => {
  return { type: 'SAVE', fiscalData };
};
export const updateUser = (userupdate: {}): UpdateAction => {
  return { type: 'UPDATE', userupdate };
};
export const cleanRedux = (): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      dispatch(set({}));
      dispatch(fiscalDataSave(initFiscalData));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const getPermissions = (): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const permissionResponse = await fiscalDataService.permissionList();
      dispatch(setPermission(permissionResponse));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const getCustomer = (code: string): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const customerResponse = await customerService.getCustomerG4S(code);
      dispatch(setCustomer(customerResponse));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const page = (page: number, filters: FiscalDataFilters): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const responsepage: Pageable = await fiscalDataService.paginatedFiscalData(page, filters);
      dispatch(set(responsepage));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const getFiscalDataAction = (userId: string): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const response = await fiscalDataService.getFiscalData(userId);
      dispatch(fiscalDataSave(response));
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};

export const saveFiscalData = (fiscalData: FiscalData, callback: Function): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const responseuser = await fiscalDataService.save(fiscalData);
      dispatch(fiscalDataSave(responseuser));
      callback();
      notification.success({
        description: 'Perfil fiscal registrado exitósamente.',
        duration: 3,
        message: 'Éxito',
      });
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};
export const activateUserById = (active: string): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const responseuser: Pageable = await fiscalDataService.activateUserById(active);
      dispatch(updateUser(responseuser));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};
export const desactivateUserById = (desactive: string): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const responseuser = await fiscalDataService.deactivateUserById(desactive);
      dispatch(updateUser(responseuser));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const trysendpassword = (userEmail: string): ITA => {
  return async (): Promise<void> => {
    try {
      await fiscalDataService.trysendpassword(userEmail);
      notification.success({
        description: 'Correo Enviado Exitosamente',
        duration: 3,
        message: 'Éxito',
      });
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const passwordrecovery = (userEmail: string): ITA => {
  return async (): Promise<void> => {
    try {
      await fiscalDataService.passwordrecovery(userEmail);
      await notification.success({
        description: 'Correo Enviado Exitosamente',
        duration: 3,
        message: 'Éxito',
      });
      setTimeout(() => (window.location.href = '/login'), 3500);
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const deleteFiscalData = (id: string): ITA => {
  return async (): Promise<void> => {
    try {
      await fiscalDataService.deleteFiscalData(id);
      await notification.success({
        description: 'Perfil fiscal Borrado',
        duration: 3,
        message: 'Éxito',
      });
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};