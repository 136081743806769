/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Brandon Leon <brandon@ssf.com.mx>
 *
 */
import RevisorService from '../../../services/revisor';
import { parseApiError } from '../../../commons/error';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RevisionAction, BEGIN_FETCH, FETCH_COMPLETED, CLEAN, SET_INIT_DATE_REVISOR, SET_TOTALS } from './types';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { RevisionTotales } from 'types/type';
import { Popups } from 'commons/components/popups/popups';

const revisorService = new RevisorService();

type ITA = ThunkAction<Promise<void>, RootState, {}, RevisionAction>;
type IDA = ThunkDispatch<{}, {}, RevisionAction>;

const startFetch = (): RevisionAction => {
  return { type: BEGIN_FETCH };
};

/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): RevisionAction => {
  return { type: FETCH_COMPLETED };
};


export const cleanRevision = (): RevisionAction => {
  return { type: CLEAN };
};

export const setInitDateRevisor = (date: Date): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch(startFetch());
  dispatch({ type: SET_INIT_DATE_REVISOR, initDateRevisor: date });
  dispatch(endFetch());
};

export const getSalestotalByDate = (initDate: Date, endDate: Date, useCut: boolean): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch())
    const totals: RevisionTotales = await revisorService.salestotalByDate(initDate, endDate, useCut);
    dispatch({ type: SET_TOTALS, totals: totals });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const initProcess = (
  litros: number,
  total: number,
  litrosD: number,
  totalD: number,
  litrosAn: number,
  totalAn: number,
  initDate: Date,
  endDate: Date,
  csv: string,
  percent: number,
  useCut: boolean,
): ITA => async (
  dispatch: IDA,
  getState,
  ): Promise<void> => {
    if (getIsFetching(getState())) {
      return Promise.resolve();
    }
    try {
      dispatch(startFetch())
      await revisorService.initProcess(litros, total, litrosD, totalD, litrosAn, totalAn, initDate, endDate, csv, percent, useCut);
      const totals: RevisionTotales = await revisorService.salestotalByDate(initDate, endDate, useCut);
      dispatch({ type: SET_TOTALS, totals: totals });
      dispatch(endFetch());
    }
    catch (err) {
      dispatch(endFetch());
      Popups.notifyException(parseApiError(err));
    }
  }

export const getRevisorExcel = (date: { initDate: Date, endDate: Date}): ITA =>
  async (dispatch: IDA, getState): Promise<void> => {
    if(getIsFetching(getState())){
      return Promise.resolve();
    }
    try{
      dispatch(startFetch());
      await revisorService.getRevisorExcel(date);
      dispatch(endFetch());
    }
    catch(err){
      dispatch(endFetch())
      Popups.notifyException(parseApiError(err));
    }
  }