/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 *
 *
 */

import { initSystemConfig, initSystemConfigInvoice, initSettings} from 'commons/initTypes';
import { combineReducers, Reducer } from 'redux';
import { SystemConfig, SystemConfigInvoice, Settings } from 'types/type';

export interface ConfigurationState {
  readonly isFetching: boolean;
  readonly days: number;
  readonly months: number;
  readonly years: number;
  readonly daysInvoice: number;
  readonly monthsInvoice: number;
  readonly yearsInvoice: number;
  readonly systemConfig: SystemConfig;
  readonly systemConfigInvoice: SystemConfigInvoice;
  readonly settings: Settings;
}

const isFetching: Reducer<boolean> = (
  state = false,
  action = { type: '' },
): boolean => {
  switch (action.type) {
    case 'BEGIN_FETCH':
      return true;
    case 'FETCH_COMPLETED':
      return false;
    default:
      return state;
  }
};

const days: Reducer<number> = (
  state = 0,
  action = { type: '' }
): number => {
  switch (action.type) {
    case 'DAYS_TO_DELETE':
      return action.days;
    default:
      return state;
  }
}

const months: Reducer<number> = (
  state = 0,
  action = { type: '' }
): number => {
  switch (action.type) {
    case 'MONTHS_TO_DELETE':
      return action.month;
    default:
      return state;
  }
}

const years: Reducer<number> = (
  state = 0,
  action = { type: '' }
): number => {
  switch (action.type) {
    case 'YEARS_TO_DELETE':
      return action.years;
    default:
      return state;
  }
}

const daysInvoice: Reducer<number> = (
  state = 0,
  action = { type: '' }
): number => {
  switch (action.type) {
    case 'DAYS_TO_DELETE_INVOICE':
      return action.daysInvoice;
    default:
      return state;
  }
}

const monthsInvoice: Reducer<number> = (
  state = 0,
  action = { type: '' }
): number => {
  switch (action.type) {
    case 'MONTHS_TO_DELETE_INVOICE':
      return action.monthsInvoice;
    default:
      return state;
  }
}

const yearsInvoice: Reducer<number> = (
  state = 0,
  action = { type: '' }
): number => {
  switch (action.type) {
    case 'YEARS_TO_DELETE_INVOICE':
      return action.yearsInvoice;
    default:
      return state;
  }
}

const systemConfig: Reducer<SystemConfig> = (
  state: SystemConfig = initSystemConfig,
  action = { type: '' }
): SystemConfig => {
  switch (action.type) {
    case 'SYS_CONF':
      return action.systemConfig;
    default:
      return state;
  }
}

const systemConfigInvoice: Reducer<SystemConfigInvoice> = (
  state: SystemConfigInvoice = initSystemConfigInvoice,
  action = { type: '' }
): SystemConfigInvoice => {
  switch (action.type) {
    case 'SYS_CONF_INV':
      return action.systemConfigInvoice;
    default:
      return state;
  }
}

const settings: Reducer<Settings> = (
  state: Settings = initSettings,
  action = { type: '' }
): Settings => {
  switch (action.type) {
    case 'SYS_SETTINGS':
      return action.settings;
    default:
      return state;
  }
}


export default combineReducers<ConfigurationState>({
  isFetching,
  days,
  months,
  years,
  daysInvoice,
  monthsInvoice,
  yearsInvoice,
  systemConfig,
  systemConfigInvoice,
  settings,
});
