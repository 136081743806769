import { Form, Input, Button, Row, Col } from 'antd';
import React , {FormEvent } from 'react'
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { emailPattern } from 'commons/regularExpressions';
import { MailOutlined } from '@ant-design/icons';


interface CounterProps extends FormComponentProps {
    saveUser: (e: FormEvent, form: WrappedFormUtils) => void;
    goBack: () => void;
}

type Props = CounterProps & FormComponentProps;

class SignInForm extends React.Component<Props> {


  render() {
    const { form : {getFieldDecorator}, saveUser, goBack } = this.props;
    return (
        <Form  onSubmit={(e: FormEvent) : void => saveUser(e, this.props.form)}>
        <Row>
          <Row style={{ textAlign: 'center' }}>
            <p />
            <h2>Introduzca sus datos</h2>
            <p />
          </Row>
          <Row>
            <Col span={2}>
              <div>&nbsp;</div>
            </Col>
            <Col span={20}>
              <Form.Item hasFeedback>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor ingrese un nombre!',
                    },
                  ],
                })(
                  <Input
                    placeholder="Nombre"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <div>&nbsp;</div>
            </Col>
            <Col span={20}>
              <Form.Item hasFeedback>
                {getFieldDecorator('email', {
                  rules: [
                    { required: true, message: 'Ingresa un email' },
                    {
                      pattern: emailPattern,
                      message: 'Ingresa un email valido',
                    },
                  ],
                })(
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Email"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <div>&nbsp;</div>
            </Col>
          </Row>
          <Form.Item />
        </Row>
        <Button
          block
          className="button-invoice-serch-style"
          type="primary"
          htmlType="submit"
          id="login_button"
        >
          Registrar
        </Button>
        <div>&nbsp;</div>
        <Button
          block
          className="button-invoice-serch-style"
          type="ghost"
          id="cancel_button"
          onClick={() => goBack()}
        >
          Cancelar
        </Button>
      </Form>
    );
  }
}

export default Form.create<CounterProps>()(SignInForm);