import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import {
  Pageable,
  User,
  Credentials,
  NewPassword,
  Permission,
} from '../../types/type';

class UserService extends Service {
  public getAll = (): Promise<User> =>
    axios
      .get(createCoreServiceUrl('users'))
      .then((response: AxiosResponse<User>): User => response.data);

  public getUser = (userId: string): Promise<User> =>
    axios
      .get(createCoreServiceUrl(`users/${userId}`))
      .then((response: AxiosResponse<User>): User => response.data);

  public getCanRegisterUser = ():Promise<boolean> =>
    axios
      .get(createCoreServiceUrl('users/canregister'))
      .then((response: AxiosResponse<boolean>): boolean => response.data);

  public authenticateUser = (credentials: Credentials): Promise<string> =>
    axios
      .post(createCoreServiceUrl('users/token'), credentials)
      .then((response: AxiosResponse<string>): string => response.data);

  public authenticateCustomer = (credentials: Credentials): Promise<string> =>
    axios
      .post(createCoreServiceUrl('customer/token'), credentials)
      .then((response: AxiosResponse<string>): string => response.data);

  public usersList = (page: number): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl(`users/page/${page}`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public permissionList = (): Promise<Permission[]> =>
    axios
      .get(createCoreServiceUrl('users/permissions'))
      .then(
        (response: AxiosResponse<Permission[]>): Permission[] => response.data,
      );

  public save = (user: User): Promise<User> =>
    axios
      .post(createCoreServiceUrl('users'), { ...user, client: user.isClient })
      .then((response: AxiosResponse<User>): User => response.data);

  public updatePassword = (user: NewPassword): Promise<string> =>
    axios
      .post(createCoreServiceUrl('users/passwordupdate'), user)
      .then((response: AxiosResponse<string>): string => response.data);

  public activateUserById = (userId: string): Promise<Pageable> =>
    axios
      .post(createCoreServiceUrl(`users/${userId}/activate`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public deactivateUserById = (userId: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/${userId}/deactivate`))
      .then((response: AxiosResponse<User>): User => response.data);

  public trysendpassword = (userEmail: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/trysendpassword/${userEmail}`))
      .then((response: AxiosResponse<User>): User => response.data);

  public passwordrecovery = (userEmail: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/passwordrecovery/${userEmail}`))
      .then((response: AxiosResponse<User>): User => response.data);
}

export default UserService;
