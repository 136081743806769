/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import { User, Permission, Customer, FiscalData } from 'types/type';
import { initUser } from 'commons/initTypes';

export interface UsersListI {
  content: User[];
  number?: number;
  totalElements?: number;
}

export interface UserSavedI {
  usersaved: User;
}

export interface UsersState {
  readonly content: User[];
  readonly usersList: UsersListI;
  readonly usersSave: UserSavedI;
  readonly userupdate: {};
  readonly permissionList: Permission[];
  readonly customer: Customer;
}

export interface State {
  readonly fiscalDataList : FiscalData[];
}

const fiscalDataList: Reducer<FiscalData[]> = (
  state: FiscalData[] = [],
  action
): FiscalData[] => {
  switch (action.type) {
    case "SET_FISCAL_DATA_LIST":
      return action.fiscalDataList
    default:
      return state
  }
}


const usersSave: Reducer<UserSavedI> = (
  state: UserSavedI = { usersaved: initUser },
  action,
): UserSavedI => {
  switch (action.type) {
    case 'SAVE':
      return { usersaved: { ...state.usersaved, ...action.usersaved } };
    default:
      return state;
  }
};

const permissionList: Reducer<Permission[]> = (
  state: Permission[] = [],
  action,
): Permission[] => {
  switch (action.type) {
    case 'SET_PERMISSION':
      return [state, ...action.permissionList];
    default:
      return state;
  }
};

export type InvoiceUserState = UsersState & State

export default combineReducers<InvoiceUserState>({
  usersSave,
  permissionList,
  fiscalDataList
});
