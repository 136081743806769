/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { MainLayout } from 'commons/components/layout';
import PrivateRoute from 'security/PrivateRoute';
import menu from './menuRoutes';
import Login from './login';
import forgotPassword from './login/forgotPassword';
import Activatepassword from './activatepassword';
import InvoiceComplete from 'routes/invoices/invoiceComplete';
import NewUser from 'routes/users/components/newUsers';
import NewFiscalData from 'routes/fiscalData/components/newFiscalData';
import LogOut from './login/logout';
import Downloadzip from './downloadZipFile';
import { Token, getToken, getDecodeToken } from 'security';

const Routes = (): JSX.Element => (
  <Router>
    <MainLayout menu={menu}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={LogOut} />
        <Route path="/activatepassword/:token" component={Activatepassword} />
        <Route path="/forgotPassword" component={forgotPassword} />
        <Route path="/factura-finish" component={InvoiceComplete} />
        <Route path="/users/newUser/:id" component={NewUser} />
        <Route path="/fiscalData/newUserFiscalData/:id" component={NewFiscalData} />
        <Route path="/download/zip/:token" component={Downloadzip} />
        {menu.map(
          (item): React.ReactNode => {
            const { secure, key, component, ...rest } = item;
            let new_component = component;
            if (!isEmpty(getToken())) {
              const token: Token = getDecodeToken();
              new_component =
                token.isClient && item.key === 'x' ?
                  (): JSX.Element => <Redirect to="/home" /> :
                  component;
            }
            if (secure === true) {
              return (
                <PrivateRoute exact {...rest} component={new_component} key={key} />
              );
            }
            return <Route exact {...rest} key={key} />;
          },
        )}
      </Switch>
    </MainLayout>
  </Router>
);
export default Routes;
