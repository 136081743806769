/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */
import SaleService from 'services/sale';
import { parseApiError } from 'commons/error';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SaleAction,
  SET_END_DATE,
  SET_INIT_DATE,
  SET_SALE,
  SET_SALE_SELECTED,
  SET_SUM_TOTALLK,
  SET_SUM_TOTALLK_BILLABLE,
  SET_SUM_TOTALLK_SELECTED,
  SET_SUM_TOTALM,
  SET_SUM_TOTALM_BILLABLE,
  SET_SUM_TOTALM_SELECTED,
  PAYMENT_CHANGE,
  TOTAL_ELEMENTS_BILLABLE,
  TOTAL_ELEMENTS_SELECTED,
  CLEAN,
} from './types';
import { isEmpty } from 'lodash';
import { getIsFetching } from './reducers';
import { RootState } from 'store/configureStore';
import { Sale, SalesListAndTotal } from 'types/type';
import { Popups } from 'commons/components/popups/popups';

const saleService = new SaleService();

const startFetch = (): SaleAction => {
  return { type: BEGIN_FETCH };
};
type ITA = ThunkAction<Promise<void>, RootState, {}, SaleAction>;
type IDA = ThunkDispatch<{}, {}, SaleAction>;
/**
 * This action creator is not public beca1use it should be used
 * automatically by other action creators that need fetch data from
 * backend.
 */
const endFetch = (): SaleAction => {
  return { type: FETCH_COMPLETED };
};

export const cleanSales = (): SaleAction => {
  return { type: CLEAN };
};

export const setInitDate = (date: Date): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch({ type: SET_INIT_DATE, initDate: date });
  dispatch(endFetch());
};

export const setEndDate = (date: Date): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  dispatch({ type: SET_END_DATE, endDate: date });
  dispatch(endFetch());
};

export const invoiceAll = (): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  Popups.notifySuccess('Se van a facturar todas las ventas mayores a 72hrs');
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    saleService.invoiceAll();
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const downloadFile = (
  id: string,
  name: string,
  saleFolio: string,
  showFile: boolean,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  dispatch(startFetch());
  Popups.notifySuccess('Descargando archivo ' + saleFolio + '-' + name);
  saleService.downloadFile(id, name, saleFolio, showFile);
  dispatch(endFetch());
};

export const changeBillable = (id: string, billable: boolean): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const {
      saleList,
      sumTotalLKBillable,
      sumTotalMBillable,
      totalElementsBillable,
      saleSelectedList,
    } = getState().saleState;
    const { content } = saleList;
    let response = await saleService.changeBillable(id, billable);
    let newcontent: Sale[] = [];
    let newSumTotalLk = 0;
    let newSumTotalM = 0;
    let newTotalElements = 0;
    if (content !== undefined) {
      newcontent = content.map((item: Sale) => {
        if (item.id === response.id) {
          if (response.billable) {
            newSumTotalLk = sumTotalLKBillable + Number(response.totalVolume);
            newSumTotalM = sumTotalMBillable + Number(response.total);
            newTotalElements= totalElementsBillable + 1;
            
          } else {
            newSumTotalLk = sumTotalLKBillable - Number(response.totalVolume);
            newSumTotalM = sumTotalMBillable - Number(response.total);
            newTotalElements= totalElementsBillable - 1;
          }
          return response;
        }
        return item;
      });
    }
    dispatch({
      type: SET_SALE,
      saleList: { ...saleList, content: newcontent },
    });
    dispatch({
      type: SET_SUM_TOTALM_BILLABLE,
      sumTotalMBillable: newSumTotalM || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_BILLABLE,
      sumTotalLKBillable: newSumTotalLk || 0,
    });
    let totalLkSelected = saleSelectedList.reduce(
      (acumulator, sale) => acumulator + sale.totalVolume,
      0,
    );
    let totalMSelected = saleSelectedList.reduce(
      (acumulator, sale) => acumulator + sale.total,
      0,
    );
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: newSumTotalM - totalMSelected,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected: newSumTotalLk - totalLkSelected,
    });
    dispatch({
      type: TOTAL_ELEMENTS_BILLABLE,
      totalElementsBillable: newTotalElements,
    })
    dispatch({
      type: TOTAL_ELEMENTS_SELECTED,
      totalElementsSelected: newTotalElements,
    })
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const changePaymentType = (
  id: string,
  paymentType: string,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const { saleList } = getState().saleState;
    const { content } = saleList;
    let response = await saleService.changePaymentCondition(id, paymentType);
    let newcontent: Sale[] = [];
    if (content !== undefined) {
      newcontent = content.map((item: Sale) => {
        if (item.id === response.id) {
          return response;
        }
        return item;
      });
    }
    dispatch({
      type: SET_SALE,
      saleList: { ...saleList, content: newcontent },
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const changeToBill = (
  id: string,
  toBill: boolean,
  total: number,
  totalVolume: number,
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const {
      saleList,
      saleSelectedList = [],
      sumTotalMSelected,
      sumTotalLKSelected,
    } = getState().saleState;
    let newSaleSelectedList = saleSelectedList;
    const { content } = saleList;
    let newcontent: Sale[] = [];
    if (content !== undefined) {
      newcontent = content.map((item: Sale) => {
        if (item.id === id) {
          return {
            ...item,
            toBill,
          };
        } else {
          return item;
        }
      });
    }
    let newSumTotalMSelected = 0;
    let newSumTotalLkSelected = 0;
    if (toBill) {
      newSaleSelectedList.push({ id, total, totalVolume });
      newSumTotalMSelected = sumTotalMSelected - total;
      newSumTotalLkSelected = sumTotalLKSelected - totalVolume;
    } else {
      newSaleSelectedList = saleSelectedList.filter(sale => sale.id !== id);
      newSumTotalMSelected = sumTotalMSelected + total;
      newSumTotalLkSelected = sumTotalLKSelected + totalVolume;
    }
    dispatch({
      type: SET_SALE,
      saleList: { ...saleList, content: newcontent },
    });
    dispatch({
      type: SET_SALE_SELECTED,
      saleSelectedList: newSaleSelectedList,
    });
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: newSumTotalMSelected,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected: newSumTotalLkSelected,
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

export const alreadyInvoice = (id: string): ITA => async (
  dispatch: IDA,
  getState,
): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const { saleList } = getState().saleState;
    const { content } = saleList;
    let response = await saleService.alreadyInvoice(id);
    let newcontent: Sale[] = [];
    if (content !== undefined) {
      newcontent = content.map((item: Sale) => {
        if (item.id === response.id) {
          return response;
        }
        return item;
      });
    }
    dispatch({
      type: SET_SALE,
      saleList: { ...saleList, content: newcontent },
    });
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

/**
 *This action give sales from db by filters(initDate,endDte,Sale status and Page)
 */

export const page = (
  customer: string,
  initDate: Date,
  endDate: Date,
  filter: string,
  pagePosition: number,
  orderDate: string,
  allowBillable: boolean,
  distributionCenter: string,
  requiredInvoice: string,
  userId: string,
  rfc: string,
  orderRFC: string
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    const { saleSelectedList = [] } = getState().saleState;
    let responsepage: SalesListAndTotal;
    if (isEmpty(customer)) {
      responsepage = await saleService.saleList(
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        userId,
        "",
        [],
        "",
        rfc,
        orderRFC,
        []
      );
    } else {
      responsepage = await saleService.saleListByRfc(
        customer,
        initDate,
        endDate,
        filter,
        pagePosition,
        orderDate,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        userId,
        '',
        [],
        '',
        rfc,
        orderRFC,
        []
      );
    }
    let paymentChange = await saleService.ispaymentChange();
    responsepage.salePage.content.forEach((salePage: Sale) => {
      if (saleSelectedList.some(sale => sale.id === salePage.id)) {
        salePage.toBill = true;
      } else salePage.toBill = false;
    });
    dispatch({ type: SET_SALE, saleList: responsepage.salePage });
    dispatch({
      type: SET_SUM_TOTALM,
      sumTotalM: responsepage.sumSales.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK,
      sumTotalLK: responsepage.sumSales.totalVolume || 0,
    });
    dispatch({
      type: SET_SUM_TOTALM_BILLABLE,
      sumTotalMBillable: responsepage.sumSalesTotalBillable.total || 0,
    });
    dispatch({
      type: SET_SUM_TOTALLK_BILLABLE,
      sumTotalLKBillable: responsepage.sumSalesTotalBillable.totalVolume || 0,
    });
    let totalLkSelected = saleSelectedList.reduce(
      (acumulator, sale) => acumulator + sale.totalVolume,
      0,
    );
    let totalMSelected = saleSelectedList.reduce(
      (acumulator, sale) => acumulator + sale.total,
      0,
    );
    dispatch({
      type: SET_SUM_TOTALM_SELECTED,
      sumTotalMSelected: responsepage.sumSalesToBill.total - totalMSelected,
    });
    dispatch({
      type: SET_SUM_TOTALLK_SELECTED,
      sumTotalLKSelected:
        responsepage.sumSalesToBill.totalVolume - totalLkSelected,
    });
    dispatch({
      type: PAYMENT_CHANGE,
      paymentChange,
    });
    dispatch({
      type: TOTAL_ELEMENTS_BILLABLE,
      totalElementsBillable: responsepage.sumSalesTotalBillable.totalElements,
    })
    dispatch({
      type: TOTAL_ELEMENTS_SELECTED,
      totalElementsSelected: responsepage.sumSalesToBill.totalElements,
    })
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
};

/* export const getExcel = (
  customer: string,
  initDate: Date,
  endDate: Date,
  orderDate: string,
  filter: string,
  allowBillable: boolean,
  distributionCenter: string,
  requiredInvoice: string,
  userId: string,
  rfc: string,
  orderRFC: string
): ITA => async (dispatch: IDA, getState): Promise<void> => {
  if (getIsFetching(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch(startFetch());
    if (isEmpty(customer)) {
      await saleService.getExcel(
        initDate,
        endDate,
        orderDate,
        filter,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        userId,
        '',
        [],
        '',
        rfc,
        orderRFC,
        []
      );
    } else {
      await saleService.getExcelByRfc(
        customer,
        initDate,
        endDate,
        orderDate,
        filter,
        allowBillable,
        distributionCenter,
        requiredInvoice,
        userId,
        '',
        [],
        '',
        rfc,
        orderRFC,
        []
      );
    }
    dispatch(endFetch());
  } catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}; */