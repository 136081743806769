import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Button, Card, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { downloadZip, downloadZip2 } from './redux/actions';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { RootState } from 'store/configureStore';
import { connect } from 'react-redux';
interface HomeRouterProps {
    token: string;
}
interface DispatchProps {
    downloadZip: (token: string) => void;
    downloadZip2: (token: string) => void;
}
interface DownloadProps {
    isFetching: boolean
}
type Props = DownloadProps &
    DispatchProps &
    RouteComponentProps<HomeRouterProps>;

class Downloadzip extends Component<Props> {
    state = {
        secondSpin: false
    }
    componentDidMount() {
        const { match: { params }, downloadZip } = this.props;
        downloadZip(params.token)
    }
    secondDownloadMethod = ():void => {
        const { match: { params }, downloadZip2 } = this.props;
        downloadZip2(params.token)
        this.setState({ secondSpin: !this.state.secondSpin })
    }
    public render = (): React.ReactNode => {
        const { isFetching } = this.props;
        return (
            <Row>
                <Col style={{ margin: '0 auto', textAlign: 'center' }}>
                    <Card className="invoice-card">
                        <p>
                            <DownloadOutlined style={{ fontSize: '10rem', color: '#4B86C7' }} />
                        </p>
                        <p>
                            <h1>Descargando archivo zip</h1>
                            {!this.state.secondSpin && <Spin tip="Cargando..." spinning={isFetching} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                        </p>
                        <p>
                            Si tu descarga no comienza automaticamente da click en el siguiente enlace
                        </p>
                        <p>
                            <button type="button" className="link-button" onClickCapture={this.secondDownloadMethod}>Descargar zip</button>
                            <br />
                            {this.state.secondSpin && <Spin tip="Cargando..." spinning={isFetching} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                        </p>
                        <p>
                            <Link to="/">
                                <Button
                                    type="primary"
                                    className="button-invoice-serch-style"
                                >
                                    Ir a Inicio
                                </Button>
                            </Link>
                        </p>
                    </Card>
                </Col>
            </Row>
        );
    };
}
const mapStateToProps = (states: RootState): DownloadProps => {
    return {
        isFetching: states.downloadState.isFetching
    };
};

const mapDispatchToProps = {
    downloadZip,
    downloadZip2,
};
export default connect<DownloadProps, DispatchProps, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
)(Downloadzip);