import React, { Component } from 'react';
import { Modal, Table, Col, Row, AutoComplete, Input, Tag, Button, Popconfirm, Icon, Badge } from 'antd';
import { RootState } from 'store/configureStore';
import { connect } from 'react-redux';
import { FiscalPermission, Equipment, EquipmentsList } from 'types/type';
import Text from 'antd/lib/typography/Text';
import { setFiscalPermissions, createFiscalPermission, updateFiscalPermission, deleteFiscalPermission, getFiscalPermission, getEquipmentsNumbers } from '../redux/actions';
import CompanyService from '../../../services/company';
import { initEquipmentList } from 'commons/initTypes';

import { isAllowed } from 'security/index';
import { Permissions as P } from 'commons/permissions';
import { Redirect } from 'react-router';

const companyService = new CompanyService();

interface EditigFiscalPermission {
  equipments: Equipment[];
  numberPermission: string;
  startFolio: number;
  serie: string;
}

interface EquipmentNumberForDS {
  value: string;
  text: string;
  centerId: string;
}

type EquipmentNumbersDS = Array<EquipmentNumberForDS>;

const initialEditigFiscalPermission: EditigFiscalPermission = {
  equipments: Array<Equipment>(),
  numberPermission: "",
  startFolio: 0,
  serie: ""
}

const initialEquipmentNumbersDS: EquipmentNumbersDS = []

interface FiscalPermissionModalProps {
  companyId: string | null | undefined;
  distCenterId: string;
  visible: boolean;
  onCancel: Function;
  onOk: Function;
}

interface StateProps {
  fiscalPermissions: Array<FiscalPermission>,
  equipmentNumbers: Array<Equipment>,
  isFetching: boolean;
}

interface DispatchProps {
  setFiscalPermissions: (fiscalPermissions: Array<FiscalPermission>) => void;
  createFiscalPermission: (fiscalPermission: FiscalPermission) => void;
  updateFiscalPermission: (fiscalPermission: FiscalPermission) => void;
  deleteFiscalPermission: (id: string) => void;
  getFiscalPermission: (companyId: string) => void;
  getEquipmentsNumbers: (distCenterId: string) => void;
}

type Props = FiscalPermissionModalProps & StateProps & DispatchProps;

class FiscalPermissionModal extends Component<Props> {
  public static defaultProps = {
    companyId: "",
    distCenterId: "",
    visible: false,
  }

  public state = {
    editigFiscalPermission: initialEditigFiscalPermission,
    equipmentNumbersDS: initialEquipmentNumbersDS,
    AutoCompleteValue: ' ',
    equipmentList : initEquipmentList,
    originFPData: initialEditigFiscalPermission,
  }

  componentDidMount() {
    this.chargeData();
  }

  onClose() {
    this.setState({
      editigFiscalPermission: initialEditigFiscalPermission,
      equipmentNumbersDS: initialEquipmentNumbersDS,
      AutoCompleteValue: ' '
    });
  }

  private async chargeData() {
    await this.getEquipmentListByCompany();
    await this.props.getFiscalPermission(this.props.companyId || '');
    this.setEquipmentsNumberDS();
  }

  private getEquipmentListByCompany(){
    companyService.getEquipmentsByCompanyId(this.props.companyId? this.props.companyId : "").then((equipmentList: EquipmentsList)=>{
      /* let fiscalPermissions :Array<FiscalPermission> = equipmentList.serviceNumberList.map((serviceNumber) => {
        return serviceNumberToFiscalPermission(serviceNumber);
      }); */
      this.setState({equipmentList: equipmentList});
    }); 
  }

  setEquipmentsNumberDS(filter: string = "") {
    const equipmentList: Array<Equipment> = this.state.equipmentList.unAssignedEquipments;
    let equipments: Array<Equipment> = [];
    if (filter) {
      const regex = new RegExp(filter, "i");
      equipments = equipmentList.filter(equipment => 
        equipment.equipmentNumber.toString().match(regex) || 
        equipment.applicationType.toString().match(regex) || 
        equipment.tag.toString().match(regex));
    } else {
      equipments = [...equipmentList];
    }
    const equipmentNumbersDS = equipments.map(en => (this.convertEquipmentToDS(en)));
    this.setState({ equipmentNumbersDS });
  }

  private addNewFiscalPermission = () => {
    const { fiscalPermissions, setFiscalPermissions } = this.props;
    fiscalPermissions.unshift({
      id: "",
      isNew: true,
      editable: true,
      equipments: [],
      lastFolio: 0,
      numberPermission: "",
      serie: "",
      startFolio: 0,
      status: false,
      companyId: '',
      idInvoice: '',
      equipmentList: Array<Equipment>(),
    });
    setFiscalPermissions(fiscalPermissions);
  }

  private onCancelEdit = (record: FiscalPermission) => {
    this.chargeData();
  }

  private onEdit = (record: FiscalPermission) => {
    this.setState({ originFPData: { ...record } });
    if (record.id) {
      const { fiscalPermissions } = this.props;
      if (!fiscalPermissions.some(f => f.editable)) {
        const index = fiscalPermissions.findIndex(fd => fd.id === record.id);
        if (index > -1) {
          const { setFiscalPermissions } = this.props;
          fiscalPermissions[index].editable = true;
          setFiscalPermissions(fiscalPermissions);
        }
      }
    }
  }

  onDelete = async (record: FiscalPermission) => {
    const { deleteFiscalPermission, getFiscalPermission, companyId } = this.props;
    await deleteFiscalPermission(record.id)
    getFiscalPermission(companyId || '');
    this.getEquipmentListByCompany();
  }

  private onSave  = async (record: FiscalPermission) => {
    const { updateFiscalPermission, createFiscalPermission, getFiscalPermission, companyId } = this.props;
    record.companyId = companyId;
    if ( record.id ) {
      record.editable = false;
      await updateFiscalPermission(record);
      getFiscalPermission(companyId || '');
      this.getEquipmentListByCompany();
      this.setState({ editigFiscalPermission: initialEditigFiscalPermission });
    } else {
      record.isNew = true;
      record.editable = false;
      await createFiscalPermission(record);
      getFiscalPermission(companyId || '');
      this.getEquipmentListByCompany();
      this.setState({ editigFiscalPermission: initialEditigFiscalPermission });
    } 
  }

  removeItemFromArrray( item: any, array: Array<any>) : Array<any>  {
    const index: number = array.indexOf(item, 0);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }


  ssEditigFiscalPermisssion(key: string, value: any) {
    this.setState({
      ...this.state,
      editigFiscalPermission: {
        ...this.state.editigFiscalPermission,
        [key]: value
      }
    })
  }

  returnTextFromEquipment = (equipment:Equipment): string =>{
    return equipment.equipmentNumber + " " + equipment.applicationType + " " + equipment.tag;
  }

  convertEquipmentToDS(equipment: Equipment): EquipmentNumberForDS {
    let equipmentNumber: EquipmentNumberForDS = {value: equipment.id, centerId: equipment.distributionCenterId, text: this.returnTextFromEquipment(equipment)}
    return equipmentNumber;
  }

  filterEquipments(): Array<EquipmentNumberForDS> {
    let allEquipmens : Array<EquipmentNumberForDS> = this.state.equipmentList.unAssignedEquipments.map(equipment =>{
      return this.convertEquipmentToDS(equipment);
    });
    return allEquipmens;
  }

  render() {
    if (!isAllowed([P.CAN_VIEW_COMPANIES])) {
      return <Redirect to="/home" />
    }
    const { visible, onCancel, onOk, fiscalPermissions, isFetching } = this.props;
    const equipmentsNumbersDS = this.filterEquipments();
    return <Modal
      visible={visible}
      onCancel={() => { onCancel(); this.onClose(); }}
      onOk={() => { onOk(); this.onClose(); }}
      width="90%"
    >
      {!fiscalPermissions.some(fp => fp.isNew || fp.editable) &&
        <Button style={{ marginBottom: 20 }} onClick={this.addNewFiscalPermission}>Crear asociación</Button>
      }
      {(this.state.equipmentList.unAssignedEquipments.length !== 0) &&
        <div  style={{ float: 'right', margin: 20, color: 'orangered',  fontWeight: 'bolder'}}>
         <Badge count={this.state.equipmentList.unAssignedEquipments.length} showZero={false}> 
             <div >
              <Icon type='warning'/>
              &nbsp;&nbsp; Existen equipos gaspar sin asignar &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </Badge>
        </div>
      }
      <Table
        pagination={false}
        dataSource={fiscalPermissions}
        loading={isFetching}
      >
        <Table.Column
          title="Número de permiso"
          dataIndex="numberPermission"
          render={(text, record: FiscalPermission) => {
            if (record.editable) {
              return <Input placeholder="Número de permiso" onChange={event => {
                  this.ssEditigFiscalPermisssion("numberPermission", event.target.value);
                  record.numberPermission = event.target.value;
                }} value={record.numberPermission || text} />
            } else {
              return <Text>{text}</Text>
            }
          }} />
        <Table.Column
          title="Serie"
          dataIndex="serie"
          render={(text, record: FiscalPermission) => {
            if (record.editable) {
              return <Input placeholder="Serie" onChange={event => {
                this.ssEditigFiscalPermisssion("serie", event.target.value);
                record.serie = event.target.value;
              }} value={record.serie || text} />
            } else {
              return <Text>{text}</Text>
            }
          }} />
        <Table.Column
          title="Folio"
          dataIndex="startFolio"
          render={(text, record: FiscalPermission) => {
            if (record.editable) {
              return <Input placeholder="Folio" type="numeric" onChange={event => {
                this.ssEditigFiscalPermisssion("startFolio", isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value));
                record.startFolio = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value);
              }} value={record.startFolio} />
            } else {
              return <Text>{text}</Text>
            }
          }} />
        <Table.Column title="Equipos GASPAR" render={(text, record: FiscalPermission) => {
          if (record.editable) {
            return <Row>
              <Row>
                <AutoComplete autoClearSearchValue={true}
                  size="large"
                  onChange={(value) => this.setState({ AutoCompleteValue: value })}
                  onSelect={(value) => {
                    let equipment = this.state.equipmentList.unAssignedEquipments.find(searched => searched.id === value );
                    if(equipment !== undefined){
                      record.equipmentList.push(equipment);
                      this.state.editigFiscalPermission.equipments.push(equipment);
                    }
                    this.removeItemFromArrray(equipment, this.state.equipmentList.unAssignedEquipments);
                    this.setState({ AutoCompleteValue: '' })
                  }} dataSource={equipmentsNumbersDS}
                  onSearch={value => this.setEquipmentsNumberDS(value)}
                  value={this.state.AutoCompleteValue}
                >
                  <Input.Search size="large" placeholder="Buscar equipo" value={this.state.AutoCompleteValue} />
                </AutoComplete>
              </Row>
              {record.equipmentList && record.equipmentList.length >= 0 &&
                <Row style={{ marginTop: 8, marginBottom: 8, maxWidth: "20vw" }}>
                  {record.equipmentList.map((equipment) => <p><Tag key={equipment.id} onClose={() => {
                    record.equipmentList = this.removeItemFromArrray(equipment, record.equipmentList);
                    equipmentsNumbersDS.push(this.convertEquipmentToDS(equipment));
                    this.state.equipmentList.unAssignedEquipments.push(equipment);
                  }} closable >{ this.returnTextFromEquipment(equipment)}</Tag></p>)}
                </Row>
              }
            </Row>
          } else {
            return <Col>
              <Row style={{ maxWidth: "20vw" }}>
                {record.equipmentList.map((equipment) => <p><Tag>{ this.returnTextFromEquipment(equipment) }</Tag></p>)} 
              </Row>
            </Col>
          }
        }} />
        <Table.Column title="Accciones" render={(text, record: FiscalPermission) => {
          if (record.editable) {
            return <Row gutter={8}>
              <Col span={12}>
                <Button type="danger" onClick={() => this.onCancelEdit(record)}>Cancelar</Button>
              </Col>
              <Col span={12}>
                <Button onClick={() => this.onSave(record)} >Guardar</Button>
              </Col>
            </Row>
          } else {
            return <Row gutter={8}>
              <Col span={12}>
                <Popconfirm
                  title="Está seguro de que lo quiere eliminar?"
                  okText="Si"
                  cancelText="No"
                  onConfirm={() => this.onDelete(record)}
                >
                  <Button type="danger">Eliminar</Button>
                </Popconfirm>
              </Col>
              <Col span={12}>
                <Button disabled={fiscalPermissions.some(f => f.editable)} onClick={() => this.onEdit(record)} >Editar</Button>
              </Col>
            </Row>
          }
        }} />


      </Table>

    </Modal>
  }
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    fiscalPermissions: states.companyState.fiscalPermissions,
    equipmentNumbers: states.companyState.equipments,
    isFetching: states.companyState.isFetching,
  };
};

const mapDispatchToProps = {
  setFiscalPermissions,
  createFiscalPermission,
  updateFiscalPermission,
  deleteFiscalPermission,
  getFiscalPermission,
  getEquipmentsNumbers,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(FiscalPermissionModal);
