import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { SystemConfig, SystemConfigInvoice, Settings } from 'types/type';
import { getToken } from 'security/index';

class ConfigService extends Service {
  public getDaysToDelete = (): Promise<number> =>
    axios
      .get(createCoreServiceUrl('logs/days'))
      .then((response: AxiosResponse<number>): number => response.data);
  public getMonthsToDelete = (): Promise<number> =>
    axios
      .get(createCoreServiceUrl('logs/months'))
      .then((response: AxiosResponse<number>): number => response.data);
  public getYearsToDelete = (): Promise<number> =>
    axios
      .get(createCoreServiceUrl('logs/years'))
      .then((response: AxiosResponse<number>): number => response.data);
  public getDaysToDeleteInvoice = (): Promise<number> =>
    axios
      .get(createCoreServiceUrl('logs/days/invoice'))
      .then((response: AxiosResponse<number>): number => response.data);
  public getMonthsToDeleteInvoice = (): Promise<number> =>
    axios
      .get(createCoreServiceUrl('logs/months/invoice'))
      .then((response: AxiosResponse<number>): number => response.data);
  public getYearsToDeleteInvoice = (): Promise<number> =>
    axios
      .get(createCoreServiceUrl('logs/years/invoice'))
      .then((response: AxiosResponse<number>): number => response.data);
  public getSystemConfig = (): Promise<SystemConfig> =>
    axios
      .get(createCoreServiceUrl('systemConfig'))
      .then((response: AxiosResponse<SystemConfig>): SystemConfig => response.data);
  public getSystemConfigInvoice = (): Promise<SystemConfigInvoice> =>
    axios
      .get(createCoreServiceUrl('systemConfigInvoice'))
      .then((response: AxiosResponse<SystemConfigInvoice>): SystemConfigInvoice => response.data);
  public getSystemSettings = (): Promise<Settings> =>
    axios
      .get(createCoreServiceUrl((getToken() !== null)? 'systemSettings': 'privacyUrl'))
      .then((response: AxiosResponse<Settings>): Settings => response.data);
  public saveNewConfigurations = (
    systemConfig: SystemConfig,
    systemConfigInvoice: SystemConfigInvoice,
    settings: Settings,
    days: number,
    months: number,
    years: number,
    daysInvoice: number,
    monthsInvoice: number,
    yearsInvoice: number,
  ): Promise<SystemConfigInvoice> =>
    axios
      .post(createCoreServiceUrl('systemConfig'), { systemConfig, systemConfigInvoice, settings, days, months, years, daysInvoice, monthsInvoice, yearsInvoice })
      .then((response: AxiosResponse<SystemConfigInvoice>): SystemConfigInvoice => response.data);
}

export default ConfigService;
