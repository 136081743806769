// store/session/actions.ts
import { AnyAction } from 'redux';
import UserService from '../../../services/user';
import { saveToken } from '../../../security/';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { NewPassword } from '../../../types/type';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

export type Dispatch = ThunkDispatch<{}, {}, AnyAction>;

const userService = new UserService();
// Action Definition
export interface SetAction {
  type: 'SET';
  accessToken: string;
}
// Union Action Types
export type Action = SetAction;
// Action Creators
export const set = (accessToken: string): SetAction => {
  return { type: 'SET', accessToken };
};
export const changetoken = (
  chagepassword: NewPassword,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await userService.updatePassword(chagepassword);
      saveToken(response);
      dispatch(set(response));
      notification.success({
        description: 'Autentificado correctamente.',
        duration: 3,
        message: 'Éxito',
      });
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};
