import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Button,
  Col,
  Pagination,
  Form,
  Table,
  Divider, Select
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import { FormComponentProps } from 'antd/lib/form/Form';
import './style.css';
import {
  page,
  deleteFiscalData,
} from './redux/actions';
import { newFiscalDataListI } from './redux/reducers';
import { RootState } from '../../store/configureStore';
import { FiscalDataFilters, FiscalData } from '../../types/type';
import { Link } from 'react-router-dom';
import { getDecodeToken } from 'security';
import { Redirect } from 'react-router';
import FiscalDataService from 'services/fiscalData';
const fiscalDataService = new FiscalDataService();

interface DispatchProps {
  page: (page: number, filters: FiscalDataFilters) => void;
  deleteFiscalData: (id: string) => void;
}
interface StateProps {
  fiscalDataList: newFiscalDataListI;
}
type Props = FormComponentProps & StateProps & DispatchProps;

class FiscalDataView extends Component<Props> {
  public state = {
    data: [],
    valor: 0,
    paginationenable: true,
    visible: false,
    orderRfc: "DESC",
    onlySearchedData: [],
    searchedRFCValue: "Todos",
  };

  public async componentWillMount() {
    await this.getPage(0);
  }

  getPage(page: number) {
    this.props.page(page, {
      userId: getDecodeToken().id,
      isSales: false,
      distCent: "",
      isDescRfc: (this.state.orderRfc==="ASC")? false: true
    });
  }

  public getDataSourcePageable = (responseZero: any): FiscalData[] => {
    console.log(responseZero);
    if(this.state.onlySearchedData.length > 0 && this.state.searchedRFCValue!== "Todos"){
      return this.state.onlySearchedData;
    }
    if(responseZero[0] !== undefined){
      return responseZero[0];
    }
    return [];
  }

  public getDataSourceRFC = (responseOne: any): string[] => {
    console.log(responseOne);
    if(responseOne[1] !== undefined){
      let rfcList: Array<string> = responseOne[1];
      if(!rfcList.includes("Todos"))
        rfcList.unshift("Todos");
      return rfcList;
    }
    return [];
  }

  public searchByRFC = (rfc: string) : void =>{
    this.setState({searchedRFCValue: rfc}, ()=>{
      let filters = {
        userId: getDecodeToken().id,
        isDescRfc: (this.state.orderRfc==="ASC")? false: true,
        rfc: rfc
      };
      fiscalDataService.searchFiscalDataByRFC(0, filters).then((value)=>{
        console.log(value);
        this.setState({onlySearchedData: value});
      });
    });
  }

  public handlePaginationChange = (current: number): void => {
    this.getPage(current - 1);
    this.setState({ valor: current });
  };

  public deleteFiscalData = async (id: string) => {
    await this.props.deleteFiscalData(id);
    this.getPage(this.state.valor);
    this.setState({ valor: this.state.valor });
  };
  public handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (filters.rfc) {
      this.onChangeRfcOrder(filters.rfc)
    }
  }

  public onChangeRfcOrder =  (value: SelectValue):  void => {
    value = value.toString().trim();
    this.setState({
      orderRfc: (value==="ASC")? "ASC": "DESC"},
      () => {
        this.getPage((this.state.valor !== 0)? this.state.valor-1 : 0)
    });
  };

  private renderTableColumns = (): any => [
    {
      title: 'RFC',
      dataIndex: 'rfc',
      key: 'rfc',
      fixed: 'left',
      filteredValue: [this.state.orderRfc!== undefined? this.state.orderRfc: "DESC"],
      filterMultiple: false,
      filters: [{ value: 'ASC', text: 'Ascendente' }, { value: 'DESC', text: 'Descendente' }],
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Razón Social',
      dataIndex: 'businessName',
      key: 'businessName',
      fixed: 'left',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      key: 'email',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'C.P.',
      dataIndex: 'zipCode',
      key: 'zipCode',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Calle',
      dataIndex: 'street',
      key: 'street',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'No. Ext.',
      dataIndex: 'numExt',
      key: 'numExt',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'No. Int.',
      dataIndex: 'numInt',
      key: 'numInt',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Colonia',
      dataIndex: 'colony',
      key: 'colony',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Minicipio',
      dataIndex: 'municipality',
      key: 'municipality',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      render: (text: string): JSX.Element => <Col>{text}</Col>,
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (text: string, item: any): JSX.Element => (
        <Row>
          <Link
            key="3"
            to={{ pathname: `/fiscalData/newUserFiscalData/${item.id}` }}
          >
            Editar
          </Link>
          <Divider type="vertical" />
          <button
            key="resend"
            type="button"
            className="link-button"
            onClick={() => this.deleteFiscalData(item.id)}
          >
            Eliminar
          </button>
        </Row>
      ),
    },
  ];

  public render = (): JSX.Element => {
    if (!getDecodeToken().isClient){ return <Redirect to="/home" /> }
    return (
      <Row style={{ backgroundColor: '#E5E5E5' }}>
        <Col
          style={{
            backgroundColor: '#F1F7FF',
            paddingRight: 24,
            paddingLeft: 24,
            paddingTop: 24,
          }}
        >
          <Link
            key="3"
            to={{ pathname: '/fiscalData/newUserFiscalData/0' }}
          >
            <Button
              type="primary"
              className="button-new"
              size="small"
              icon="plus"
              id="user_new"
            >
              Registrar datos fiscales
            </Button>
          </Link>
        </Col>
        <Row
          style={{ marginLeft: 24, marginRight: 24, backgroundColor: '#fff' }}
        >
             <Col span={14}>
          <Col
            xs={{ span: 23, offset: 1 }}
            sm={{ span: 23, offset: 1 }}
            md={{ span: 23, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 11, offset: 1 }}
            xxl={{ span: 11, offset: 1 }}
          >
            <label className="label">{'Busca por RFC:'}</label>
            <Select
              defaultActiveFirstOption
              showSearch
              onChange={value => this.searchByRFC(value.toString())}
              style={{ width: '100%' }}
            >
              {
              this.getDataSourceRFC(this.props.fiscalDataList.content).map(fs =>
                <Select.Option value={fs}>{fs}</Select.Option>
              )}
            </Select>
          </Col>
        </Col>
          <Col span={24}>
            <Table
              dataSource={this.getDataSourcePageable(this.props.fiscalDataList.content)}
              rowKey={ (record : FiscalData) => record.id}
              columns={this.renderTableColumns()}
              pagination={false}
              scroll={{ x: true }}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <Pagination
          current={(this.state.searchedRFCValue=== "Todos")? (this.props.fiscalDataList.number || 0) + 1 : 1}
          total={ (this.state.searchedRFCValue=== "Todos")? this.props.fiscalDataList.totalElements: 1}
          onChange={this.handlePaginationChange}
          style={{ marginLeft: 24, marginTop: 24 }}
        />
      </Row>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    fiscalDataList: states.newFiscalDataState.fiscalDataList,
  };
};

const mapDispatchToProps = {
  page,
  deleteFiscalData,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(FiscalDataView));