export const BEGIN_FETCH = 'BEGIN_FETCH';
export const FETCH_COMPLETED = 'FETCH_COMPLETED';

interface BeginFetchAction {
  type: typeof BEGIN_FETCH;
}

interface EndFetchAction {
  type: typeof FETCH_COMPLETED;
}

export type DownloadAction = EndFetchAction | BeginFetchAction;
