import { getDecodeToken } from "security";
import { isEmpty } from 'lodash';
import { Sale } from "types/type";

export const isCancelableSale = (record: Sale): boolean => {
    if (record.cancelable) {
        if (isEmpty(getDecodeToken().customerCode)) {
            return true;
        } else return record.clientCancel;
    }
    return false;
};

export const isCancelable = (record: Sale): boolean => {
    if (record.cancelable && ['XAXX010101000', 'AAA010101AAA'].includes(record.invoiceRfc)) {
        if (isEmpty(getDecodeToken().customerCode)) {
            return true;
        } else return record.clientCancel;
    }
    return false;
};

export const subStringWithEllipsis = (str : string, end: number): string => {
    const substr = str.substring(0, end);
    return substr.length < str.length ? substr+'...' : substr;
};

