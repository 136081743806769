import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { Invoice } from '../../types/type';

class InvoiceService extends Service {
  public saveInvoice = (invoice: Invoice, userId: string = ''): Promise<Invoice> =>
    axios
      .post(createCoreServiceUrl('v1/invoice'), {
        invoice: { ...invoice },
        sales: invoice.sales,
        ...(userId ? { userId } : {})
      })
      .then((response: AxiosResponse<Invoice>): Invoice => response.data);
  public saveInvoiceMultiple = (invoice: Invoice, userId: string = ''): Promise<Invoice> =>
    axios
      .post(createCoreServiceUrl('invoice/multiple'), {
        invoice: { ...invoice },
        sales: invoice.sales,
        ...(userId ? { userId } : {})
      })
      .then((response: AxiosResponse<Invoice>): Invoice => response.data);
  public resendInvoce = (id: string): Promise<Invoice> =>
    axios
      .post(createCoreServiceUrl(`sale/invoice/${id}`))
      .then((response: AxiosResponse<Invoice>): Invoice => response.data);
  public cancelInvoice = (id: string): Promise<string> =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          createCoreServiceUrl(`sale/cancelInvoiceSale/${id}`),
        );
        if (
          response.data.message &&
          response.data.message.toLowerCase() === 'ok'
        ) {
          resolve(response.data.description);
        } else {
          reject(new Error(response.data.message || 'Cancelación fallida'));
        }
      } catch (error) {
        reject(error);
      }
      return "ok";
    });
  public saveInvoiceAndCancel = (invoice: Invoice, userId: string = ''): Promise<Invoice> =>
    axios
      .post(createCoreServiceUrl('v1/invoiceAndCancel'), {
        invoice: { ...invoice },
        sales: invoice.sales,
        ...(userId ? { userId } : {})
      },{
        params:
            {multiple: false}
        })
      .then((response: AxiosResponse<Invoice>): Invoice => response.data);
  public saveInvoiceAndCancelMultiple = (invoice: Invoice, userId: string = ''): Promise<Invoice> =>
    axios
      .post(createCoreServiceUrl('v1/invoiceAndCancel'), {
        invoice: { ...invoice },
        sales: invoice.sales,
        ...(userId ? { userId } : {})
      },{
        params:
            {multiple: true}
        }
      )
      .then((response: AxiosResponse<Invoice>): Invoice => response.data);
}

export default InvoiceService;
