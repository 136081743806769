import { AnyAction } from 'redux';

import SupportService from '../../../services/suppport/index';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {  } from './reducers';

const supportService = new SupportService();

const startFetch = (): { type: string } => {
    return { type: 'BEGIN_FETCH' };
};

const endFetch = (): { type: string } => {
    return { type: 'FETCH_COMPLETED' };
};

export const reactivateIUS = (ius: string, total: number, xmlCancel: File): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(startFetch());
            const response: string = await supportService.reactivateIUS(ius, total, xmlCancel);
            dispatch({ type: 'SET', LogEventList: response });
            dispatch(endFetch());
        } catch (err) {
            notification.error(parseApiError(err));
            dispatch(endFetch());
        }
    }
}