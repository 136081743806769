/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 * Edit by David Canul, 27 may 2019
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { regimenFiscal } from 'commons/components/regimenFiscal';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RootState } from 'store/configureStore';
import {
  Card,
  Button,
  Input,
  Col,
  Row,
  Select,
  List,
  InputNumber,
  Spin,
} from 'antd';
import { Strings as i8n } from 'commons/strings';
import { getUserAction, saveuser, getFiscalData } from './redux/actions';
import {
  saveInvoiceAndCancel,
  saveInvoiceAndCancelMultiple,
  removeSaleToStagingArea,
  cancelInvoice,
  addSaleToStagingArea,
} from 'routes/invoices/redux/actions';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { UserSavedI } from 'routes/users/redux/reducers';
import { Permission, User, Sale, FiscalData, Invoice } from 'types/type';
import moment from 'moment';
import { Popups } from 'commons/components/popups/popups';
import { getDecodeToken } from 'security';
import { usoCFDI } from 'commons/components/regimenFiscal';
import { InvoiceRules } from 'routes/invoices/components/FiscalData/rulesFiscalData';
import MaskedInput from 'react-text-mask';
import { isEmpty } from 'lodash';
import { isCancelable } from 'commons/functions';
import { SelectValue } from 'antd/lib/select';
import { Redirect } from 'react-router';
import './style.css';
import FiscalDataService from 'services/fiscalData';
const fiscalDataService = new FiscalDataService();

interface DispatchProps {
  getUserAction: (id: string) => void;
  saveuser: (user: User) => void;
  removeSaleToStagingArea: (item: Sale) => void;
  getFiscalData: (userId: string) => void;
  saveInvoiceAndCancel: (invoice: Invoice, userId: string) => void;
  saveInvoiceAndCancelMultiple: (invoice: Invoice, userId: string) => void;
  cancelInvoice: (id: any, retry: boolean) => void;
  addSaleToStagingArea: (
    ius: string,
    total: number,
    clean: boolean
  ) => void;
}

interface HomeRouterProps {
  id: string;
}

interface StateProps {
  user: UserSavedI;
  isFetching: boolean;
  permissionList: Permission[];
  fiscalDataList: FiscalData[];
  readonly salesStagingArea: Sale[];

}

type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

class InvoiceUser extends Component<Props> {

  public state = {
    ius: "",
    total: 0,
    fiscaldata: null,
    name: "",
    email: "",
    selectedRFC: null,
    selectedRFcID: null,
    usoCFDI: [],
    regimenFiscal: [],
    formasPago: [],
  }

  private iusMakedRef: React.RefObject<MaskedInput>;

  constructor(props: Props) {
    super(props);
    this.iusMakedRef = React.createRef();
  }

  componentDidMount() {
    const { getFiscalData, addSaleToStagingArea, form } = this.props
    addSaleToStagingArea('0', 0, true);
    form.resetFields();
    getFiscalData(getDecodeToken().id);
    fiscalDataService.fiscalDataList().then((response)=>{
      this.setState({
        usoCFDI: response.useCFDIS,
        regimenFiscal: response.regimenFiscals,
        formasPago: response.paymentCode,
      });
    });
  }

  componentWillUnmount() {
    this.props.addSaleToStagingArea('0', 0, true);
  }

  public onChangeRfc = (value: SelectValue): void => {
    value = value.toString().split(',')[1]
    const fiscalData = this.props.fiscalDataList.find((fd) => fd.rfc === value);
    console.log(fiscalData);
    if (fiscalData) {
      this.setState({
        fiscalData,
        fiscaldata: fiscalData,
        selectedRFC: value.toString(),
        selectedRFcID: fiscalData.id
      });
      this.props.form.setFieldsValue({
        email: fiscalData.email,
        name: fiscalData.businessName,
      });
    }
  };

  public getFiscalDataFromUser = (value:string) : string => {
    if(this.state.fiscaldata !== null){
      switch (value) {
        case "regimen":
          if(this.state.fiscaldata['fiscalRegime'] !== null){
            let keyRegime = this.state.fiscaldata['fiscalRegime'];
            let valueRegimen = this.state.regimenFiscal.filter(x => x['code'] === keyRegime).map((item: {code:string, description:string}): string =>(
              item.code.concat(" - ").concat(item.description)));
            if(valueRegimen[0] !== undefined)
              return valueRegimen[0];
          }
          break;
        case "zipCode":
          if(this.state.fiscaldata['zipCode'] !== null)
            return this.state.fiscaldata['zipCode'];
          break;
      }
    }
    return "";
  }

  /* private groupArray(saleList: any[], key: string): Array<any> {
    return saleList.reduce(function (prevValue, currentValue) {
      (prevValue[currentValue[key]] = prevValue[currentValue[key]] || []).push(currentValue);
      return prevValue;
    }, {});
  }; */

  handleOnSubmit = (saleBilledAndNotCancellable: boolean, isMultiple: boolean) => {
    const { form, salesStagingArea, fiscalDataList } = this.props;
    let newSalesStagingArea: any[] = [];
    /*if (salesStagingArea.length > 1 && isMultiple) {
      const groups: Sale[] = this.groupArray(salesStagingArea, "numberPermission");
      for (let i in groups) { newSalesStagingArea.push(groups[i]) }
    } else **/newSalesStagingArea = [salesStagingArea];

    let error = "";
    form.validateFieldsAndScroll(
      async (err, values): Promise<void> => {
        if (!err) {
          try {
            if (newSalesStagingArea.length > 0) {
              for (let i = 0; i <= newSalesStagingArea.length - 1; i++) {
                if (!saleBilledAndNotCancellable) {

                  const fiscalData = fiscalDataList.find((fd) => fd.id === this.state.selectedRFcID);
                  if (fiscalData) {
                    const invoice = {
                      city: fiscalData.city,
                      externalNumber: fiscalData.numExt,
                      internalNumber: fiscalData.numInt,
                      email: values.email,
                      sales: newSalesStagingArea[i],
                      name: values.name,
                      usoCfdi: values.usoCfdi,
                      colony: fiscalData.colony,
                      fiscalRegime: fiscalData.fiscalRegime,
                      municipality: fiscalData.municipality,
                      rfc: fiscalData.rfc,
                      state: fiscalData.state,
                      zipCode: fiscalData.zipCode,
                      street: fiscalData.street,
                      formaPago: values.formaPago,
                      idG4s: "",
                      id: "",
                    }
                    const id = getDecodeToken().id;
                    if (isMultiple) this.props.saveInvoiceAndCancelMultiple(invoice, id)
                    else this.props.saveInvoiceAndCancel(invoice, id);
                  }
                } else {
                  error = "Una de las ventas ya ha sido Facturada y no puede ser cancelada.";
                  throw new Error("Una de las ventas ya ha sido Facturada y no puede ser cancelada.");
                }
              }
              if (isEmpty(error)) {
                this.props.addSaleToStagingArea('0', 0, true);
                form.resetFields();
                this.props.history.push("/home");
              }
            } else {
              error = "No hay ventas a facturar";
              throw new Error("No hay ventas a facturar");
            }
          } catch (erro) {
            Popups.notifyError(error)
          }
        }
      },
    );
  }

  public handleOnSubmitIUS = async (): Promise<void> => {
    try {
      if (!isEmpty(this.state.total.toString()) && this.state.ius.trim() !== '') {
        try {
          await this.props.addSaleToStagingArea(
            `${this.state.ius.toUpperCase()}`,
            this.state.total,
            false,
          );
        }
        catch (err) {
          Popups.notifyException(err);
        }
        this.setState({ ius: "", total: "" })
      } else {
        throw new Error();
      }
    } catch (error) {
      Popups.notifyError("IUS y monto vacio");
    }
  };

  public renderSalesStagingArea = (): JSX.Element => {
    return (
      <Col style={{ backgroundColor: '#fff' }}>
        {this.props.salesStagingArea.length > 0 ? (
          <Card
            className={'card-invoice-content'}
            type="inner"
            title={i8n.IUS_ADDED_IN_STAGING_AREA}
          >
            <List
              dataSource={this.props.salesStagingArea}
              renderItem={(item: Sale, index: number): JSX.Element => (
                <Col>
                  <List.Item
                    actions={
                      [
                        <button
                          key="1"
                          id={'invoice_delete_' + index.toString()}
                          type="button"
                          className="link-button"
                          onClick={() => {
                            this.props.removeSaleToStagingArea(item);
                          }}
                        >
                          Eliminar
                        </button>
                      ]
                    }
                  >
                    <List.Item.Meta
                      title={
                        <div id={'invoice_list_' + index.toString()}>
                          {item.IUSCode +
                            ' | $' +
                            item.total +
                            ' | ' +
                            moment(item.endDate).format('DD/MM/YYYY')}
                        </div>
                      }
                    />
                  </List.Item>
                </Col>
              )}
            />
          </Card>
        ) : (
          <Card bordered={false}>
            <div>
              <p />
            </div>
          </Card>
        )}
      </Col>
    );
  };


  public render = (): JSX.Element => {
    if (!getDecodeToken().isClient){ return <Redirect to="/home" /> }
    const hexRegex = /[0-9a-fA-F]/;
    const iusFragment = [hexRegex, hexRegex, hexRegex, hexRegex];
    const { form: { getFieldDecorator }, user: { usersaved }, salesStagingArea, isFetching } = this.props;
    const saleBilledAndNotCancellable = salesStagingArea.some(s => s.billed && !isCancelable(s));
    return (
      <Spin tip="Cargando..." spinning={isFetching}>
        <Card>
          <Form>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>RFC a facturar </h2>
                Selecciona un RFC
              </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <label className="label">{'RFC:'}</label>
                    <Select
                      defaultActiveFirstOption
                      showSearch
                      onChange={value => this.onChangeRfc(value)}
                      style={{ width: '100%' }}
                    >
                      {this.props.fiscalDataList.map(fs =>
                        <Select.Option value={fs.id+',' + fs.rfc}>{fs.rfc}</Select.Option>
                      )}
                    </Select>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Razón social">
                      {getFieldDecorator('name', {
                        initialValue: '',
                        rules: [
                          {
                            required: false,
                            whitespace: true,
                            message: 'Razón social',
                          },
                          {
                            max: 250,
                            whitespace: true,
                            message: 'No se permiten mas de 250 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="Razón social" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Uso de CFDI">
                      {getFieldDecorator('usoCfdi', {
                        initialValue: "G03",
                        rules: InvoiceRules.usoCfdiRule,
                      })(
                        <Select showSearch size="large">
                           {this.state.usoCFDI!==undefined && this.state.usoCFDI.map((item: {code:string, description:string}): React.ReactNode=>(
                          <Select.Option key={item.code} value={item.code}>
                          <div id={item.code}>
                            {item.code.concat(" - ").concat(item.description)}
                          </div>
                        </Select.Option>
                        ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Correo electróninco">
                      {getFieldDecorator('email', {
                        initialValue: usersaved.email || '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa tu correo',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                          {
                            type: "email",
                            message: 'Ingresa un correo valido',
                          },
                        ],
                      })(<Input size="large" placeholder="Correo electrónico" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                    hidden={this.state.formasPago.length===0}
                  >
                    <Form.Item hasFeedback label="Forma de Pago">
                      {getFieldDecorator('formaPago', {
                        rules: [
                          {
                            required: this.state.formasPago.length>0,
                            whitespace: true,
                            message: 'Ingresa la forma de Pago',
                          },
                        ],
                      })(
                        <Select showSearch size="large">
                          {this.state.formasPago!==undefined && this.state.formasPago.map((item: {code:string, description:string}): React.ReactNode=>(
                            <Select.Option key={item.code} value={item.code}>
                            <div id={item.code}>
                              {item.code.concat(" - ").concat(item.description)}
                            </div>
                          </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={14}></Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Input addonBefore="Código Postal" readOnly disabled value={this.getFiscalDataFromUser("zipCode")} />
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Input addonBefore="Régimen Fiscal" readOnly disabled value={this.getFiscalDataFromUser("regimen")} />
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Tickets a facturar
                </h2>
                Introduce IUS y monto para agregar tickets a facturar
              </Col>
                <Col span={14}>
                  {!saleBilledAndNotCancellable ? (
                    <div>
                      <Row style={{ backgroundColor: '#f1f7ff', padding: 20 }}>
                        <Col span={12} offset={2}>
                          <label className="label" htmlFor="">IUS</label>
                          <MaskedInput
                            ref={this.iusMakedRef}
                            onChange={(e) => this.setState({ ius: e.target.value })}
                            value={this.state.ius}
                            mask={[
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                            ]}
                            id="ius"
                            showMask
                            style={{ fontSize: '21px' }}
                            className="ant-input ant-input-large"
                          />
                        </Col>
                        <Col offset={3} xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                          <Row>
                            <label className="label" >Monto $</label>
                          </Row>
                          <InputNumber value={this.state.total} onChange={text => this.setState({ total: text })} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={23}>
                          <Form.Item>
                            <Button
                              className="button-invoice-serch-style"
                              type="dashed"
                              id="search_sale_button"
                              onClick={() => this.handleOnSubmitIUS()}
                            >
                              {' + Añadir  '}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {saleBilledAndNotCancellable &&
                        'Una de las ventas ya ha sido Facturada y no puede ser cancelada.'
                      }
                    </div>
                  )}
                  <Col>
                    {this.renderSalesStagingArea()}
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Col span={11} />
              <Col span={3}>
                <Link to="/home" onClick={() => this.props.addSaleToStagingArea('0', 0, true)}>
                  <Button id="cancel_button" type="default" block size="large">
                    Cancelar
                </Button>
                </Link>
              </Col>
              <Col span={4} offset={1}>
                <Button
                  className="button-customer-serch-style"
                  block
                  size="large"
                  type="primary"
                  onClick={() => this.handleOnSubmit(saleBilledAndNotCancellable, false)}
                >
                  Factura por venta
              </Button>
              </Col>
              <Col span={4} offset={1}>
                <Button
                  className="button-customer-serch-style"
                  block
                  size="large"
                  type="primary"
                  onClick={() => this.handleOnSubmit(saleBilledAndNotCancellable, true)}
                >
                  Factura Agrupada
              </Button>
              </Col>
            </Card>
          </Form>
        </Card>
      </Spin>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    user: states.usersState.usersSave,
    permissionList: states.usersState.permissionList,
    salesStagingArea: states.invoiceState.salesStagingArea,
    fiscalDataList: states.invoiceUserState.fiscalDataList,
    isFetching: states.invoiceState.isFetching
  };
};

const mapDispatchToProps = {
  getUserAction,
  saveuser,
  getFiscalData,
  addSaleToStagingArea,
  saveInvoiceAndCancel,
  saveInvoiceAndCancelMultiple,
  removeSaleToStagingArea,
  cancelInvoice
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(InvoiceUser));
