import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { Customer } from '../../types/type';

class CustomerService extends Service {
  public getCustomerG4S = (customerId: string): Promise<Customer> =>
    axios
      .get(createCoreServiceUrl(`customer/getCustomerG4S/${customerId}`))
      .then((response: AxiosResponse<Customer>): Customer => response.data);
}
export default CustomerService;
