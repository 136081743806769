/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import { Company, DistributionCenter, UnavailableFolio, FiscalPermission, Equipment, CompanyImages, CompanySales, EquipmentsList } from 'types/type';
import {
  initcompany,
  initDistributionCenter,
  initUnavailableFolio,
  initEquipmentList,
} from 'commons/initTypes';
import { RootState } from 'store/configureStore';
import {
  CompanyAction,
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SET_DISTRIBUTION_CENTER,
  SET_COMPANY,
  GET_UNAVAILABLE_FOLIO,
  SET_FISCAL_PERMISSIONS,
  CREATE_FISCAL_PERMISSION,
  UPDATE_FISCAL_PERMISSION,
  DELETE_FISCAL_PERMISSION,
  SET_EQUIPMENTS,
  IS_EQUIMENT_NUMBER,
  MULTICENTER,
  SET_COMPANY_IMAGES,
  SET_COMPANY_SALES,
  SET_EQUIPMENT_LIST,
} from './types';

export interface CompanyI {
  company: Company;
}
export interface CompanyListI {
  content?: Company[];
  number?: number;
  totalElements?: number;
}
export interface DistributionCenterI {
  distributionCenter: DistributionCenter[];
}
export interface UnavailableFolioI {
  unavailableFolio: UnavailableFolio[];
}

export interface CompanyState {
  readonly company: Company;
  readonly companyList: CompanyListI;
  readonly companyImagesList: CompanyImages[];
  readonly companySalesList: CompanySales[];
  readonly distributionCenter: DistributionCenterI;
  readonly unavailableFolio: UnavailableFolioI;
  readonly isFetching: boolean;
  readonly multicenter: boolean;
  readonly isEquimentNumber: boolean;
  readonly fiscalPermissions: Array<FiscalPermission>;
  readonly equipments: Array<Equipment>;

  readonly equipmentList: EquipmentsList;
}

const company: Reducer<Company> = (
  state: Company = initcompany,
  action,
): Company => {
  switch (action.type) {
    case 'SET':
      return { ...state, ...action.company };
    default:
      return state;
  }
};

const multicenter: Reducer<boolean> = (
  state: boolean = false,
  action,
): boolean => {
  switch (action.type) {
    case MULTICENTER:
      return action.multicenter;
    default:
      return state;
  }
};

const isEquimentNumber: Reducer<boolean> = (
  state: boolean = false,
  action,
): boolean => {
  switch (action.type) {
    case IS_EQUIMENT_NUMBER:
      return action.isEquimentNumber;
    default:
      return state;
  }
};

const companyList: Reducer<CompanyListI> = (
  state: CompanyListI = { content: [] },
  action,
): CompanyListI => {
  switch (action.type) {
    case SET_COMPANY:
      return { ...action.companyList };
    default:
      return state;
  }
};

const companyImagesList: Reducer<CompanyImages[]> = (
  state: CompanyImages[] = [],
  action,
): CompanyImages[] => {
  switch (action.type) {
    case SET_COMPANY_IMAGES:
      return [...[...action.companyImagesList]];
    default:
      return state;
  }
};

const companySalesList: Reducer<CompanySales[]> = (
  state: CompanySales[] = [],
  action,
): CompanySales[] => {
  switch (action.type) {
    case SET_COMPANY_SALES:
      return [...[...action.companySalesList]];
    default:
      return state;
  }
};

const distributionCenter: Reducer<DistributionCenterI> = (
  state: DistributionCenterI = { distributionCenter: [initDistributionCenter] },
  action,
): DistributionCenterI => {
  switch (action.type) {
    case SET_DISTRIBUTION_CENTER:
      return { ...state, distributionCenter: action.distributionCenter };
    default:
      return state;
  }
};
const unavailableFolio: Reducer<UnavailableFolioI> = (
  state: UnavailableFolioI = { unavailableFolio: [initUnavailableFolio] },
  action,
): UnavailableFolioI => {
  switch (action.type) {
    case GET_UNAVAILABLE_FOLIO:
      return { ...state, unavailableFolio: action.unavailableFolio };
    default:
      return state;
  }
};
const isFetching: Reducer<boolean> = (
  state = false,
  action: CompanyAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};

const fiscalPermissions: Reducer<Array<FiscalPermission>> = (
  state = [],
  action: CompanyAction,
): Array<FiscalPermission> => {
  switch (action.type) {
    case SET_FISCAL_PERMISSIONS:
      return [...action.fiscalPermissions];
    case CREATE_FISCAL_PERMISSION:
      const newState = [...state]
      newState.shift()
      return [action.fiscalPermission, ...newState];
    case UPDATE_FISCAL_PERMISSION:
      const index = state.findIndex((fp) => fp.id === action.fiscalPermission.id);
      const newState2 = [...state]
      newState2[index] = action.fiscalPermission
      return newState2;
    case DELETE_FISCAL_PERMISSION:
      const index2 = state.findIndex((fp) => fp.id === action.id);
      const newState3 = [...state]
      newState3.splice(index2, 1)
      return newState3;
    default:
      return state;
  }
};

const equipments: Reducer<Array<Equipment>> = (
  state = [],
  action: CompanyAction,
): Array<Equipment> => {
  switch (action.type) {
    case SET_EQUIPMENTS:
      return [...action.equipments];
    default:
      return state;
  }
};

const equipmentList: Reducer<EquipmentsList> = (
  state: EquipmentsList = { unAssignedEquipments: initEquipmentList.unAssignedEquipments, serviceNumberList: initEquipmentList.serviceNumberList },
  action,
): EquipmentsList => {
  switch (action.type) {
    case SET_EQUIPMENT_LIST:
      return { ...state, unAssignedEquipments: action.unAssignedEquipments, serviceNumberList: action.serviceNumberList };
    default:
      return state;
  }
};

export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;


export default combineReducers<CompanyState>({
  company,
  multicenter,
  isEquimentNumber,
  companyList,
  companyImagesList,
  companySalesList,
  distributionCenter,
  unavailableFolio,
  isFetching,
  fiscalPermissions,
  equipments,
  equipmentList,
});
