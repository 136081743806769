/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import { Sale, SaleSelected } from 'types/type';
import { RootState } from 'store/configureStore';
import {
  BEGIN_FETCH,
  FETCH_COMPLETED,
  SaleAction,
  SET_END_DATE,
  SET_INIT_DATE,
  SET_SALE,
  SET_SALE_SELECTED,
  SET_SUM_TOTALLK,
  SET_SUM_TOTALLK_BILLABLE,
  SET_SUM_TOTALM,
  SET_SUM_TOTALM_BILLABLE,
  SET_SUM_TOTALLK_SELECTED,
  SET_SUM_TOTALM_SELECTED,
  PAYMENT_CHANGE,
  TOTAL_ELEMENTS_BILLABLE,
  TOTAL_ELEMENTS_SELECTED,
} from './types';
import moment, { Moment } from 'moment';

export interface SaleI {
  sale: Sale;
}
export interface SaleList {
  content?: Sale[];
  number?: number;
  totalElements?: number;
}

export interface SaleState {
  readonly saleList: SaleList;
  readonly isFetching: boolean;
  readonly initDate: Moment;
  readonly endDate: Moment;
  readonly sumTotalLK: number;
  readonly sumTotalM: number;
  readonly sumTotalLKBillable: number;
  readonly sumTotalMBillable: number;
  readonly sumTotalLKSelected: number;
  readonly sumTotalMSelected: number;
  readonly saleSelectedList: SaleSelected[];
  readonly paymentChange: boolean;
  readonly totalElementsBillable: number;
  readonly totalElementsSelected: number;
}

const saleList: Reducer<SaleList> = (
  state: SaleList = { content: [] },
  action,
): SaleList => {
  switch (action.type) {
    case SET_SALE:
      const nextState = { ...action.saleList };
      return nextState;
    case 'CLEAN':
      return { content: [] };
    default:
      return state;
  }
};

const isFetching: Reducer<boolean> = (
  state = false,
  action: SaleAction,
): boolean => {
  switch (action.type) {
    case BEGIN_FETCH:
      return true;
    case FETCH_COMPLETED:
      return false;
    default:
      return state;
  }
};
export const getIsFetching = (store: RootState): boolean =>
  store.invoiceState.isFetching;

export const initDate: Reducer<Date> = (
  state = moment('2019-10-1').toDate(),
  action,
): Date => {
  switch (action.type) {
    case SET_INIT_DATE:
      return action.initDate;
    default:
      return state;
  }
};

export const endDate: Reducer<Date> = (
  state = moment().toDate(),
  action,
): Date => {
  switch (action.type) {
    case SET_END_DATE:
      return action.endDate;
    default:
      return state;
  }
};

export const sumTotalLK: Reducer<number> = (state = 0, action): number => {
  switch (action.type) {
    case SET_SUM_TOTALLK:
      return action.sumTotalLK;
    default:
      return state;
  }
};

export const sumTotalM: Reducer<number> = (state = 0, action): number => {
  switch (action.type) {
    case SET_SUM_TOTALM:
      return action.sumTotalM;
    default:
      return state;
  }
};

export const sumTotalLKBillable: Reducer<number> = (
  state = 0,
  action,
): number => {
  switch (action.type) {
    case SET_SUM_TOTALLK_BILLABLE:
      return action.sumTotalLKBillable;
    default:
      return state;
  }
};

export const sumTotalMBillable: Reducer<number> = (
  state = 0,
  action,
): number => {
  switch (action.type) {
    case SET_SUM_TOTALM_BILLABLE:
      return action.sumTotalMBillable;
    default:
      return state;
  }
};

export const sumTotalLKSelected: Reducer<number> = (
  state = 0,
  action,
): number => {
  switch (action.type) {
    case SET_SUM_TOTALLK_SELECTED:
      return action.sumTotalLKSelected;
    default:
      return state;
  }
};

export const sumTotalMSelected: Reducer<number> = (
  state = 0,
  action,
): number => {
  switch (action.type) {
    case SET_SUM_TOTALM_SELECTED:
      return action.sumTotalMSelected;
    default:
      return state;
  }
};

export const saleSelectedList: Reducer<SaleSelected[]> = (
  state: SaleSelected[] = [],
  action,
): SaleSelected[] => {
  switch (action.type) {
    case SET_SALE_SELECTED:
      return action.saleSelectedList;
    default:
      return state;
  }
};

const paymentChange: Reducer<boolean> = (
  state: boolean = false,
  action,
): boolean => {
  switch (action.type) {
    case PAYMENT_CHANGE:
      return action.paymentChange;
    default:
      return state;
  }
};

const totalElementsBillable: Reducer<number> = (
  state: number = 0,
  action,
): number => {
  switch (action.type) {
    case TOTAL_ELEMENTS_BILLABLE:
      return action.totalElementsBillable;
    default:
      return state;
  }
};

const totalElementsSelected: Reducer<number> = (
  state: number = 0,
  action,
): number => {
  switch (action.type) {
    case TOTAL_ELEMENTS_SELECTED:
      return action.totalElementsSelected;
    default:
      return state;
  }
};

export default combineReducers<SaleState>({
  saleList: saleList,
  initDate,
  endDate,
  sumTotalLK,
  sumTotalM,
  sumTotalLKBillable,
  sumTotalMBillable,
  saleSelectedList,
  isFetching,
  sumTotalLKSelected,
  sumTotalMSelected,
  paymentChange,
  totalElementsBillable,
  totalElementsSelected,
});
