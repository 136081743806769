import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/configureStore';
import { } from './redux/reducers';
import { reactivateIUS } from './redux/actions';
import { Row, Spin, Upload, Button, InputNumber, Col, Card } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import MaskedInput from 'react-text-mask';
import './style.css';
import { isEmpty } from 'lodash';
import Form, { FormComponentProps } from 'antd/lib/form';

import { isAllowed } from 'security/index';
import { Permissions as P } from 'commons/permissions';
import { Redirect } from 'react-router';


const hexRegex = /[0-9a-fA-F]/;
const iusFragment = [hexRegex, hexRegex, hexRegex, hexRegex];

interface DispatchProps {
    reactivateIUS: (ius: string, total: number, xmlCancel: File) => void;
}

interface StateProps {
    isFetching: boolean;
}

type Props = StateProps & DispatchProps & FormComponentProps;

class Support extends Component<Props> {
    public state = {
        xmlCancel: new File([''], ''),
        IUS: '',
        total: 0,
    };
    public handleBeforeUploadKey = (file: File): boolean => {
        this.setState({ xmlCancel: file });
        return false;
    };
    private handleRemoveKey = (): void => {
        this.setState({ xmlCancel: new File([''], '') });
    };
    public uploadButton = (text: string): JSX.Element => (
        <div>
            <Button size="large" style={{ color: '#2E76D1', borderColor: '#2E76D1' }}>
                <UploadOutlined /> {text}
            </Button>
        </div>
    );
    public render = (): React.ReactNode => {
        if (!isAllowed([P.CAN_VIEW_SUPPORT])) {
            return <Redirect to="/home" />
          }
        const { form } = this.props;
        const { getFieldDecorator } = form;
        console.log(this.state.xmlCancel);
        return <Spin tip="Cargando..." spinning={this.props.isFetching}>
            <Card>
                <Form>
                    <Card className={'customer-card'}>
                        <Row>
                            <Col span={10}>
                                <h2 style={{ color: '#296FC4' }}>Reactivar IUS en plataforma</h2>
                            </Col>
                            <Col span={14}>
                                <Col
                                    xs={{ span: 23, offset: 1 }}
                                    sm={{ span: 23, offset: 1 }}
                                    md={{ span: 23, offset: 1 }}
                                    lg={{ span: 11, offset: 1 }}
                                    xl={{ span: 11, offset: 1 }}
                                    xxl={{ span: 11, offset: 1 }}
                                >
                                    <Form.Item label="IUS">
                                        {getFieldDecorator('ius', {
                                            initialValue: this.state.IUS,
                                            valuePropName: 'checked',
                                            rules: [{
                                                max: 24,
                                                min: 24,
                                                message: 'Ingresa un ius valido',
                                            },]
                                        })(<MaskedInput
                                            mask={[
                                                ...iusFragment,
                                                '-',
                                                ...iusFragment,
                                                '-',
                                                ...iusFragment,
                                                '-',
                                                ...iusFragment,
                                                '-',
                                                ...iusFragment,
                                            ]}
                                            id="ius"
                                            showMask
                                            size={23}
                                            style={{ fontSize: '20px' }}
                                            className="ant-input ant-input-large"
                                            onChangeCapture={(e) => {
                                                let IUS = e.currentTarget.value.substring(0, 24).toUpperCase();
                                                if (!isEmpty(IUS)) this.setState({ IUS });
                                            }}
                                        />)}
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 23, offset: 1 }}
                                    sm={{ span: 23, offset: 1 }}
                                    md={{ span: 23, offset: 1 }}
                                    lg={{ span: 11, offset: 1 }}
                                    xl={{ span: 11, offset: 1 }}
                                    xxl={{ span: 11, offset: 1 }}
                                >
                                    <Form.Item label="Total">
                                        {getFieldDecorator('total', {
                                            initialValue: this.state.total,
                                        })(<InputNumber onChange={total => this.setState({ total })} />)}
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 23, offset: 1 }}
                                    sm={{ span: 23, offset: 1 }}
                                    md={{ span: 23, offset: 1 }}
                                    lg={{ span: 11, offset: 1 }}
                                    xl={{ span: 11, offset: 1 }}
                                    xxl={{ span: 11, offset: 1 }}
                                >
                                    <Form.Item label="Acuse de cancelación XML si se tiene">
                                        {getFieldDecorator('xmlCancel', {
                                            initialValue: this.state.xmlCancel,
                                        })(<Upload
                                            name="xmlFile"
                                            action="/"
                                            accept=".xml"
                                            beforeUpload={this.handleBeforeUploadKey}
                                            onRemove={this.handleRemoveKey}
                                        >
                                            {isEmpty(this.state.xmlCancel) ?
                                                this.uploadButton('Acuse de cancelación XML') :
                                                ""}
                                        </Upload>)}
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 23, offset: 1 }}
                                    sm={{ span: 23, offset: 1 }}
                                    md={{ span: 23, offset: 1 }}
                                    lg={{ span: 11, offset: 1 }}
                                    xl={{ span: 11, offset: 1 }}
                                    xxl={{ span: 11, offset: 1 }}
                                >
                                    <Button
                                        disabled={(isEmpty(this.state.IUS) && this.state.total > 0)}
                                        type='primary'
                                        onClick={
                                            () => {
                                                this.props.reactivateIUS(this.state.IUS, this.state.total, this.state.xmlCancel);
                                                this.setState({ IUS: "", total: 0, xmlCancel: new File([''], '') });
                                            }
                                        }
                                    >
                                        Activar IUS
                                    </Button>
                                </Col>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Card>
        </Spin >
    }
}

const mapStateToProps = (states: RootState): StateProps => {
    return {
        isFetching: states.supportState.isFetching,
    }
}

const mapDispatchToProps = {
    reactivateIUS
};

export default connect<StateProps, DispatchProps, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(Support));