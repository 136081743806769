/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import React, { Component, FormEvent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Input, Form, Button, Card } from 'antd';
import { connect } from 'react-redux';
import decode from 'jwt-decode';
import { FormComponentProps } from 'antd/lib/form/Form';
import logo from 'assets/logo.png';
//FIXME Each component should use its own style. Otherwise they wont be decoupled.
import './../style.css';
import { passwordvalidation } from '../../../commons/regularExpressions';
import { changetoken } from '../redux/actions';
import { AccessToken } from '../redux/reducers';
import { RootState } from '../../../store/configureStore';
import { NewPassword } from '../../../types/type';
import { getToken } from 'security';
import { LockOutlined } from '@ant-design/icons';

interface HomeRouterProps {
  token: string;
}

interface DispatchProps {
  changetoken: (values: NewPassword) => void;
}
interface StateProps {
  accessToken: AccessToken;
}
type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

class ActivatePassword extends Component<Props> {
  public state = { confirmDirty: false };
  private handleOnSubmit = (e: FormEvent): void => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      (err: any, values: any): void => {
        if (!err) {
          this.props.changetoken(values);
        }
      },
    );
  };
  public compareToFirstPassword = (
    rule: {},
    value: string,
    callback: Function,
  ): void => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Las contraseñas no coinciden');
    } else {
      callback();
    }
  };
  public validateToNextPassword = (
    rule: {},
    value: string,
    callback: Function,
  ): void => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['secondpassword'], { force: true });
    }
    callback();
  };

  public handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  public render = (): JSX.Element => {
    const { getFieldDecorator } = this.props.form;
    if (getToken()) return <Redirect to="/" />;
    const token = (tokenval: string): { id: string } => {
      try {
        const value: any = decode(tokenval);
        return value;
      } catch (error) {
        return { id: '' };
      }
    };
    return (
      <React.Fragment>
        <Card className="activate-card">
          <img className="header-logo" src={logo} alt="company_logo" />
          <Row>
            <Col>
              <Form onSubmit={this.handleOnSubmit} className="login-form">
                <h2 className="style-text-align-center">
                  Ingrese una contraseña para acceder al sistema.
                </h2>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Campo obligatorio' },
                      { validator: this.validateToNextPassword },
                      {
                        pattern: passwordvalidation,
                        message:
                          'Mínimo 8 caracteres una letra mayúscula y un número',
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder="Contraseña"
                      onBlur={this.handleConfirmBlur}
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('secondpassword', {
                    rules: [
                      { required: true, message: 'Campo obligatorio' },
                      { validator: this.compareToFirstPassword },
                      {
                        pattern: passwordvalidation,
                        message:
                          'Mínimo 8 caracteres una letra mayúscula y un numero',
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder="Contraseña"
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('id', {
                    initialValue: token(this.props.match.params.token).id,
                  })(<Input className="style-display-none" />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('token', {
                    initialValue: this.props.match.params.token,
                  })(<Input className="style-display-none" />)}
                </Form.Item>
                <Button
                  className="full-width"
                  type="primary"
                  htmlType="submit"
                  id="activatePassword_button"
                >
                  Confirmar Contraseña
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  };
}
const mapStateToProps = (states: RootState): StateProps => {
  return {
    accessToken: states.loginState.accessToken,
  };
};

const mapDispatchToProps = {
  changetoken,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(ActivatePassword));
