/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 * Edit by David Canul, 27 may 2019
 *
 */

import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';

import Form, { FormComponentProps } from 'antd/lib/form';
import { RootState } from 'store/configureStore';
import {
  Card,
  Button,
  Input,
  Col,
  Row,
} from 'antd';
import { getUserAction, saveuser } from './redux/actions';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { UserSavedI } from 'routes/users/redux/reducers';
import { Permission, User } from 'types/type';

interface DispatchProps {
  getUserAction: (id: string) => void;
  saveuser: (user: User) => void;
}

interface HomeRouterProps {
  id: string;
}

interface StateProps {
  user: UserSavedI;
  permissionList: Permission[];
}

type Props = FormComponentProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<HomeRouterProps>;

class CompanyFormComponent extends Component<Props> {
 
  componentDidMount(){
    const { id } = this.props.match.params;
    this.props.getUserAction(id);
  }

  handleOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { form, user: { usersaved } } = this.props;
    form.validateFields(
      (err, values): void => {
        if (!err) {
          this.props.saveuser({ ...usersaved, ...values, isClient: true });
          this.props.getUserAction(usersaved.id);
          form.resetFields();
        }
      },
    )
  }

  public render = (): JSX.Element => {
    const { form : { getFieldDecorator }, user: { usersaved } } = this.props;
    return (
      <Card>
        <Form onSubmit={this.handleOnSubmit}>
          <Card className={'customer-card'}>
            <Row>
              <Col span={10}>
                <h2 style={{ color: '#296FC4' }}>Datos de usuario</h2>
              </Col>
              <Col span={14}>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label="Nombre/s">
                    {getFieldDecorator('name', {
                      initialValue: usersaved.name || '',
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: 'Ingresa tu nombre',
                        },
                        {
                          max: 100,
                          whitespace: true,
                          message: 'No se permiten mas de 100 caracteres',
                        },
                      ],
                    })(<Input size="large" placeholder="Nombre/s" />)}
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label="Apellido paterno">
                    {getFieldDecorator('lastName', {
                      initialValue: usersaved.lastName || '',
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: 'Ingresa apellido',
                        },
                        {
                          max: 100,
                          whitespace: true,
                          message: 'No se permiten mas de 100 caracteres',
                        },
                      ],
                    })(<Input size="large" placeholder="Apellido paterno" />)}
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label="Apellido Materno">
                    {getFieldDecorator('secondLastName', {
                      initialValue: usersaved.secondLastName || '',
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: 'Ingresa tu apellido materno',
                        },
                        {
                          max: 100,
                          whitespace: true,
                          message: 'No se permiten mas de 100 caracteres',
                        },
                      ],
                    })(<Input size="large" placeholder="Apellido materno" />)}
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label="Correo electróninco">
                    {getFieldDecorator('email', {
                      initialValue: usersaved.email || '',
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: 'Ingresa tu correo',
                        },
                        {
                          max: 100,
                          whitespace: true,
                          message: 'No se permiten mas de 100 caracteres',
                        },
                      ],
                    })(<Input size="large" placeholder="Correo electrónico" />)}
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label="Telefono">
                    {getFieldDecorator('number', {
                      initialValue: usersaved.number || '',
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: 'Ingresa tu numero de telefono',
                        },
                        {
                          max: 10,
                          whitespace: true,
                          message: 'No se permiten mas de 10 caracteres',
                        },
                      ],
                    })(<Input size="large" placeholder="Telefono" />)}
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label="Dirección">
                    {getFieldDecorator('direction', {
                      initialValue: usersaved.direction || '',
                      rules: [
                        {
                          required: false,
                          whitespace: true,
                          message: 'Ingresa tu dirección',
                        },
                        {
                          max: 100,
                          whitespace: true,
                          message: 'No se permiten mas de 100 caracteres',
                        },
                      ],
                    })(<Input size="large" placeholder="Dirección" />)}
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Card>
          {/*<Card className={'customer-card'}>
            <Row>
              <Col span={10}>
                <h2 style={{ color: '#296FC4' }}>
                  Cambio de contraseña
                </h2>
                Aquí puedes definir una nueva contraseña si así lo deseas
              </Col>
              <Col span={14}>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label="Contraseña">
                    {getFieldDecorator('password', {
                      initialValue: '',
                      rules: [
                        {
                          max: 100,
                          whitespace: true,
                          message: 'No se permiten mas de 100 caracteres',
                        },
                        {
                          required: true,
                          message: 'La contraseña es requerida',
                        },
                      ]
                    })(<Input size="large" placeholder="contraseña" />)}
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 23, offset: 1 }}
                  sm={{ span: 23, offset: 1 }}
                  md={{ span: 23, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  <Form.Item hasFeedback label='Repetir contraseña'>
                    {getFieldDecorator('password2', {
                      initialValue: '',
                      rules: [
                        {
                          max: 100,
                          whitespace: true,
                          message: 'No se permiten mas de 100 caracteres',
                        },
                        {
                          required: true,
                          message: 'La confirmacion de la contraseña es requerida',
                        },
                      ]
                    })(<Input size="large" placeholder="contraseña" />)}
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Card>*/}
          <Card className={'customer-card'}>
            <Col span={11} />
            <Col span={6}>
              <Link to="/home">
                <Button id="cancel_button" type="default" block size="large">
                  Cancelar
                </Button>
              </Link>
            </Col>
            <Col span={6} offset={1}>
              <Button
                className="button-customer-serch-style"
                block
                size="large"
                type="primary"
                htmlType="submit"
              >
                Modificar usuario
              </Button>
            </Col>
          </Card>
        </Form>
      </Card>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    user: states.usersState.usersSave,
    permissionList: states.usersState.permissionList,
  };
};

const mapDispatchToProps = {
  getUserAction,
  saveuser,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(CompanyFormComponent));
