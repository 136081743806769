/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 */

import JwtDecode from 'jwt-decode';
import axios from 'axios';
import {isEmpty} from 'lodash';
export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh-token';

export interface Token {
  id: string;
  name: string;
  email: string;
  rfc: string;
  permissions: string[];
  iat: string;
  customerCode: string;
  isClient: boolean;
}

export const getDecodeToken = (): Token  => JwtDecode(getToken() as string) as Token;

export const getToken = (): string | null | undefined =>
  localStorage.getItem(TOKEN_KEY);

/**
 * This function only evaluates if the user is authenticated
 * using the token in the local storage.
 * If the token have expired then this method should invalidate
 * the stored token and return false.
 */
export const isAuthenticated = (): boolean => {
  try {
    if (!isEmpty(getToken())) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

/**
 * This function takes a list of permissions that are required by any
 * given component or screen in the system and verify that the user have
 * all those rights otherwise the function return false.
 */
export const isAllowed = (rights: string[] | undefined): boolean => {
  if (rights === undefined) return true;
  try {
    const token = getDecodeToken();
    return rights.every(right => token.permissions.includes(right));
  } catch (e) {
    return false;
  }
};

export const getRefreshToken = (): string | null =>
  localStorage.getItem(REFRESH_TOKEN_KEY);

export const saveToken = (token: string | undefined): void => {
  removeToken();
  localStorage.setItem(TOKEN_KEY, token || '');
};
export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};

axios.interceptors.request.use(
  conf => {
    const configuration = conf;
    const token = getToken();
    if (token !== null && token !== undefined && token !== '') {
      configuration.headers.Authorization = `Bearer ${token}`;
    }
    return configuration;
  },
  error => {
    return Promise.reject(error);
  },
);
