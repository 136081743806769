export const regimenFiscal = [
  { key: '601', name: 'General de Ley Personas Morales' },
  { key: '603', name: 'Personas Morales con Fines no Lucrativos' },
  { key: '605', name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
  { key: '606', name: 'Arrendamiento' },
  { key: '608', name: 'Demás ingresos' },
  { key: '609', name: 'Consolidación' },
  {
    key: '610',
    name:
      'Residentes en el Extranjero sin Establecimiento Permanente en México',
  },
  { key: '611', name: 'Ingresos por Dividendos (socios y accionistas)' },
  {
    key: '612',
    name: 'Personas Físicas con Actividades Empresariales y Profesionales',
  },
  { key: '614', name: 'Ingresos por intereses' },
  { key: '616', name: 'Sin obligaciones fiscales' },
  {
    key: '620',
    name:
      'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
  },
  { key: '621', name: 'Incorporación Fiscal' },
  {
    key: '622',
    name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
  },
  { key: '623', name: 'Opcional para Grupos de Sociedades' },
  { key: '624', name: 'Coordinados' },
  { key: '626', name: 'Régimen Simplificado de Confianza'},
  { key: '628', name: 'Hidrocarburos' },
  { key: '607', name: 'Régimen de Enajenación o Adquisición de Bienes' },
  {
    key: '629',
    name:
      'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
  },
  { key: '630', name: 'Enajenación de acciones en bolsa de valores' },
  { key: '615', name: 'Régimen de los ingresos por obtención de premios' },
];

export const usoCFDI = [
  { key: 'G01', name: 'Adquisición de mercancías' },
  { key: 'G02', name: 'Devoluciones, descuentos o bonificaciones' },
  { key: 'G03', name: 'Gastos en general' },
  { key: 'I01', name: 'Construcciones' },
  { key: 'I02', name: 'Mobiliario y equipo de oficina por inversiones' },
  { key: 'I03', name: 'Equipo de transporte' },
  { key: 'I04', name: 'Equipo de computo y accesorios' },
  { key: 'I05', name: 'Dados, troqueles, moldes, matrices y herramental' },
  { key: 'I06', name: 'Comunicaciones telefónicas' },
  { key: 'I07', name: 'Comunicaciones satelitales' },
  { key: 'I08', name: 'Otra maquinaria y equipo' },
  { key: 'D01', name: 'Honorarios médicos, dentales y gastos hospitalarios.' },
  { key: 'D02', name: 'Gastos médicos por incapacidad o discapacidad' },
  { key: 'D03', name: 'Gastos funerales.' },
  { key: 'D04', name: 'Donativos.' },
  {
    key: 'D05',
    name:
      'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
  },
  { key: 'D06', name: 'Aportaciones voluntarias al SAR.' },
  { key: 'D07', name: 'Primas por seguros de gastos médicos.' },
  { key: 'D08', name: 'Gastos de transportación escolar obligatoria.' },
  {
    key: 'D09',
    name:
      'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
  },
  { key: 'D10', name: 'Pagos por servicios educativos (colegiaturas)' },
  { key: 'P01', name: 'Por definir' },
];
export const formaPago = [
  { key: '01', name: 'Efectivo' },
  { key: '02', name: 'Cheque nominativo' },
  { key: '03', name: 'Transferencia electrónica de fondos' },
  { key: '04', name: 'Tarjeta de crédito' },
  { key: '05', name: 'Monedero electrónico' },
  { key: '06', name: 'Dinero electrónico' },
  { key: '08', name: 'Vales de despensa' },
  { key: '12', name: 'Dación en pago' },
  { key: '13', name: 'Pago por subrogación' },
  { key: '14', name: 'Pago por consignación' },
  { key: '15', name: 'Condonación' },
  { key: '17', name: 'Compensación' },
  { key: '23', name: 'Novación' },
  { key: '24', name: 'Confusión' },
  { key: '25', name: 'Remisión de deuda' },
  { key: '26', name: 'Prescripción o caducidad' },
  { key: '27', name: 'A satisfacción del acreedor' },
  { key: '28', name: 'Tarjeta de débito' },
  { key: '29', name: 'Tarjeta de servicios' },
  { key: '30', name: 'Aplicación de anticipos' },
  { key: '31', name: 'Intermediario pagos' },
  { key: '99', name: 'Por definir' },
];
