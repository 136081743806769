/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 * Modified by Brandon Leon <brandon@ssf.com.mx>
 *
 * This are the permissions that exist in the application. This are stored in the database
 * and could look as exesive to create a constats file in the front end but this way
 * is easier to mantain the code base because we can track the places where a permission is applied
 * without the need to search for a given string but looking for the usages of the constant.
 */

export class Permissions {
  public static CAN_VIEW_SALES = 'CAN_VIEW_SALES';
  public static CAN_VIEW_COMPANIES = 'CAN_VIEW_COMPANIES';
  public static CAN_VIEW_COMPANY_DETAILS = 'CAN_VIEW_COMPANY_DETAILS';
  public static CAN_CIEW_CUSTOMER_PORTFOLIO = 'CAN_CIEW_CUSTOMER_PORTFOLIO';
  public static CAN_VIEW_USERS = 'CAN_VIEW_USERS';
  public static CAN_CREATE_INVOICES = 'CAN_CREATE_INVOICES';
  public static CAN_VIEW_MY_SALES = 'CAN_VIEW_MY_SALES';
  public static CAN_SET_BILLABLE = 'CAN_SET_BILLABLE';
  public static CAN_VIEW_REVISOR = 'CAN_VIEW_REVISOR';
  public static CAN_CHANGE_CUSTOMER_CODE = 'CAN_CHANGE_CUSTOMER_CODE';
  public static CAN_CHANGE_SALE_TYPE = 'CAN_CHANGE_SALE_TYPE';
  public static CAN_VIEW_LOGS = 'CAN_VIEW_LOGS';
  public static CAN_VIEW_SUPPORT = 'CAN_VIEW_SUPPORT';
}
