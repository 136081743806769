import React from 'react';
import { Redirect } from 'react-router-dom';
import { removeToken } from '../../security/';

class LogOut extends React.PureComponent {
  public state = {
    redirect: false,
  };
  public clicklogout = (): void => {
    removeToken();
    this.setState({
      redirect: true,
    });
  };

  public componentDidMount(){
    removeToken();
    window.location.reload();
  }

  public render = (): React.ReactNode => {
    return <Redirect to="/login" />;
  };
}

export default LogOut;
