/* 
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 * 
 * @Author: Brandon Leon 
 * @Date: 2020-10-13 17:39:50 
 * @Last Modified by: Brandon Leon
 * @Last Modified time: 2020-11-13 23:29:23
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Spin, DatePicker, Input, InputNumber, Button, Radio } from 'antd';
import { RootState } from 'store/configureStore';
import moment, { Moment } from 'moment';
import { setInitDateRevisor, getSalestotalByDate, initProcess, getRevisorExcel } from './redux/actions'
import { RevisionTotales } from 'types/type';
import { arrayRevisorFormat } from 'commons/regularExpressions';
import { isEmpty } from 'lodash';
import { Popups } from 'commons/components/popups/popups';
import { ExportOutlined, FileExcelOutlined } from '@ant-design/icons';
import './style.css';

interface ChildComponentProps {
    onClose: Function;
}

interface DispatchProps {
    setInitDateRevisor: (date: Date) => void;
    getSalestotalByDate: (initDate: Date, endDate: Date, useCut: boolean) => void;
    initProcess: (litros: number, total: number, litros_d: number, total_d: number, litros_an: number, total_an: number, initDate: Date, endDate: Date, csv: string, percent: number, useCut: boolean) => void;
    getRevisorExcel: (dates: { initDate: Date, endDate: Date }) => void;
}
interface RevisionProps {
    isFetching: boolean;
    initialDate: Moment;
    totals: RevisionTotales;
}
type Props = RevisionProps & DispatchProps & ChildComponentProps;

class Revisor extends Component<Props> {
    state = {
        litters: 0.00,
        total: 0.00,
        csv: '10,15,20,25,30',
        percent: 0,
        ok: true,
        useCut: false,
    }

    componentWillMount() {
        this.findByDate(this.props.initialDate);
    }

    findByDate(date: Moment) {
        const dates = this.getDates(date);
        this.props.setInitDateRevisor(moment(date).toDate());
        this.props.getSalestotalByDate(dates.initDate, dates.endDate, this.state.useCut);
        this.setState({ litters: '0', total: '0', percent: 0 });
    }

    getDates(date: Moment) {
        const initDate = moment(date).hours(0).minutes(0).seconds(0).toDate();
        const endDate = moment(date).hours(23).minutes(59).seconds(59).toDate();
        return { initDate, endDate };
    }

    formatNumber = (num: number = 0.00, decimals: number = 2): string =>
        Number(num.toFixed(decimals)).toLocaleString(undefined, {
            maximumFractionDigits: decimals,
        });

    calculateMaxPercent = (total: number, half: number): number => {
        if (total === 0) return 0;
        return (half * 100) / total;
    }

    calculate20Percent = (total: number, half: number): number => {
        const twentyPercent = half - (20 * half) / 100;
        return this.calculateMaxPercent(total, twentyPercent);
    }

    calculatePercent = (total: number, litters: number, percent: number): void => {
        const totalC = (percent * total) / 100;
        const littersC = (percent * litters) / 100
        this.setState({ total: totalC, litters: littersC, percent });
    }

    checkCsvValidation = (csv: string) => {
        if (arrayRevisorFormat.test(csv)) this.setState({ csv, ok: true });
        else { this.setState({ ok: false }); Popups.notifyError("Formato de litros incorrecto"); }
    }

    setUseCut = (value: boolean) => {
        this.setState({ useCut: value });
        const dates = this.getDates(this.props.initialDate);
        this.props.getSalestotalByDate(dates.initDate, dates.endDate, value);
        this.setState({ litters: '0', total: '0', percent: 0 });
    }

    public render = (): JSX.Element => {
        const { isFetching, initialDate, totals } = this.props;
        let maxPercent = this.calculateMaxPercent(totals.total_day, totals.half_day);
        let maxLess20Percent = this.calculate20Percent(totals.total_day, totals.half_day);
        return (
            <Spin tip="Cargando..." spinning={isFetching} style={{ height: '100%' }}>
                <Card style={{ height: '100%' }}>
                    <Row gutter={8}>
                        <Col span={8}>
                            {!totals.readed && <label>Selecciona una Fecha</label>}
                            {totals.readed && <label>Fecha Aplicada</label>}
                            <br />
                            <DatePicker
                                size="small"
                                defaultValue={moment(initialDate)}
                                onChange={(date: Moment | null) => date !== null ? this.findByDate(date) : null}
                                disabledDate={(s: Moment | null) => moment(s).isAfter(moment())}
                            />
                            <button
                                type="button"
                                className="link-button"
                                onClick={() => this.props.getRevisorExcel(this.getDates(initialDate))}
                            >
                                Reporte Revisor <FileExcelOutlined /> <ExportOutlined />
                            </button>
                        </Col>
                        <Col span={6} >
                            <label>{totals.readed ? "P" : "Seleccionar p"}armetro de Retiro</label>
                            {!totals.readed && <Radio.Group
                                disabled={totals.readed}
                                onChange={e => this.setUseCut(e.target.value)}
                                value={totals.readed ? totals.useCut : this.state.useCut}>
                                <Radio disabled={totals.totalCortes < 1} value={true}>{totals.totalCortes || 0} Cortes del dia</Radio>
                                <Radio value={false}>Dia natural</Radio>
                            </Radio.Group>}
                            {totals.readed && <br/>}
                            {totals.readed && (totals.useCut ? (totals.totalCortes || 0) + " Cortes del dia" : "Dia natural")}
                        </Col>
                        <Col span={totals.readed ? 8 : 4}>
                            <label>Ventas del dia</label>
                            <br />
                            <Input disabled value={totals.total_ventas} />
                        </Col>
                        <Col span={4} hidden={totals.readed}>
                            <label>Ventas aplicables</label>
                            <br />
                            <Input disabled value={totals.total_ventas_ret} />
                        </Col>
                    </Row>
                    <Card className={'customer-card'}>
                        <Row gutter={8}>
                            <Col span={10}>
                                {this.state.useCut ? <label>Corte total del dia</label> :<label>Venta total del dia</label>}
                                <br />
                                <Input disabled value={'$' + this.formatNumber(totals.total_day)} />
                            </Col>
                            <Col span={10} offset={2}>
                                {this.state.useCut ? <label>Cortes en Efectivo que no requieren factura y no estan facturados</label> : <label>Ventas en Efectivo que no requieren factura y no estan facturadas</label>}
                                <br />
                                <Input disabled value={'$' + this.formatNumber(totals.half_day)} />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={10}>
                                <label>Litros vendidos en el dia</label>
                                <br />
                                <Input disabled value={this.formatNumber(totals.litters_day) + ' L'} />
                            </Col>
                            <Col span={10} offset={2}>
                                <label>Litros en Efectivo no requiere factura y no esta facturado</label>
                                <br />
                                <Input disabled value={this.formatNumber(totals.half_litters) + ' L'} />
                            </Col>
                        </Row>
                    </Card>
                    <Card className={'customer-card'}>
                        <Row gutter={8}>
                            <Col span={10}>
                                {totals.readed ? <label>Porcentaje aplicado</label> : <label>Porcentaje</label>}
                                {!totals.readed && <br />}
                                {!totals.readed &&
                                    <label>
                                        Recomendado {this.formatNumber(maxLess20Percent)}% - Maximo (No recomendado) {this.formatNumber(maxPercent, 3)}%
                                    </label>
                                }
                                <br />
                                <InputNumber
                                    disabled={totals.readed}
                                    value={totals.readed ? totals.percent : this.state.percent}
                                    style={{ width: '100%' }}
                                    min={0}
                                    max={Number(maxPercent.toFixed(3))}
                                    onChange={(value: number | undefined) => this.calculatePercent(totals.total_day, totals.litters_day, value || 0)} />
                            </Col>
                            <Col span={10} offset={2}>
                                {!totals.readed && <br />}
                                {!totals.readed && <label>Litros a dejar por venta (numeros enteros separados por coma)</label>}
                                {totals.readed && <label>Litros dejados por venta</label>}
                                <Input disabled={totals.readed} defaultValue={!isEmpty(totals.csv) ? totals.csv.replace('{', '').replace('}', '') : this.state.csv} onChange={(e) => this.checkCsvValidation(e.target.value)} />
                            </Col>
                        </Row>
                    </Card>
                    <Card className={'customer-card'}>
                        <Row gutter={8}>
                            <Col span={10}>
                                {totals.readed && <label>Total retirado</label>}
                                {!totals.readed && <label>Total aproximado a retirar</label>}
                                <br />
                                <Input disabled value={'$' + this.formatNumber(totals.readed ? totals.total_ret : this.state.total | 0, 2)} />
                            </Col>
                            <Col span={10} offset={2}>
                                {totals.readed && <label>Litros retirados</label>}
                                {!totals.readed && <label>Litros aproximado a retirar</label>}
                                <br />
                                <Input disabled value={this.formatNumber(totals.readed ? totals.litters_ret : this.state.litters | 0, 2) + ' L'} />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={10}>
                                <Button
                                    disabled={!(totals.total_day > 0) || !this.state.ok || this.state.percent < 1}
                                    hidden={totals.readed}
                                    onClick={() => {
                                        const dates = this.getDates(this.props.initialDate);
                                        const { litters, total, csv, percent, useCut } = this.state;
                                        this.props.initProcess(
                                            litters,
                                            total,
                                            totals.litters_day,
                                            totals.total_day,
                                            totals.half_litters,
                                            totals.half_day,
                                            dates.initDate,
                                            dates.endDate,
                                            csv,
                                            percent,
                                            useCut
                                        );
                                    }}
                                >
                                    Aplicar
                                </Button>
                            </Col>
                            <Col span={10} offset={2}>
                                <Button onClick={this.props.onClose()} >Salir modo revisor</Button>
                            </Col>
                        </Row>
                    </Card>
                </Card>
            </Spin>
        );
    };
}

const mapStateToProps = (states: RootState): RevisionProps => {
    return {
        isFetching: states.revisorSate.isFetching,
        initialDate: states.revisorSate.initDateRevisor,
        totals: states.revisorSate.totals,
    };
};

const mapDispatchToProps = {
    setInitDateRevisor,
    getSalestotalByDate,
    initProcess,
    getRevisorExcel,
};

export default connect<RevisionProps, DispatchProps, {}, RootState>(
    mapStateToProps,
    mapDispatchToProps,
)(Revisor);
