import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { Corte } from 'types/type';
import moment from 'moment';

class CortesService extends Service {
  public getCortes = (
    equipmentIds : Array<string> | undefined,
    initDate: Date,
    endDate : Date
  ): Promise<Array<Corte>> =>
    axios
      .get(createCoreServiceUrl(
        'cuts?' + (equipmentIds && equipmentIds !== undefined ? `&equipmentIds=${equipmentIds}` : '') +
        `&initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
        `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}`
      ))
      .then((response: AxiosResponse<Array<Corte>>): Array<Corte> => response.data);
}

export default CortesService;
