import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from 'store/configureStore';
import ConfigService from '../../../services/config/index';
import { Popups } from 'commons/components/popups/popups';
import { parseApiError } from 'commons/error';
import { SystemConfig, SystemConfigInvoice, Settings } from 'types/type';

const configService = new ConfigService();


type ITA = ThunkAction<Promise<void>, RootState, {}, AnyAction>;
type IDA = ThunkDispatch<{}, {}, AnyAction>;

const startFetch = (): { type: string } => {
  return { type: 'BEGIN_FETCH' };
};

const endFetch = (): { type: string } => {
  return { type: 'FETCH_COMPLETED' };
};

export const getDayToDelete = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const days: number = await configService.getDaysToDelete();
    dispatch({ type: 'DAYS_TO_DELETE', days });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getMonthToDelete = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const month: number = await configService.getMonthsToDelete();
    dispatch({ type: 'MONTHS_TO_DELETE', month });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getYearToDelete = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const years: number = await configService.getYearsToDelete();
    dispatch({ type: 'YEARS_TO_DELETE', years });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getDayToDeleteInvoice = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const daysInvoice: number = await configService.getDaysToDeleteInvoice();
    dispatch({ type: 'DAYS_TO_DELETE_INVOICE', daysInvoice });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getMonthToDeleteInvoice = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const monthsInvoice: number = await configService.getMonthsToDeleteInvoice();
    dispatch({ type: 'MONTHS_TO_DELETE_INVOICE', monthsInvoice });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getYearToDeleteInvoice = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const yearsInvoice: number = await configService.getYearsToDeleteInvoice();
    dispatch({ type: 'YEARS_TO_DELETE_INVOICE', yearsInvoice });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getSystemConfig = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const systemConfig: SystemConfig = await configService.getSystemConfig();
    dispatch({ type: 'SYS_CONF', systemConfig });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getSystemConfigInvoice = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const systemConfigInvoice: SystemConfigInvoice = await configService.getSystemConfigInvoice();
    dispatch({ type: 'SYS_CONF_INV', systemConfigInvoice });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}
export const getSystemSettings = (): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch())
    const settings: Settings = await configService.getSystemSettings();
    dispatch({ type: 'SYS_SETTINGS', settings });
    dispatch(endFetch());
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}

export const getPrivacyUrl = async (): Promise<Settings|undefined> => {
  try {
    return configService.getSystemSettings();
  }
  catch (err) {
    Popups.notifyException(parseApiError(err));
  }
}

export const saveNewConfigurations = (
  systemConfig: SystemConfig,
  systemConfigInvoice: SystemConfigInvoice,
  settings: Settings,
  days: number,
  months: number,
  years: number,
  daysInvoice: number,
  monthsInvoice: number,
  yearsInvoice: number,
): ITA => async (dispatch: IDA): Promise<void> => {
  try {
    dispatch(startFetch());
    await configService.saveNewConfigurations(systemConfig, systemConfigInvoice, settings, days, months, years, daysInvoice, monthsInvoice, yearsInvoice);
    dispatch(endFetch());
    Popups.notifySuccess("Las nuevas configuraciones se han guardado y seran usadas al momento");
  }
  catch (err) {
    dispatch(endFetch());
    Popups.notifyException(parseApiError(err));
  }
}