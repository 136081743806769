/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by David Canul <david@ssf.com.mx>, Jun 2019
 *
 * This component lists all the sales crawled from g4s
 */
import React, { Component } from 'react';
import {
  Row,
  Pagination,
  Col,
  DatePicker,
  Table,
  Divider,
  Spin,
  Select,
  Button,
  Modal,
  Form,
  Card,
  Input,
  Descriptions,
  Tag,
} from 'antd';
import { CloseCircleOutlined, ExportOutlined, FileSearchOutlined } from '@ant-design/icons';
import {
  Sale,
  SaleSelected,
  Invoice,
  Email,
  FiscalData,
} from 'types/type';
import { connect } from 'react-redux';
import { RootState } from 'store/configureStore';
import { SaleList } from './redux/reducers';
import {
  alreadyInvoice,
  changeBillable,
  changePaymentType,
  changeToBill,
  downloadFile,
  invoiceAll,
  page,
  setEndDate,
  setInitDate,
  cleanSales,
} from './redux/actions';
import { getSaleByIus, createExcelByFilters } from '../../sales/redux/actions'
import { getFiscalData } from '../invoiceUser/redux/actions'
import {
  addSaleToStagingArea,
  cancelInvoice,
  InvoiceActionConstructors,
  removeSaleToStagingArea,
  resendInvoce,
  saveInvoce,
} from '../../invoices/redux/actions';
import moment, { Moment } from 'moment';
import Text from 'antd/lib/typography/Text';
import { Permissions as P } from 'commons/permissions';
import { isAllowed, getDecodeToken } from 'security/index';
import { SelectValue } from 'antd/lib/select';
import { isEmpty } from 'lodash';
import { InvoiceState } from 'routes/invoices/redux/reducers';
import FiscalDataForm from '../../login/FiscalData/fiscalDataForm';
import { InvoiceRules as r } from '../../login/FiscalData/rulesFiscalData';
import { FormComponentProps } from 'antd/lib/form';
import { initInvoice } from 'commons/initTypes';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Popups } from 'commons/components/popups/popups';
import { Strings as i8n } from 'commons/strings';
import { Redirect } from 'react-router';
import MaskedInput from 'react-text-mask';
import './style.css';

interface DispatchProps {
  page: (
    customer: string,
    initDate: Date,
    endDate: Date,
    filter: string,
    pagePosition: number,
    orderDate: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    userId: string,
    rfc: string,
    orderRFC: string
  ) => void;
  /* getExcel: (
    customer: string,
    initDate: Date,
    endDate: Date,
    orderDate: string,
    filter: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    userId: string,
    rfc: string,
    orderRFC: string
  ) => void; */
  downloadFile: (id: string, name: string, saleFolio: string, showFile: boolean) => void;
  changeBillable: (id: string, billable: boolean) => void;
  changePaymentType: (id: string, paymentType: string) => void;
  changeToBill: (
    id: string,
    toBill: boolean,
    total: number,
    totalVolume: number,
  ) => void;
  resendInvoce: (id: string) => void;
  cancelInvoice: (id: string) => void;
  setInitDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  alreadyInvoice: (id: string) => void;
  invoiceAll: () => void;
  cleanSales: () => void;
  getFiscalData: (userId: string) => void;
  getSaleByIus: (ius: string) => void;
  createExcelByFilters: (
    initDate: Date,
    endDate: Date,
    orderDate: string,
    filter: string,
    allowBillable: boolean,
    distributionCenter: string,
    requiredInvoice: string,
    serviceNumberId: string,
    equipmentId: Array<string>,
    formaPago: string,
    rfc: string,
    orderRFC: string,
    cutFolio: Array<string>,

    userId: string,
    reportType: string,
    emailToSend: string,
    isAdmin: boolean,
  ) => void;
}
interface StateProps {
  salePage: SaleList;
  isFetching: boolean;
  initialDate: Moment;
  endedDate: Moment;
  multicenter: boolean;
  sumTotalLK: number;
  sumTotalM: number;
  sumTotalLKBillable: number;
  sumTotalMBillable: number;
  sumTotalLKSelected: number;
  sumTotalMSelected: number;
  saleSelectedList: SaleSelected[];
  salesStagingArea: Sale[];
  paymentChange: boolean;
  totalElementsBillable: number;
  totalElementsSelected: number;
  fiscalDataList: FiscalData[];
}

type Props = StateProps &
  FormComponentProps &
  InvoiceState &
  DispatchProps &
  InvoiceActionConstructors;

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const hexRegex = /[0-9a-fA-F]/;
const iusFragment = [hexRegex, hexRegex, hexRegex, hexRegex];

const VIEW_BILLABLES_SALES = isAllowed([P.CAN_SET_BILLABLE]);

class SalesListComponent extends Component<Props> {
  public state = {
    pagePosition: 1,
    invoiceStatus: 'SALE_INVOICE',
    unitType: 'L',
    endOpen: false,
    orderDate: 'DESC',
    dc: '00000000-0000-0000-0000-000000000000',
    invoiceRequired: 'ALL',
    showModal: false,
    invoice: initInvoice,
    rfc: 'ALL',
    orderRFC: 'DESC',
    IUS: "",

    emptyArray: [],

    excelModal: false,
    excelReportType: "VENTAS",
    excelEmail: "",
  };

  private getInitialTime = (): Moment => moment(this.props.initialDate);

  private getEndTime = (): Moment => moment(this.props.endedDate);

  /**
   * This function execute when the component is mounting and get the first page from
   * sales
   */
  public componentWillMount(): void {
    this.chargeData();
  }

  public componentWillUnmount(): void {
    this.props.cleanSales();
  }

  public async chargeData() {
    const id = await getDecodeToken().id;
    this.setState({ excelEmail: getDecodeToken().email });
    await this.props.page(
      '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      id,
      this.state.rfc,
      this.state.orderRFC
    );
    this.props.getFiscalData(id);
    this.props.saleSelectedList.forEach((sale: SaleSelected) => this.props.changeToBill(sale.id, false, sale.total, sale.totalVolume));
  }

  /**
   * This function change page and replaces current position in the pagination
   */
  public handlePaginationChange = (currentPage: number): void => {
    this.props.page(
      '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      currentPage,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      getDecodeToken().id,
      this.state.rfc,
      this.state.orderRFC
    );
    this.setState({ valor: currentPage });
  };

  /**
   * This function called when a date picker (start or end) changes.
   */
  public onDatePickerChange = (
    startDateValue: Moment,
    endDateValue: Moment,
  ): void => {
    if (startDateValue && endDateValue) {
      const { invoiceStatus, pagePosition } = this.state;
      this.props.setInitDate(startDateValue.toDate());
      this.props.setEndDate(endDateValue.toDate());
      this.props.page(
        '',
        startDateValue.toDate(),
        endDateValue.toDate(),
        invoiceStatus,
        pagePosition,
        this.state.orderDate,
        VIEW_BILLABLES_SALES,
        this.state.dc,
        this.state.invoiceRequired,
        getDecodeToken().id,
        this.state.rfc,
        this.state.orderRFC
      );
    }
    this.setState({ IUS: "" });
  };

  /**this function change radio element  and find status on the db from state element*/
  public onChangeRadio = (value: SelectValue): void => {
    this.setState({
      invoiceStatus: value,
      IUS: "",
    });
    this.props.page(
      '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      value.toString(),
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      getDecodeToken().id,
      this.state.rfc,
      this.state.orderRFC
    );
  };

  public onChangeOrderDate = (value: SelectValue): void => {
    this.setState({
      orderDate: value.toString(),
      IUS: "",
    });
    this.props.page(
      '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      value.toString(),
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      getDecodeToken().id,
      this.state.rfc,
      this.state.orderRFC
    );
  };

  public onChangeRequiredInvoice = (value: SelectValue): void => {
    this.setState({
      invoiceRequired: value,
      IUS: "",
    });
    this.props.page(
      '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      value.toString(),
      getDecodeToken().id,
      this.state.rfc,
      this.state.orderRFC
    );
  };

  public onChangeRfc = (value: SelectValue): void => {
    value = value.toString().split(',')[1]
    this.setState({
      rfc: (value)? value.toString(): 'ALL',
      IUS: "",
    });
    this.props.page(
      '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      getDecodeToken().id,
      (value)? value.toString() : 'ALL',
      this.state.orderRFC
    );
  };

  public onChangeRfcOrder = (value: SelectValue): void => {
    this.setState({
      orderRFC: value,
      IUS: "",
    });
    this.props.page(
      '',
      this.getInitialTime().toDate(),
      this.getEndTime().toDate(),
      this.state.invoiceStatus,
      this.state.pagePosition,
      this.state.orderDate,
      VIEW_BILLABLES_SALES,
      this.state.dc,
      this.state.invoiceRequired,
      getDecodeToken().id,
      this.state.rfc,
      value.toString()
    );
  };

  /**
   * This function render elements from the filters
   */
  private renderFilters = (): JSX.Element => {
    return (
      <Row
        style={{
          backgroundColor: '#F1F7FF',
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 23,
          paddingBottom: 23,
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={13} lg={11} xl={8} xxl={6} style={{ paddingLeft: 24, paddingRight: 24 }}>
            <Row>
              <label className="label">{
                'Consultar período de venta'
              }</label>
            </Row>
            <Row>
              <DatePicker.RangePicker
                defaultValue={[
                  moment(this.props.initialDate),
                  moment(this.props.endedDate),
                ]}
                showTime
                format={DATE_FORMAT}
                onOk={(dates: any): void => {
                  this.onDatePickerChange(dates[0], dates[1]);
                  this.props.saleSelectedList.forEach((sale: SaleSelected) => this.props.changeToBill(sale.id, false, sale.total, sale.totalVolume));
                }}
              />
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={9}
            xl={6}
            xxl={6}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <label className="label">{'Estatus de facturación'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue="SALE_INVOICE"
              onChange={(value: SelectValue): void => {
                this.onChangeRadio(value);
                this.props.saleSelectedList.forEach((sale: SaleSelected) => this.props.changeToBill(sale.id, false, sale.total, sale.totalVolume));
              }}
              style={{ width: '100%' }}
            >
              <Select.Option value={'SALE_INVOICE'}>Todo</Select.Option>
              <Select.Option value={'NOT_INVOICE'}>Ventas</Select.Option>
              <Select.Option value={'INVOICE'}>Facturas</Select.Option>
              <Select.Option value={'IN_PROGRESS'}>Ventas en proceso de facturación</Select.Option>
              <Select.Option value={'ERROR'}>Facturas fallidas</Select.Option>
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={5}
            xxl={5}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <label className="label">{'Mostrar cantidad como:'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue="L"
              onChange={(value: SelectValue): void =>
                this.setState({ unitType: value.toString() })
              }
              style={{ width: '100%' }}
            >
              <Select.Option value={'L'}>Litros</Select.Option>
              <Select.Option value={'K'}>Kilos</Select.Option>
            </Select>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={5}
            xxl={5}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <label className="label">{'RFC:'}</label>
            <Select
              defaultActiveFirstOption
              defaultValue="ALL"
              showSearch
              onChange={(value: SelectValue): void => this.onChangeRfc(value)}
              style={{ width: '100%' }}
            >
              <Select.Option value={'ALL'}>Todos</Select.Option>
              {this.props.fiscalDataList.map(fs =>
                <Select.Option value={fs.id+',' + fs.rfc}>{fs.rfc}</Select.Option>
              )}
            </Select>
          </Col>
        </Row>
      </Row >
    );
  };

  /**
   * Third step of the component wizard. Request email and create the
   * invoice.
   */
  private renderEmailForm = (): JSX.Element => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form>
        <Col span={24}>
          <p style={{ marginTop: 20, marginBottom: 20 }}>
            Ingresa el correo electronico donde quieres que te enviemos la
            factura
          </p>
          <Card
            className={'card-invoice-content'}
            style={{
              backgroundColor: '#f1f7ff',
              margin: '0 auto',
              maxWidth: '30rem',
            }}
          >
            <Form.Item hasFeedback label="Correo Electrónico">
              {getFieldDecorator('email', {
                rules: r.emailRule,
              })(<Input size="large" placeholder="Correo electrónico" />)}
            </Form.Item>
          </Card>
        </Col>
      </Form>
    );
  };

  renderCustomButton = (form: WrappedFormUtils<Invoice>): JSX.Element => (
    <Button
      size="large"
      type="primary"
      disabled={this.props.isFetching}
      onClick={() => this.onRequestInvoice(form)}
    >
      {this.props.isFetching ? 'Loading...' : 'Facturar'}
    </Button>
  );

  /**
   * This function is called when the button invoicing is
   * called. Is the final step.
   */
  private onRequestInvoice = (form: WrappedFormUtils<Invoice>): void => {
    form.validateFields(
      async (err, values: Invoice): Promise<void> => {
        if (err) {
          Promise.resolve();
        } else {
          this.setState({ invoice: { ...values } });
          const invoice = { ...values };
          this.props.form.validateFields(
            async (err, fields: Email): Promise<void> => {
              !Boolean((err || {}).email) &&
                Popups.executeOrNotifyError(
                  (): Promise<void> => {
                    this.createInvoice(
                      this.props.salesStagingArea,
                      fields,
                      invoice,
                    );
                    form.resetFields();
                    this.setState({ invoice: initInvoice });
                    return Promise.resolve();
                  },
                );
            },
          );
        }
      },
    );
  };

  private createInvoice = async (
    salesStagingArea: Sale[],
    fields: Email,
    invoice: Invoice,
  ): Promise<void> => {
    const Invoice = {
      ...invoice,
      email: fields.email,
      sales: salesStagingArea,
      fiscalRegime: '',
    };
    await this.props.saveInvoce(Invoice);
    this.props.addSaleToStagingArea('0', 0, true);
    Popups.notifySuccess(i8n.INVOICE_CREATED);
    this.setState({ showModal: !this.state.showModal });
    this.chargeData();
  };

  public renderCancelButton = (): JSX.Element => (
    <Button
      type="ghost"
      onClick={() => {
        this.setState({ showModal: !this.state.showModal });
        this.props.removeSaleToStagingArea(this.props.salesStagingArea[0]);
      }}
    >
      Cancelar <CloseCircleOutlined />
    </Button>
  );

  public getPaymentConditionType = (paymentCondition: string): string => {
    switch (paymentCondition) {
      case 'CASH':
        return 'Contado';
      case 'CREDIT':
        return 'Crédito';
      default:
        return 'vales';
    }
  };

  public sumaSelectSales = (sumaSelectSales: SaleSelected[]): number => {
    let sum = 0;
    sumaSelectSales.forEach(s => (sum = sum + s.total));
    sum = this.props.sumTotalMBillable - sum;
    return sum;
  };

  public sumaSelectSalesLK = (sumaSelectSales: SaleSelected[]): number => {
    let sum = 0;
    sumaSelectSales.forEach(s => (sum = sum + s.totalVolume));
    sum = this.props.sumTotalLKBillable - sum;
    return sum;
  };

  public formatNumber = (number: number): string =>
    Number(number.toFixed(2)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });

  public handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (filters.endDate) {
      if (filters.endDate.length > 0 && filters.endDate[0] !== this.state.orderDate) {
        this.onChangeOrderDate(filters.endDate[0]);
      } else if (filters.endDate.length < 1) {
        this.onChangeOrderDate(this.state.orderDate);
      }
    }
    if (filters.invoiceRfc) {
      if (filters.invoiceRfc.length > 0 && filters.invoiceRfc[0] !== this.state.orderRFC) {
        this.onChangeRfcOrder(filters.invoiceRfc[0])
      } else if (filters.invoiceRfc < 1) {
        this.onChangeRfcOrder(this.state.orderRFC)
      }
    }
  };

  public render = (): React.ReactNode => {
    const {
      salePage,
      paymentChange,
    } = this.props;
    const { form: { getFieldDecorator }, } = this.props;
    let content = salePage.content;
    if (!getDecodeToken().isClient) return <Redirect to="/sales" />
    return (
      <Spin tip="Cargando..." spinning={this.props.isFetching}>
        <Modal
          title="Agregar datos fiscales"
          visible={this.state.showModal}
          width="100rem"
          centered
          destroyOnClose
          style={{ paddingTop: '0px' }}
          closable={false}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
        >
          <FiscalDataForm
            showFiscalDataProvidedNext={false}
            showFiscalDataProvidedPrev={false}
            isFetching={this.props.isFetching}
            invoice={this.state.invoice}
            customChildren={this.renderEmailForm}
            customButton={this.renderCustomButton}
            renderCancelButton={this.renderCancelButton}
          />
        </Modal>
        <Form>
          <Modal
            title={`Generación de Reporte Excel | ${this.state.excelReportType}`}
            visible={this.state.excelModal}
            width='60%'
            style={{ paddingTop: '0px' }}
            closable={false}
            cancelButtonProps={{ hidden: true }}
            okButtonProps={{ hidden: true }}
          >
            <Row>
              <p>El reporte incluirá las {this.state.excelReportType} desde la fecha {moment(this.props.initialDate).format(DATE_FORMAT)} hasta {moment(this.props.endedDate).format(DATE_FORMAT)}</p>
              <p>Se enviara las instrucciones de descarga del documento Excel al correo: {this.state.excelEmail}</p>
              <p>Si lo desea, puede modificar la dirección de correo a la cual se enviará el reporte solicitado.</p>
            </Row>
            <Row>
              <Form.Item>
                {getFieldDecorator("email", {
                  initialValue: this.state.excelEmail,
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "Ingresa un correo electrónico valido",
                    },
                  ],
                })(<Input placeholder="Correo electrónico"  
                      onChange={(output) => {
                        this.setState({ excelEmail: output.target.value });
                      }}/>)}
              </Form.Item>
            </Row>
            <br></br>
            <Row>
              <Col span={4}>
                <Button
                  type='primary'
                  onClick={() => {
                    this.props.createExcelByFilters(
                      this.getInitialTime().toDate(),
                      this.getEndTime().toDate(),
                      this.state.orderDate,
                      this.state.invoiceStatus,
                      VIEW_BILLABLES_SALES,
                      this.state.dc,
                      this.state.invoiceRequired,

                      "",
                      this.state.emptyArray,
                      "",
                      
                    
                      this.state.rfc,
                      this.state.orderRFC,
                      this.state.emptyArray,
                      getDecodeToken().id,

                      this.state.excelReportType,
                      this.state.excelEmail,
                      true
                    );
                    this.setState({ excelModal: !this.state.excelModal });
                  }}
                >
                  Generar Reporte Excel
              </Button>
              </Col>
              <Col span={4} offset={16}>
                <Button
                  type='danger'
                  onClick={() => {
                    this.setState({ excelModal: !this.state.excelModal });
                  }}
                >
                  Cancelar
              </Button>
              </Col>
            </Row>
          </Modal>
        </Form>
        <div className={'content-backgroud'}>
          {this.renderFilters()}
          <Row style={{ marginLeft: 20, marginRight: 20 }}>
            <Row gutter={32} style={{ marginTop: 20 }}>
              {!(!isEmpty(this.state.IUS) && (content !== undefined && content.length === 1)) && <Col span={24}>
                {this.formatNumber(salePage.totalElements || 0)} Resultados
                <button
                  hidden={
                    ((this.state.invoiceStatus)==='NOT_INVOICE' ||
                    (this.state.invoiceStatus)==='SALE_INVOICE' ||
                    (this.state.invoiceStatus)==='INVOICE')
                    ? false: true}
                  type="button"
                  className="link-button"
                  onClickCapture={() =>{
                    if(this.state.invoiceStatus==='SALE_INVOICE'){
                      this.setState({ excelModal: true, excelReportType: "Ventas y Facturas" });
                    }
                    else if(this.state.invoiceStatus==='NOT_INVOICE'){
                      this.setState({ excelModal: true, excelReportType: "Ventas" });
                    }
                    else if(this.state.invoiceStatus==='INVOICE'){
                      this.setState({ excelModal: true, excelReportType: "Ventas Facturadas" });
                    }
                    
                  }}>| Exportar a Excel <ExportOutlined />
                </button>
              </Col>}
              <Col span={24}>
                <Col span={6}>
                  <Descriptions bordered size="small" style={{ background: "white" }}>
                    <Descriptions.Item label="Ventas">
                      {this.formatNumber(salePage.totalElements || 0)}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions bordered size="small" style={{ background: "white" }}>
                    <Descriptions.Item label="Total Ventas" >
                      ${this.formatNumber(this.props.sumTotalM)}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions bordered size="small" style={{ background: "white" }}>
                    <Descriptions.Item label={`Total ${this.state.unitType === 'L' ? 'Litros' : 'Kilos'}`}>
                      {this.state.unitType === 'L'
                        ? `${this.formatNumber(this.props.sumTotalLK)} L`
                        : `${this.formatNumber(this.props.sumTotalLK * 2)} K`}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col
                span={24}
                style={{ backgroundColor: "white" }}
              >
                <Table scroll={{ x: true }} rowKey={(record: Sale) => record.id} dataSource={content} pagination={false} onChange={this.handleTableChange}>
                  <Table.Column
                    title="Fecha Venta"
                    dataIndex="endDate"
                    key="endDate"
                    filteredValue={[this.state.orderDate]}
                    filterMultiple={false}
                    filters={[{ value: 'ASC', text: 'Ascendente' }, { value: 'DESC', text: 'Descendente' }]}
                  />
                  <Table.Column
                    title="Fecha de Factura"
                    dataIndex="invoiceDate"
                    key="invoiceDate"
                  />
                  <Table.Column
                    title="IUS"
                    dataIndex="IUSCode"
                    key="IUSCode"
                    filterIcon={<FileSearchOutlined className="anticon anticon-filter ant-table-filter-selected ant-dropdown-trigger" />}
                    filterDropdown={({ confirm }) =>
                      <Row>
                        <Col span={19}>
                          <MaskedInput
                            mask={[
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                              '-',
                              ...iusFragment,
                            ]}
                            id="ius"
                            showMask
                            onPaste={(e) => { console.log(e.currentTarget.value) }}
                            value={this.state.IUS}
                            style={{ fontSize: '22px' }}
                            className="ant-input ant-input-large"
                            onChangeCapture={(e) => {
                              this.setState({ IUS: e.currentTarget.value.substring(0, 24).toUpperCase() })
                            }}
                          />
                        </Col>
                        <Col span={5}>
                          <Button onClick={
                            () => {
                              this.props.getSaleByIus(this.state.IUS);
                              if (confirm) confirm();
                            }}
                          >Buscar</Button>
                        </Col>
                      </Row>
                    }
                  />
                  <Table.Column
                    title="# Cliente"
                    dataIndex="customerCode"
                    key="customer"
                  />
                  <Table.Column
                    title="Cliente"
                    dataIndex="customerName"
                    key="customerName"
                  />
                  <Table.Column
                    title="RFC"
                    dataIndex="invoiceRfc"
                    key="invoiceRfc"
                    render={(text: string): React.ReactNode => {
                      return (text === 'XAXX010101000'?"":text)
                    }}
                    filteredValue={[this.state.orderRFC]}
                    filterMultiple={false}
                    filters={[{ value: 'ASC', text: 'Ascendente' }, { value: 'DESC', text: 'Descendente' }]}
                  />
                  <Table.Column
                    title="Estatus"
                    key="status"
                    render={(text, row: Sale) => <Tag color={row.billed ? "green" : ""} >{row.status}</Tag>}
                  />
                  <Table.Column
                    title="Tipo de Pago"
                    dataIndex="paymentConditionType"
                    key="tipopago"
                    render={(text: string, row: Sale): JSX.Element => {
                      return !isEmpty(row.invoice) ||
                        row.billed ||
                        !paymentChange ||
                        !VIEW_BILLABLES_SALES ? (
                        <Text>{this.getPaymentConditionType(text)}</Text>
                      ) : (
                        <Select
                          defaultValue={text}
                          style={{ width: '100%' }}
                          onSelect={(value: string) =>
                            this.props.changePaymentType(row.id, value)
                          }
                        >
                          <Select.Option key="1" value="CASH">
                            Contado
                        </Select.Option>
                          <Select.Option key="2" value="CREDIT">
                            Crédito
                        </Select.Option>
                          <Select.Option key="3" value="VOUCHERS" disabled>
                            Vales
                        </Select.Option>
                        </Select>
                      );
                    }}
                  />
                  <Table.Column
                    title={'Cantidad(' + this.state.unitType + ')'}
                    dataIndex="totalVolume"
                    key="totalVolume"
                    render={(text: string): React.ReactNode => {
                      return this.state.unitType === 'L'
                        ? this.formatNumber(Number(text))
                        : this.formatNumber(Number(text) * 2);
                    }}
                  />
                  <Table.Column title="Monto" dataIndex="total" key="total" render={(row: string) => <Text>{'$' + this.formatNumber(Number(row))}</Text>} />
                  <Table.Column
                    title="Acciones"
                    dataIndex="invoice"
                    key="invoice"
                    render={(text: string, record: Sale): React.ReactNode => {
                      return !isEmpty(record.invoice) ? (
                        record.billed ? (
                          <span>
                            <Row>
                              <button
                                type="button"
                                className="link-button"
                                onClickCapture={() => this.props.resendInvoce(record.id)}
                              >
                                Reenviar factura
                            </button>
                            </Row>
                            <Row>
                              <button
                                type="button"
                                className="link-button"
                                onClickCapture={() =>
                                  this.props.downloadFile(
                                    record.id,
                                    'file.pdf',
                                    record.IUSCode,
                                    false,
                                  )
                                }
                              >
                                PDF
                            </button>
                              <Divider type="vertical" />
                              <button
                                type="button"
                                className="link-button"
                                onClickCapture={() =>
                                  this.props.downloadFile(
                                    record.id,
                                    'file.xml',
                                    record.IUSCode,
                                    false
                                  )
                                }
                              >
                                XML
                            </button>
                            </Row>
                            {/*isCancelable(record) && (
                              <Row>
                                <button
                                  type="button"
                                  className="link-button"
                                  onClick={async () => {
                                    try {
                                      await this.props.cancelInvoice(record.id);
                                    } catch (error) {
                                      message.error(error.message);
                                    }
                                  }}
                                >
                                  Cancelar Factura
                              </button>
                              </Row>
                            )*/}
                          </span>
                        ) : (
                          <Row>
                            <span>Factura en progreso</span>
                          </Row>
                        )
                      ) : (record.billed) ? 
                        <Row>
                          <span>Facturada por otro medio</span>
                        </Row>
                        :  <Row>
                            <span>Sin acciones</span>
                          </Row>
                    }}
                  />
                </Table>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Pagination
                  style={{ flex: 1 }}
                  current={(this.props.salePage.number || 0) + 1}
                  total={this.props.salePage.totalElements}
                  onChange={this.handlePaginationChange}
                />
              </Col>
            </Row>
          </Row>
        </div>
      </Spin>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    salePage: states.saleState.saleList,
    isFetching: states.saleState.isFetching,
    initialDate: states.saleState.initDate,
    endedDate: states.saleState.endDate,
    multicenter: states.companyState.multicenter,
    sumTotalLK: states.saleState.sumTotalLK,
    sumTotalM: states.saleState.sumTotalM,
    sumTotalLKBillable: states.saleState.sumTotalLKBillable,
    sumTotalMBillable: states.saleState.sumTotalMBillable,
    sumTotalLKSelected: states.saleState.sumTotalLKSelected,
    sumTotalMSelected: states.saleState.sumTotalMSelected,
    saleSelectedList: states.saleState.saleSelectedList,
    salesStagingArea: states.invoiceState.salesStagingArea,
    paymentChange: states.saleState.paymentChange,
    totalElementsBillable: states.saleState.totalElementsBillable,
    totalElementsSelected: states.saleState.totalElementsSelected,
    fiscalDataList: states.invoiceUserState.fiscalDataList
  };
};

const mapDispatchToProps = {
  page,
  downloadFile,
  changeBillable,
  changePaymentType,
  changeToBill,
  resendInvoce,
  setInitDate,
  setEndDate,
  alreadyInvoice,
  cancelInvoice,
  invoiceAll,
  addSaleToStagingArea,
  removeSaleToStagingArea,
  saveInvoce,
  cleanSales,
  getFiscalData,
  getSaleByIus,
  createExcelByFilters,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(SalesListComponent));
