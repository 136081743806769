import { AnyAction } from 'redux';
import UserService from '../../../services/user';
import { saveToken } from '../../../security/';
import { parseApiError } from '../../../commons/error';
import { notification } from 'antd';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

const userService = new UserService();
// Action Definition
export interface SetAction {
  type: 'SET';
  accessToken: string;
}

export interface SetcanRegisterAction {
  type: 'SET_CAN_REGISTER';
  canRegistred: boolean
}
// Union Action Types
export type Action = SetAction;
// Action Creators
export const set = (accessToken: string): SetAction => {
  return { type: 'SET', accessToken };
};

export const setCanRegistred = (canRegistred: boolean): SetcanRegisterAction => {
  return { type: 'SET_CAN_REGISTER', canRegistred };
};

export const canRegisterUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response: boolean = await userService.getCanRegisterUser();
      dispatch(setCanRegistred(response));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  }
}

export const login = (
  username: string,
  password: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response: string = await userService.authenticateUser({
        username,
        password,
      });
      saveToken(response);
      dispatch(set(response));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const loginCustomer = (
  username: string,
  password: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    try {
      const response: string = await userService.authenticateCustomer({
        username,
        password,
      });
      saveToken(response);
      dispatch(set(response));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};
