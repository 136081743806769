/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 * Written by Ricardo Sansores <ricardo@ssf.mx>, May 2019
 *
 *
 */

import { combineReducers, Reducer } from 'redux';
import { User, Permission, Customer } from 'types/type';
import { initUser } from 'commons/initTypes';

export interface UsersListI {
  content: User[];
  number?: number;
  totalElements?: number;
}

export interface UserSavedI {
  usersaved: User;
}

export interface UsersState {
  readonly content: User[];
  readonly usersList: UsersListI;
  readonly usersSave: UserSavedI;
  readonly userupdate: {};
  readonly permissionList: Permission[];
  readonly customer: Customer;
}

const updateActiveUser = (state: UsersListI, updatedUser: User): User[] => {
  const contentList = state.content.map(
    (item: User): User => {
      if (item.id === updatedUser.id) {
        return { ...item, isActive: updatedUser.isActive };
      }
      return item;
    },
  );
  return contentList;
};

const usersList: Reducer<UsersListI> = (
  state: UsersListI = { content: [] },
  action,
): UsersListI => {
  switch (action.type) {
    case 'SET':
      return { ...state, ...action.usersList };
    case 'UPDATE':
      const content = updateActiveUser(state, action.userupdate);
      return { ...state, content };
    default:
      return state;
  }
};

const usersSave: Reducer<UserSavedI> = (
  state: UserSavedI = { usersaved: initUser },
  action,
): UserSavedI => {
  switch (action.type) {
    case 'SAVE':
      return { usersaved: { ...state.usersaved, ...action.usersaved } };
    default:
      return state;
  }
};

const permissionList: Reducer<Permission[]> = (
  state: Permission[] = [],
  action,
): Permission[] => {
  switch (action.type) {
    case 'SET_PERMISSION':
      return [state, ...action.permissionList];
    default:
      return state;
  }
};

const customer: Reducer<Customer> = (
  state: Customer | any = {},
  action,
): Customer => {
  switch (action.type) {
    case 'SET_CUSTOMER':
      return { state, ...action.customer };
    default:
      return state;
  }
};

export default combineReducers<UsersState>({
  usersList,
  usersSave,
  permissionList,
  customer,
});
