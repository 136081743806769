/**
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getDayToDelete,
  getMonthToDelete,
  getYearToDelete,
  getDayToDeleteInvoice,
  getMonthToDeleteInvoice,
  getYearToDeleteInvoice,
  getSystemConfig,
  getSystemConfigInvoice,
  getSystemSettings,
  saveNewConfigurations,
} from './redux/actions';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RootState } from 'store/configureStore';
import {
  Card,
  Col,
  Row,
  Spin,
  Switch,
  InputNumber,
  Button,
  Input,
  Modal,
  Slider,
  TimePicker, Select,
} from 'antd';
import './style.css';
import { SystemConfig, SystemConfigInvoice, Settings } from 'types/type';
import moment from 'moment';
import { Popups } from 'commons/components/popups/popups';
import Text from 'antd/lib/typography/Text';

import { LockOutlined } from '@ant-design/icons';

import { getDecodeToken } from 'security';
import { Redirect } from 'react-router';

interface DispatchProps {
  getDayToDelete: () => void;
  getMonthToDelete: () => void;
  getYearToDelete: () => void;
  getDayToDeleteInvoice: () => void;
  getMonthToDeleteInvoice: () => void;
  getYearToDeleteInvoice: () => void;
  getSystemConfig: () => void;
  getSystemSettings: () => void;
  getSystemConfigInvoice: () => void;
  saveNewConfigurations: (
    systemConfig: SystemConfig,
    systemConfigInvoice: SystemConfigInvoice,
    settings: Settings,
    days: number,
    months: number,
    years: number,
    daysInvoice: number,
    monthsInvoice: number,
    yearsInvoice: number,
  ) => void;
}

interface StateProps {
  isFetching: boolean;
  days: Number;
  months: Number;
  years: Number;
  daysInvoice: Number;
  monthsInvoice: Number;
  yearsInvoice: Number;
  systemConfig: SystemConfig;
  systemConfigInvoice: SystemConfigInvoice;
  settings: Settings;
}

interface RangeToHour {
  range: Number;
  value: Number;
}

type Props = FormComponentProps & StateProps & DispatchProps;

const marks = {
  0: '-10 Hrs',
  10: '-9 Hrs',
  20: '-8 Hrs',
  30: '-7 Hrs',
  40: '-6 Hrs',
  50: '-5 Hrs',
  60: '-4 Hrs',
  70: '-3 Hrs',
  80: '-2 Hrs',
  90: '-1 Hr',
  100: {
    style: {
      color: 'red',
    },
    label: <strong>0 Hrs</strong>,
  },
};
const { Option } = Select;

const conversion : RangeToHour[] = [
  {range: 0, value: 10},
  {range:10, value: 9},
  {range:20, value: 8},
  {range:30, value: 7},
  {range:40, value: 6},
  {range:50, value: 5},
  {range:60, value: 4},
  {range:70, value: 3},
  {range:80, value: 2},
  {range:90, value: 1},
  {range:100, value: 0}
];
class Configuration extends Component<Props> {
  public state = {
    configModal: false,
  };

  public componentWillMount(): void {
    this.chargeData();
  }

  public convertPeriodToHour = (value: Number): Number => {
    const result:RangeToHour|undefined = conversion.find(
      (itemrange: RangeToHour)=>itemrange.range === value);
    return result!==undefined? result.value: 0;
  }
  public convertHourToPeriod = (value: String): Number => {
    const result:RangeToHour|undefined = conversion.find(
      (itemrange: RangeToHour)=>itemrange.value === Number(value));
    return result!==undefined? result.range: 0;
  }
  public getMaxOrMinBetweenHours(hourA: Number, hourB: Number, isMax: boolean){
    if(hourA > hourB){
      return (isMax)? hourA: hourB;
    }
    return (isMax)? hourB : hourA;
  }

  private async chargeData() {
    await this.props.getDayToDelete();
    await this.props.getMonthToDelete();
    await this.props.getYearToDelete();
    await this.props.getDayToDeleteInvoice();
    await this.props.getMonthToDeleteInvoice();
    await this.props.getYearToDeleteInvoice();
    await this.props.getSystemConfig();
    await this.props.getSystemConfigInvoice();
    await this.props.getSystemSettings();
  }

  private buildPrivacyUrl = (urlValue: string, preUrlValue : string): string =>{
    if(urlValue==="#" || urlValue===""){
      return "#";
    }
    return preUrlValue.concat(urlValue);
  }

  private handleOnSubmitModal = (): void => {
    this.props.form.validateFieldsAndScroll(
      async (err, values): Promise<void> => {
        console.log(values);
        const systemConfig: SystemConfig = {
          id: 1,
          genericPublicCode: values.genericPublicCode,
          autoInvoice: values.autoInvoice,
          syncSales: values.syncSales,
          g4sPaginationSize: values.g4sPaginationSize,
          syncCuts: values.syncCuts,
          g4sCutsSize: values.g4sCutsSize,
          minTotal: values.minTotal,
          userRegister: values.userRegister,
          changePayment: values.changePayment,
          paymentCodes: values.paymentCodes,
          invoiceCredit: values.invoiceCredit,
          maxConceptsNumber: values.maxConceptsNumber,
        };
        const systemConfigInvoice: SystemConfigInvoice = {
          id: 1,
          maxZipTimeHours: moment(values.maxZipTime).hours(),
          maxZipTimeMinutes: moment(values.maxZipTime).minutes(),
          maxZipSize: values.maxZipSize,
          rfcEmailReject: values.rfcEmailReject,
          emailContact: values.emailContact,
          siteContact: values.siteContact,
        };
        const settings: Settings = {
          timeOutCron: values.cronTimeout,
          timeOutLapse: this.getMaxOrMinBetweenHours(
            this.convertPeriodToHour(values.timeoutPeriod[0]), 
            this.convertPeriodToHour(values.timeoutPeriod[1]),
            true
          ).toString(),
          timeOutSince: this.getMaxOrMinBetweenHours(
            this.convertPeriodToHour(values.timeoutPeriod[0]), 
            this.convertPeriodToHour(values.timeoutPeriod[1]),
            false
          ).toString(),
          privacityUrl : this.buildPrivacyUrl(values.privacityUrl, values.preUrl),
          useG4scredentials: values.useG4scredentials,
          g4sPassword: values.g4sPassword,
          g4sUser: values.g4sUser,
          assignPaymentCode: values.assignPaymentCode,
          useCFDIS: values.useCFDIS,

        };
        if (err !== null) {
          Object.keys(err).forEach((key: any) => {
            Popups.notifyError('Error ' + err[key].errors[0].message);
          });
        } else {
          this.props.saveNewConfigurations(
            systemConfig,
            systemConfigInvoice,
            settings,
            values.days,
            values.months,
            values.years,
            values.daysInvoice,
            values.monthsInvoice,
            values.yearsInvoice,
          );
        }
      },
    );
  };

  public render = (): JSX.Element => {
    if (getDecodeToken().isClient) {
      return <Redirect to="/home" />;
    }
    const {
      isFetching,
      days,
      months,
      years,
      daysInvoice,
      monthsInvoice,
      yearsInvoice,
      systemConfig,
      systemConfigInvoice,
      settings,
      form,
    } = this.props;
    const { getFieldDecorator } = form;
    const selectBefore = (
      <Form.Item className='privacy'>
        {getFieldDecorator('preUrl', {
          initialValue: 'http://',
        })(
        <Select>
          <Option value="http://">http://</Option>
          <Option value="https://">https://</Option>
        </Select>)}
      </Form.Item>);
    return (
      <Spin tip="Cargando..." spinning={isFetching}>
        <Card>
          <Form>
            <Modal
              title={'Guardar configuraciones del sistema'}
              visible={this.state.configModal}
              width="60%"
              style={{ paddingTop: '0px' }}
              closable={false}
              cancelButtonProps={{ hidden: true }}
              okButtonProps={{ hidden: true }}
            >
              <Row>
                <p>
                  Estas configuraciones{' '}
                  <Text style={{ color: 'red' }}>
                    modifican el comportamiento del sistema
                  </Text>{' '}
                  si estas seguro de querer modificarlas presiona aceptar
                </p>
                <p>
                  Si quieres revisarlas antes de modificarlas presiona cancelar
                </p>
              </Row>
              <Row>
                <Col offset={3} span={9}>
                  <Button
                    className="button-customer-serch-style"
                    type="primary"
                    onClick={() => {
                      this.setState({ configModal: !this.state.configModal });
                      this.handleOnSubmitModal();
                    }}
                  >
                    Aceptar
                  </Button>
                </Col>
                <Col offset={5} span={7}>
                  <Button
                    type="danger"
                    onClick={() => {
                      this.setState({ configModal: false });
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Modal>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Configuracion de Importacion ventas y cortes
                  </h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Activar sincronizacion de ventas">
                      {getFieldDecorator('syncSales', {
                        initialValue: systemConfig.syncSales,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Ventas por minuto">
                      {getFieldDecorator('g4sPaginationSize', {
                        initialValue: systemConfig.g4sPaginationSize,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa un minimo de ventas',
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (value < 0) {
                                callback('El valor no puede ser negativo');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      })(<InputNumber size="large" placeholder="Ventas" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Activar sincronizacion de cortes">
                      {getFieldDecorator('syncCuts', {
                        initialValue: systemConfig.syncCuts,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Cortes por minuto">
                      {getFieldDecorator('g4sCutsSize', {
                        initialValue: systemConfig.g4sCutsSize,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa un minimo de cortes',
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (value < 0) {
                                callback('El valor no puede ser negativo');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      })(<InputNumber size="large" placeholder="Cortes" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      hasFeedback
                      label="Codigo cliente en ventas con codigo erroneo"
                    >
                      {getFieldDecorator('genericPublicCode', {
                        initialValue: systemConfig.genericPublicCode,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa un codigo valido en G4S',
                          },
                        ],
                      })(<Input size="large" placeholder="Codigo cliente" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      help="Codigos separados por coma"
                      hasFeedback
                      label="Codigos de ventas internas"
                    >
                      {getFieldDecorator('paymentCodes', {
                        initialValue: systemConfig.paymentCodes,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa los codigos de pago',
                          },
                        ],
                      })(<Input size="large" placeholder="Codigos" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      hasFeedback
                      label="No importar ventas con total menor a"
                    >
                      {getFieldDecorator('minTotal', {
                        initialValue: systemConfig.minTotal,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa un minimo de precio',
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (value < 0) {
                                callback('El total no puede ser negativo');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      })(<InputNumber size="large" placeholder="Total" />)}
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 23, offset: 1 }}
                    xl={{ span: 23, offset: 1 }}
                    xxl={{ span: 23, offset: 1 }}
                  >
                    <Form.Item label={'¿Usar credenciales para exportar ventas?'}>
                      {getFieldDecorator('useG4scredentials', {
                        initialValue: settings.useG4scredentials,
                        valuePropName: 'checked',
                        
                      })(<Switch onChange={ (value) =>{
                        settings.useG4scredentials = value;
                      }} />)}
                    </Form.Item>
                  </Col>
                  {settings.useG4scredentials && 
                  (<Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Usuario">
                      {getFieldDecorator('g4sUser', {
                        initialValue: settings ? settings.g4sUser : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa tu credencial Usuario',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(<Input size="large" placeholder="username" />)}
                    </Form.Item>
                  </Col>)}
                  {settings.useG4scredentials && 
                  (<Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item hasFeedback label="Contraseña">
                      {getFieldDecorator('g4sPassword', {
                        initialValue: settings ? settings.g4sPassword : '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message:
                              'Ingrese su credencial contraseña',
                          },
                          {
                            max: 100,
                            whitespace: true,
                            message: 'No se permiten mas de 100 caracteres',
                          },
                        ],
                      })(
                        <Input.Password
                          prefix={<LockOutlined />}
                          size="large"
                          placeholder="password"
                          type="password"
                        />,
                      )} 
                    </Form.Item>
                  </Col>)}


                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Configuracion de facturacion
                  </h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Activar facturacion Automatica">
                      {getFieldDecorator('autoInvoice', {
                        initialValue: systemConfig.autoInvoice,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Cambiar tipo y forma de pago">
                      {getFieldDecorator('changePayment', {
                        initialValue: systemConfig.changePayment,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Permitir al cliente seleccionar la forma de pago a Facturar">
                      {getFieldDecorator('assignPaymentCode', {
                        initialValue: settings.assignPaymentCode,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  {/* <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Usos de CFDI seleccionables para Facturar">
                      {getFieldDecorator('useCFDIS', {
                        initialValue: settings.useCFDIS.filter(item => item['active']===true).map(item=> item['code']),
                      })(
                        <Select showSearch size="large"  mode="multiple" maxTagCount={2} allowClear
                          onChange={(change)=>{
                            let arr = settings.useCFDIS.filter(item => item['active']===true).map(item=> item['code']);

                            console.log("QUE?", arr);
                            console.log("Change", change);
                          }}
                          >
                          {settings.useCFDIS.map((item: {code:string, description:string}): React.ReactNode=>(
                            <Select.Option key={item.code} value={item.code} title={item.code}>
                            <div id={item.code}>
                              {item.code.concat(" - ").concat(item.description)}
                            </div>
                        </Select.Option>
                        ))}
                      </Select>)}
                    </Form.Item>
                  </Col> */}
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Facturar Creditos">
                      {getFieldDecorator('invoiceCredit', {
                        initialValue: systemConfig.invoiceCredit,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      hasFeedback
                      label="Conceptos maximos por factura"
                    >
                      {getFieldDecorator('maxConceptsNumber', {
                        initialValue: systemConfig.maxConceptsNumber,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa un minimo de conceptos',
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (value < 0) {
                                callback('El valor no puede ser negativo');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      })(<InputNumber size="large" placeholder="Conceptos" />)}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Configuracion de plataforma
                  </h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Activar Registro de clientes">
                      {getFieldDecorator('userRegister', {
                        initialValue: systemConfig.userRegister,
                        valuePropName: 'checked',
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item
                      help="RFC separado por coma"
                      label="RFC al que no se envian correos"
                    >
                      {getFieldDecorator('rfcEmailReject', {
                        initialValue: systemConfigInvoice.rfcEmailReject,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa un minimo un RFC',
                          },
                        ],
                      })(<Input size="large" placeholder="RFC" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 18, offset: 1 }}
                    xl={{ span: 18, offset: 1 }}
                    xxl={{ span: 18, offset: 1 }}
                  >
                    <Form.Item label="URL del Aviso de privacidad">
                      {getFieldDecorator('privacityUrl', {
                        initialValue: settings.privacityUrl===''? "#": settings.privacityUrl.replace('http://', '').replace('https://', ''),
                        rules: [
                          {
                            required: true,
                            message: 'Escribe la url del aviso de privacidad',
                          },
                        ],
                      }) (<Input size="large" placeholder="URL" addonBefore={selectBefore} width="auto"/>)}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>

            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Configuración de recuperación de ventas
                  </h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 18, offset: 1 }}
                    xl={{ span: 18, offset: 1 }}
                    xxl={{ span: 18, offset: 1 }}
                  >
                    <Form.Item label="Periodo en el cual se buscarán ventas/facturas 'perdidas' que no cumplieron su proceso de facturación">
                    {getFieldDecorator('timeoutPeriod', {
                          initialValue: settings.timeOutSince!==''?[
                            this.convertHourToPeriod(settings.timeOutLapse),
                            this.convertHourToPeriod(settings.timeOutSince)
                          ]: [80, 90],
                          rules: [
                            {
                              required: true,
                              message:
                                'Debes establecer la periodicidad sobre la cual se buscarán ventas-facturas',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value[0] === value[1]) {
                                  callback('El valor incial y final del periodo no puede ser igual');
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<Slider
                        marks={marks}
                        step={10}
                        max={90}
                        tipFormatter={null}
                        range
                        included
                      />)}
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 18, offset: 1 }}
                    xl={{ span: 18, offset: 1 }}
                    xxl={{ span: 18, offset: 1 }}
                  >
                    
                    <Form.Item label="Indica cada cuantos minutos se realizara la comprobación">
                    {getFieldDecorator('cronTimeout', {
                        initialValue: settings.timeOutCron,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa cada cuanto tiempo deseas se realice esta comprobación',
                          },
                          {
                            pattern: RegExp('^[0-9]*$'),
                            message: 'Solo puedes ingresar numero enteros'
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (value < 1 || value > 59) {
                                callback('El valor debe estar entre 1 y 59 minutos');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      })(<InputNumber size="large" placeholder="Periodicidad"/>)}
                      <small>
                        *Esta configuración requere un reinicio del servidor
                        para ser aplicada
                      </small>
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>

            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>Configuracion de archivo zip</h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Seleccionar duracion maxima del zip">
                      {getFieldDecorator('maxZipTime', {
                        initialValue: moment(systemConfigInvoice.maxZipTimeHours + ":" + systemConfigInvoice.maxZipTimeMinutes, 'HH:mm'),
                        rules: [
                          {
                            required: true,
                            message: 'Debes establecer un minimo de tiempo',
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (moment(value).hours() === 0 && moment(value).minutes() === 0) {
                                callback("Debes establecer un minimo de tiempo");
                              }
                              else {
                                callback();
                              }
                            }
                          }
                        ],
                      })(<TimePicker format='HH:mm' />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item help="0 es sin limite" label="Selecciona el tamaño maximo del zip en Mb">
                      {getFieldDecorator('maxZipSize', {
                        initialValue: systemConfigInvoice.maxZipSize,
                        rules: [
                          {
                            required: true,
                            message: 'Ingresa un valor para el tamaño del zip',
                          },
                          {
                            validator: (rule, value, callback) => {
                              if (value < 0) {
                                callback('El valor no puede ser negativo para el tamaño del zip');
                              }
                              else {
                                callback();
                              }
                            }
                          }
                        ],
                      })(<InputNumber about="holi" size='large' placeholder="tamaño" />)}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>

            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>
                    Configuración de correo al cliente
                  </h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Email de atencion a cliente">
                      {getFieldDecorator('emailContact', {
                        initialValue: systemConfigInvoice.emailContact,
                      })(<Input size="large" placeholder="Email" />)}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <Form.Item label="Sitio web de atencion a cliente">
                      {getFieldDecorator('siteContact', {
                        initialValue: systemConfigInvoice.siteContact,
                      })(<Input size="large" placeholder="Sitio Web" />)}
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Row>
                <Col span={10}>
                  <h2 style={{ color: '#296FC4' }}>Configuracion de logs</h2>
                </Col>
                <Col span={14}>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <label>Borrar logs plataforma despues de</label>
                    <br />
                    <Col span={8}>
                      <Form.Item hasFeedback label="Años">
                        {getFieldDecorator('years', {
                          initialValue: years,
                          rules: [
                            {
                              required: true,
                              message: 'Ingresa un valor para los años',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value < 0) {
                                  callback(
                                    'El valor no puede ser negativo para los años',
                                  );
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<InputNumber size="small" placeholder="Años" />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item hasFeedback label="Meses">
                        {getFieldDecorator('months', {
                          initialValue: months,
                          rules: [
                            {
                              required: true,
                              message: 'Ingresa un valor para los meses',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value < 0) {
                                  callback(
                                    'El valor no puede ser negativo para los meses',
                                  );
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<InputNumber size="small" placeholder="Meses" />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item hasFeedback label="Dias">
                        {getFieldDecorator('days', {
                          initialValue: days,
                          rules: [
                            {
                              required: true,
                              message: 'Ingresa un valor para los dias',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value < 0) {
                                  callback(
                                    'El valor no puede ser negativo para los dias',
                                  );
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<InputNumber size="small" placeholder="Dias" />)}
                      </Form.Item>
                    </Col>
                  </Col>
                  <Col
                    xs={{ span: 23, offset: 1 }}
                    sm={{ span: 23, offset: 1 }}
                    md={{ span: 23, offset: 1 }}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                    xxl={{ span: 11, offset: 1 }}
                  >
                    <label>Borrar logs facturacion despues de</label>
                    <br />
                    <Col span={8}>
                      <Form.Item hasFeedback label="Años">
                        {getFieldDecorator('yearsInvoice', {
                          initialValue: yearsInvoice,
                          rules: [
                            {
                              required: true,
                              message: 'Ingresa un valor para los años',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value < 0) {
                                  callback(
                                    'El valor no puede ser negativo para los años',
                                  );
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<InputNumber size="small" placeholder="Años" />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item hasFeedback label="Meses">
                        {getFieldDecorator('monthsInvoice', {
                          initialValue: monthsInvoice,
                          rules: [
                            {
                              required: true,
                              message: 'Ingresa un valor para los meses',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value < 0) {
                                  callback(
                                    'El valor no puede ser negativo para los meses',
                                  );
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<InputNumber size="small" placeholder="Meses" />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item hasFeedback label="Dias">
                        {getFieldDecorator('daysInvoice', {
                          initialValue: daysInvoice,
                          rules: [
                            {
                              required: true,
                              message: 'Ingresa un valor para los dias',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (value < 0) {
                                  callback(
                                    'El valor no puede ser negativo para los dias',
                                  );
                                } else {
                                  callback();
                                }
                              },
                            },
                          ],
                        })(<InputNumber size="small" placeholder="Dias" />)}
                      </Form.Item>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className={'customer-card'}>
              <Col span={5} offset={19}>
                <Button
                  className="button-customer-serch-style"
                  block
                  size="large"
                  type="primary"
                  onClick={() =>
                    this.setState({ configModal: !this.state.configModal })
                  }
                >
                  {isFetching ? 'Cargando...' : 'Modificar configuracion'}
                </Button>
              </Col>
            </Card>
          </Form>
        </Card>
      </Spin>
    );
  };
}

const mapStateToProps = (states: RootState): StateProps => {
  return {
    isFetching: states.configurationState.isFetching,
    days: states.configurationState.days,
    months: states.configurationState.months,
    years: states.configurationState.years,
    daysInvoice: states.configurationState.daysInvoice,
    monthsInvoice: states.configurationState.monthsInvoice,
    yearsInvoice: states.configurationState.yearsInvoice,
    systemConfig: states.configurationState.systemConfig,
    systemConfigInvoice: states.configurationState.systemConfigInvoice,
    settings: states.configurationState.settings,
  };
};

const mapDispatchToProps = {
  getDayToDelete,
  getMonthToDelete,
  getYearToDelete,
  getDayToDeleteInvoice,
  getMonthToDeleteInvoice,
  getYearToDeleteInvoice,
  getSystemConfig,
  getSystemConfigInvoice,
  getSystemSettings,
  saveNewConfigurations,
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create()(Configuration));
