import {
  Form,
  Col,
  Input,
  Row,
  Select,
  Button,
  Divider,
  Card,
} from 'antd';
import React, { FormEvent } from 'react';
import {
  FormComponentProps,
  WrappedFormUtils,
  ValidationRule,
} from 'antd/lib/form/Form';
import { InvoiceRules as r } from './rulesFiscalData';
import { Invoice } from 'types/type';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import FiscalDataService from 'services/fiscalData';
const fiscalDataService = new FiscalDataService();

interface CounterProps extends FormComponentProps {
  showFiscalDataProvidedNext?: boolean;
  showFiscalDataProvidedPrev?: boolean;
  onFiscalDataProvidedPrevius?: (e: FormEvent, form: WrappedFormUtils) => void;
  onFiscalDataProvidedNext?: (e: FormEvent, form: WrappedFormUtils) => void;
  renderCancelButton: () => JSX.Element;
  customChildren?: () => JSX.Element;
  customButton?: (form: WrappedFormUtils<Invoice>) => JSX.Element;
  isFetching: boolean;
  invoice: Invoice;
}
type Props = CounterProps & FormComponentProps;

class FiscalDataForm extends React.Component<Props> {
  public state = {
    usoCFDI: [],
      regimenFiscal: [],
      formasPago: [],
  };
  componentDidMount() {
    fiscalDataService.fiscalDataList().then((response)=>{
      this.setState({
        usoCFDI: response.useCFDIS,
        regimenFiscal: response.regimenFiscals,
        formasPago: response.paymentCode
      });
    });
  }

  private formImputItemRender = (
    invoiceItem: string,
    label: string,
    name: string,
    placeholder: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item hasFeedback label={label}>
          {this.props.form.getFieldDecorator(name, {
            initialValue: invoiceItem,
            rules: rule,
          })(<Input size="large" placeholder={placeholder} />)}
        </Form.Item>
      </Col>
    );
  };
  private formImputItemRenderSmall = (
    invoiceItem: string,
    label: string,
    name: string,
    placeholder: string,
    rule: ValidationRule[],
  ): JSX.Element => {
    return (
      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
        <Form.Item hasFeedback label={label}>
          {this.props.form.getFieldDecorator(name, {
            initialValue: invoiceItem,
            rules: rule,
          })(<Input size="large" placeholder={placeholder} />)}
        </Form.Item>
      </Col>
    );
  };
  public render = (): JSX.Element => {
    const {
      showFiscalDataProvidedNext = false,
      showFiscalDataProvidedPrev = true,
      onFiscalDataProvidedPrevius = () => {},
      onFiscalDataProvidedNext = () => {},
      renderCancelButton,
      customChildren = () => {},
      customButton = () => <div />,
      invoice,
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Row gutter={16}>
        <Form className="login-form">
          <Row gutter={16}>
            <h2>Datos Fiscales</h2>
            Los campos marcados con * son obligatorios
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
              <Card style={{ marginTop: 16, backgroundColor: '#F1F7FF' }}>
                {this.formImputItemRender(
                  invoice.name,
                  'Razón Social',
                  'name',
                  'Razón Social',
                  r.nameRule,
                )}
                {this.formImputItemRender(
                  invoice.rfc,
                  'RFC',
                  'rfc',
                  'Ingresa un RFC',
                  r.rfcRule,
                )}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item hasFeedback label="Uso de CFDI">
                    {getFieldDecorator('usoCfdi', {
                      initialValue: 'G03', // - Gastos en general
                      rules: r.usoCfdiRule,
                    })(
                      <Select showSearch size="large">
                        {this.state.usoCFDI!==undefined && this.state.usoCFDI.map((item: {code:string, description:string}): React.ReactNode=>(
                          <Select.Option key={item.code} value={item.code}>
                          <div id={item.code}>
                            {item.code.concat(" - ").concat(item.description)}
                          </div>
                        </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item hasFeedback label="Régimen fiscal">
                      {getFieldDecorator('fiscalRegime', {
                        initialValue: '',
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Ingresa un nombre de empresa',
                          },
                        ],
                      })(
                        <Select showSearch size="large">
                          {this.state.regimenFiscal!== undefined && this.state.regimenFiscal.map((item: {code:string, description:string}): React.ReactNode=>(
                            <Select.Option key={item.code} value={item.code}>
                            <div id={item.code}>
                              {item.code.concat(" - ").concat(item.description)}
                            </div>
                          </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} hidden={this.state.formasPago.length===0}>
                    <Form.Item hasFeedback label="Forma de Pago">
                      {getFieldDecorator('formaPago', {
                        rules: [
                          {
                            required: this.state.formasPago.length>0,
                            whitespace: true,
                            message: 'Ingresa la forma de Pago',
                          },
                        ],
                      })(
                        <Select showSearch size="large">
                          {this.state.formasPago!==undefined && this.state.formasPago.map((item: {code:string, description:string}): React.ReactNode=>(
                            <Select.Option key={item.code} value={item.code}>
                            <div id={item.code}>
                              {item.code.concat(" - ").concat(item.description)}
                            </div>
                          </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
              <Card style={{ marginTop: 16, backgroundColor: '#F1F7FF' }}>
                {this.formImputItemRender(
                  invoice.street,
                  'Calle',
                  'street',
                  'Ingresa una Calle',
                  r.streetRule,
                )}
                {this.formImputItemRenderSmall(
                  invoice.externalNumber,
                  'Número exterior',
                  'externalNumber',
                  'Ingresa un Número exterior',
                  r.externalNumberRule,
                )}
                {this.formImputItemRenderSmall(
                  invoice.internalNumber,
                  'Número interior',
                  'internalNumber',
                  'Ingresa un Número interior',
                  r.internalNumberRule,
                )}
                {this.formImputItemRenderSmall(
                  invoice.zipCode,
                  'Código postal',
                  'zipCode',
                  'Ingresa un Código postal',
                  r.zipCodeRule,
                )}
                {this.formImputItemRender(
                  invoice.colony,
                  'Colonia',
                  'colony',
                  'Ingresa una Colonia',
                  r.colonyRule,
                )}
                {this.formImputItemRenderSmall(
                  invoice.street,
                  'Ciudad',
                  'city',
                  'Ingresa una Ciudad',
                  r.cityRule,
                )}
                {this.formImputItemRenderSmall(
                  invoice.municipality,
                  'Municipio',
                  'municipality',
                  'Ingresa un Municipio',
                  r.municipalityRule,
                )}
                {this.formImputItemRenderSmall(
                  invoice.state,
                  'Estado',
                  'state',
                  'Ingresa un Estado',
                  r.stateRule,
                )}
              </Card>
            </Col>
            <p />
            <Col />
          </Row>
        </Form>
        {customChildren()}
        <Col span={24} className="align-center-col">
          <Divider />
          {showFiscalDataProvidedPrev && (
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Button
                type="primary"
                id="previus_fiscal_data_button"
                onClick={(e): void =>
                  onFiscalDataProvidedPrevius(e, this.props.form)
                }
              >
                <ArrowLeftOutlined />
                Anterior
              </Button>
            </Col>
          )}
          {showFiscalDataProvidedNext && (
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Button
                type="primary"
                id="next_fiscal_data_button"
                onClick={(e): void =>
                  onFiscalDataProvidedNext(e, this.props.form)
                }
              >
                Siguiente <ArrowRightOutlined  />
              </Button>
            </Col>
          )}
          {!showFiscalDataProvidedNext && (
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              {customButton(this.props.form)}
            </Col>
          )}
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            {renderCancelButton()}
          </Col>
        </Col>
      </Row>
    );
  };
}
export default Form.create<CounterProps>()(FiscalDataForm);