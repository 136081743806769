import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import {
  Pageable,
  User,
  Credentials,
  NewPassword,
  Permission,
  FiscalData,
  FiscalDataFilters,
  FiscalCatalogList,
} from '../../types/type';

class FiscalDataService extends Service {
  public getAll = (filters: FiscalDataFilters): Promise<FiscalData[]> =>
    axios
      .get(createCoreServiceUrl('fiscalData'), {
        params: filters
      })
      .then((response: AxiosResponse<FiscalData[]>): FiscalData[] => response.data);

  public getFiscalData = (userId: string): Promise<FiscalData> =>
    axios
      .get(createCoreServiceUrl(`fiscalData/${userId}`))
      .then((response: AxiosResponse<FiscalData>): FiscalData => response.data);

  public deleteFiscalData = (id: string): Promise<FiscalData> =>
    axios
      .delete(createCoreServiceUrl(`fiscalData/${id}`))
      .then((response: AxiosResponse<FiscalData>): FiscalData => response.data);

  public authenticateUser = (credentials: Credentials): Promise<string> =>
    axios
      .post(createCoreServiceUrl('users/token'), credentials)
      .then((response: AxiosResponse<string>): string => response.data);

  public authenticateCustomer = (credentials: Credentials): Promise<string> =>
    axios
      .post(createCoreServiceUrl('customer/token'), credentials)
      .then((response: AxiosResponse<string>): string => response.data);

  public paginatedFiscalData = (page: number, filters: FiscalDataFilters): Promise<Pageable> =>
    axios
      .get(createCoreServiceUrl(`fiscalData/page/${page}`), {
        params: filters
      })
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public searchFiscalDataByRFC = (page: number, filters: any): Promise<FiscalData[]> =>
      axios
        .get(createCoreServiceUrl(`fiscalDataByRFC`), {
          params: filters
        })
        .then((response: AxiosResponse<FiscalData[]>): FiscalData[] => response.data);

  public permissionList = (): Promise<Permission[]> =>
    axios
      .get(createCoreServiceUrl('users/permissions'))
      .then(
        (response: AxiosResponse<Permission[]>): Permission[] => response.data,
      );

  public save = (fiscalData: FiscalData): Promise<FiscalData> =>
    axios
      .post(createCoreServiceUrl('fiscalData'), fiscalData)
      .then((response: AxiosResponse<FiscalData>): FiscalData => response.data);

  public updatePassword = (user: NewPassword): Promise<string> =>
    axios
      .post(createCoreServiceUrl('users/passwordupdate'), user)
      .then((response: AxiosResponse<string>): string => response.data);

  public activateUserById = (userId: string): Promise<Pageable> =>
    axios
      .post(createCoreServiceUrl(`users/${userId}/activate`))
      .then((response: AxiosResponse<Pageable>): Pageable => response.data);

  public deactivateUserById = (userId: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/${userId}/deactivate`))
      .then((response: AxiosResponse<User>): User => response.data);

  public trysendpassword = (userEmail: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/trysendpassword/${userEmail}`))
      .then((response: AxiosResponse<User>): User => response.data);

  public passwordrecovery = (userEmail: string): Promise<User> =>
    axios
      .post(createCoreServiceUrl(`users/passwordrecovery/${userEmail}`))
      .then((response: AxiosResponse<User>): User => response.data);

  public fiscalDataList = (): Promise<FiscalCatalogList>  => 
    axios.get(createCoreServiceUrl((`catalog/list`)))
      .then((response: AxiosResponse<FiscalCatalogList>): FiscalCatalogList => response.data)
}

export default FiscalDataService;