/* 
 *    __________ ______ Copyright (C) Smart Software Factory SA de CV
 *   / ___/ ___// ____/ All Rights Reserved
 *   \__ \__ \ / /_     Unauthorized copying of this file,
 *  ___/ /__/ / __/     via any medium is strictly prohibited
 * /____/____/_/        Proprietary and confidential
 * 
 * @Author: Brandon Leon 
 * @Date: 2020-10-13 17:39:50 
 * @Last Modified by: Brandon Leon
 * @Last Modified time: 2020-10-13 17:53:48
 */
import axios, { AxiosResponse } from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../commons/services';
import { RevisionTotales } from '../../types/type';
import moment from 'moment';

class RevisorService extends Service {
    public salestotalByDate = (
        initDate: Date,
        endDate: Date,
        useCut: boolean,
    ): Promise<RevisionTotales> =>
        axios
            .get(
                createCoreServiceUrl(
                    'revisor/day' +
                    `?initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
                    `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
                    `&useCut=${useCut}`
                ),
            )
            .then(
                (response: AxiosResponse<RevisionTotales>): RevisionTotales =>
                    response.data,
            );

    public initProcess = (
        litros: number,
        total: number,
        litros_d: number,
        total_d: number,
        litros_an: number,
        total_an: number,
        initDate: Date,
        endDate: Date,
        csv: string,
        percent: number,
        useCut: boolean,
    ): Promise<RevisionTotales> =>
        axios
            .post(
                createCoreServiceUrl(
                    'revisor/process' +
                    `?litros=${litros}` +
                    `&total=${total}` +
                    `&litrosD=${litros_d}` +
                    `&totalD=${total_d}` +
                    `&litrosAn=${litros_an}` +
                    `&totalAn=${total_an}` +
                    `&initDate=${moment(initDate).format('YYYY-MM-DD HH:mm:ss')}` +
                    `&endDate=${moment(endDate).format('YYYY-MM-DD HH:mm:ss')}` +
                    `&csv=${csv}` +
                    `&percent=${percent}` +
                    `&useCut=${useCut}`
                ),
            )
            .then(
                (response: AxiosResponse<RevisionTotales>): RevisionTotales =>
                    response.data,
            );
    public getRevisorExcel = (
        date: { initDate: Date, endDate: Date},
    ): Promise<void> =>
        axios
            .get(
                createCoreServiceUrl(
                    'revisor/getExcel' +
                    `?initDate=${moment(date.initDate).format('YYYY-MM-DD HH:mm:ss')}`)+
                    `&endDate=${moment(date.endDate).format('YYYY-MM-DD HH:mm:ss')}`
            )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  `Revisor día ${moment(date.initDate).format('YYYY-MM-DD')}.csv`,
                );
                document.body.appendChild(link);
                link.click();
              });
}

export default RevisorService;
