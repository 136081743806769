import { AnyAction } from 'redux';
import UserService from '../../../../services/user';
import { parseApiError } from '../../../../commons/error';
import { notification } from 'antd';
import { User, Permission, Customer } from '../../../../types/type';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { initUser, initCustomer } from 'commons/initTypes';

const userService = new UserService();

type ITA = ThunkAction<Promise<void>, {}, {}, AnyAction>;
type IDA = ThunkDispatch<{}, {}, AnyAction>;

export interface SetAction {
  type: 'SET';
  usersList: {};
}
export interface SetPermissionAction {
  type: 'SET_PERMISSION';
  permissionList: Permission[];
}
export interface SetCustomerAction {
  type: 'SET_CUSTOMER';
  customer: Customer;
}
export interface SaveAction {
  type: 'SAVE';
  usersaved: User;
}
export interface UpdateAction {
  type: 'UPDATE';
  userupdate: {};
}

export type Action = SetAction;

export const set = (usersList: {}): SetAction => {
  return { type: 'SET', usersList };
};
export const setPermission = (
  permissionList: Permission[],
): SetPermissionAction => {
  return { type: 'SET_PERMISSION', permissionList };
};
export const setCustomer = (customer: Customer): SetCustomerAction => {
  return { type: 'SET_CUSTOMER', customer };
};

export const usersSave = (usersaved: User): SaveAction => {
  return { type: 'SAVE', usersaved };
};
export const updateUser = (userupdate: {}): UpdateAction => {
  return { type: 'UPDATE', userupdate };
};
export const cleanRedux = (): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      dispatch(setPermission([]));
      dispatch(set({}));
      dispatch(usersSave(initUser));
      dispatch(updateUser({}));
      dispatch(setCustomer(initCustomer));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const getPermissions = (): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const permissionResponse = await userService.permissionList();
      dispatch(setPermission(permissionResponse));
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};

export const getUserAction = (userId: string): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const response = await userService.getUser(userId);
      dispatch(usersSave(response));
    } catch (error) {
      notification.error(parseApiError(error));
    }
  };
};

export const saveuser = (user: User, getUserList: boolean = true): ITA => {
  return async (dispatch: IDA): Promise<void> => {
    try {
      const responseuser = await userService.save(user);
      dispatch(usersSave(responseuser));
      notification.success({
        description: 'Usuario registrado exitósamente, revisa tu correo para activar tu cuenta.',
        duration: 3,
        message: 'Éxito',
      });
      if(getUserList){
        const responsepage = await userService.usersList(1);
        dispatch(set(responsepage));
      }
    } catch (err) {
      notification.error(parseApiError(err));
    }
  };
};
